import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { Job, DemandType, PEFAJob } from '../shared/shared-component';


@Injectable({
  providedIn: 'root'
})
export class PEFAJobService {
  private client: HttpClient;
  private baseURL: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }

  public saveJob(id: string, job: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/PEFARequests/' + id + '/PEFAJob/', job).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getPefaJob(id: string): Observable<PEFAJob> {
    return this.client.get<PEFAJob>(this.baseURL + 'api/PEFARequests/' + id + '/PEFAJob/').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public hasJobDemands(id: string): Observable<boolean> {
    return this.client.get<boolean>(this.baseURL + 'api/Jobs/HasDemands/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



  private handleError(error) {
    let errorMessage = '';
    //let errors: any = JSON.stringify(error.json());
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
    }
    this.messageService.add(errorMessage)
    return throwError(errorMessage);
  };
}
