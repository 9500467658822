import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";

import moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

@Component({
  selector: 'dialog-modal-pefa-invoice',
  templateUrl: 'invoice-pefa-dialog.component.html',
})
export class PefaInvoiceDialog implements OnInit {

  public invoiceForm = new FormGroup({
    PefaID: new FormControl('', Validators.required),
    BookingID: new FormControl(''),
    InvoiceRef: new FormControl('', Validators.required),
    InvoiceDate: new FormControl(moment(), Validators.required),
    PaymentParty: new FormControl('Client'),
    PaymentReference: new FormControl(''),
    Amount: new FormControl('0', Validators.required),
    Currency: new FormControl('AUS', Validators.required),
    PaymentType: new FormControl('', Validators.required),
    Reason: new FormControl(''),
    IsPartial: new FormControl(false)
  });

  public defaultAmount: string;
  public currency: string = "AUD";

  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<PefaInvoiceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public officeAdminService: OfficeAdminPEFAService) {
  }



  ngOnInit(): void {
    this.officeAdminService.calculatePEFAAmount(this.data.pefaId).subscribe(
      companyFees => {
        this.defaultAmount = companyFees.total.toFixed(2);
        this.currency = companyFees.currency;
        this.invoiceForm.patchValue({
          Amount: companyFees.total.toFixed(2),
          Currency: companyFees.currency,
          PefaID: this.data.pefaId,
          BookingID: this.data.bookingId
        }

        );
      }, error => console.error(error));
  };


  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(): void {
    this.officeAdminService.raisePEFAInvoice(this.invoiceForm.value).subscribe(
      () => this.dialogRef.close(true)
    );
    
  }
}
