<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <h1 mat-dialog-title>Add Note</h1>
    </div>
  </div>
</div>


<div mat-dialog-content>

  <form [formGroup]="noteForm">

    <input id="ID" class="form-control" type="hidden" formControlName="id">

    <div *ngIf="data.noteId" class="form-group row">
      <mat-form-field>

        <mat-label>
          Reason For Edit
        </mat-label>
        <input matInput type="text"
               formControlName="reason" required>
      </mat-form-field>
    </div>


    <div class="form-group row">
      <mat-form-field>
        <mat-label>Pre-defined Notes</mat-label>
        <mat-select (selectionChange)="updateContent($event)">
          <mat-option value="Follow up on Medical Clearance">Follow up on Medical Clearance</mat-option>
          <mat-option value="Contact Provider for appt">Contact Provider for appt</mat-option>
          <mat-option value="Contact Worker">Contact Worker</mat-option>
          <mat-option value="Follow up with Referrer">Follow up with Referrer</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width:500px">

        <mat-label>
          Content
        </mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  #content
                  cdkAutosizeMinRows="10"
                  class="form-control"
                  cols="100"
                  formControlName="content" required></textarea>
      </mat-form-field>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <mat-checkbox id="adminOnly" type="checkbox" formControlName="adminOnly" labelPosition="before">Admin Only</mat-checkbox>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <mat-checkbox id="important" type="checkbox" formControlName="important" labelPosition="before">Important</mat-checkbox>
      </div>
    </div>    <div class="form-group row">
      <div class="col-sm-3">
        <mat-checkbox id="firstContact" type="checkbox" formControlName="firstContact" labelPosition="before">First Contact</mat-checkbox>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <mat-checkbox #todo id="createToDo" type="checkbox" formControlName="createToDo" labelPosition="before">Create To Do Item</mat-checkbox>
      </div>
    </div>
    <div *ngIf="todo.checked" class="form-group row">
      <div class="col-sm-4">
        <mat-label>
          Due Date/Time
        </mat-label>
      </div>
      <div class="col-8">
        <mat-form-field>
          <input matInput id="dueDate" [ngxMatDatetimePicker]="picker" type="text" placeholder="appointmentDateTime" formControlName="dueDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker [showSpinners]="true" [stepMinute]="15" enableMeridian="true">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="todo.checked" class="form-group row">
      <div class="col-sm-2">
          <mat-label>Assign ToDo</mat-label>
        </div>
        <div class="col-sm-12">
          <button matTooltip="assign" mat-raised-button href="" (click)="openAssignPerson()"><mat-icon aria-hidden="false" aria-label="Assign">Assign</mat-icon></button>
        </div>
      </div>
  </form>
</div>

<div mat-dialog-actions style="margin-top: 2rem;">
        <button mat-raised-button color="primary" (click)="onNoClick(false)">Cancel</button>
        <!--<button mat-raised-button color="warn" [mat-dialog-close]="reason" [disabled]="false" cdkFocusInitial (click)="onYesClick()">Cancel booking</button>-->
        <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="onSubmit()" [disabled]="!noteForm.valid">Save Note</button>
</div>
