import { Component, OnInit, Inject, ViewChild, Input, EventEmitter, Output } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MedicalClearance, PEFARequest, JOBFIT_DATE_FORMAT } from "../shared/shared-component";
import { MedicalClearanceService } from "./medicalclearance.service";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { AddMedicalClearanceDialog } from "./add-medical-clearance-dialog.component";


@Component({
  selector: 'app-medical-list',
  templateUrl: './medical-list.component.html'
})

export class MedicalListComponent {

  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  @Input()
  public pefaRequest: PEFARequest;

  public dataSource: MatTableDataSource<MedicalClearance> = new MatTableDataSource<MedicalClearance>();

  private _clearances: MedicalClearance[] = [];

  @Output() changed = new EventEmitter<boolean>();


  displayedColumns = [
    "condition",
    "otherInfo",
    "status",
    "outcome",
    "outcomeNotes",
    "createdDate",
    "outcomeDate",
    "doctor",
    "attachment"

  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //Modal

  constructor(public medicalService: MedicalClearanceService,
    public pefaService: PEFARequestService,
    private dialog: MatDialog
  ) {

  }


  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pefaService.getPEFAClearances(this.pefaRequest.id).subscribe(response => {
      this._clearances = response;
      this.dataSource.data = this._clearances;
    }
    );
  }

  public get medicals() {
    return this._clearances;
  }

  public delete(id: string) {
    this.medicalService.delete(id, this.pefaRequest.id).subscribe(result => {
      this.dataSource.data.splice(this.dataSource.data.findIndex(r => r.id === id));
      this._clearances.splice(this.dataSource.data.findIndex(r => r.id === id));
      this.changed.emit();
    });
  }

  public edit(id: string) {
    const dialogRef = this.dialog.open(AddMedicalClearanceDialog, {
      data: {
        clearanceId : id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }



  public complete(clearance: MedicalClearance) {
    clearance.status = "COMPLETED";
    clearance.outcome = "DECLINED";
    if (clearance.doctor && !clearance.doctor.id) {
      delete clearance.doctor
    }

    this.medicalService.saveMedicalClearance(clearance.id, clearance).subscribe(result => {
      this.pefaService.getPEFAClearances(this.pefaRequest.id).subscribe(response => {
        this._clearances = response;
        this.dataSource.data = this._clearances;
        this.changed.emit();
      }
      );

    });
  }
}
