import { Component, Input, AfterViewInit, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { MessageService } from '../message/message.service';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { AddNewPerson } from './person/person-dialog.component';
import { Company, Person, PersonRelationship } from './shared-component';
import { SharedService } from './shared.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-relationship-dialog',
  templateUrl: './relationship-dialog.component.html',
})
export class RelationshipDialogComponent implements OnInit {
  companies: Company[];
  filteredCompanies: Company[];
  people: Person[];
  dialog: MatDialog;
  company: Company;
  filteredPeople: Person[];
  relationshipId: string;
  originalPersonId: string;
  originalCompanyId: string;
  personId: string;
  companyId: string;
  relationship: PersonRelationship;
  relationshipForm = 
    new FormGroup({
      type: new FormControl('', Validators.required),
      status: new FormControl('ACTIVE', Validators.required),
      startDate: new FormControl(moment(), Validators.required),
      endDate: new FormControl(''),
      personID: new FormControl('', Validators.required),      
      companyID: new FormControl('', Validators.required),
  })
  subject: Subject<string> = new Subject();
  searchTerm: string = '';

  constructor(public dialogRef: MatDialogRef<RelationshipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private officeAdminService: OfficeAdminPEFAService, private messageService: MessageService, public personService: SharedService) {    
  }

  ngOnInit() {


    this.relationship = this.data.relationship
    this.personId = this.data.personId;
    this.companyId = this.data.companyId;
    this.originalPersonId = this.data.personId;
    this.originalCompanyId = this.data.companyId;

    var type = this.data.peopleType;

    if (this.personId) {
      this.officeAdminService.getAllCompany().subscribe(res => {
        this.companies = res;
        this.filteredCompanies = res;
      });
      this.relationshipForm.get("personID").setValue(this.personId);
    }

    if (this.companyId) {
      if (type && type == 'ASSESSOR') {
        this.officeAdminService.getAllAssessors().subscribe(results => {
          this.people = results;
          this.filteredPeople = results;
        })
      } else {
        this.personService.getPeopleLite('').subscribe(response => {
          this.people = response;
          this.filteredPeople = response;
        });
      }
      this.relationshipForm.get("companyID").setValue(this.companyId);
    }

    if (this.relationship) {
      this.relationshipForm.patchValue(this.relationship);
    }

    this.subject.pipe(debounceTime(400),
    ).subscribe(term => {
      this.searchTerm = term;
      // console.log(this.searchTerm);
    });
  }


  saveRelationship() {

    if (this.relationship) {
      this.officeAdminService.saveRelationship(this.relationship.id, this.relationshipForm.value).subscribe(res => {
        this.messageService.add("Relationship Updated");
        this.dialogRef.close({ outcome: true, relation: res });
      })
    } else {
      this.officeAdminService.addRelationship(this.relationshipForm.value).subscribe(res => {
        this.messageService.add("Relationship added");
        console.log(JSON.stringify(res));
        this.dialogRef.close({ outcome: true, relation: res});
      })

    }
  }





  displayName(company: Company): string {
      return company?company.name : '';
  }


  displayPersonName(person: Person): string {
    return person ? person.firstName + ' ' + person.lastName : '';
  }


  onCompanyKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredCompanies = this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);

    if (this.filteredCompanies.length == 1 && filterValue == this.filteredCompanies[0].name.toLowerCase()) {
      let companyId = this.filteredCompanies[0].id;
      this.relationshipForm.patchValue({
        companyID: companyId
      });
    } else {
      this.relationshipForm.patchValue({
        companyID: 0
      });
    }
  }


  setCompanyFormData($event: MatAutocompleteSelectedEvent) {
    let companyId = $event.option.value.id;
    this.relationshipForm.patchValue({
        companyID: companyId      
    });
  }


  onPersonKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.subject.next(filterValue);    

    this.filteredPeople = this.people.filter(option => ((option.firstName && option.firstName.toLowerCase().indexOf(filterValue)) >= 0 || (option.lastName && option.lastName.toLowerCase().indexOf(filterValue) >= 0)));

    if (this.filteredPeople.length == 1 && filterValue == (this.filteredPeople[0].firstName.toLowerCase() + ' ' + this.filteredPeople[0].lastName.toLowerCase())) {
      let personId = this.filteredPeople[0].id;
      this.relationshipForm.patchValue({
        personID: personId
      });
    } else {
      this.relationshipForm.patchValue({
        personID: 0
      });
    }
  }


  setPersonFormData($event: MatAutocompleteSelectedEvent) {
    let personId = $event.option.value.id;
    this.relationshipForm.patchValue({
      personID: personId
    });
  }
  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  openAddPerson() {
    const dialogRef = this.dialog.open(AddNewPerson, {
      data: {
        companyId: this.company.id,
      }
    });
  }
}
