    <mat-label>
      Assign To
    </mat-label>
    <div class="form-row">
      <div class="col form-group">
        <mat-form-field>
          <input id="person" type="text" placeholder="Type or Select a Person" matInput [matAutocomplete]="autoPerson" (keyup)="onPersonKey($event)" required>
          <mat-autocomplete #autoPerson="matAutocomplete" [displayWith]="displayPersonName" (optionSelected)="setPersonData($event)">
            <mat-option *ngFor="let person of filteredPeople" [value]="person">
              {{person.firstName}} {{person.lastName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <mat-dialog-actions>
      <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onClose(false)">Close</button>
      <button mat-raised-button color="warn" style="margin-top: 1rem;" mat-button (click)="assignTodo(true)">Save</button>
    </mat-dialog-actions>
