<div class="mat-elevation-z8 col-md-12" *ngIf="!loading">
  <form [formGroup]="JobForm" (ngSubmit)="saveChanges()">

    <h2>Job Details</h2>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label for="name">Name</mat-label>
          <input matInput type="text" formControlName="name" id="input-company-name" required />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label for="description">Description</mat-label>
          <input matInput type="text" formControlName="description">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-checkbox formControlName="isActive" labelPosition="before">Active</mat-checkbox>
      </div>
    </div>     

    <div class="row" style="margin-top: 5px;">
      <div class="col-2">
        Source *
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-select formControlName="source" (selectionChange)="onSourceChanged()" required>
            <mat-option [value]="1">Customer JTA/PDA confirmed</mat-option>
            <mat-option [value]="2">Customer supplied</mat-option>
            <mat-option [value]="3">Copied without changes</mat-option>
            <mat-option [value]="4" disabled>JFSI Supplied</mat-option>
            <mat-option [value]="5" disabled>JHG Supplied</mat-option>
            <!-- <mat-option [value]="6" disabled>JobFit System database</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field *ngIf="showSourceNotes">
          <mat-label for="sourceNotes">Job copied from?</mat-label>
          <input matInput type="text" formControlName="sourceNotes">
        </mat-form-field>
      </div>
    </div>    

    <div class="row" style="margin-top: 5px;">
      <div class="col-2" formGroupName="owner">
        <mat-label for="name">Owner</mat-label>
        <input id="id" type="hidden" formControlName="id">
      </div>

      <div class="col-10">
        <span>
          <a *ngIf="job.owner != null" [routerLink]="['/office-company-detail', job.owner.id]">{{job.owner.name}}</a>
          <mat-label *ngIf="job.owner == null">Not Selected</mat-label>
        </span>&nbsp;
        <button (click)="changeCompany()" mat-raised-button class="mat-accent" type="button">Select Company</button>
      </div>
    </div>

    <div *ngIf="job != null && job.owner != null && job.owner.jodCompanyId != null && isStaunchAdmin" class="row" style="margin-top: 5px;">
      <div class="col-2" formGroupName="owner">        
        <mat-label for="name">JOD Job:</mat-label>
      </div>
      <div class="col-10">
        <span *ngIf="jodJob != null"><a href="https://jobfitsystem.staunch.io/jobs/jobs-details/{{jodJob.Id}}"><img src="/assets/jod.png" style="height: 24px;"/> {{jodJob.Name}}</a> <span>({{jodJob.CompanyName}})</span></span>
        <span *ngIf="jodJob == null">None</span>
        &nbsp;&nbsp;<button (click)="changeJOD()" mat-raised-button class="mat-accent" type="button">Select JOD Job</button>
        <div *ngIf="jobsLinked != null && jobsLinked.length > 0">
          <div>Also linked:</div>
          <ul>
            <li *ngFor="let job of jobsLinked;"><a [routerLink]="['/office-job-detail', job.id]"><img src="/assets/console.svg" style="height: 24px;"/> {{job.owner.name}} / {{job.name}}</a></li>
          </ul>
        </div>
        <br/>
      </div>
    </div>   
    
    <div class="row" style="margin-top: 5px;">
      <div class="col-6" *ngIf="job.createdByUser">
        <mat-form-field>
          <mat-label>Created By</mat-label>
          <input matInput type="text" readonly="readonly" [value]="job.createdByUser" />  
          <mat-hint align="end">{{ job.createdByDate | date:dateTimeFormat }}</mat-hint>            
        </mat-form-field> 
      </div>
      <div class="col-6" *ngIf="job.updatedByUser">
        <mat-form-field>
          <mat-label>Updated By</mat-label>
          <input matInput type="text" readonly="readonly" [value]="job.updatedByUser" />  
          <mat-hint align="end">{{ job.updatedByDate | date:dateTimeFormat }}</mat-hint>            
        </mat-form-field> 
      </div>      
    </div>   
    
    <div class="row">
      <div class="col-12">
        <button mat-raised-button class="mat-primary" type="submit">Save Changes</button>
      </div>
    </div>

    <div class="container-fluid" style="margin-top: 15px;">
      <div *ngIf="job != null && jodJob != null" class="alert alert-secondary">
        <img src="/assets/jod.png" style="height: 24px;"/>&nbsp;&nbsp;Postural Tolerance and Material Handling cannot be updated because this job is linked to JOD.
        <div *ngIf="job.jodJobLastSync == null" style="margin-top: 10px;">
          <mat-icon style="color: #d08c33; position: relative; top: 3px; margin-left: 5px;">warning</mat-icon>Job has not been synced with JOD.
        </div>
        <div *ngIf="job.jodJobLastSync != null" style="margin-top: 10px;">
          <mat-icon style="color: #33d03b; position: relative; top: 3px; margin-left: 5px;">check</mat-icon>Job last synced with JOD [{{job.jodJobLastSync | date:dateFormat}}].
        </div>        
      </div>

      <app-demand-view [demands]="job.demands" [companyRules]="jodCompanyRules" [demandsForm]="JobForm" [isLinkedToJod]="jodJob != null"></app-demand-view>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-label for="description">Notes</mat-label>
      </div>
    </div>
    <div class="row">
      <ol>
        <li *ngFor="let note of job.notes; let i=index">
          {{note}}
        </li>
      </ol>
    </div>
    <button mat-raised-button class="mat-primary" type="submit">Save Changes</button>
  </form>
</div>
