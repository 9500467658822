  <div class="row">
    <div class="col text-center">
      <h1 mat-dialog-title>Upload New Medical Clearance</h1>
    </div>
  </div>

  <div mat-dialog-content class="container">
    <form [formGroup]="medicalClearanceForm">
      <div>
        <mat-label>Select Conditions for this clearance</mat-label>
        <div class="text-left" formArrayName="clearances">
          <div class="row">
            <div class="col-3">

            </div>
            <div class="col-2">
              <mat-label>MC Advice</mat-label>
            </div>
            <div class="col-2">
              <mat-label>MC Advice Notes</mat-label>
            </div>
            <div class="col-2">
              <mat-label>Condition Notes</mat-label>
            </div>
            <div class="col-2">
              <mat-label>Outcome Date</mat-label>
            </div>
          </div>

          <div class="row" *ngFor="let clearance of clearances.controls; let j=index" [formGroupName]="j">
            <div class="col-3">
              <mat-checkbox formControlName="selected"
                            [labelPosition]="after">
                {{clearance.controls.condition.value}}
              </mat-checkbox>
              <input type="hidden" formControlName="id" />
            </div>
            <div class="col-2">
              <mat-form-field style="display:block">
                <mat-label>Outcome</mat-label>
                <mat-select formControlName="outcome">
                  <mat-option value="CLEARED">Cleared</mat-option>
                  <mat-option value="RESTRICTED">Cleared with Restriction</mat-option>
                  <mat-option value="RESTRICTED_DATE">Cleared at Date</mat-option>
                  <mat-option value="REVIEWED">Not Cleared - With Review</mat-option>
                  <mat-option value="NOT_CLEARED">Not Cleared - No Review</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field style="display:block">
                <mat-label>Condition Notes</mat-label>
                <input matInput formControlName="otherInfo" />
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field style="display:block">
                <mat-label>MC Advice</mat-label>
                <input matInput formControlName="outcomeNotes" />
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field style="display:block">
                <mat-label>Review Date</mat-label>
                <input matInput formControlName="outcomeDate" [matDatepicker]="outcomePicker" />
                <mat-datepicker-toggle matSuffix [for]="outcomePicker"></mat-datepicker-toggle>
                <mat-datepicker #outcomePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <mat-divider></mat-divider>

        </div>
      </div>

      <div class="row">

        <div class="col text-center">
          <div style="margin-top: 1rem;">
            <input hidden #fileInput type="file" id="file">
            <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
            &nbsp;
            <mat-label *ngIf="fileInput.files[0]">{{fileInput.files[0].name}}</mat-label>

          </div>
        </div>

      </div>

    </form>

  </div>

      <mat-dialog-actions>
        <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Cancel</button>
        <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onSubmit(fileInput.files)" type="submit">Save</button>
      </mat-dialog-actions>
