<div *ngIf="loading == true">
  <div class="row">
    <div class="col-2 col-sm-4 col-md-4"></div>
    <div class="col-10 col-sm-8 col-md-8">
      <div class="progress-loader" style="overflow:visible; margin:0 auto;">
        <mat-progress-spinner [mode]="'indeterminate'" [diameter]="40">
        </mat-progress-spinner>
      </div>
    </div>
  </div>
</div>

<ng-template #NoCompanyToList>
  <p *ngIf="loading == false">There are no companies </p>
</ng-template>

<div *ngIf="companies.length > 0; else NoCompanyToList">

  <mat-toolbar class="mat-primary">Companies</mat-toolbar>
  <div style="margin: 10px 0 10px 0;">
    <a [routerLink]="['/office-company-detail']"><button mat-raised-button class="mat-accent" type="button">Add New Company</button></a>
  </div>  

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
  </mat-form-field>
  <mat-checkbox [(ngModel)]="active" (change)="toggleActive($event)">Show Active</mat-checkbox>

 

  <!--<p *ngIf=""><em>Loading...</em></p>-->

  <div class="mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"><span><a [routerLink]="['/office-company-detail', row.id]">{{ row.name}}</a></span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
        <mat-cell *matCellDef="let row"><span *ngIf="row.address else elseName">{{ row.address.address }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="abn">
        <mat-header-cell *matHeaderCellDef mat-sort-header>ABN</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.companyNumber }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.status }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span><a mat-raised-button matTooltip="Delete Company" style="text-decoration: none;" (click)="delete(row.id)"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></a></span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns2; sticky:true;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>

    </mat-table>
  </div>

  <ng-template #elseName>
    <span>&nbsp;</span>
  </ng-template>

  <!---->
</div>
