<div *ngIf="loading == true">
  <div class="progress-loader" style="overflow:visible; margin:0 auto;">
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="40">
    </mat-progress-spinner>
  </div>
</div>

<mat-toolbar class="mat-primary">Jobs</mat-toolbar>
<!--<p *ngIf=""><em>Loading...</em></p>-->

<div style="margin: 10px 0 10px 0;">
  <a [routerLink]="['/office-job-detail']"><button mat-raised-button class="mat-accent" type="button">Add New Job</button></a>
</div>

<div class="row">
  <div class="col-10">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">  
    </mat-form-field>    
  </div>
  <div class="col-2" style="margin-top: 10px;"><mat-checkbox [(ngModel)]="onlyActive" (change)="applyOnlyActive()">Only Active</mat-checkbox></div>
</div>

<div class="mat-elevation-z8">
  <mat-table #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
      <mat-cell *matCellDef="let row"><span><a [routerLink]="['/office-job-detail', row.id]">{{ row.name}}</a></span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.description else elseName">{{ row.description }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef mat-sort-header="companyName">Owner</mat-header-cell>
      <mat-cell *matCellDef="let row"><a [routerLink]="['/office-company-detail', row.companyID]">{{ row.companyName }}</a></mat-cell>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <mat-header-cell *matHeaderCellDef mat-sort-header="isActive">Active</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.isActive }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="isPublic">
      <mat-header-cell *matHeaderCellDef mat-sort-header="isPublic">Public</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.isPublic }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="isJobfitted">
      <mat-header-cell *matHeaderCellDef mat-sort-header="isJobfitted">Jobfitted</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.isJobfitted }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastModifiedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header="lastModifiedDate">Last Modified</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.lastModifiedDate | date:dateTimeFormat }}</span></mat-cell>
    </ng-container>    
    <mat-header-row *matHeaderRowDef="displayedColumns2; sticky:true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>

  </mat-table>
</div>

<ng-template #elseName>
  <span>&nbsp;</span>
</ng-template>

<!---->
<mat-paginator [length]="dataSource.data.length" [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]" [showFirstLastButtons]="true"></mat-paginator>
