import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Person } from "../shared/shared-component";
import { SharedService } from "./shared.service";


@Component({
  selector: 'app-person-dialog',
  templateUrl: './person-dialog.component.html'
})

export class PersonSelectorDialog {

  private _people: Person[] = [];
  public dataSource: MatTableDataSource<Person> = new MatTableDataSource<Person>();

  displayedColumns = [
    "firstName",
    "lastName",
    "type",
    "select",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //Modal
  constructor(public dialogRef: MatDialogRef<PersonSelectorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public personService: SharedService) {
    this.dataSource = new MatTableDataSource<Person>();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.data.type && this.data.type == "REPORT_WRITER") {
      this.personService.getReportWriters().subscribe(response => {
        this._people = response;
        this.dataSource.data = this._people;
      }
      );

    } else {
      this.personService.getPeople().subscribe(response => {
        this._people = response;
        this.dataSource.data = this._people;
      }
      );
    }
  }

  public get people() {
    return this._people;
  }

  public selectPerson(person: Person) {

    this.dialogRef.close({ "outcome": true, "person":person });

  }

  onCancelClick(): void {
    this.dialogRef.close({"outcome":false});
  }

  //Material Table
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
