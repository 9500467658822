<mat-expansion-panel>
  <mat-expansion-panel-header [ngClass]="{'highlight' : noteView.details.important}">
    <mat-panel-title>
      <span *ngIf="noteView.details.important">Important - </span><span [innerHTML]="noteView.headline"></span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  
  <mat-card>
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col note">
            <span [innerHTML]="noteView.details.content"></span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Important
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.important}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              By
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdBy?.userName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Created Date
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdDate | date:dateFormat}}</span>
          </div>
        </div>

      </div>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="editNote()" mat-raised-button type="button"><mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon></button>
      <button (click)="deleteNote()" mat-raised-button type="button"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></button>
    </mat-card-actions>
  </mat-card>

</mat-expansion-panel>
