import { Component, Inject, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { first  } from 'rxjs/operators';

@Component({
  selector: 'app-jod-settings',
  templateUrl: './jod-settings.component.html',
  styleUrls: ['./jod-settings.component.css']
})
export class JodSettingsComponent implements OnInit {
  public isStaunchAdmin: boolean = false;
  public jodSubs: any[];
  public jodJobs: any = {}; // dictionary of JOD jobs
  public jodJobsSync: any = {}; // dictionary of JOD jobs and if they have been synced
  public jodJobsDemands: any = {}; // dictionary of JOD jobs and their default demands
  public consoleJobs: any = {}; // dictionary of Console jobs by JOD job id
  public loadPercent: Number = 0;
  public selectedJodJob = null;
  public selectedJodDemands = null;
  public selectedConsoleJob = null;
  public selectedConsoleDemands = null;
  private _loading = false;

    async ngOnInit(): Promise<void> {
        this.authorizeService.getUser().subscribe(result => {
            this.isStaunchAdmin = result.profile.role.filter(r => r == "STAUNCH_ADMIN").length > 0;      
        });
        
        await this.officeAdminService.getJodSubscriptions().pipe(first()).toPromise().then(async subs => {
            this.jodSubs = subs;
            this.loadPercent = 100;
        });   
    } 

   async onLoadSubscription(sub) {
        if (this._loading) {
            return;
        }

        // console.log(sub);        
        let current = 0;
        this._loading = true;
        await this.officeAdminService.getConsoleCompanies(sub['Id']).pipe(first()).toPromise().then(async companies => {
            // console.log(companies);
            sub['companies'] = companies;            

            // only load jod jobs for subs that have connections
            if (companies.length > 0) {
                // build Jod Job dictionary
                await this.officeAdminService.getJodJobs(sub['Id']).pipe(first()).toPromise().then(jod_jobs => {
                    sub['jod_jobs'] = jod_jobs;
                    // for showing linked console jobs
                    sub['jod_jobs']['console_jobs'] = [];

                    for(let jod of jod_jobs) {                    
                        if (jod && jod['Id']) {
                            // console.log(jod);
                            this.jodJobs[jod['Id']] = jod['Name'];
                            this.jodJobsSync[jod['Id']] = false;
                            this.jodJobsDemands[jod['Id']] = null;
                        }                    
                    }
                });                
            }

            let total = companies.length;
            for(let company of companies) {                
                // console.log(company);
                this.loadPercent = (++current / total) * 100.0;

                await this.officeAdminService.getConsoleJobsLinkedToJod(company['id']).pipe(first()).toPromise().then(async jobs => {
                    // console.log(jobs);
                    company['jobs'] = jobs;
                    
                    // add to jod/console dictionary 
                    for(let job of jobs) {
                        if (!(job.jodJobId in this.consoleJobs)) {
                            this.consoleJobs[job.jodJobId] = [];                                
                        }
                        // console.log(job);
                        this.consoleJobs[job.jodJobId].push(job);
                    }

                    // determine if there has been a last sync on all console jobs
                    //console.log(this.consoleJobs);
                    for(let key in this.jodJobs) {
                        if (this.consoleJobs[key] && this.consoleJobs[key].length > 0) {
                            //console.log(this.consoleJobs[key]);

                            // TODO: if we need to to check to ptc mismatches, we may need this code
                            // if (this.jodJobsDemands[key] == null) {
                            //     await this.officeAdminService.getJodPefaTestCriteria(key).pipe(first()).toPromise().then(async res => {
                            //         this.jodJobsDemands[key] = res;   
                            //         //console.log(res);
                            //     });
                            // }

                            let allSynced = true;
                            for(let job of this.consoleJobs[key]) {
                                if (!job.jodJobLastSync) {
                                    allSynced = false;
                                    break;
                                }
                            }
                            this.jodJobsSync[key] = allSynced;
                        }
                    }                    
                    // console.log(this.jodJobsSync);
                    // console.log(this.consoleJobs);
                });
                await this._sleep();                        
            }

            this._loading = false;
        });        
    }

    async onSyncJob(jod_job) {
        //console.log(jod_job);
        this.jodJobsSync[jod_job.Id] = false;
        await this.officeAdminService.getJodPefaTestCriteria(jod_job.Id).pipe(first()).toPromise().then(async res => {
            let data = res;
            // console.log(data);
            await this.officeAdminService.syncPefaJobDemands(jod_job.Id, data).pipe(first()).toPromise().then(rowsAffected => {
                if (rowsAffected > 0) {
                    this.jodJobsSync[jod_job.Id] = true;
                }
            });
        });
    }

    async onSyncUsingJod() {
        if (!this.selectedJodJob) {
            return;
        }
        if (window.confirm(`All Console Jobs linked to [${this.selectedJodJob.Name}] will be updated with the [JOD set Job Demands].\n\nAre you sure?`)) {
            this.jodJobsSync[this.selectedJodJob.Id] = false;
            await this.officeAdminService.getJodPefaTestCriteria(this.selectedJodJob.Id).pipe(first()).toPromise().then(async res => {
                let data = res;
                // console.log(data);
                await this.officeAdminService.syncPefaJobDemands(this.selectedJodJob.Id, data).pipe(first()).toPromise().then(rowsAffected => {
                    if (rowsAffected > 0) {
                        this.jodJobsSync[this.selectedJodJob.Id] = true;
                        alert('Success :)');
                    }
                });
            });            
        }
    }

    async onSyncUsingConsole() {
        if (!this.selectedConsoleJob) {
            return;
        }
        let theJodJobName = this.jodJobs[this.selectedConsoleJob.jodJobId];
        if (theJodJobName != null) {
            if (window.confirm(`All Console Jobs linked to [${theJodJobName}] will be updated with the demands from [${this.selectedConsoleJob.owner.name} / ${this.selectedConsoleJob.name}].\n\nAre you sure?`)) {
                this.jodJobsSync[this.selectedConsoleJob.jodJobId] = false;
                let payload = {
                    companyId: 0,
                    jobName: '',
                    data: this.selectedConsoleDemands.data
                };
                await this.officeAdminService.syncPefaJobDemands(this.selectedConsoleJob.jodJobId, payload).pipe(first()).toPromise().then(rowsAffected => {
                    if (rowsAffected > 0) {
                        this.jodJobsSync[this.selectedConsoleJob.jodJobId] = true;
                        alert('Success :)');
                    }
                });
            }
        }
    }

    onSelectJob(jod_job, not_disabled) {
        if (not_disabled) {
            this.selectedJodJob = jod_job;
            //console.log(this.selectedJodJob);
            this.officeAdminService.getJodPefaTestCriteria(jod_job.Id).pipe(first()).toPromise().then(async res => {
                this.selectedJodDemands = res;
                //console.log(this.selectedJodDemands); 
            });

        } else {
            this.selectedJodJob = null;
        }
    }

    onSelectConsoleJob(console_job) {
        this.selectedConsoleJob = console_job;
        //console.log(this.selectedConsoleJob);
        this.officeAdminService.getConsolePefaTestCriteria(console_job.id).pipe(first()).toPromise().then(async res => {
            this.selectedConsoleDemands = res;
            //console.log(this.selectedConsoleDemands); 
        });        
    }

    _sleep() {
        return new Promise((resolve) => {setTimeout(() => {resolve(true)}, 250)});
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public officeAdminService: OfficeAdminPEFAService,
        private authorizeService: AuthorizeService,
    ) {
    }
}

