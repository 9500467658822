import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Job, JOBFIT_DATETIME_FORMAT, DemandType } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { MessageService } from '../message/message.service';
import { JobService } from './job.service';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { CompanySelectorDialog } from '../shared/company-dialog.component';
import { JodJobSelectorDialog } from '../shared/jod-job-dialog.component';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html'
})
export class JobDetailComponent implements AfterViewInit {
  public readonly dateTimeFormat = JOBFIT_DATETIME_FORMAT;
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;
  public job: Job;
  public jodJob: any = null;
  public jodCompanyRules: any = null;
  public jobsLinked: Job[] = null;
  public loading: boolean = true;
  public isStaunchAdmin: boolean = false;
  public showSourceNotes: boolean = false;
  public demandTypes: DemandType[];

  JobForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    jodLink: new FormControl(''),
    isActive: new FormControl(true),
    source: new FormControl(null, Validators.required),
    sourceNotes: new FormControl(null),
    isPublic: new FormControl(false),
    isJobfitted: new FormControl(false),
    jodJobId: new FormControl(null),
    owner: new FormGroup({
      id: new FormControl('')
    }),    
    demands: new FormArray([]),
  });

  ngAfterViewInit(): void {
    this.authorizeService.getUser().subscribe(result => {
      this.isStaunchAdmin = result.profile.role.filter(r => r == "STAUNCH_ADMIN").length > 0;      
    });

    if (this.route.snapshot.paramMap.get('id')) {
      this.jobService.getJob(this.route.snapshot.paramMap.get('id')).subscribe(results => {
        this.job = results;          
        this.jobService.getDemandTypes().subscribe(results => {
          this.demandTypes = results;
        });

        for (let entry of this.job.demands) {
          this.addSavedDemand();
        };

        if (!this.job.owner) {
          delete this.job.owner;
        }
        this.showSourceNotes = this.job.source == 3;
        this.JobForm.patchValue(this.job);
        
        if (this.job.jodJobId) {
          this.jobService.getJodJob(this.job.jodJobId).subscribe(results => {
            this.jodJob = results;
            //console.log('this.jodJob', this.jodJob);
            // get company rules
            this.jobService.getJodCompanyRules(this.jodJob.CompanyId).subscribe(results => {
              this.jodCompanyRules = results;
            });
          });

          this.jobService.getJobsLinked(this.job.jodJobId).subscribe(results => {
            //console.log(results);  
            if (results != null) {
              this.jobsLinked = results.filter(l => l.id != this.job.id);
            } else {
              this.jobsLinked = [];
            }            
          });          
        }

        this.loading = false;
      });
    } else {
      this.jobService.getDemandTypes().subscribe(results => {
        this.demandTypes = results;
        this.loading = false;
        this.job = this.JobForm.value;
      });
    }
  }

  sharedService: SharedService;

  messageService: MessageService;

  constructor(
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public jobService: JobService,
    private dialog: MatDialog,
    private authorizeService: AuthorizeService,
    private officeAdminService: OfficeAdminPEFAService
  ) {
    this.messageService = messageService;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  saveChanges() {
    if (this.JobForm.valid) {
      var jobValue = this.JobForm.value;

      jobValue.demands.forEach(function (entry) {
        if (entry.id == '') {
          delete entry.id;
        }
      });
  
      if (this.job.id) {
        this.jobService.saveJob(this.job.id, jobValue).subscribe(update => {
          this.jobService.getJob(this.route.snapshot.paramMap.get('id')).subscribe(results => {
            this.job = results;
            //this.JobForm.patchValue(results);
            this.loading = false;
            this.messageService.add("Job Updated");
          });
  
        }
        );
      } else {
        delete jobValue.id;
  
        this.jobService.addJob(jobValue).subscribe(update => {
          this.job = update;
          this.JobForm.patchValue(update);
          this.loading = false;
          this.router.navigate(['/office-job-detail', { id: update.id }])
            this.messageService.add("Job Added");
          });  
        }
    } else {
      // focus on invalid field
      const firstElementWithError = document.querySelector('.ng-invalid');
      if (firstElementWithError) { 
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      };      
    }
  }

  public changeCompany() {

    const dialogRef = this.dialog.open(CompanySelectorDialog, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        var company = result.company;
        this.job.owner = company;
        this.JobForm.patchValue(
          {
            owner: {
              id: company.id,
            },
          });
      }
    });

  }

  public changeJOD() {
    const dialogRef = this.dialog.open(JodJobSelectorDialog, {
      data: {
        companyId: this.job.owner.jodCompanyId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        if (result.job != null) {
          // save and reload
          result.job;
          this.job.jodJobId = result.job.Id;          
          this.JobForm.get('jodJobId').setValue(this.job.jodJobId);

          this.officeAdminService.linkJodJobs(this.job.id, result.job.Id).subscribe(result => {
            this.jobService.getJodJob(this.job.jodJobId).subscribe(results => {
              //console.log(results);
              this.jodJob = results;
            });
  
            this.jobService.getJobsLinked(this.job.jodJobId).subscribe(results => {              
              if (results != null) {
                this.jobsLinked = results.filter(l => l.id != this.job.id);
              } else {
                this.jobsLinked = [];
              }            
            });
          });
        } else {
          this.JobForm.get('jodJobId').setValue(null);
          this.jodJob = null;
          this.jobsLinked = null;
          this.officeAdminService.unlinkJodJobs(this.job.id).subscribe(result => {
            // console.log(result);
          });          
        }
      }
    });
  }

  get demands() {
    return this.JobForm.get('demands') as FormArray;
  }


  addDemand() {
    this.demands.push(
      new FormGroup({
        value: new FormControl('', Validators.required),
        frequency: new FormControl('', Validators.required),
        demandType: new FormGroup({
          id: new FormControl(''),
          name: new FormControl(''),
          type: new FormControl(''),
          group: new FormControl(''),
        })
      })
    );
  }

  public addSavedDemand() {
    this.demands.push(
      new FormGroup({
        id: new FormControl('', Validators.required),
        value: new FormControl('', Validators.required),
        frequency: new FormControl('', Validators.required),
        demandType: new FormGroup({
          id: new FormControl(''),
          name: new FormControl(''),
          type: new FormControl(''),
          group: new FormControl(''),
        })
      })
    );
  }

  onSourceChanged() {
    this.showSourceNotes = this.JobForm.controls['source'].value == 3;
  }
}

