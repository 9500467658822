<ng-template #NoCompanyToList>
  <p *ngIf="loading == false">There are no Reports</p>
</ng-template>

<div >

  <mat-toolbar class="mat-primary">
    Reports
  </mat-toolbar>

  <!--<p *ngIf=""><em>Loading...</em></p>-->
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
  </mat-form-field>
  <div class="mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"><span><a [routerLink]="['/report-detail', row.id]">{{ row.name}}</a></span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.description }}</span></mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
  </div>

  <ng-template #elseName>
    <span>&nbsp;</span>
  </ng-template>
  <!---->
</div>
<mat-paginator></mat-paginator>

