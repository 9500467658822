import { Component, OnInit, Inject, ViewChild, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable, MatSortModule } from "@angular/material/sort"
import moment from "moment";
import { ProviderService } from "../provider/provider.service";
import { Provider, Booking, BookingStatus, BookingType, JOBFIT_DATETIME_FORMAT } from "../../shared/shared-component";

@Component({
  selector: 'app-provider-booking-list',
  templateUrl: './provider-booking-list.component.html'
})
export class ProviderBookingListComponent implements OnInit {

  @Input() provider: Provider
  public loading: boolean = true;
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;
  private _appointments: Booking[] = [];
  public dataSource: MatTableDataSource<Booking>;
 

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;



  appointmentColumns = [    
    "assessor",
    "appointmentDateTime",
    "providerName",
    "bookingType",
    "status",   
  ];

  constructor(public providerBooking: ProviderService) {
    this.dataSource = new MatTableDataSource<Booking>();     
  }

  ngOnInit(): void {
    this.loadProviderBookings();
    this.dataSource.sort = this.sort;
    
  }

  public get bookings() {
    return this._appointments
  }
  loadProviderBookings() {

    this.providerBooking.getBookings(this.provider.id).subscribe(
      bookings => {
        this._appointments = bookings;
        this.dataSource.data = bookings;
        this.loading = false;
      }, error => console.error(error)
     );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }


}

