import { Component, OnInit } from '@angular/core';
import { MessageService } from './message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  constructor(public messageService: MessageService, private _snackBar: MatSnackBar) { }

  ngOnInit() {

    this.messageService.messages.subscribe(message => {
      this._snackBar.open(message, '', {
        duration: 2000,
        horizontalPosition: "right"
      });
    });

  }   

   

}
