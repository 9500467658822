<mat-toolbar *ngIf="!loading" class="mat-accent">{{company.name}}</mat-toolbar>
<form [formGroup]="companyForm" (ngSubmit)="saveChanges()">
  <div>
    <ul>
      <li class="mat-error" *ngFor="let error of errors">{{error}}</li>
    </ul>
  </div>
  <mat-tab-group dynamicHeight style="margin: 15px;">
    <mat-tab label="Details">
      <div class="form-row">
        <input type="hidden" formControlName="id" />

        <div class="col-md-2">
          <mat-label for="name" class="required">Name</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input id="name" matInput type="text" formControlName="name" required />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Company Number</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="companyNumber" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Account Email</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="accountEmail" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Phone Number</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="phoneNumber" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="Fax">Fax Number</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="faxNumber" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Client Consent Name (Legal Name)</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="clientConsentTxtName" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">

        <div class="col-md-2">
          <mat-label for="tier">Tier</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select formControlName="tier" required>
              <mat-option value="TIER 1">TIER 1</mat-option>
              <mat-option value="TIER 2">TIER 2</mat-option>
              <mat-option value="TIER 3">TIER 3</mat-option>
              <mat-option value="TIER 4">TIER 4</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="status">Status</mat-label></div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select type="text" formControlName="status" required>
              <mat-option value="PENDING">PENDING</mat-option>
              <mat-option value="ACTIVE">ACTIVE</mat-option>
              <mat-option value="INACTIVE">INACTIVE</mat-option>
              <mat-option value="ONHOLD">ON HOLD</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="name">Status Note</mat-label></div>
        <div class="col-md-4">
          <mat-form-field>
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutoSizeMaxRows="5"
                      formControlName="statusNote"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label class="col-form-label" style="vertical-align:top">Notes</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field style="display:inline">
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="5"
                      formControlName="notes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Office" style="margin: 15px;">
      <div formGroupName="address">
        <input type="hidden" formControlName="id" />

        <div class="form-row">
          <div class="col-md-2">
            <mat-label style="vertical-align:top">Address</mat-label>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <textarea matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="5"
                        formControlName="address"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-2">
            <mat-label >Region</mat-label>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <input matInput type="text" formControlName="region" />
            </mat-form-field>
          </div>


        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="company != null && company.id != '0'" label="People" class="container-fluid">
      <div style="margin: 15px 0 15px 0;">
        <button type="button" mat-raised-button class="mat-primary" (click)="openAddPerson()">Add New Person</button>&nbsp;&nbsp;
        <button type="button" mat-raised-button class="mat-primary" (click)="openRelationshipDialog()">Add Existing Person</button>
      </div>
      
      <ng-template #NoProviderToList>
        <p *ngIf="loading == false">There are no Relationships</p>
      </ng-template>

      <div *ngIf="company && company.people && company.people.length > 0; else NoProviderToList">
        <!--<p *ngIf=""><em>Loading...</em></p>-->
        <div class="mat-elevation-z8">
          <mat-table #table [dataSource]="peopleDataSource" matSort>
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header>id</mat-header-cell>
              <mat-cell *matCellDef>id</mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.person.firstName}} {{ row.person.lastName}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.status }}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.type }}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{row.person.email}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.startDate | date:dateFormat }}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="endDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
              <mat-cell *matCellDef="let row"><span *ngIf="row.endDate else elseName">{{ row.endDate| date:dateFormat }}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="delete">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button (click)="editPerson(row.id)" mat-raised-button type="button"><mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon></button>
                <button (click)="deletePerson(row.id)" mat-raised-button type="button"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="peopleDisplayedColumns; sticky:true;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: peopleDisplayedColumns"></mat-row>

          </mat-table>
        </div>

        <ng-template #elseName>
          <span>&nbsp;</span>
        </ng-template>

        <!---->
      </div>

      <mat-paginator></mat-paginator>
    </mat-tab>

    <mat-tab *ngIf="company != null && company.id != '0'" label="Payments Types">
      <div class="row p-4">
        <button (click)="addPaymentType()" mat-raised-button class="mat-accent" type="button">New Payment Type</button>
      </div>
      <div class="p-2" formArrayName="paymentTypes">

        <div *ngFor="let paymentType of paymentTypes.controls; let i=index">
          <div [formGroupName]="i" class="form-row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select formControlName="type" required>
                  <mat-option value="CC">Credit Card</mat-option>
                  <mat-option value="PO">Purchase Order</mat-option>
                  <mat-option value="ACCT">Account</mat-option>
                  <mat-option value="other">Other</mat-option>
                </mat-select>
              </mat-form-field>
            </div>


            <div class="col-md-2">
              <mat-form-field>
                <mat-label for="reference">Reference</mat-label>
                <input matInput type="text" formControlName="reference" required />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label for="limit">Limit</mat-label>
                <input matInput type="text" formControlName="limit" required />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label for="expiry">Expiry</mat-label>
                <input matInput type="text" formControlName="expiry" [matDatepicker]="apptpicker" />
                <mat-datepicker-toggle matSuffix [for]="apptpicker"></mat-datepicker-toggle>
                <mat-datepicker #apptpicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" required>
                  <mat-option value="ACTIVE">ACTIVE</mat-option>
                  <mat-option value="PENDING">PENDING</mat-option>
                  <mat-option value="COMPLETED">COMPLETED</mat-option>
                  <mat-option value="EXPIRED">EXPIRED</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <mat-divider></mat-divider>
          </div>
        </div>


      </div>
    </mat-tab>
    <mat-tab *ngIf="company != null && company.id != '0'" label="Jobs">
      <ng-template matTabContent>
        <app-office-job-list [companyId]="company.id"></app-office-job-list>
      </ng-template>
    </mat-tab>

    
  <mat-tab label="PEFA Rules" style="margin: 15px;">
      <input type="hidden" formControlName="id" />
      <div class="form-group">
        <div class="form-row">
          <div class="col-md-2">
            <mat-label for="bpDia">Blood Pressure Limits</mat-label>
          </div>
          <div class="col-md-3">
            <table>
              <tr>
                <td style="width: 85px;">
                  <mat-form-field hint="(145 to 170)" class="formFieldWidth80">
                    <mat-label>Systolic</mat-label>
                    <input matInput type="number" min="145" max="170" formControlName="bpSys" />
                    <mat-hint align="end">(145 to 170)</mat-hint>
                  </mat-form-field>      
                </td>
                <td style="width: 20px;text-align: center;">
                  <span style="font-size: xx-large">/</span>
                </td>
                <td style="width: 85px;">
                  <mat-form-field hint="(95 to 100)" class="formFieldWidth80">
                    <mat-label>Diastolic</mat-label>
                    <input matInput type="number"  min="95" max="100" formControlName="bpDia" />              
                    <mat-hint align="end">(95 to 100)</mat-hint>
                  </mat-form-field>                  
                </td>
                <td style="padding-left: 5px;">
                  <span class="tbl-lbl">mmHg</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-7">
            <table>
              <tr>
                <td>
                  <mat-form-field >
                    <mat-label>Approved By</mat-label>
                    <input matInput type="text" formControlName="approvedByBp" />              
                  </mat-form-field>                   
                </td>
                <td>
                  <mat-form-field *ngIf="company != null && company.updatedByNmBp != null">
                    <mat-label>Updated By</mat-label>
                    <input matInput type="text" readonly="readonly" [value]="company.updatedByNmBp" />  
                    <mat-hint align="end">{{ company.updatedAtBp | date:dateTimeFormat }}</mat-hint>            
                  </mat-form-field>   
                </td>
              </tr>
            </table>            
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2"><mat-label for="clearanceLocation">Pre Appt Medical Clearance</mat-label></div>
          <div class="col-md-3">
            <mat-radio-group formControlName="clearanceLocation">
              <mat-radio-button value="PreTest">Required&nbsp;&nbsp;</mat-radio-button>
              <mat-radio-button value="NR">Not Required</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-md-7">
            <table>
              <tr>
                <td>
                  <mat-form-field >
                    <mat-label>Approved By</mat-label>
                    <input matInput type="text" formControlName="approvedByCl" />              
                  </mat-form-field>                   
                </td>
                <td>
                  <mat-form-field *ngIf="company != null &&  company.updatedByNmCl != null">
                    <mat-label>Updated By</mat-label>
                    <input matInput type="text" readonly="readonly" [value]="company.updatedByNmCl" />  
                    <mat-hint align="end">{{ company.updatedAtCl | date:dateTimeFormat }}</mat-hint>            
                  </mat-form-field>  
                </td>                
              </tr>
            </table>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2"><mat-label for="bookingNotes">Booking Rules</mat-label></div>
          <div class="col-md-3">
            <mat-form-field>
              <textarea matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutoSizeMaxRows="2"
                        formControlName="bookingNotes" maxlength="256"></textarea>
            </mat-form-field>
          </div>
          <div class="col-md-7">
            <table>
              <tr>
                <td>
                  <mat-form-field >
                    <mat-label>Approved By</mat-label>
                    <input matInput type="text" formControlName="approvedByBr" />              
                  </mat-form-field>                   
                </td>
                <td>
                  <mat-form-field *ngIf="company != null && company.updatedByNmBr != null">
                    <mat-label>Updated By</mat-label>
                    <input matInput type="text" readonly="readonly" [value]="company.updatedByNmBr" />  
                    <mat-hint align="end">{{ company.updatedAtBr | date:dateTimeFormat }}</mat-hint>            
                  </mat-form-field>   
                </td>                
              </tr>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="company != null && company.id != '0' && isStaunchAdmin == true" label="JOD Settings">
      <div style="margin: 10px;">
        <div class="alert alert-warning" role="alert">
          <strong>Warning:</strong> If you're not sure what this is then please turn back.
        </div>

        <div class="form-row">
          <div class="col-md-2" style="margin-top: 8px;">
            <mat-label for="jodSubscription" >JOD Subscription</mat-label>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-select formControlName="jodCompanyId">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let jodSub of jodSubsDataSource" [value]="jodSub.Id">{{ jodSub.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field *ngIf="company != null && company.updatedByJod != null">
              <mat-label>Updated By</mat-label>
              <input matInput type="text" readonly="readonly" [value]="company.updatedByJod" />  
              <mat-hint align="end">{{ company.updatedAtJod | date:dateTimeFormat }}</mat-hint>
            </mat-form-field>  
          </div>
        </div>   
        <div *ngIf="company != null && companyForm.get('jodCompanyId').value != null && companyForm.get('jodCompanyId').value != '' && isStaunchAdmin == true" class="form-row">
          <div class="col-md-2" style="margin-top: 8px;">
            <mat-label for="jodSubscription">Link JOD Jobs:</mat-label>
          </div>
          <div class="col-md-10">
            <table mat-table [dataSource]="jobsDataSource" class="mat-elevation-z2 tbl-jod">
              <ng-container matColumnDef="console">
                <th mat-header-cell *matHeaderCellDef><img src="/assets/console.svg" style="height: 24px;"/>&nbsp;<strong>Console</strong></th>
                <td mat-cell *matCellDef="let row"><a [routerLink]="['/office-job-detail', row.id]">{{ row.name}}</a></td>
              </ng-container>
              <ng-container matColumnDef="spacer">
                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                <td mat-cell *matCellDef="let row">&nbsp;</td>
              </ng-container>              
              <ng-container matColumnDef="jod">
                <th mat-header-cell *matHeaderCellDef><img src="/assets/jod.png" style="height: 24px;"/>&nbsp;<strong>JOD</strong></th>
                <td mat-cell *matCellDef="let row">
                  <mat-select [(value)]="row.jodJobId" (selectionChange)="onJodJobChange($event, row.id)">
                    <!-- <mat-select-trigger>
                      <img src="/assets/jod.png" style="height: 24px;"/>&nbsp;{{jodJobsNames != null && jodJobsNames[row.jodJobId] != undefined ? jodJobsNames[row.jodJobId] : ''}}
                    </mat-select-trigger>                     -->
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let jodJob of jodJobsDataSource" [value]="jodJob.Id">{{ jodJob.Name }}</mat-option>
                  </mat-select>
                </td>
              </ng-container>              
              <tr mat-header-row *matHeaderRowDef="jobDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: jobDisplayedColumns;"></tr>
            </table>
          </div>
        </div>             
      </div>
    </mat-tab>
    
  </mat-tab-group>
  <button mat-raised-button class="mat-primary" [disabled]="!companyForm.valid" type="submit" style="margin-left: 15px;">Save Changes</button>
</form>
