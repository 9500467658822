import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Doctor, Assessor, DemandType } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { MessageService } from '../message/message.service';
import { DoctorService } from './doctor.service';

@Component({
  selector: 'app-doctor-detail',
  templateUrl: './doctor-detail.component.html',
})
export class DoctorDetailComponent implements AfterViewInit {
  public doctor: Doctor;
  public demandTypes: DemandType[];
  DoctorForm = new FormGroup({
    id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    phoneNumber: new FormControl(''),
    faxNumber: new FormControl(''),
    email: new FormControl(''),
    preferredContactType: new FormControl('PHONE'),
  });

  ngAfterViewInit(): void {

    const doctorId = this.route.snapshot.paramMap.get('id');

    if (doctorId) {
      this.doctorService.getDoctor(doctorId).subscribe(results => {
        this.doctor = results;
        this.DoctorForm.patchValue(this.doctor);
      });
    }
    

  }

  sharedService: SharedService;

  messageService: MessageService;

  constructor(
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public doctorService: DoctorService,
  ) {
    this.messageService = messageService;
  }

  saveChanges() {

    if (this.doctor) {


      this.doctorService.saveDoctor(this.doctor.id, this.DoctorForm.value).subscribe(update => {
        this.doctorService.getDoctor(this.doctor.id).subscribe(results => {
          this.doctor = results;
          this.DoctorForm.patchValue(results);
          this.messageService.add("Doctor Updated");
        });

      }
      );
    } else {
      const value = this.DoctorForm.value;
      delete value.id;
      this.doctorService.addDoctor(value).subscribe(update => {
        this.doctorService.getDoctor(update.id).subscribe(results => {
          this.doctor = results;
          this.DoctorForm.patchValue(results);
          this.messageService.add("Doctor Added");
        });

      });
    }

  }

}

