import { Component, OnInit, Inject, ViewChild, Provider, Output, EventEmitter, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { JobList, JOBFIT_DATETIME_FORMAT } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-office-job-list',
  templateUrl: './office-job-list.component.html',
  styleUrls: ['./office-job-list.component.css']
})

export class OfficeJobListComponent implements OnInit {
  public readonly dateTimeFormat = JOBFIT_DATETIME_FORMAT;
  public officeAdminService: OfficeAdminPEFAService;
  public all_jobs: JobList[] = [];
  public jobs: JobList[] = [];

  currentListId: number = -1;
  loading: boolean = true;
  onlyActive: boolean = true;
  filterValue: string = "";

  @Input() companyId: string;

  //Material Table
  displayedColumns2 = [
    "owner",
    "name",
    "description",
    "isActive",
    "lastModifiedDate"
  ];

  dataSource: MatTableDataSource<JobList>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: JobList, filter: string) => {
      return this.findJobs(data, filter);
    };
    this.loadAllJobs();
  }

  constructor(public dialog: MatDialog, officeAdminService: OfficeAdminPEFAService, public fb: FormBuilder, public messageService: MessageService) {
    this.officeAdminService = officeAdminService;
    this.dataSource = new MatTableDataSource();
  }
  public findJobs(job: JobList, filter: string): boolean {    
    if (this.onlyActive) {
      return job.isActive === true && ((job.companyName && job.companyName.toLowerCase().includes(filter)) || (job.name && job.name.toLowerCase().includes(filter)));
    } else {
      return ((job.companyName && job.companyName.toLowerCase().includes(filter)) || (job.name && job.name.toLowerCase().includes(filter)));
    }    
  }

  //Material Table
  applyFilter(filterValue: string) {    
    if (filterValue) {
      this.filterValue = filterValue.trim().toLowerCase();
    } else {
      this.filterValue = '';
    }    
    this.dataSource.filter = this.filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyOnlyActive() {
    if (this.onlyActive) {
      this.jobs = this.all_jobs.filter(r => r.isActive);
    } else {
      this.jobs = this.all_jobs;
    }
    this.dataSource.data = this.jobs;   
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  loadAllJobs() {
    this.officeAdminService.getJobsForList(this.companyId).subscribe(
      result => {
        // console.log(result);

        if (this.companyId) {
          // console.log("Company Id " + this.companyId);
          this.all_jobs = result.filter(r => r.companyID == this.companyId);          
        } else {
          this.all_jobs = result;
        }
        if (this.onlyActive) {
          this.jobs = this.all_jobs.filter(r => r.isActive);
        } else {
          this.jobs = this.all_jobs;
        }
        this.dataSource.data = this.jobs;
        this.dataSource.filter = '';
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }       
        this.loading = false;
    })
  }
}
