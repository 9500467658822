
<mat-toolbar [ngClass]="{'small' : small}">
  <mat-toolbar-row>
    <div *ngIf="hasImportantNote()">
      <mat-icon matTooltip="{{importantNote}}" matTooltipClass="class-tooltip" class="mat-warn" aria-hidden="false" aria-label="Add Note">priority_high</mat-icon>
    </div>
    <div class="p-1">
      <button mat-raised-button matTooltip="Add Note" (click)="openNoteDialog()" isIconButton="true"><p class="mb-0" *ngIf="!small">Add Note</p><mat-icon aria-hidden="false" aria-label="Add Note">comment</mat-icon></button>
    </div>
    <div class="p-1">
      <button mat-raised-button matTooltip="Attach File" (click)="openAttachmentDialog()" isIconButton="true"><p class="mb-0" *ngIf="!small">Attach File</p><mat-icon aria-hidden="false" aria-label="Add Note">attachment</mat-icon></button>
    </div>
    <div class="p-1">
      <a mat-raised-button matTooltip="Launch Pre-Appointment Questions" style="text-decoration: none;" href="{{getMedicalFormURL()}}" target="_blank"><p class="mb-0" *ngIf="!small">Launch Pre-Appointment Questions</p><mat-icon>notes-medical</mat-icon></a>
    </div>
    <div class="p-1" *ngIf="!isNew()">
      <button mat-raised-button matTooltip="Add Medical Clearance Condition" (click)="openAddMCDialog()" isIconButton="true"><p class="mb-0" *ngIf="!small">Add Medical Condition</p><mat-icon fontSet="fas" style="height:auto;" fontIcon="fa-ambulance"></mat-icon></button>
    </div>
    <div class="p-1" *ngIf="!isNew()">
      <button mat-raised-button matTooltip="Generate Medical Clearance Letter" (click)="openMCLetterDialog()" isIconButton="true"><p class="mb-0" *ngIf="!small">Medical Clearance Letter</p><mat-icon fontSet="fas" style="height:auto;" fontIcon="fa-file-medical"></mat-icon></button>
    </div>
    <div class="p-1" *ngIf="!isNew()">
      <button mat-raised-button matTooltip="Upload Medical Clearance" (click)="openMCDialog()"><p class="mb-0" *ngIf="!small">Upload MC</p><mat-icon aria-hidden="false" aria-label="Upload MC">local_hospital</mat-icon></button>
    </div>
    <div class="p-1">
      <button matTooltip="Send Message" mat-raised-button href="" (click)="openSendMessage()"><p class="mb-0" *ngIf="!small">Send Message</p><mat-icon aria-hidden="false" aria-label="Send Mail">mail</mat-icon></button>
    </div>
    <div class="p-1" *ngIf="isNew()">
      <button mat-raised-button matTooltip="Accept Request" (click)="accept()" [disabled]="disableAccept">Accept</button><span *ngIf="disableAccept" class="mat-caption" style="color: red;">&nbsp;{{disableAcceptMessage}}</span>
    </div>
    <div class="p-1" *ngIf="isNew()">
      <button mat-raised-button matTooltip="Reject Request" (click)="reject()">Reject</button>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="!isNew()">
    <div class="p-1">
      <button [disabled]="isMCRequired() || isComplete()" aria-label="Book" matTooltip="Make a booking" mat-raised-button (click)="openBookingDialog()"><p class="mb-0" *ngIf="!small">Book</p><mat-icon aria-hidden="false" aria-label="Book">event_note</mat-icon></button>
    </div>
    <div class="p-1">
      <button mat-raised-button matTooltip="Upload Report" (click)="openDialog()"><p class="mb-0" *ngIf="!small">Upload Report</p><mat-icon aria-hidden="false" aria-label="Upload Report">note_add</mat-icon></button>
    </div>
    <div class="p-1" *ngIf="pefaRequest.report">
      <a mat-raised-button matTooltip="Download Report" style="text-decoration: none;" (click)="downloadReport()" download><p class="mb-0" *ngIf="!small">Download Report</p><mat-icon aria-hidden="false" aria-label="Download">system_update</mat-icon></a>
    </div>
    <div class="p-1">
      <button [disabled]="!isDraftReport()" matTooltip="QA Report" mat-raised-button href="" (click)="openQADialog()"><p class="mb-0" *ngIf="!small">Record QA</p><mat-icon aria-hidden="false" aria-label="Record QA">verified</mat-icon></button>
    </div>
    <div class="p-1">
      <button [disabled]="!isAdminReviewReport()" matTooltip="Release Report" mat-raised-button href="" (click)="releaseReport()"><p class="mb-0" *ngIf="!small">Release Report</p><mat-icon aria-hidden="false" aria-label="Release Report">send</mat-icon></button>
    </div>
    <div class="p-1">
      <button [disabled]="isPaid()" matTooltip="Generate Invoice" mat-raised-button href="" (click)="openInvoiceDialog()"><p class="mb-0" *ngIf="!small">Generate Invoice</p><mat-icon aria-hidden="false" aria-label="Generate Invoice">attach_money</mat-icon></button>
    </div>

    <div class="p-1" *ngIf="!(isComplete())">
      <button matTooltip="Cancel Request" mat-raised-button href="" (click)="cancelRequest()"><p class="mb-0" *ngIf="!small">Cancel Request</p><mat-icon aria-hidden="false" aria-label="Cancel Request">cancel</mat-icon></button>
    </div>
    <div class="p-1" *ngIf="isOnHold()">
      <button matTooltip="Restart Request" mat-raised-button href="" (click)="activateRequest()"><p class="mb-0" *ngIf="!small">Restart Request</p><mat-icon aria-hidden="false" aria-label="Restart Request">not_started</mat-icon></button>
    </div>
    <div class="p-1">
      <span><a mat-raised-button matTooltip="PI Report" style="text-decoration: none;" (click)="downloadPIReport()" download><mat-icon aria-hidden="false" aria-label="Download PI Report">history_edu</mat-icon></a></span>
    </div>
    <div class="p-1">
      <span><a mat-raised-button matTooltip="Report KPI" style="text-decoration: none;" (click)="reportKPI()" download><mat-icon aria-hidden="false" aria-label="Report KPI">report_problem</mat-icon></a></span>
      <span style="position: relative;left: 5px;color: transparent;cursor: pointer;" (click)="createReportReadyToDo()">.</span>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
