import { Component, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonRelationship } from './shared-component';

@Component({
  selector: 'app-relationship-detail',
  templateUrl: './relationship-detail.component.html',
})
export class RelationshipDetailComponent {
  @Input() index: number;
  @Input() relationshipForm: FormGroup;

  @Output() deleteEvent: EventEmitter<number> = new EventEmitter<number>();

  public deletePerson() {
    this.deleteEvent.emit(this.index);
  }
}
