import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Booking, Provider } from "../../shared/shared-component";
import { MessageService } from '../../message/message.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  private client: HttpClient;
  private baseURL: string;



  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }


  getBookings(providerId: string): Observable<Booking[]> {
    return this.client.get<Booking[]>(this.baseURL + 'api/Provider/GetBookings/' + providerId)
      .pipe(
        retry(1),
        catchError(this.handleError)
    ); 
  };


  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
    }
    return throwError(errorMessage);
  };
}


