<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <h1 mat-dialog-title>Cancel Booking</h1>
    </div>
  </div>
</div>


<div mat-dialog-content>

    <div class="row">
      <div class="col-12 text-center">
        <p>Why was the booking canceled?<span style="color: red;">*</span></p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <mat-form-field>
          <input style="width: 200px;" matInput [(ngModel)]="reason" (change)="checkReasonEntered()">
          <button mat-button *ngIf="reason" matSuffix mat-icon-button aria-label="Clear" (click)="clearReason()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="row text-center">
      <div class="col-12">
        <mat-checkbox [(ngModel)]="noShow">Appointment No Show</mat-checkbox>
      </div>
    </div>

    <div *ngIf="enableStatus" class="row">
      <div class="col-12 text-center">
          <p style="margin-top: 5px; color: red; font-size: 12px;">{{statusText}}</p>
      </div>
    </div>

  </div>

<mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="onNoClick(false)">Exit</button>
        <button mat-raised-button color="warn" [disabled]="enableCancelBtn" (click)="onYesClick()">Cancel booking</button>
</mat-dialog-actions>
