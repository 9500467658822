import { Inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';  
import { Observable, Subject } from 'rxjs';
import moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private message$: Subject<Notice>;
  private dismiss$: Subject<string>;
  private connection: signalR.HubConnection;

  constructor(@Inject('BASE_URL') baseUrl: string) {
    this.message$ = new Subject<Notice>();
    this.connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(baseUrl + "notificationHub")
      .build();
    this.connect();
  }
  private connect() {
    this.connection.start().catch(err => console.log(err));
    this.connection.on('notify', (message) => {
      console.log("Notify : " + JSON.stringify(message));
      var notice = new Notice("1", message, new Date());
      this.message$.next(notice);
    });
    this.connection.on('Dismiss', (id) => {
      this.dismiss$.next(id);
    });
  }
  public getNotice(): Observable<Notice> {
    return this.message$.asObservable();
  }

  public getDismiss(): Observable<string> {
    return this.dismiss$.asObservable();
  }

  public disconnect() {
    this.connection.stop();
  }


}

export class Notice {
  constructor(
    public id: string,
    public message: string,
    public createdDate: Date
  ) { }
}
