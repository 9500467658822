import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import moment from "moment";
import { MessageService } from "../message/message.service";
import { PEFARequest } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "./office-admin-pefa.service";

@Component({
  selector: 'app-office-pefa-summary',
  templateUrl: './pefa-summary.component.html',
  styleUrls: ['./office-admin-pefa.component.css']

})

export class OfficePefaSummaryComponent implements AfterViewInit {

  @Input() pefaRequest: PEFARequest;

  refreshing: boolean = false;

  isJobUpdated: boolean = false;

  @Output() changed: EventEmitter<boolean> = new EventEmitter(true);

  @ViewChild('companyBookingNotesTemplate') customTemplate: TemplateRef<any>;
  @ViewChild('companyJODNotesTemplate') customTemplate2: TemplateRef<any>;

  constructor(private officeService: OfficeAdminPEFAService, private messageService: MessageService, public dialog: MatDialog) {
    
  }

  public ngAfterViewInit() {
    // if (this.pefaRequest.pefaJob != null && this.pefaRequest.pefaJob.job != null) {
    //   this.isJobUpdated = moment(this.pefaRequest.pefaJob.audit?.lastModifiedDate).isBefore(this.pefaRequest.pefaJob.job.audit?.lastModifiedDate);
    // } else {
    //   this.isJobUpdated = false;
    // }    
    // We no longer need this reminder (red strip above) because we will pull the latest demands at the time of the PEFA
    this.isJobUpdated = false;
  }

  openCompanyBookingNotesDialog(): void {
    const dialogRef = this.dialog.open(this.customTemplate, {
      width: '250px'
    });
  }

  openCompanyJODNotesDialog() {
    const dialogRef = this.dialog.open(this.customTemplate2, {
      width: '450px'
    });
  }

  public updateStatus() {

    this.refreshing = true;
    this.officeService.refreshMedical(this.pefaRequest.id).subscribe(
      res => this.messageService.add("Status Refreshed"),
      err => {
        this.messageService.add("Refresh Failed " + err);
        this.refreshing = false;
      },
      () => {
        this.refreshing = false;
        this.changed.emit();
    });
  }

  public updateDemands() {
    this.officeService.updateJobDemands(this.pefaRequest.id).subscribe(
      res => this.messageService.add("Demands updated- please refresh outstanding bookings before paperwork is sent"),
      err => {
        this.messageService.add("Refresh Failed " + err);
      },
      () => {
        this.changed.emit();
      }
    );
  }

}
