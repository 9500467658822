import { Component, AfterViewInit, Input, OnInit, ViewChild } from "@angular/core";
import { Report, JOBFIT_DATE_FORMAT, ReportCriteria, Company } from "../shared/shared-component";
import { FormBuilder, FormGroup, FormControl, RequiredValidator, Validators } from "@angular/forms";
import { ReportService } from "./report.service";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: 'app-criteria-multicompany',
  templateUrl: './multicompany-criteria.component.html',
})

export class MultiCompanyCriteriaComponent implements OnInit {

  @Input() criteria: ReportCriteria;
  public readonly dateFormat = JOBFIT_DATE_FORMAT;

  @Input() criteriaForm: FormGroup

  selection = new SelectionModel<Company>(true, []);

  private _company: Company[] = [];
  public dataSource: MatTableDataSource<Company> = new MatTableDataSource<Company>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = [
    "select",
    "name",
  ];

  constructor(
    private fb: FormBuilder,
    public reportService: ReportService,
    public companyService: OfficeAdminPEFAService,
  ) {

  }

  public ngOnInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.companyService.getAllCompany().subscribe(result => {
      this._company = result;
      this.dataSource.data = this._company;
    });

    if (this.criteria.required) {
      this.criteriaForm.addControl(this.criteria.parameterName, new FormControl('', Validators.required));
    } else {
      this.criteriaForm.addControl(this.criteria.parameterName, new FormControl(''));
    }

    this.selection.changed.subscribe(changes => {
      this.criteriaForm.get(this.criteria.parameterName).setValue(this.selection.selected.map(u => u.id).join(","));
    })
  }


  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Company): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
}

