import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Provider } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment-timezone";

@Component({
  selector: 'app-office-provider-list',
  templateUrl: './office-provider-list.component.html',
  styleUrls: ['./office-provider-list.component.css']
})

export class OfficeProviderListComponent implements OnInit {

  public officeAdminService: OfficeAdminPEFAService;
  public companies: Provider[] = [];
  //provider: Provider;
  currentListId: number = -1;
  loading: boolean = true;

  //Material Table
  displayedColumns2 = [
    "name",
    "address",
    "ladder",
    "tier",
    "status",
  //  "button",
  ];

  dataSource: MatTableDataSource<Provider>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadAllCompanies();

/*    this.officeAdminService.getAllProviders().subscribe(res => {
      this.provider = res;
    })*/
  }
 

  constructor(public dialog: MatDialog, officeAdminService: OfficeAdminPEFAService, public fb: FormBuilder, public messageService: MessageService) {
    this.officeAdminService = officeAdminService;
    this.dataSource = new MatTableDataSource();
    this.dataSource.filterPredicate = (data: Provider, filter: string) => {
      var lowerfilter = filter.toLowerCase();
      return ((data.name && data.name.toLowerCase().includes(lowerfilter)) || data.locations.findIndex(r => r.region.toLowerCase().includes(lowerfilter) || r.address.toLowerCase().includes(lowerfilter)) != -1);
    };
  }



  //Material Table
  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadAllCompanies() {
    this.officeAdminService.getAllProviders().subscribe(
      result => {
        this.companies = result;
        this.dataSource.data = this.companies;
        this.loading = false;
      })
  }

  public static hasInsurance(provider: Provider): boolean {
    var result = false;
    if (provider.providerAudits && provider.providerAudits.length > 0) {
      var audit = provider.providerAudits.reduce((p, c) => p.auditDate > c.auditDate ? p : c);

      if (audit) {
        result = (moment().isBefore(moment(audit.indeminityInsurancePolicyExpiry)) && moment().isBefore(moment(audit.liabilityInsurancePolicyExpiry)));
      }
    }

    return result;
  }  

  classForProvider(provider: Provider): string {
    var color = 'active-provider';

    if (!OfficeProviderListComponent.hasInsurance(provider)) {
      // color = 'red-provider';
      color = 'active-noinsurance-provider';
    } else if (provider.status == 'PENDING') {
      color = 'pending-provider';

    } else if (provider.status == 'ONHOLD') {
      color = 'hold-provider';

    } else if (provider.status == 'INACTIVE') {
      color = 'red-provider';
    }
    return color;

  }  

/*  sendProviderSignUp(providerId:string) {
    console.log(providerId)
    this.officeAdminService.emailProviderSignUp(providerId).subscribe(results => {

    },
      error => {
        console.log("Error " + error);
      });
    this.messageService.add("Invitation Sent");    
  }*/
}
