import { Component, Inject, Injectable, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Action } from "rxjs/internal/scheduler/Action";
import { OfficeAdminBookingActionsComponent } from "../office-admin-pefa/office-admin-booking-actions.component";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { Booking } from "./shared-component";
import { SharedService } from "./shared.service";

@Component({
  selector: 'confirm-dialog-modal',
  templateUrl: 'confirm-dialog.component.html',

})
@Injectable()
export class ConfirmDialog {

  public actions: OfficeAdminBookingActionsComponent;
  message: string = '';
  showReason: boolean = false;
  prompt: boolean = false;
  officeAdminService: OfficeAdminPEFAService;

  constructor (
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.message) {
      this.showReason = true;
    }

    if (data.prompt) {
      this.prompt = true;
    }
  }
  public onNoClick() {
    this.dialogRef.close({ outcome: false });
  }

  public onYesClick() {
    if (this.showReason) {
      this.dialogRef.close({ outcome: true, message: this.message });
    } else {
      this.dialogRef.close({ outcome: true });

    }
  }
}
