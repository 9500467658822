import { Component, Inject, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PEFARequestService } from './pefarequest.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { PEFARequest } from '../shared/shared-component';

@Component({
  selector: 'app-pefarequest-detail',
  templateUrl: './pefarequest-detail.component.html'
})
export class PEFARequestDetailComponent {
  public pefarequest: PEFARequest;
  public pefaRequestService: PEFARequestService;

    messageService: MessageService;

  constructor(pefaRequestService: PEFARequestService,
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    this.pefaRequestService = pefaRequestService;
    this.messageService = messageService;
 
  }

  ngOnInit() {
    this.loadRequest();
  }

  public saveRequest() {
    this.messageService.add("Request has been updated");
    this.loadRequest();
  }

  private loadRequest() {
    this.pefaRequestService.getPEFARequest(this.route.snapshot.paramMap.get('id')).subscribe(
      request => this.pefarequest = request);

  }

}
