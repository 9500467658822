
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { PEFARequest, SearchStats, PEFASearchResults } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "./office-admin-pefa.service";



export class PEFARequestsDataSource implements DataSource<PEFARequest> {

  private lessonsSubject = new BehaviorSubject<PEFARequest[]>([]);

  private searchStatsSubject = new BehaviorSubject<any>({});

  public searchStats$ = this.searchStatsSubject.asObservable();

  private lengthSubject = new BehaviorSubject<number>(0);
  public length$ = this.lengthSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private officeService: OfficeAdminPEFAService) {

  }

  loadPEFARequests(
    filter: string,
    status:string,
    sort: string,
    sortDirection: string,
    pageIndex: number,    
    pageSize: number) {

    this.loadingSubject.next(true);
    this.lessonsSubject.next([]);

    this.officeService.getAllPEFA(filter, 
      status, sort, sortDirection, pageIndex, pageSize).pipe(
        finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(searchResults => {
        this.lessonsSubject.next((<PEFASearchResults>searchResults).results.results);
        this.searchStatsSubject.next((<PEFASearchResults>searchResults).buckets);
        this.lengthSubject.next((<PEFASearchResults>searchResults).results.count);
      });

  }

  connect(collectionViewer: CollectionViewer): Observable<PEFARequest[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.searchStatsSubject.complete();
    this.loadingSubject.complete();
    this.lengthSubject.complete();
  }

}
