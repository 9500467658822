import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
//import { BehaviorSubject, concat, from } from 'rxjs';
//import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { MessageService } from '../message/message.service';
import { ResponseStatus } from '../shared/shared-component';

export class CompanyUserRole {
  username: string
  userId: string
  fName: string
  lName: string
  referrer: boolean
  receiver: boolean
  companyAdmin: boolean

  constructor(
    username: string,
    userId: string,
    fName: string,
    lName: string,
    referrer: boolean,
    receiver: boolean,
    companyAdmin: boolean,
  ) { }
}

export class CompanyUser {

  constructor (
  fName: string,
  lName: string,
  siteName: string,
  mobNum: string,
  workNum: string,
  userName: string,
  eMail: string,
  passWord: string,
  confirmPassword: string
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {

  private client: HttpClient;
  private baseURL: string;

  

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }

    createNewUser(newUser: CompanyUser): Observable<any> {
        this.messageService.add('Adding new user');
        return this.client.post<any>(this.baseURL + 'api/User/AddCompanyUser', newUser, {observe: 'response'})
            .pipe(
               
        catchError(this.handleError)
    );
  }

  setCompanyUserRoles(newUserRoles: CompanyUserRole[]): Observable<CompanyUserRole[]> {
      return this.client.post<CompanyUserRole[]>(this.baseURL + 'api/User/ChangeUserRoles', newUserRoles)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  //private handleError<T>(operation = 'operation', result?: T) {
  //  return (error: any): Observable<T> => {

  //    // TODO: send the error to remote logging infrastructure
  //    console.error(error); // log to console instead

  //    // TODO: better job of transforming error for user consumption
  //    //this.log(`${operation} failed: ${error.message}`);

  //    // Let the app keep running by returning an empty result.
  //    return of(result as T);
  //  };
  //}

 private handleError(error) {
   let errorMessage = '';
   //let errors: any = JSON.stringify(error.json());
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;


    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  };

  getOfficeAdmin(): Observable<CompanyUserRole[]> {
    return this.client.get<CompanyUserRole[]>(this.baseURL + 'api/User/ChangeUserRoles').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getCompanyAdminUsers(): Observable<CompanyUserRole[]> {
    return this.client.get<CompanyUserRole[]>(this.baseURL + 'api/User/CompanyUsers').pipe(
      retry(1),
      catchError(this.handleError/*<CompanyUserRole[]>('getCompanyAdminUsers', [])*/)
    );
  }

  toFormGroup(users: CompanyUserRole[]): FormGroup {

    let groups: FormGroup = new FormGroup({})
    //let array: FormArray = new FormArray([]);

    users.forEach(user => {
      groups.addControl(user.username, new FormGroup({
          username: new FormControl(user.username),
          userId: new FormControl(user.userId),
          fName: new FormControl(user.fName),
          lName: new FormControl(user.lName),
          referrer: new FormControl(user.referrer),
          receiver: new FormControl(user.receiver),
          companyAdmin: new FormControl(user.companyAdmin)
        }));
      //console.warn("adding group id " + user.username);
    });

    return groups;
  }
}
