import { Component, OnInit, Inject, ViewChild, Provider, Output, EventEmitter, Input, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { OfficeAdminPEFAService } from './office-admin-pefa.service';
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PEFARequest, PEFA_STATUS, BookingStatus, JOBFIT_DATE_FORMAT } from "../shared/shared-component";
import { style, state, transition, animate, trigger } from "@angular/animations";
import { PefaInvoiceDialog } from "../shared/invoice-pefa-dialog.component";
import { MatSelectChange } from "@angular/material/select";
import { ConfigService } from "../config/config.service";
import { PEFARequestsDataSource } from "./pefarequests.datasource";
import { tap } from "rxjs/operators";
import { merge } from "rxjs";
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { JOBFIT_DATETIME_FORMAT } from "../shared/shared-component";
import { kSTORAGE_CALENDAR } from '../shared/constants';




@Component({
  selector: 'app-office-admin-pefa-list',
  templateUrl: './office-admin-pefa-list.component.html',
  styleUrls: ['./office-admin-pefa-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class OfficeAdminPEFAListComponent implements OnInit, AfterViewInit {
  public readonly dateTimeFormat = JOBFIT_DATETIME_FORMAT;
  public readonly dateFormat = JOBFIT_DATE_FORMAT;  
  public officeAdminService: OfficeAdminPEFAService;
  public companypefas: PEFARequest[] = [];
  contents: Object;
  showActions = false;


  //Modal
  file: File;
  pefaId: number;
  companyId: number = -1;
  currentListId: number = -1;
  statusEnum = PEFA_STATUS;
  loading: boolean = true;
  filterText: string = "";
  selectedStatus: string = "active";


  //Material Table
  adminView = [
    "name",
    "contact",
    "company",
    "title",
    "appointmentdate",
    "responseTime",
    "status",
    "referralType",
    "otherInfo",
    "modifiedDate",
    "actions",
  ];

  reportView = [
    "name",
    "contact",
    "company",
    "title",
    "appointmentdate",
    "responseTime",
    "status",
    "referralType",
    "actions",
  ];

  accountView = [
    "name",
    "company",
    "status",
    "paymentMethod",
    "referralType",
    "actions",
  ];


  displayedColumns3 = [
    "provider",
    "assessor",
    "type",
    "appointmentDateTime",
    "status",
    "bookingActions",
  ];

  displayedColumns2 = this.adminView;


  dataSource: PEFARequestsDataSource;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatButtonToggleGroup) statusToggle: MatButtonToggleGroup;

  public newCount: number = 0;
  public allCount: number = 0;
  public mcCount: number = 0;
  public activeCount: number = 0;
  public holdCount: number = 0;
  public pendingCount: number = 0;
  public completeCount: number = 0;

  ngOnInit(): void {
    // preload our calendar before all child elements use it
    if (localStorage.getItem(kSTORAGE_CALENDAR) == null) {
      this.configService.getBusinessCalendarSettings(false).subscribe(results => {
        localStorage.setItem(kSTORAGE_CALENDAR, JSON.stringify(results));
      });
    }

    //this.loadALLPEFAList();
    this.dataSource = new PEFARequestsDataSource(this.officeAdminService);
    this.dataSource.loadPEFARequests(this.filterText, this.selectedStatus, "audit.lastModifiedDate", "desc", 1, 50);
    this.dataSource.searchStats$.subscribe(stats => {
      this.activeCount = stats.ACTIVE;
      this.pendingCount = stats.PENDING;
      this.mcCount = stats.MC_REQUIRED;
      this.newCount = stats.NEW;
      this.completeCount = stats.COMPLETE;
      this.holdCount = stats.ON_HOLD;
      this.allCount = stats.ALL;
      
    });
  }

  ngAfterViewInit() {
    this.statusToggle.change.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.statusToggle.change, this.paginator.page, this.sort.sortChange)
      .pipe(
        tap(() => this.loadRequestPage())
      )
      .subscribe();

  }

  loadRequestPage() {
    this.dataSource.loadPEFARequests(
      this.filterText,
      this.statusToggle.value,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex+1,
      this.paginator.pageSize);
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  constructor(public dialog: MatDialog, private configService: ConfigService, officeAdminService: OfficeAdminPEFAService, public fb: FormBuilder, public messageService: MessageService, private cd: ChangeDetectorRef) {
    this.officeAdminService = officeAdminService;
  }
  //

  public toggleAction(): void {
    this.showActions = !this.showActions;

  }

  public actionsView(): boolean {
    return this.showActions;
  }

  public updateView(event: string) {

    if (event == "reports") {
      this.displayedColumns2 = this.reportView;
    } else if (event == "accounts") {
      this.displayedColumns2 = this.accountView;
    } else {
      this.displayedColumns2 = this.adminView;
    }


  }
}
