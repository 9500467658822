<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <h4 mat-dialog-title>Diagnostics</h4>
    </div>
  </div>
</div>


<div mat-dialog-content style="font-size: 0.8em;">  
  <div class="row">
    <div class="col-12" style="font-size: 0.9em;">
      This information is meant to be used for development/debugging purposes.<br/><br/>
    </div>
  </div>  
    <div class="row">
      <div class="col-3">
        Last Page:
      </div>
      <div class="col-9">
        <input type="text" [value]="this.last_page" readonly="readonly" style="width: 100%;"/>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        Last Submit:
      </div>
      <div class="col-9">
        <input type="text" [value]="this.last_submit" readonly="readonly" style="width: 100%;"/><br/>
        <button mat-raised-button color="primary" mat-button [disabled]="disableDownload" (click)="onDownloadSnapshot()">Download as PDF</button>
      </div>
    </div>    
</div>

<button mat-raised-button color="primary" mat-button (click)="onClose()">Close</button>
