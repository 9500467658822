import { Component, Inject, OnInit, ViewChild} from '@angular/core';
//import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgForm, NgModel } from '@angular/forms';
import { UserAdminListComponent } from './company-admin-list.component';

//Service
import { UserAdminService, /*Role, User,*/ CompanyUser, CompanyUserRole } from './company-admin-service';
import { sameInputValueValidator } from './company-admin.directive';
import { ResponseStatus } from '../shared/shared-component';

@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.css']
})

export class UserAdminComponent implements OnInit {

    @ViewChild(UserAdminListComponent, { static: false }) child: UserAdminListComponent;
    userAdminService: UserAdminService;
    adminNewUserForm: FormGroup;
    companyRoleUsers: CompanyUserRole[] = [];

    saveStatus: string = "";
    errorShow: boolean = false;
    errorList: string[] = [];
    responseStatus: ResponseStatus;
    res: string;
    res2: any;

  submitResponse: any;

  //regex,  1 Cap, 1 lowercase, 1 symbol, 6 length  //**this should go into the Common!
    passwordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{6,}';
    //passwordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&\\(\\)])[A-Za-z\\d$@$!%*?&\\(\\)]{6,}'; // this does allow () however passing () in string to core fails?

 ngOnInit(): void {
    this.adminNewUserForm = new FormGroup({
      fName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      siteName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      mobNum: new FormControl('', [Validators.required, Validators.pattern("([0-9][0-9]*)")]),
      workNum: new FormControl('', [Validators.required, Validators.pattern("([0-9][0-9]*)")]),
      userName: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(100)]),
      eMail: new FormControl('', [Validators.required, Validators.email]),
      passWord: new FormControl('', [Validators.required, Validators.pattern(this.passwordRegex), Validators.minLength(6), Validators.maxLength(100)]),
      confirmPassword: new FormControl('', [Validators.required]),
    } , { validators: sameInputValueValidator });

  }

  get f() { return this.adminNewUserForm.controls; }

  constructor(/*private http: HttpClient,*/ private formBuilder: FormBuilder, private roleformBuilder: FormBuilder, private userAdminSer: UserAdminService) {
      this.userAdminService = userAdminSer;
  }

  onReset() {
        this.adminNewUserForm.reset();
  }

    onSubmitNewUser(): void {
        this.saveStatus = "Saving new user";
        this.userAdminService.createNewUser(this.adminNewUserForm.value).subscribe(response => {
            this.res = JSON.stringify(response);
            this.res2 = JSON.parse(this.res);
            this.responseStatus = this.res2.body;
            if (this.responseStatus.hasErrors) {
                this.errorShow = true;
                this.errorList = this.responseStatus.errors;
                this.saveStatus = this.responseStatus.error;
            } else {
                this.errorShow = false;
                this.saveStatus = this.responseStatus.successMessage;
                this.child.refresh();
                this.adminNewUserForm.reset();
            }
        }
    );
 //   this.adminNewUserForm.reset();
  }

  testbuttoncall(): void {
    console.log('Test change user roles clicked - calling service');
    //this.companyAdminService.setCompanyUserRoles(this.temp).subscribe();
  }


  onBlur(field: NgModel) {
    //console.log('in onBlur: ', field.valid);
  }

}
