import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Provider, Practice } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MatDialog } from "@angular/material/dialog";
import { PracticeService } from "./practice.service";


@Component({
  selector: 'app-practice-list',
  templateUrl: './practice-list.component.html',
})

export class PracticeListComponent implements OnInit {

  public practiceService: PracticeService;
  public practices: Practice[] = [];

  loading: boolean = true;

  //Material Table
  displayedColumns2 = [
    "name",
    "address",
    "email",
    "phone",
    "fax",
    "preferredContactType",
  ];

  dataSource: MatTableDataSource<Practice>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadPractices();
  }

  constructor(public dialog: MatDialog, practiceService: PracticeService, public fb: FormBuilder, public messageService: MessageService) {
    this.practiceService = practiceService;
    this.dataSource = new MatTableDataSource();
  }

  //Material Table
  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadPractices() {
    this.practiceService.getPractices().subscribe(
      result => {
        this.practices = result;
        this.dataSource.data = this.practices;
        this.loading = false;
      })
  }
}
