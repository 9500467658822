<ng-template #NoCompanyToList>
  <p *ngIf="!loading">There is no training for this assessor</p>
</ng-template>
<button mat-raised-button class="mat-primary" (click)="addTraining()" type="button">Add Training</button>

<div class="container-fluid" *ngIf="trainingFormArray" [formGroup]="assessorForm">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort formArrayName="training">
    <ng-container matColumnDef="courseName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Course Name</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label for="name">Course Name</mat-label>
          <input matInput type="text" formControlName="courseName" required />
        </mat-form-field>


      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="completedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Completed Date</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label for="name">Completed Date</mat-label>
          <input matInput [matDatepicker]="completedPicker" type="text" formControlName="completedDate" required />
          <mat-datepicker-toggle matSuffix [for]="completedPicker"></mat-datepicker-toggle>
          <mat-datepicker #completedPicker></mat-datepicker>

        </mat-form-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="trainer">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Trainer</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label for="name">Trainer</mat-label>
          <input matInput type="text" formControlName="trainer" required />
        </mat-form-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label for="name">Location</mat-label>
          <input matInput type="text" formControlName="location" required />
        </mat-form-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="outcome">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Outcome</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label for="name">Outcome</mat-label>
          <input matInput type="text" formControlName="outcome" required />
        </mat-form-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assessmentDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Assessment Date</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label for="name">Assessment Date</mat-label>
          <input matInput [matDatepicker]="assessmentPicker" type="text" formControlName="assessmentDate" required />
          <mat-datepicker-toggle matSuffix [for]="assessmentPicker"></mat-datepicker-toggle>
          <mat-datepicker #assessmentPicker></mat-datepicker>
        </mat-form-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assessor">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Assessor</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label for="name">Assessor</mat-label>
          <input matInput type="text" formControlName="assessor" required />
        </mat-form-field>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

</div>
