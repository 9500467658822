import { Component, Injectable, Inject, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { /*of,*/ tap } from 'rxjs/operators';
import { Observable, throwError} from 'rxjs';

//Service
import { UserAdminService, CompanyUserRole } from './company-admin-service';

@Component({
  selector: 'app-user-admin-list',
  templateUrl: './company-admin-list.component.html',
  styleUrls: ['./company-admin.component.css']
})

@Injectable()
export class UserAdminListComponent implements OnInit {

  roleCheckForm: FormGroup;
  formBuilder: FormBuilder;

  @Input() companyUsers: CompanyUserRole[] = [];
  newCompanyUser: CompanyUserRole[] = [];
  userAdminService: UserAdminService;
    saveStatus: string = "";
    errorShow: boolean = false;

  ngOnInit(): void {
      this.userAdminService.getCompanyAdminUsers().subscribe(

      companyUsers => {

        this.companyUsers = companyUsers
        this.createFormGroup(companyUsers);
        
          });

      this.saveStatus = "";
  }

  createFormGroup(users: CompanyUserRole[]) {

   // let groups: any = {};
   //console.warn("creating groups " + users.length);
      this.roleCheckForm = this.userAdminService.toFormGroup(users);

  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, formBuilder: FormBuilder, private companyAdminSer: UserAdminService) {

      this.userAdminService = this.companyAdminSer;
    this.formBuilder = formBuilder;
    
  }

  submit() {
      //console.warn(JSON.stringify(this.roleCheckForm.value));
      this.saveStatus = "Saving roles";

    for (let user in this.roleCheckForm.value) {
      let rolechange: CompanyUserRole = this.roleCheckForm.get(user).value;
      this.newCompanyUser.push(rolechange);
    }

    this.companyUsers = this.newCompanyUser;
    this.newCompanyUser = [];
      this.userAdminService.setCompanyUserRoles(this.companyUsers).subscribe(
          result => {
              if (result) {
                  this.saveStatus = "Role changes Saved";
                  this.errorShow = false;
              } else {
                  this.saveStatus = "Error - role change has not been successful";
                  this.errorShow = true;
              }
          }
      );

  }

  refresh() {
    this.ngOnInit();
  }

}


