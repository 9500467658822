<mat-card>
  <mat-card-title>
    Worker Information
  </mat-card-title>
  <mat-card-content>
    <div class="container-fluid" *ngIf="request">
      <div class="row">
        <div class="col">
          <mat-label>Name</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.firstName}} {{request.worker.lastName}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Preferred Name</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.preferredName}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Phone</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.phoneNumber}}</mat-label>
        </div>
      </div>      <div class="row">
        <div class="col">
          <mat-label>DOB</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.dateOfBirth | date:dateFormatting}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Email</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.email}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Weight</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.weight}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Availability</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.availability.length > 0?request.worker.availability[0].notes:"Any Time"}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Preferred Location</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.preferredTestLocation}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Job Title</mat-label>
        </div>
        <div class="col" *ngIf="request.pefaJob">
          <mat-label>{{request.pefaJob.name}}</mat-label>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>
    Referral Information
  </mat-card-title>
  <mat-card-content>
    <div class="container-fluid" *ngIf="request">
      <div class="row">
        <div class="col">
          <mat-label>Report Priority</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.responseTime}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Referral Type</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.referralType.name}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Company Name</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.company.name}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Referrer Name</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.referrerDetails.name}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Referrer Number</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.referrerDetails.phoneNumber}}</mat-label>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="provider">
  <mat-card-title>
    Provider Information
  </mat-card-title>
  <mat-card-content>
    <div class="container-fluid" *ngIf="request">
      <div class="row">
        <div class="col">
          <mat-label>Phone</mat-label>
        </div>
        <div class="col">
          <mat-label>{{provider.phoneNumber}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Address</mat-label>
        </div>
        <div class="col">
          <mat-label>{{provider.locations[0].address}}</mat-label>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
