import { FormControl, Validators, FormGroup, FormArray } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MessageService } from "../message/message.service";
import { MedicalClearance, Utility } from "../shared/shared-component";
import moment from "moment";


@Component({
  selector: 'dialog-modal-medical-clearance',
  templateUrl: 'medical-clearance-dialog.component.html',
})
export class MedicalClearanceDialog implements OnInit {

  conditions: MedicalClearance[];

  public medicalClearanceForm = new FormGroup({
    id: new FormControl(''),
    clearances: new FormArray([])
  });

  uploadSuccess = "";



  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<MedicalClearanceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pefaService: PEFARequestService, public messageService:MessageService) {
  }



  ngOnInit(): void {
    this.pefaService.getPEFAClearances(this.data.pefaId).subscribe(
      clearance => {
        this.conditions = clearance;


        for (let entry of this.conditions) {
          this.addClearancesForm(entry);
        };
      }, error => console.error(error));
  };

  addClearancesForm(condition: MedicalClearance) {
    if (condition.status == "PENDING" || condition.status == "NEW") {
      var clearancesList = this.medicalClearanceForm.get('clearances') as FormArray
      clearancesList.push(
        new FormGroup({
          selected: new FormControl(true),
          id: new FormControl(condition.id),
          condition: new FormControl(condition.condition),
          outcome: new FormControl(condition.outcome),
          outcomeDate: new FormControl(condition.outcomeDate?moment(condition.outcomeDate):''),
          outcomeNotes: new FormControl(condition.outcomeNotes),
          otherInfo: new FormControl(condition.otherInfo),
          status: new FormControl(condition.status),
          doctor: new FormGroup({
            id: new FormControl(''),
            name: new FormControl(''),
          })
        })

      )
    }


  }

  get clearances() {
    return this.medicalClearanceForm.get('clearances') as FormArray
  }


  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(files: File): void {
    this.medicalClearanceForm.controls.id.setValue(this.data.pefaId);
    var formData = new FormData();
    console.log("Clearances : " + this.clearances.length);
    for (var i = 0; i < this.clearances.length; i++) {
      // instead of passing this.arrayValues.toString() iterate for each item and append it to form.
      formData = Utility.convertModelToFormData(this.medicalClearanceForm.value, formData);
      if (this.clearances.at(i).get("selected").value) {

      }
    }

    console.log("Form : " + JSON.stringify(formData));
    let fileToUpload = <File>files[0];
    formData.append('file', fileToUpload, fileToUpload.name);

    this.pefaService.saveMedicalClearance(formData).subscribe(() => {
      this.messageService.add("Medical Clearance successfully saved");
      this.dialogRef.close(true);
      }
    );

  }


}
