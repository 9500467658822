import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { OfficeAdminPEFAService } from './office-admin-pefa.service';
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { OfficeAdminPEFAListComponent } from './office-admin-pefa-list.component';
import { HttpClient} from '@angular/common/http';
import { Company } from "../shared/shared-component";

@Component({
    selector: 'app-office-admin-pefa',
    templateUrl: './office-admin-pefa.component.html',
    styleUrls: ['./office-admin-pefa.component.css']
})

export class OfficeAdminPEFAComponent implements OnInit {
    private client: HttpClient;
    private baseURL: string;
    public filterText: string;

    //Counter: number = 5;

    @ViewChild(OfficeAdminPEFAListComponent, { static: false }) child: OfficeAdminPEFAListComponent;
    // public companys: Company[] = [];
    private officeAdminService: OfficeAdminPEFAService;
    //companyListForm: FormGroup;
    noCompany: number = -1;

    ngOnInit(): void {
        // this.officeAdminService.getAllCompany().subscribe(
        //     companys => {
        //         this.companys = companys 
        //     }, error => console.error(error) 
        // );

        // this.companyListForm = new FormGroup({
        //     CompanyList: new FormControl()
        // });

        // this.companyListForm.get('CompanyList').setValue(-1); //Setting the dropdown default to 'No Company'
    }

    constructor(OfficeAdminPEFAService: OfficeAdminPEFAService, public fb: FormBuilder, http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.officeAdminService = OfficeAdminPEFAService;
        this.client = http;
        this.baseURL = baseUrl;
    }


    onSubmit() {
        console.log("submit clicked");
    }

    public uploadFile(files) {

        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        return this.client.post<any>(this.baseURL + 'api/PEFARequests/UploadPEFADoc', formData).subscribe();
    }
}
