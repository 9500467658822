<div class="container-fluid">
  <h4>PEFA List</h4>
</div>


<!--<p *ngIf=""><em>Loading...</em></p>-->
<ol>
  <li>
    <mat-form-field>
      <input matInput [(ngModel)]="name" placeholder="What's your name?">
    </mat-form-field>
  </li>

  <li *ngIf="animal">
    You chose: <i>{{animal}}</i>
  </li>
</ol>

<table class='table'>
  <!--*ngIf="pefarequests"-->
  <thead>
    <tr>
      <th>Name</th>
      <th>Referral Type</th>
      <th>Job Title</th>
      <th>Job Site</th>
      <th>Requested By</th>
      <th>Appointment Date</th>
      <th>Status</th>
      <th>Score</th>
      <th></th>
      <th>Upload</th>
      <th>Download</th>
      <th>*Delete*</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let pefarequest of companypefas">
      <td *ngIf="pefarequest.worker"><a [routerLink]="['/pefarequest', pefarequest.id]">{{ pefarequest.worker.firstName}} {{ pefarequest.worker.lastName}}</a></td>
      <td>{{ pefarequest.referralType }}</td>
      <td *ngIf="pefarequest.job">{{ pefarequest.job.title }}</td>
      <td *ngIf="pefarequest.job">{{ pefarequest.job.site }}</td>
      <td>{{ pefarequest.requesterName }}</td>
      <td>{{ pefarequest.appointmentDate| date:dateFormat }}</td>
      <td>
      <!--{{ pefarequest.status }}-->
      </td>
      <td>
      <!--{{ pefarequest.score }}-->
      </td>
      <!--Need more information on 'status'  what status can we upload on?  Complete? all? a few?-->
      <td><button *ngIf="pefarequest.status == 'NEW'" (click)="accept(pefarequest.id)">Upload</button></td>
      <td>
        <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files,pefarequest.id)" style="display:none;">
        <!-- this will prevent button from rendering if score has no value, disabled for now -->
        <!--<button *ngIf="pefarequest.score" type="button" class="btn btn-success" (click)="file.click()">Upload File</button>-->
        <button type="button" class="btn btn-success" (click)="file.click()">Upload</button>
      </td>
      <!--This is currently a test download button-->
      <td>
        <!--<a class="btn btn-success" href="/api/PEFARequests/getAttachment/{{pefarequest.id}}/{{pefarequest.report.id}}">Download Report</a>-->
        <a class="btn btn-success" href="">Download Report</a>
      </td>
      <td>
        <button type="button" class="btn btn-danger" (click)="deletePEFARequest(pefarequest.id)">Delete - PEFA</button>
      </td>
    </tr>
  </tbody>
</table>
