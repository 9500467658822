import { Component, Inject, OnInit, Input, ResolvedReflectiveFactory } from "@angular/core";
import { Provider, Booking, BookingStatus, pefaBooking, BookingType, Assessor, Location, JobDemand, BookingJobDemand, Utility, PEFARequest, PEFAJob, DemandType, Company, Timezone } from "./shared-component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { MessageService } from "../message/message.service";
import { from, zip, of } from "rxjs";
import { groupBy, mergeMap, toArray } from "rxjs/operators";
import moment from "moment";
import { OfficePEFARequestService } from "../office-admin-pefarequest/office-admin-pefarequest.service";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { PEFAJobService } from "../pefajob/pefajob.service";

@Component({
  selector: 'dialog-modal-Update-booking',
  templateUrl: 'UpdateBooking.html',
  styleUrls: ['./office-admin-pefa.component.css']
})

export class UpdateBookingDialog {
  @Input()
  public request: PEFARequest;
  public requestId: string;
  providers: Provider[];

  bookingId: string;
  loading: boolean = true;
  bookingTypes: BookingType[];

  assessors: Assessor[];
  locations: Location[];
  groupedProviders: any[] = new Array();
  demands: JobDemand[] = new Array();
  company: Company = null;
  //Timezone Dropdown
  timezones= Timezone.getTimezone();
  //localtime
  localTime: string = Timezone.convertHhMmFromToTZ('9:00 AM', moment.tz.guess(), moment.tz.guess());
  selectedTimeZone: any;
   //Booking Hour
  bookHourTime: string = '9:00 AM';
  bookingDate = moment();
  showHasNoDemands: boolean = false;

  public bookingForm = new FormGroup({
    id: new FormControl(''),
    provider: new FormGroup({
      id: new FormControl('', Validators.required),
      name: new FormControl(''),
    }),
    bookingLocation: new FormGroup({
      id: new FormControl('')
    }),
    availability: new FormControl(''),
    appointmentDateTime: new FormControl(moment().tz(), Validators.required),
    appointmentTime: new FormControl(''),
    appointmentTimezone: new FormControl(''),
    status: new FormControl(''),
    workerStatus: new FormControl(''),
    notes: new FormControl(''),
    assessor: new FormGroup({
      id: new FormControl('', Validators.required),
      name: new FormControl(''),
    }),
    bookingType: new FormGroup({
      id: new FormControl('', Validators.required),
      displayName: new FormControl(''),
    }),
    tests: new FormArray([]),

  });
    booking: Booking;
  selectedProvider: Provider;
    minBookingDate = moment();
    showAll: boolean = false;
    checked: boolean = true;


  ngOnInit(): void {
    this.loading = true;
    if (this.data.holdDate) {
      this.minBookingDate = moment(this.data.holdDate);
    }

        this.officeAdminService.getPEFABooking(this.bookingId).subscribe(
          Response => {
            this.booking = Response;
            if (!Response.assessor) {
              delete Response.assessor;
            }


            if (!Response.location) {
              delete Response.location;
            }
            this.bookingForm.patchValue(Response);
            var availability = Response.pefArequest.worker.availability[0];
            this.bookingForm.patchValue({ availability: availability?.notes });
            this.demands = Response.pefArequest.pefaJob.demands;

            this.bookingForm.patchValue({ appointmentDateTime: moment(this.booking.appointmentDateTime) });

            this.booking.appointmentTimezone = this.booking.appointmentTimezone ?? Timezone.DEFAULT;
            let apptDateTime = Timezone.convertUtcToTZAsMomentObject(this.booking.appointmentDateTime, this.booking.appointmentTimezone);
            this.bookingForm.patchValue({ appointmentTime: apptDateTime.format('h:mm A') });
            this.selectedTimeZone = this.booking.appointmentTimezone;
            this.bookingForm.patchValue({ appointmentTimezone: this.selectedTimeZone });

            this.bookingDate = apptDateTime;
            this.bookHourTime = apptDateTime.format('h:mm A');
            this.localTime = Timezone.convertHhMmFromToTZ(this.bookHourTime, this.selectedTimeZone, moment.tz.guess());
            this.demands.sort((a, b) => {
              return (a.demandType.type === 'MH' ? 10 : 0) - (b.demandType.type === 'MH' ? 10 : 0) + a.demandType.orderAssessment - b.demandType.orderAssessment;
              
            });

            for (let demand of this.demands) {
              if (demand.demandType.type === 'ST' || demand.demandType.type === 'DT') {
                this.addDemand(demand);
              }
              if (demand.demandType.type === 'MH') {
                if (demand.frequency === 'O') {
                  this.addDemand(demand);
                }
              }
            }

            // check if we may be missing Job Demands
            try {
              this.showHasNoDemands = true;
              for(var d of this.demands) {
                if (d.demandType.type === 'MH' && parseFloat(d.value) > 0.0) {
                  this.showHasNoDemands = false;
                  break;
                }
                if ((d.demandType.type === 'ST' || d.demandType.type === 'DT') && d.frequency != 'NT') {
                  this.showHasNoDemands = false;
                  break;
                }          
              }
            } catch (e: any) { console.error(e); }

            for (let entry of this.booking.tests) {
              this.addCurrentTest(entry);
            };

            this.officeAdminService.getActiveProviders().subscribe(
              Response => {
                this.providers = Response
                this.providers = this.providers.filter(p => this.hasInsurance(p));
                if (this.providers.findIndex(p => p.id == this.booking.provider.id) == -1) {                 
                    this.providers.push(this.booking.provider);
                    this.generateGroupProviders(this.providers);                  
                } else {

                  this.generateGroupProviders(this.providers);
                }

                this.selectedProvider = this.providers.find(r => r.id == this.booking.provider.id);
                this.updateAssessorList();

              }, error => console.error(error));
          }, error => console.error(error)
        );

    this.officeAdminService.getAllBookingTypes().subscribe(
      Response => {
        this.bookingTypes = Response
        this.loading = false;
      }, error => console.error(error));

    this.pefaService.getCompany(this.data.pefaId).subscribe(result => {
      this.company = result;
    });      
  }

  hasInsurance(provider: Provider): boolean {
    var result = false;
    if (provider.providerAudits && provider.providerAudits.length > 0) {
      var audit = provider.providerAudits.reduce((p, c) => p.auditDate > c.auditDate ? p : c);

      if (audit) {
        result = (moment().isBefore(moment(audit.indeminityInsurancePolicyExpiry)) && moment().isBefore(moment(audit.liabilityInsurancePolicyExpiry)));
      }
    }

    return result;
  }


  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpdateBookingDialog>,
    @Inject(MAT_DIALOG_DATA) public data: pefaBooking, public pefaService: PEFARequestService, public officeAdminService: OfficeAdminPEFAService, public messageService: MessageService) {
    this.bookingId = this.data.bookingId;    
  }

  //openCancelDialog(): void {
  //    const dialogRef = this.dialog.open(CancelBookingDialog, {
  //        width: '500px',
  //        height: '350px',
  //        data: {}
  //    });

  //    dialogRef.afterClosed().subscribe(result => {
  //        console.log("Cancel closed")
  //    });
  //}

  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  savebooking(): void {
    this.bookHourTime =  this.bookingForm.controls['appointmentTime'].value
    this.bookingDate = this.bookingForm.controls['appointmentDateTime'].value;
    let apptDateTime = moment.tz(`${this.bookingDate.format('YYYY-MM-DD')} ${this.bookHourTime}`, 'YYYY-MM-DD hh:mm A', this.selectedTimeZone);

    this.bookingForm.patchValue({ appointmentDateTime: moment(apptDateTime.format()) });
    const formData = this.bookingForm.value;

    formData.tests = formData.tests.filter(obj => obj.selected === true);
    for (let i in formData.tests) {
      if (formData.tests[i].id === null) {
        delete formData.tests[i].id;
      }

    }

    this.officeAdminService.saveBooking(this.data.bookingId.toString(), formData).subscribe(
      Response => {
        this.messageService.add("Booking successfully updated");
        this.dialogRef.close(true);//this needs to reflect on success of saving
      }
      , error => {
        console.error(error);
        this.messageService.add("Booking failed to update");
        this.dialogRef.close(false);//this needs to reflect on success of saving
      });

  }
  onTimezoneChange(event: any) {
    this.selectedTimeZone = event.value;
    this.localTime = Timezone.convertHhMmFromToTZ(this.bookHourTime, this.selectedTimeZone, moment.tz.guess());
    this.bookingDate = Timezone.convertUtcToTZAsMomentObject(this.booking.appointmentDateTime, this.selectedTimeZone);
  }
  onTimeChange(event: any) {
    this.bookHourTime = event?.target?.value;
    this.localTime = Timezone.convertHhMmFromToTZ(this.bookHourTime, this.selectedTimeZone, moment.tz.guess());
    this.bookingDate = Timezone.convertUtcToTZAsMomentObject(this.booking.appointmentDateTime, this.selectedTimeZone);
  }

  bookingDateChanged(event: any) {
    this.bookingDate = event.value;
  }
  updateAssessorList(): void {
    var providerId = this.bookingForm.get("provider").get("id").value;

    this.selectedProvider = this.providers.find(r => r.id == providerId.toString());
    this.officeAdminService.getAssessorsForProvider(providerId).subscribe(result => this.assessors = result);

    this.locations = this.selectedProvider?.locations.filter(l => l.type == "physical");
    if (this.locations && this.locations.length > 0) {
      this.bookingForm.get("bookingLocation").get("id").setValue(this.locations[0].id);
    } else {
      this.bookingForm.get("bookingLocation").get("id").setValue("");
    }
  }

  public get bookStatusEnum() {
    return BookingStatus
  }

  public compareStatus(obj1: any, obj2: any): boolean {
    return obj1 === obj2;
  }

  get tests() {
    return this.bookingForm.get('tests') as FormArray
  }


  addCurrentTest(condition: BookingJobDemand) {

    for (let demand of this.tests.controls) {
      if (demand.get("demandTypeId").value === condition.demandTypeID) {
        demand.get("id").setValue(condition.id);
        demand.get("selected").setValue(true);
      }
    }
      

  }


  addDemand(condition: JobDemand) {
    var testList = this.tests.push(
      new FormGroup({
        selected: new FormControl(false),
        id: new FormControl(),
        demandTypeId: new FormControl(condition.demandType.id),
        name: new FormControl(condition.demandType.name),
      })

    )
  }

  classForProvider(provider: Provider): string {
    var color = 'active-provider';
    if (!this.hasInsurance(provider)) {
      // color = 'red-provider';
      color = 'active-noinsurance-provider';
    } else if (provider.status == 'PENDING') {
      color = 'pending-provider';

    } else if (provider.status == 'ONHOLD') {
      color = 'hold-provider';

    } else if (provider.status == 'INACTIVE') {
      color = 'red-provider';

    }
    return color;
  }

  showAllItems() {

    this.showAll = !this.showAll;

    if (this.showAll) {
      this.officeAdminService.getAllProviders().
        subscribe(
          Response => {
            this.providers = Response

            this.generateGroupProviders(this.providers);

          }, error => console.error(error));

    } else {
      this.officeAdminService.getActiveProviders().
        subscribe(
          Response => {
            this.providers = Response
            this.providers = this.providers.filter(p => this.hasInsurance(p));
            if (this.providers.findIndex(p => p.id == this.booking.provider.id) == -1) {
              this.officeAdminService.getProvider(this.booking.provider.id).subscribe(res => {
                this.providers.push(res);
                this.generateGroupProviders(this.providers);
              });
            } else {

              this.generateGroupProviders(this.providers);
            }

          }, error => console.error(error));

    }

  }


  private generateGroupProviders(providers: Provider[]) {
    this.groupedProviders = new Array();
    var flatProviders: any[] = new Array();
    providers.forEach(function (provider) {

      provider.locations.forEach(function (location) {
        if (location.type == "physical") {

          var providerClone = Object.assign({}, provider);
          providerClone.location = location;
          flatProviders.push(providerClone);
        }
      });
    });

    const source = from(flatProviders);

    const pipeline = source.pipe(
      groupBy(
        providers => providers.location.region
      ),
      mergeMap(group => zip(of(group.key), group.pipe(toArray())))
    );

    pipeline.subscribe(response => {
      this.groupedProviders.push(response);
    });
  }

  onChangeDemand(evt: any) {    
    // demandType.id (12 = Ladder Climb, 6 = Stair Climb)
    let ladder = this.bookingForm.value.tests.find(d => d.demandTypeId == 12);
    let stair = this.bookingForm.value.tests.find(d => d.demandTypeId == 6);

    if (ladder && stair) {
      if (evt.checked == true) {
        // if ladder climb is selected, also select stair climb
        if (ladder.selected == true && stair.selected == false) {        
          let control = this.tests.controls.find(c => c.value.demandTypeId == 6);          
          control.get('selected').setValue(true);
        } 
      } else { // uncheck
        // if stair is deselected, also deselect stair climb
        if (stair.selected == false && ladder.selected == true) {
          let control = this.tests.controls.find(c => c.value.demandTypeId == 12);
          control.get('selected').setValue(false);
        }  
      }
    }
  }

  onSelectAll() {
    for (let demand of this.tests.controls) {
      demand.get("selected").setValue(true);
    }
  }

  onDeSelectAll() {
    for (let demand of this.tests.controls) {
      demand.get("selected").setValue(false);
    }        
  }
}
