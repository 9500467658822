<p *ngIf="!pefarequest"><em>Loading...</em></p>
<mat-accordion *ngIf="pefarequest" class="container-fluid" multi="true">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <h2>Participant Details</h2>
    </mat-expansion-panel-header>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="FirstName">
        First Name
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.worker.firstName}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="LastName">
        Last Name
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.worker.lastName}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="emailAddress">
        Email Address
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.worker.emailAddress}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="dateOfBirth">
        Date Of Birth
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.worker.dateOfBirth | date:'shortDate'}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="mobile">
        Mobile
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.worker.mobile}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="home">
        Home Phone Number
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.worker.home}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="work">
        Work Phone Number
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.worker.work}}</p>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true">

    <mat-expansion-panel-header>
        <h2>Job Details</h2>
    </mat-expansion-panel-header>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="title">
        job Title
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.pefaJob.name}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="jobSite">
        job Site
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.pefaJob.site}}</p>
      </div>
    </div>
  </mat-expansion-panel>



  <mat-expansion-panel  expanded="true">
    <mat-expansion-panel-header>
        <h2>Referral Details</h2>
    </mat-expansion-panel-header>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="Recruiter">
        Recruiter
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.recruiter}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="AppointmentDate">
        Appointment Due Date
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.appointmentDate | date:'shortDate'}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="ResponseTime">
        Report Due Date
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.responseTime}}</p>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="ReferralType">
        Referral Type
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.referralType.name}}</p>

      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="PaymentMethod">
        Payment Method
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.paymentMethod}}</p>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="otherInfo">
        Other Notes
      </label>
      <div class="col-sm-7">
        <p class="form-control">{{pefarequest.otherInfo}}</p>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>

<mat-tab-group dynamicHeight="true">
  <mat-tab class="bookingsTab" label="Bookings">
    Booking
  </mat-tab>
  <mat-tab class="notesTab" label="Notes">
    Notes
  </mat-tab>
  <mat-tab class="messagesTab" label="Messages">
    Messages
  </mat-tab>

  <mat-tab class="attachmentsTab" label="Attachments">
    Attachments
  </mat-tab>

</mat-tab-group>
