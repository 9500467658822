<mat-toolbar *ngIf="!loading" class="mat-accent">{{provider?.name}}</mat-toolbar>
<form [formGroup]="providerForm" (ngSubmit)="saveChanges()">
  <mat-tab-group dynamicHeight style="width:90%" *ngIf="!loading">
    <mat-tab label="Details">

      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="name">Name</mat-label></div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="name" required />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="tier">Tier</mat-label></div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select type="text" formControlName="providerTier" required>
              <mat-option value="TIER 1">TIER 1</mat-option>
              <mat-option value="TIER 2">TIER 2</mat-option>
              <mat-option value="TIER 3">TIER 3</mat-option>
              <mat-option value="TIER 4">TIER 4</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>


      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="status">Status</mat-label></div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select type="text" formControlName="status" required>
              <mat-option value="PENDING">PENDING</mat-option>
              <mat-option value="ACTIVE">ACTIVE</mat-option>
              <mat-option value="INACTIVE">INACTIVE</mat-option>
              <mat-option value="ONHOLD">ON HOLD</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="name">Status Note</mat-label></div>
        <div class="col-md-4">
          <mat-form-field>
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutoSizeMaxRows="5"
                      formControlName="statusNote"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Company Number</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="companyNumber" required />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Account Email</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="accountEmail" required />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Contact Email</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="contactEmail" required />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Phone Number</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="phoneNumber" required />
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-2">
          <mat-label for="name">Fax Number</mat-label>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="faxNumber" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="ladderRatingKG">Ladder Rating (KG)</mat-label></div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput type="text" formControlName="ladderRatingKG" required />
          </mat-form-field>
        </div>
      </div>
      <div class="form-group form-row">
        <div class="col-md-2"><mat-label for="consentLocation">Consent Location</mat-label></div>
        <div class="col-md-4">
            <mat-radio-group formControlName="consentLocation">
              <mat-radio-button value="ASSESSOR">Assesssor</mat-radio-button>
            <mat-radio-button value="ADMIN">Admin</mat-radio-button>
            </mat-radio-group>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Locations">
      <button (click)="addLocation()" mat-raised-button class="mat-accent" type="button">Add Location</button>
      <div formArrayName="locations" *ngIf="!loading">

        <div *ngFor="let location of locations.controls; let i=index">
          <app-location-detail [index]="j" [locationForm]="location" (deleteEvent)="deleteLocation($event)"></app-location-detail>
        </div>
      </div>


    </mat-tab>

    <mat-tab label="Audits">
      <span *ngIf="provider">
        <a mat-raised-button style="text-decoration: none; margin-top: 10px; margin-left: 15px; margin-bottom: 10px;" href="{{this.jotFormUrl}}" target="_blank">New Audit</a>
      </span>
      <div *ngIf="!loading">

        <ng-template #NoCompanyToList>
          <p>There are no audits for this provider</p>
        </ng-template>

        <div class="container-fluid" *ngIf="provider && provider.providerAudits && provider.providerAudits.length > 0; else NoCompanyToList">
          <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="auditDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Audit Date</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.auditDate | date:dateFormat}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="indeminityInsurancePolicyExpiry">
              <mat-header-cell *matHeaderCellDef mat-sort-header>PI Expiry</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.indeminityInsurancePolicyExpiry | date:dateFormat}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="indeminityInsurancePolicyNo">
              <mat-header-cell *matHeaderCellDef mat-sort-header>PI Policy #</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{row.indeminityInsurancePolicyNo}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="indeminityInsurancePolicyAmount">
              <mat-header-cell *matHeaderCellDef mat-sort-header>PI Amount</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>{{ row.indeminityInsurancePolicyAmount }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="liabilityInsurancePolicyExpiry">
              <mat-header-cell *matHeaderCellDef mat-sort-header>PL Expiry</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.liabilityInsurancePolicyExpiry | date:dateFormat}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="liabilityInsurancePolicyNo">
              <mat-header-cell *matHeaderCellDef mat-sort-header>PL Policy #</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{row.liabilityInsurancePolicyNo}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="liabilityInsurancePolicyAmount">
              <mat-header-cell *matHeaderCellDef mat-sort-header>PL Amount</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>{{ row.liabilityInsurancePolicyAmount }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="workerCompPolicyExpiry">
              <mat-header-cell *matHeaderCellDef mat-sort-header>WC Expiry</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.workerCompPolicyExpiry | date:dateFormat}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="workerCompPolicyNo">
              <mat-header-cell *matHeaderCellDef mat-sort-header>WC Policy #</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{row.workerCompPolicyNo}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="workerCompPolicyAmount">
              <mat-header-cell *matHeaderCellDef mat-sort-header>WC Amount</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>{{ row.workerCompPolicyAmount }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="attachment">
              <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>
                  <a mat-raised-button matTooltip="Edit" *ngIf="row.submissionLink != null" style="text-decoration: none;" href="https://www.jotform.com/edit/{{row.submissionLink}}" target="_blank"><mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon></a>
                  <a mat-raised-button matTooltip="Copy" *ngIf="row.submissionLink != null" style="text-decoration: none;" (click)="openFormBasedOnSubmission(row.submissionLink)"><mat-icon aria-hidden="false" aria-label="Copy">content_copy</mat-icon></a>
                </span>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>

        </div>
        <mat-paginator [pageSizeOptions]="[10,25]"></mat-paginator>

      </div>


    </mat-tab>

    <mat-tab label="People" *ngIf="provider">
      <div style="margin: 15px 0 15px 0;">
        <button type="button" mat-raised-button class="mat-primary" (click)="openAddPerson()">Add New Person</button>&nbsp;&nbsp;
        <button type="button" mat-raised-button class="mat-primary" (click)="openRelationshipDialog()">Add Existing Person</button>
      </div>        

      <ng-template #NoProviderToList>
        <p *ngIf="loading == false">There are no Relationships</p>
      </ng-template>

      <div *ngIf="provider && provider.people && provider.people.length > 0; else NoProviderToList">
        <div class="mat-elevation-z8">
          <mat-table #table [dataSource]="peopleDataSource" matSort>

            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCell mat-sort-header>id</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{row.id}}</span>></mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.person.firstName}} {{ row.person.lastName}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{row.status}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{row.person.email}}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.type }}</span></mat-cell>
              <mat-label for="type">Type</mat-label>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
              <mat-cell *matCellDef="let row"><span>{{ row.startDate | date:dateFormat }}</span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="endDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
              <mat-cell *matCellDef="let row"><span *ngIf="row.endDate else elseName">{{ row.endDate| date:dateFormat }}</span></mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button (click)="editPerson(row.id)" mat-raised-button type="button"><mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon></button>
                <button (click)="deletePerson(row.id)" mat-raised-button type="button"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="peopleDisplayedColumns; sticky:true;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: peopleDisplayedColumns"></mat-row>

          </mat-table>
        </div>

        <ng-template #elseName>
          <span>&nbsp;</span>
        </ng-template>

      </div>
      <mat-paginator></mat-paginator>
    </mat-tab>

    <mat-tab label="Bookings" *ngIf="provider">
      <ng-template matTabContent>
        <app-provider-booking-list [provider]="provider"></app-provider-booking-list>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
  <button mat-raised-button [disabled]="!providerForm.valid" class="mat-primary" type="submit" >Save Changes</button>
</form>
