<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <h1 mat-dialog-title>Send Message</h1>
    </div>
  </div>
</div>


<div mat-dialog-content *ngIf="pefaRequest">

  <form [formGroup]="messageForm">

    <div class="form-group row">
      <label class="col-sm-4 col-4 col-form-label" for="messageTemplate">
        Message Template
      </label>
      <div class="col-sm-8 col-8">
        <mat-form-field>
          <mat-select [(value)]="selectedTemplate" (selectionChange)="updateContent()">
            <mat-option>----</mat-option>
            <mat-option *ngFor="let template of messageTemplates" [value]="template">{{template.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="selectedTemplate && selectedTemplate.object == 'BOOKING' && bookings.length > 0" class="form-group row">
      <label class="col-sm-4 col-4 col-form-label" for="bookingid">
        Booking
      </label>
      <div class="col-sm-8 col-8">
        <mat-form-field>

          <mat-select formControlName="bookingId" panelClass="bigPanel" (selectionChange)="updateContent()">
            <mat-option *ngFor="let booking of bookings" [value]="booking.id">{{booking.bookingType.name}} <span *ngIf="booking.provider"> Provider : {{booking.provider.name}}</span><span *ngIf="booking.assessor">  Assessor : {{booking.assessor.firstName}} {{booking.assessor.lastName}}</span></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-4 col-form-label" for="messageTemplate">
        Message Type
      </label>
      <div class="col-sm-8 col-8">
        <mat-radio-group formControlName="type">
          <mat-radio-button value="SMS">SMS</mat-radio-button>
          <mat-radio-button value="EMAIL">Email</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-4 col-form-label" for="messageTemplate">
        To
      </label>
      <div class="col-sm-8 col-8">
        <mat-radio-group formControlName="to">
          <mat-radio-button value="WORKER">Worker ({{pefaRequest.worker.firstName}} {{pefaRequest.worker.lastName}})</mat-radio-button>
          <mat-radio-button value="REFERRER">Referrer ({{pefaRequest.referrerDetails.name}})</mat-radio-button>
          <mat-radio-button *ngIf="bookings.length > 0" value="ASSESSOR">Assessor ({{getAssessorName()}})</mat-radio-button>
          <mat-radio-button *ngIf="bookings.length > 0" value="PROVIDER">Provider ({{getProviderName()}})</mat-radio-button>
        </mat-radio-group>

      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-4 col-form-label" for="messageTemplate">
        Subject
      </label>
      <div class="col-sm-8 col-8">
        <mat-form-field>
          <input matInput type="text" formControlName="subject" class="bigPanel">
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-4 col-form-label" for="messageTemplate">
        Content
      </label>
      <div class="col-sm-8 col-8">
        <mat-form-field>

          <textarea matInput matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=25

                    mat formControlName="content" cols="150">
        </textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">
        Attachments
      </label>
    </div>
    <div formArrayName="attachments">
        <div class="row" *ngFor="let attach of attachments.controls; let j=index" [formGroupName]="j">
          <div class="col-4">
            <mat-checkbox formControlName="selected"
                          [labelPosition]="after">
              {{attach.controls.name.value}}
            </mat-checkbox>
            <input type="hidden" formControlName="id" />
          </div>
          <div class="col-4">
            <p>{{attach.controls.type.value}}</p>
          </div>
          <div class="col-4">
            <p>{{attach.controls.date.value | date:dateFormat}}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
    </div>

</form>



</div>

<div mat-dialog-actions style="margin-top: 2rem;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <button mat-raised-button color="primary" (click)="onNoClick(false)">Exit</button>
      </div>
      <div class="col-6 text-center">
      </div>
      <div class="col-4">
        <!--<button mat-raised-button color="warn" [mat-dialog-close]="reason" [disabled]="false" cdkFocusInitial (click)="onYesClick()">Cancel booking</button>-->
        <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="onSubmit()" [disabled]="!messageForm.valid">Send Message</button>
      </div>
    </div>
  </div>
</div>
