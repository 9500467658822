  <form [formGroup]="AssessorForm" (ngSubmit)="saveChanges()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Assessor Details</mat-card-title>
      </mat-card-header>
      <input type="hidden" formControlName="id" />
      <mat-card-content>
        <mat-tab-group dynamicHeight>
          <mat-tab label="Details">
            <mat-form-field>
              <mat-label for="name">First Name</mat-label>
              <input matInput type="text" formControlName="firstName" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Last Name</mat-label>
              <input matInput type="text" formControlName="lastName" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Phone Number</mat-label>
              <input matInput type="text" formControlName="phoneNumber" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Email</mat-label>
              <input matInput type="text" formControlName="email" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">JFSI ID</mat-label>
              <input matInput type="text" formControlName="jfsiid" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Qualifications</mat-label>
              <input matInput type="text" formControlName="qualifications" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Discipline</mat-label>
              <input matInput type="text" formControlName="discipline" />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Job Title</mat-label>
              <input matInput type="text" formControlName="title" />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Industry</mat-label>
              <input matInput type="text" formControlName="industry" />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Location</mat-label>
              <input matInput type="text" formControlName="location" />
            </mat-form-field>
            <mat-form-field>
              <mat-label for="name">Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option value="ACTIVE">ACTIVE</mat-option>
                <mat-option value="PENDING">PENDING</mat-option>
                <mat-option value="ON HOLD">ON HOLD</mat-option>
                <mat-option value="INACTIVE">INACTIVE</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label for="name">Notes</mat-label>
              <textarea matInput cols="50" rows="5" formControlName="notes">
          </textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Relationships">

            <ng-template #NoProviderToList>
              <p *ngIf="loading == false">There are no Relationships</p>
            </ng-template>

            <div *ngIf="assessor && assessor.relationships && assessor.relationships.length > 0; else NoProviderToList">

              <!--<p *ngIf=""><em>Loading...</em></p>-->
              <div class="mat-elevation-z8">
                <mat-table #table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span><a [routerLink]="['/office-company-detail', row.company.id]">{{ row.company.name}}</a></span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{ row.status }}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{ row.type }}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="startDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{ row.startDate | date:dateFormat }}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="endDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span *ngIf="row.endDate else elseName">{{ row.endDate| date:dateFormat }}</span></mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>

                </mat-table>
              </div>

              <ng-template #elseName>
                <span>&nbsp;</span>
              </ng-template>

              <!---->
            </div>
            <mat-paginator></mat-paginator>

            <div>
              <button type="button" mat-raised-button class="mat-primary" (click)="openRelationshipDialog()">Add Relationship</button>
            </div>

          </mat-tab>
          <mat-tab label="Training" *ngIf="assessor">
            <app-training-list [assessor]="assessor" [assessorForm]="AssessorForm"></app-training-list>
          </mat-tab>
          <mat-tab label="Insurance" *ngIf="assessor">

            <div *ngIf="!loading">

              <ng-template #NoCompanyToList>
                <p>There are no details for this asssessor</p>
              </ng-template>

              <button mat-raised-button class="mat-primary" (click)="addInsurance()" type="button">Add Insurance</button>

              <div class="container-fluid" *ngIf="assessor?.audits && assessor?.audits.length > 0; else NoCompanyToList">
                <mat-table class="mat-elevation-z8" #table [dataSource]="auditsDataSource" matSort>

                  <ng-container matColumnDef="auditDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Audit Date</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{ row.auditDate | date:dateFormat}}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="indeminityInsurancePolicyExpiry">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>PI Expiry</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{ row.indeminityInsurancePolicyExpiry | date:dateFormat}}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="indeminityInsurancePolicyNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>PI Policy #</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{row.indeminityInsurancePolicyNo}}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="indeminityInsurancePolicyAmount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>PI Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span>{{ row.indeminityInsurancePolicyAmount }}</span>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="liabilityInsurancePolicyExpiry">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>PL Expiry</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{ row.liabilityInsurancePolicyExpiry | date:dateFormat}}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="liabilityInsurancePolicyNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>PL Policy #</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span>{{row.liabilityInsurancePolicyNo}}</span></mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="liabilityInsurancePolicyAmount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>PL Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span>{{ row.liabilityInsurancePolicyAmount }}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>

              </div>
              <mat-paginator [pageSizeOptions]="[10,25]"></mat-paginator>

            </div>


          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
      <mat-card-actions>
        <button type="button"  mat-raised-button class="mat-primary" (click)="saveChanges()">Save Changes</button>
      </mat-card-actions>
    </mat-card>
  </form>
