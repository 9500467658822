<div [formGroup]="relationshipForm">

<!--  <app-new-person></app-new-person>
  <button type="button" mat-raised-button class="mat-primary" (click)="openAddPerson()">Add Person</button>
-->
  <div class="form-row" *ngIf="!originalCompanyId">
    <div class="col">

      <mat-form-field>
        <input id="name" type="text" placeholder="Type or Select a Company" matInput [matAutocomplete]="auto" (keyup)="onCompanyKey($event)" required>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName" (optionSelected)="setCompanyFormData($event)">
          <mat-option *ngFor="let company of filteredCompanies" [value]="company">
            {{company.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="form-row" *ngIf="!originalPersonId">
    <div class="col">

      <mat-form-field>
        <input id="person" type="text" placeholder="Type or Select a Person" matInput [matAutocomplete]="autoPerson" (keyup)="onPersonKey($event)" required>
        <mat-autocomplete #autoPerson="matAutocomplete" [displayWith]="displayPersonName" (optionSelected)="setPersonFormData($event)">
          <mat-option disabled class="loading" *ngIf="!filteredPeople">
            <mat-spinner diameter="35"></mat-spinner>
          </mat-option>
          <mat-option *ngFor="let person of filteredPeople" [value]="person">
            {{person.firstName}} {{person.lastName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <mat-label for="type">Type</mat-label>
        <mat-select formControlName="type" required>
          <mat-option value="REPORT_WRITER">Report Writer</mat-option>
          <mat-option value="EMPLOYEE">Employee</mat-option>
          <mat-option value="ASSESSOR">Assessor</mat-option>
          <mat-option value="REFERRER">Referrer</mat-option>
          <mat-option value="RECEIVER">Receiver</mat-option>
          <mat-option value="STATS">Stats</mat-option>
          <mat-option value="PIONLY">PI Only</mat-option>
          <mat-option value="QA">Quality Assurnce</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <mat-label for="type">Status</mat-label>
        <mat-select formControlName="status">
          <mat-option value="ACTIVE">ACTIVE</mat-option>
          <mat-option value="PENDING">PENDING</mat-option>
          <mat-option value="CANCELLED">CANCELLED</mat-option>
          <mat-option value="EXPIRED">EXPIRED</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startpicker" formControlName="startDate" required />
        <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
        <mat-datepicker #startpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="apptpicker" formControlName="endDate" />
        <mat-datepicker-toggle matSuffix [for]="apptpicker"></mat-datepicker-toggle>
        <mat-datepicker #apptpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
<!--<div mat-dialog-actions style="margin-top: 2rem;">-->
<mat-dialog-actions>
  <button mat-raised-button color="primary" mat-button (click)="onNoClick(false)">Close</button>
  <button mat-raised-button color="mat-primary" (click)="saveRelationship()" [disabled]="!relationshipForm.valid">Save</button>
</mat-dialog-actions>
<!--</div>-->
