<mat-toolbar class="mat-primary">
  <mat-toolbar-row>To Do</mat-toolbar-row>

  <mat-toolbar-row class="mat-body-2 row">
    <!--<button class="col-2" matTooltip="Create ToDo" (click)="newTodo()" mat-raised-button href="">Create New</button>-->
    <div class="col-2">
      <button mat-button (click)="newTodo()">Create To Do</button>
    </div>
    <div class="col-6">
      <mat-button-toggle-group multiple name="fontStyle" aria-label="Font Style" (change)="applyFilter($event)" #group>
        <mat-button-toggle style="padding:5px" value="billing">Billing</mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="cc">CC</mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="waiting"><span>Waiting</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="Reminder"><span>Reminder</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="pending"><span>Pending</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="urgent"><span>Urgent</span></mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="col-2"></div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mat-elevation-z8">
  <mat-table #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.description}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="worker">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pefaRequest.worker.lastName">Worker</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.pefaRequest"><a [routerLink]="['/office-admin-pefa-detail', row.pefaRequest.id]">{{ row.pefaRequest?.worker.firstName}} {{ row.pefaRequest?.worker.lastName}}</a></span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="company">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pefaRequest.company.name">Company</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.pefaRequest">{{ row.pefaRequest?.company.name}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.type}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pefaRequest.responseTime">Priority</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.pefaRequest?.responseTime }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assignedTo">
      <mat-header-cell *matHeaderCellDef mat-sort-header="assignedTo.userName">Assigned To</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.assignedTo?.userName }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="urgent">
      <mat-header-cell *matHeaderCellDef mat-sort-header="urgent">Urgent</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span><mat-icon *ngIf="row.urgent" matTooltipClass="class-tooltip" class="mat-warn" aria-hidden="false" aria-label="Important">priority_high</mat-icon></span>
        <div *ngIf="row.pefaRequest?.bookings?.assessor?.user; then app else notUsingApp"></div>
        <span>
          <ng-template app> (PA)</ng-template>
          <ng-template #notUsingApp></ng-template>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{dateColTitle}}</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>{{ row.dueDate | date:dateFormat }}<br/>{{ row.dueDate | date:timeFormat }}<div *ngIf="row.appointmentTimezone">{{row.appointmentTimezone}}<div style="font-size: 0.8em; background-color: #e1e1e1; border-radius: 4px; padding: 2px; margin-bottom: 2px; display: inline-block;">My Time: {{row.myTime}}</div><br/></div></div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
      <mat-cell *matCellDef="let row"><app-todo-actions [todo]="row" (updated)="loadTodos()"></app-todo-actions></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>

  </mat-table>
</div>

<ng-template #elseName>
  <span>&nbsp;</span>
</ng-template>
