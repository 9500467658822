<ng-container class="{{themeClass}}">
  <app-top-menu  class="{{themeClass}}" (menuToggle)="appNav.toggle()"></app-top-menu>
  <!--<div class='col-md-2 d-none d-md-block bg-light sidebar'>-->
  <app-nav-menu class="{{themeClass}}" #appNav></app-nav-menu>
  
  <!--<main role="main" class="col-md-10 ml-sm-auto col-lg-10 px-4">
      <router-outlet></router-outlet>
  </main>-->
  
  <!-- <div class="footer">Footer</div> -->
</ng-container>
