import { Component, Inject, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BusinessCalendar, Timing } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import { MessageService } from '../message/message.service';
import { ConfigService } from '../config/config.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { BusinessCalendarTimeComponent } from './business-calendar-time.component';


@Component({
  selector: 'app-business-calendar',
  templateUrl: './business-calendar.component.html',
})
export class BusinessCalendarComponent implements OnInit {
  public calendar: BusinessCalendar;
  public loading: boolean = true;

  public holidays: string[];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('monday') monday: BusinessCalendarTimeComponent;
  @ViewChild('tuesday') tuesday: BusinessCalendarTimeComponent;
  @ViewChild('wednesday') wednesday: BusinessCalendarTimeComponent;
  @ViewChild('thursday') thursday: BusinessCalendarTimeComponent;
  @ViewChild('friday') friday: BusinessCalendarTimeComponent;
  @ViewChild('saturday') saturday: BusinessCalendarTimeComponent;
  @ViewChild('sunday') sunday: BusinessCalendarTimeComponent;

  ngOnInit(): void {

    this.sharedService.getBusinessCalendarSettings(true).subscribe(results => {
      this.calendar = results;
      this.holidays = results.holidays;
    //  this.calendarForm.patchValue(results);
    });

  }

  addHoliday(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.holidays.push(value);
    }

    if (input) {
      input.value = '';
    }
  }

  removeHoliday(day: string): void {
    const index = this.holidays.indexOf(day);

    if (index >= 0) {
      this.holidays.splice(index, 1);
    }
  }

  constructor(
    private messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public sharedService: ConfigService,
  ) {
  }

  saveChanges() {    
    this.calendar.monday = this.monday.times.filter(t => this.isValid(t));
    this.calendar.tuesday = this.tuesday.times.filter(t => this.isValid(t));
    this.calendar.wednesday = this.wednesday.times.filter(t => this.isValid(t));
    this.calendar.thursday = this.thursday.times.filter(t => this.isValid(t));
    this.calendar.friday = this.friday.times.filter(t => this.isValid(t));
    this.calendar.saturday = this.saturday.times.filter(t => this.isValid(t));
    this.calendar.sunday = this.sunday.times.filter(t => this.isValid(t));


    this.calendar.holidays = this.holidays;

    this.sharedService.saveBusinessCalendarSettings(this.calendar).subscribe(results => {
      this.calendar = results;
      this.holidays = results.holidays;
    })

  }

  public isValid(t:Timing): boolean {
    return t.startTime && t.startTime.length > 0 && t.endTime && t.endTime.length > 0
  }

}

