import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import moment from 'moment';
import { PaymentType } from './shared-component';

@Component({
  selector: 'app-paymenttype-entry',
  templateUrl: './paymenttype-entry.component.html',
})
export class PaymentTypeEntryComponent implements OnInit {

  @Input() small: boolean = true;
  @Input() paymentType: string = "CC";
  @Input() payment: PaymentType = null;


  public paymentForm = new FormGroup({
    reference: new FormControl('', Validators.required),
    limit: new FormControl('0', Validators.required),
    type: new FormControl('CC', Validators.required),
    expiry: new FormControl(moment().max()),
    status: new FormControl('ACTIVE', Validators.required),
  });

  errors: string[];

  ngOnInit(): void {
    if (this.payment) {
      this.paymentForm.addControl("id", new FormControl());
      this.paymentForm.patchValue(this.payment);
    }
  }


  constructor(
  ) {
  }

}

