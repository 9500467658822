import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Company } from "../shared/shared-component";
import { SharedService } from "./shared.service";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";


@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html'
})

export class CompanySelectorDialog {

  private _company: Company[] = [];
  public dataSource: MatTableDataSource<Company> = new MatTableDataSource<Company>();

  displayedColumns = [
    "name",
    "type",
    "select",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //Modal
  constructor(public dialogRef: MatDialogRef<CompanySelectorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public companyService: OfficeAdminPEFAService) {
    this.dataSource = new MatTableDataSource<Company>();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.companyService.getAllActiveCompany().subscribe(result => {
      this._company = result;
      this.dataSource.data = this._company;
    });
  }

  public get companies() {
    return this._company;
  }

  public selectCompany(company: Company) {

    this.dialogRef.close({ "outcome": true, "company": company });

  }

  onCancelClick(): void {
    this.dialogRef.close({"outcome":false});
  }

  //Material Table
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
