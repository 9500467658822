import { Component, OnInit, Inject, ViewChild, Input, AfterViewInit } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, AbstractControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MedicalClearance, PEFARequest, Training, Assessor } from "../shared/shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import moment from "moment";


@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html'
})

export class TrainingListComponent implements OnInit {


  @Input() assessor: Assessor;

  @Input() assessorForm: FormGroup;

  trainingFormArray: FormArray = new FormArray([]);

  public dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource<AbstractControl>();

  private _training: Training[] = [];

  displayedColumns = [
    "courseName",
    "assessmentDate",
    "completedDate",
    "trainer",
    "location",
    "outcome",
    "assessor"

  ];
  //Modal

  constructor(public pefaService: PEFARequestService) {

  }


  ngOnInit(): void {

    this.trainingFormArray = this.assessorForm.get("training") as FormArray;
    this.trainingFormArray.clear();

    if (this.assessor) {
      this._training = this.assessor.training;
      for (let entry of this.assessor.training) {
        this.addSavedTraining(entry);
      };
      
    }
    
    this.dataSource.data = this.trainingFormArray.controls;
  }

  public delete(id: string) {
    //this._training.splice(this.dataSource.data.findIndex(r => r.id === id));
    this.trainingFormArray.removeAt(Number.parseInt(id));
  }

  addTraining() {
    this.trainingFormArray.push(
      new FormGroup({
        courseName: new FormControl('', Validators.required),
        assessmentDate: new FormControl(moment(), Validators.required),
        completedDate: new FormControl(moment(), Validators.required),
        trainer: new FormControl('', Validators.required),
        location: new FormControl('', Validators.required),
        outcome: new FormControl('', Validators.required),
        assessor: new FormControl('', Validators.required),
      })
    );
    this.dataSource.data = this.trainingFormArray.controls;
  }

  public addSavedTraining(training: Training) {
    this.trainingFormArray.push(
      new FormGroup({
        id: new FormControl(training.id, Validators.required),
        courseName: new FormControl(training.courseName, Validators.required),
        assessmentDate: new FormControl(training.assessmentDate, Validators.required),
        completedDate: new FormControl(training.completedDate, Validators.required),
        trainer: new FormControl(training.trainer, Validators.required),
        location: new FormControl(training.location, Validators.required),
        outcome: new FormControl(training.outcome, Validators.required),
        assessor: new FormControl(training.assessor, Validators.required),
      })
    );
  }
}
