import { Component, OnInit, Inject, ViewChild, Provider, Output, EventEmitter, Input, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PEFARequest, PEFA_STATUS, BookingStatus, Booking, BusinessCalendar, Timing } from "../shared/shared-component";
import { style, state, transition, animate, trigger } from "@angular/animations";
import { PefaInvoiceDialog } from "../shared/invoice-pefa-dialog.component";
import { MatSelectChange } from "@angular/material/select";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { ConfigService } from "../config/config.service";
import { kSTORAGE_CALENDAR } from "./constants";
import moment, { Moment } from "moment-business-days";



@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.css']

})

export class KPIComponent implements OnInit {

  @Input()
  pefaRequest: PEFARequest;

  @Input()
  big: boolean = false;

  completeInfo: string;
  reportInfo: string;
  contactInfo: string;
  apptInfo: string;
  green = "green";


  statusTime: string;
  appointmentTime: string;

  amber = "amber";

  red = "red";
  white = "aqua";
  apptStatus: string;
  contactStatus: string;
  completeStatus: string;
  reportStatus: string;
  apptStatusFill: boolean;
  contactStatusFill: boolean;
  completeStatusFill: boolean;
  reportStatusFill: boolean;
  calendar: BusinessCalendar | null = null;



  constructor(public dialog: MatDialog, officeAdminService: OfficeAdminPEFAService, public messageService: MessageService, public configService:ConfigService) {


  }

  public ngOnInit() {
    this.completeInfo = this.pefaRequest.kpiDetails.map(k => k.name == 'COMPLETE' ? k.delayReason + ': ' + k.notes : "").join("\n");
    this.apptInfo = this.pefaRequest.kpiDetails.map(k => k.name == 'APPOINTMENT' ? k.delayReason + ': ' + k.notes : "").join("\n");
    this.reportInfo = this.pefaRequest.kpiDetails.map(k => k.name == 'REPORT' ? k.delayReason + ': ' + k.notes : "").join("\n");
    this.contactInfo = this.pefaRequest.kpiDetails.map(k => k.name == 'CONTACT' ? k.delayReason + ': ' + k.notes : "").join("\n");

    // only load this if we need to the first time
    if (localStorage.getItem(kSTORAGE_CALENDAR) != null) {
      this.calendar =  JSON.parse(localStorage.getItem(kSTORAGE_CALENDAR));
    }

    if (this.calendar == null || (this.calendar != null && this.calendar.sunday == null)) {
      this.configService.getBusinessCalendarSettings(false).subscribe(results => {
        this.calendar = results;
        localStorage.setItem(kSTORAGE_CALENDAR, JSON.stringify(this.calendar));
        this._init();
      });
    } else {
      this._init();
    }

    this.apptStatusFill = this.pefaRequest.kpiDetails.filter(k => k.name == 'APPOINTMENT').length > 0;
    this.contactStatusFill = this.pefaRequest.kpiDetails.filter(k => k.name == 'CONTACT').length > 0;
    this.reportStatusFill = this.pefaRequest.kpiDetails.filter(k => k.name == 'REPORT').length > 0;
    this.completeStatusFill = this.pefaRequest.kpiDetails.filter(k => k.name == 'COMPLETE').length > 0;


  }

  _init() {
    moment.locale('en', {
      workinghours: {
        0: null,
        1: ['09:00:00', '16:00:00'],
        2: ['09:00:00', '16:00:00'],
        3: ['09:00:00', '16:00:00'],
        4: ['09:00:00', '16:00:00'],
        5: ['09:00:00', '16:00:00'],
        6: null
      },
      holidays: this.calendar.holidays,
      holidayFormat: 'YYYY-MM-DD'        
    });
    this.statusTime = this.reportStatusTime();
    this.reportStatus = this.reportStatusCalc();
    this.apptStatus = this.apptStatusCalc();
    this.contactStatus = this.contactStatusCalc();
    this.completeStatus = this.completeStatusCalc();
  }

  getEndOfDay(day: Moment): Moment{
    var newTime = day.clone();
    var time = this.getTiming(newTime);

    if (time && time.length > 0) {
      newTime.set({ 'hour': Number.parseInt(time[0].endTime.split(":")[0]), 'minute': Number.parseInt(time[0].endTime.split(":")[1]) });
    }

    return newTime;
  }

  getMidnight(day: Moment): Moment {
    var newTime = day.clone();

    newTime.set({ 'hour': 23, 'minute': 59 });

    return newTime;
  }

  private getTiming(newTime: moment.Moment): Timing[] {
    var time = null;
        switch (newTime.day()) {
            case 0:
                time = this.calendar.sunday;
                break;
            case 1:
                time = this.calendar.monday;
                break;
            case 2:
                time = this.calendar.tuesday;
                break;
            case 3:
                time = this.calendar.wednesday;
                break;
            case 4:
                time = this.calendar.thursday;
                break;
            case 5:
                time = this.calendar.friday;
                break;
            case 6:
                time = this.calendar.saturday;
                break;
        };
        return time;
  }

  getStartOfDay(day: Moment): Moment {
    var newTime = day.clone();
    var time = this.getTiming(newTime);

    if (time && time.length > 0) {
      newTime.set({ 'hour': Number.parseInt(time[0].startTime.split(":")[0]), 'minute': Number.parseInt(time[0].startTime.split(":")[1]) });
    }

    return newTime;
  }

  addHours(date: Moment, amount: number): Moment {
    var newDate = date.clone();
    if (!newDate.isBusinessDay()) {
      newDate = this.getStartOfDay(newDate.nextBusinessDay());
    } else if (newDate.isBefore(this.getStartOfDay(newDate))) {
      newDate = this.getStartOfDay(newDate);
    }


    var prevDate = newDate.clone();
    newDate.add(amount, 'hours');
    while (newDate.isAfter(this.getEndOfDay(prevDate))) {
      var minutes = newDate.diff(this.getEndOfDay(prevDate), 'minutes');
      prevDate = this.getStartOfDay(moment(prevDate).businessAdd(1, 'days'));
      newDate = prevDate.clone().add(minutes, 'minutes');
    }

    return newDate;


  }

  contactStatusCalc(): string {


    //var requestDate = moment(this.pefaRequest.requestedTime);
    var kpiRedTime = this.addHours(moment(this.pefaRequest.requestedTime), 2); //2 hour
    var kpiGreenTime = this.addHours(moment(this.pefaRequest.requestedTime), 1); //1 hour

    if (kpiRedTime.isAfter)

    var contactTime = moment();

    if (this.pefaRequest.firstContact) {
      contactTime = moment(this.pefaRequest.firstContact);
    }

    if (contactTime.isBefore(kpiGreenTime)) {
      return this.green;
    } else if (contactTime.isBefore(kpiRedTime)) {
      return this.amber;
    } else {
      return this.red;
    }
  }


  reportStatusCalc(): string {

    if (this.pefaRequest.bookings && this.pefaRequest.bookings.length > 0) {


      var earliestBooking = this.pefaRequest.bookings.reduce(function (a, b) { return moment(a.appointmentDateTime) > moment(b.appointmentDateTime) && (b.status == BookingStatus.COMPLETED || b.status == BookingStatus.CONFIRMED || b.status == BookingStatus.RESCHEDULED) ? a : b; });
      var reportDate = this.pefaRequest.completedTime ? moment(this.pefaRequest.completedTime) : moment();

      var colour = this.white;

      if (earliestBooking) {

        var kpiRedTime = moment(earliestBooking.appointmentDateTime).businessAdd(3, 'days');
        var kpiGreenTime = moment(earliestBooking.appointmentDateTime).businessAdd(1, 'days');
        if (this.pefaRequest.responseTime.toLowerCase() == "urgent") {
          var kpiRedTime = moment(earliestBooking.appointmentDateTime).businessAdd(2, 'days');
          var kpiGreenTime = moment(earliestBooking.appointmentDateTime).businessAdd(1, 'days');
        }

        if (reportDate.isBefore(this.getMidnight(kpiGreenTime))) {
          colour = this.green;
        } else if (reportDate.isBefore(this.getMidnight(kpiRedTime))) {
          colour = this.amber;
        } else {
          colour = this.red;
        }

      }

      return colour;

    } else {
      return this.white;
    }

  }



  reportStatusTime(): string {


    if (this.pefaRequest.bookings && this.pefaRequest.bookings.length > 0) {


      var earliestBooking = this.pefaRequest.bookings.reduce(function (a, b) { return moment(a.appointmentDateTime) > moment(b.appointmentDateTime) && (b.status == BookingStatus.COMPLETED || b.status == BookingStatus.CONFIRMED || b.status == BookingStatus.RESCHEDULED) ? a : b; });
      var reportDate = this.pefaRequest.completedTime ? moment(this.pefaRequest.completedTime) : moment();

      var colour = this.white;

      if (earliestBooking) {

        var kpiRedTime = moment(earliestBooking.appointmentDateTime).businessAdd( 3, 'days');
        var kpiGreenTime = moment(earliestBooking.appointmentDateTime).businessAdd( 1, 'days');

        if (this.pefaRequest.responseTime.toLowerCase() == "urgent") {
          var kpiRedTime = moment(earliestBooking.appointmentDateTime).businessAdd(2, 'days');
          var kpiGreenTime = moment(earliestBooking.appointmentDateTime).businessAdd(1, 'days');
        }


        if (reportDate.isBefore(this.getMidnight(kpiGreenTime))) {
          colour = this.green;
        } else if (reportDate.isBefore(this.getMidnight(kpiRedTime))) {
          colour = this.amber;
        } else {
          colour = this.red;
        }

      }

      return this.red;


    } else {
      return "";
    }

  }

  apptStatusCalc(): string {

    var contactTime = moment();

    if (this.pefaRequest.accepted) {
      contactTime = moment(this.pefaRequest.accepted);
    }

    if (this.pefaRequest.appointmentDate) {
      contactTime = moment(this.pefaRequest.appointmentDate);
    }

    var kpiGreenTime = this.getMidnight(moment(contactTime));

    var bookingTime = moment();

    if (this.pefaRequest.bookings && this.pefaRequest.bookings.length > 0) {


      var earliestBooking = this.pefaRequest.bookings.reduce(function (a, b) { return moment(a.appointmentDateTime).isBefore(moment(b.appointmentDateTime)) && (b.status == BookingStatus.COMPLETED || b.status == BookingStatus.CONFIRMED || b.status == BookingStatus.RESCHEDULED) ? a : b; });
      if (earliestBooking) {

        var bookingTime = moment(earliestBooking.appointmentDateTime);
      }
    }
    var colour = this.white;

    if (bookingTime.isBefore(this.getMidnight(kpiGreenTime))) {
        colour = this.green;      
      } else {
        colour = this.red;
      }

  
      return colour;


  }

  completeStatusCalc(): string {




    var requestDate = moment(this.pefaRequest.appointmentDate);
    var reportDate = moment();

    if (this.pefaRequest.completedTime) {
      reportDate = moment(this.pefaRequest.completedTime);
    } 

    var colour = this.white;


    var kpiRedTime = moment(requestDate).businessAdd(2, 'days');
    var kpiGreenTime = moment(requestDate).businessAdd(1, 'days');

    if (this.pefaRequest.responseTime.toLowerCase() == "urgent") {
      var kpiRedTime = moment(requestDate).businessAdd(1, 'days');
      var kpiGreenTime = moment(requestDate).businessAdd(1, 'days');
    }

    if (reportDate.isBefore(this.getMidnight(kpiGreenTime))) {
      colour = this.green;
    } else if (reportDate.isBefore(this.getMidnight(kpiRedTime))) {
      colour = this.amber;
    } else {
      colour = this.red;
    }



    return colour;

  }


  workDiff(start, end, measure) {

    var newStart = start.clone().startOf(measure);
    var newEnd = end.clone().startOf(measure);
    var diff = Math.abs(newStart.diff(newEnd, measure)); //get how much of a diff it was originally.

    if (measure === 'days') { // days so deal with weekends and holidays but not worry about working hours.
      //weeks different
      var weeks = newEnd.week() - newStart.week();
      diff = diff - (weeks * 2);

      if (newStart.day() == 0) {
        diff--;
      }

      if (newStart.day() == 6) {
        diff = diff - 2;
      }

      if (newEnd.day() == 6) {
        diff--;
      }

      if (newEnd.day() == 0) {
        diff = diff - 2;
      }



    }

    return Math.abs(diff);

  }


}
