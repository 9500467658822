import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, Input, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { OfficeAdminPEFAService } from './office-admin-pefa.service';
import { MessageService } from '../message/message.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { MatTableDataSource } from '@angular/material/table';
import { PEFARequest, PEFA_STATUS, BookingStatus, Booking, CancelBookingDialog, PEFAJob, BookingDiagnosticsDialog } from "../shared/shared-component";
import { PefaInvoiceDialog } from "../shared/invoice-pefa-dialog.component";
import { UploadResultsDialog } from "../shared/pefa-upload-results-dialog.component";
import { UpdateBookingDialog } from "../shared/update-booking-dialog.component";
import { DialogBooking } from "../shared/booking-dialog.component";
import { SharedService } from "../shared/shared.service";
import { ConfirmDialog } from "../shared/confirm-dialog.component";
import { P } from "@angular/cdk/keycodes";
import { sendMessage } from "@microsoft/signalr/dist/esm/Utils";




@Component({
  selector: 'app-office-admin-booking-actions',
  templateUrl: './office-admin-booking-actions.component.html'
  
})

export class OfficeAdminBookingActionsComponent implements OnInit, AfterViewInit {

  public officeAdminService: OfficeAdminPEFAService;

  @Input() booking: Booking;

  @Input() pefa: PEFARequest;

  @Output() updated = new EventEmitter<boolean>();

  loading: boolean = false;


  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
  }

  constructor(public dialog: MatDialog, officeAdminService: OfficeAdminPEFAService, public messageService: MessageService,) {
    this.officeAdminService = officeAdminService;
  }

  openResultsDialog(): void {
    const dialogRef = this.dialog.open(UploadResultsDialog, {
      data: {
        bookingId: this.booking.id,
        pefaId: this.pefa.id

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  openUpdateBookingDialog(): void {
    const dialogRef = this.dialog.open(UpdateBookingDialog, {
      data: {
        bookingId: this.booking.id,
        pefaId: this.pefa.id,
        holdDate: this.pefa.holdDate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  openCancelDialog(): void {
    const dialogRef = this.dialog.open(CancelBookingDialog, {
      data: { bookingId: this.booking.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  openInvoiceDialog(): void {
    const dialogRef = this.dialog.open(PefaInvoiceDialog, {
      data: { pefaId: this.pefa.id, bookingId: this.booking.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  updatePaperwork() {
    this.officeAdminService.downloadPaperwork(this.booking.id).subscribe(data => {
      SharedService.downloadFile(data);
      this.loading = false;
    })
  }

  public downloadPaperwork() {
    this.loading = true;

    if (this.booking.audit.lastModifiedDate < this.pefa.pefaJob.audit.lastModifiedDate) {
      const confirm = this.dialog.open(ConfirmDialog, { data: { prompt: 'Booking is working on out of date demands. Are you sure you want to generate paperwork?' } })

      confirm.afterClosed().subscribe(result => {
        if (result.outcome) {
          this.updatePaperwork();
        }
        this.loading = false;
      }
        
      );
    } else {
      this.updatePaperwork();

    }
  }

  
  public generateConfirmation() {
    this.loading = true;
    const confirm = this.dialog.open(ConfirmDialog, { data: { prompt: 'Email confirmation to provider?' } })

    confirm.afterClosed().subscribe(result => {
        this.officeAdminService.downloadConfirmation(this.booking.id, result.outcome).subscribe(data => {
          SharedService.downloadFile(data);
          //email confirmation to provider
        })
        var message = result.outcome ? 'Confirmation has been sent to provider and generated' : 'Confirmation has been generated';
          this.messageService.add(message);
          this.loading = false

    })
  }

  public isInvoiced(): boolean {
    
    return (this.pefa.paymentStatus == 'PAID' || (this.booking.bookingPayment != null)) && this.booking.providerPayment != null;

  }

  openDiagnosticsDialog(): void {
    const dialogRef = this.dialog.open(BookingDiagnosticsDialog, {
      data: { bookingId: this.booking.id }
    });
  }  

}
