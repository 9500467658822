<p-button styleClass="p-overlay-badge p-button-text p-button-rounded" (onClick)="op.toggle($event)" [disabled]="(notices?.length == 0)">
  <i class="pi pi-bell" pBadge [value]="notices?.length" badgeClass="p-badge-danger">  </i>
</p-button>
<p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true" appendTo="body">
  <ng-template pTemplate>
    <div *ngFor="let notice of notices; let i=index;">
      <p-message severity="info" [text]="notice.message">

      </p-message>
      <p-button styleClass="p-button-text p-button-rounded" label="Dismiss" (click)="dismissNotification(i)"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>
