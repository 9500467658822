<h1 mat-dialog-title>Confirmation</h1>
<div *ngIf="prompt">
  {{data.prompt}}
</div>

<div *ngIf="showReason">

  <div mat-dialog-content>
    <mat-label>Why?</mat-label>
    <mat-form-field>
      <input type="text" matInput [(ngModel)]="message" required />
    </mat-form-field>
  </div>

</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No</button>
  <button mat-button (click)="onYesClick()">Yes</button>
</div>
