<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{noteView.headline}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  
  <mat-card>
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-label>
              Filename
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.filename}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Type
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.type}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Notes
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.note}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Date Created
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdDate | date:dateFormat}}</span>
          </div>
        </div>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <a mat-raised-button matTooltip="Download" style="text-decoration: none;" (click)="downloadAttachment()" download><mat-icon aria-hidden="false" aria-label="Download">system_update</mat-icon></a>
      <a mat-raised-button matTooltip="Delete" style="text-decoration: none;" (click)="deleteAttachment()" download><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></a>
    </mat-card-actions>
  </mat-card>

</mat-expansion-panel>
