import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { PEFARequest, Company, BookingStatus, Booking, BookingType, Job, Assessor, InvoicePricing, Provider, Doctor } from '../shared/shared-component';


@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  private client: HttpClient;
  private baseURL: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }


  getDoctors(): Observable<Doctor[]> {
    return this.client.get<Doctor[]>(this.baseURL + 'api/Doctors')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };


  getDoctor(id:string): Observable<Doctor> {
    return this.client.get<Doctor>(this.baseURL + 'api/Doctors/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };


  public saveDoctor(id: string, doctor: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/Doctors/' + id, doctor).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public addDoctor(doctor: any): Observable<Doctor> {
    return this.client.post<Doctor>(this.baseURL + 'api/Doctors/', doctor).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  private handleError(error) {
    let errorMessage = '';
    //let errors: any = JSON.stringify(error.json());
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
    }
    this.messageService.add(errorMessage)
    return throwError(errorMessage);
  };
}
