<mat-card>
  <mat-card-title>
    Referral Information
  </mat-card-title>
  <mat-card-content>
    <div class="container-fluid" *ngIf="request">
      <div class="row">
        <div class="col">
          <mat-label>Client</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.company.name}} ({{request.company.tier}})</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Payment Method</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.paymentMethod}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Payment ID</mat-label>
        </div>
        <div class="col">
          <mat-label>{{getPaymentId()}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Worker Name</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.worker.firstName}} {{request.worker.lastName}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Referral Type</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.referralType.name}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Report</mat-label>
        </div>
        <div class="col">
          <mat-label>{{request.responseTime}}</mat-label>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-title>
    Bookings Information
  </mat-card-title>
  <mat-card-content>
    <div class="container-fluid" *ngIf="bookings">
      <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="provider">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Provider</mat-header-cell>
          <mat-cell *matCellDef="let row"><span *ngIf="row.provider else elseName">{{ row.provider.name }}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let row"><span>{{ row.status}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="payment">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Paid</mat-header-cell>
          <mat-cell *matCellDef="let row"><span>{{ row.payment?"Yes":"No"}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell *matCellDef="let row"><span>{{ row.bookingType.displayName}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="appointmentdate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Appointment Date</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.appointmentDateTime| date:dateFormat }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cancelDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Cancel Date</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.cancelDate| date:dateFormat }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="notes">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Notes</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row">{{ row.notes}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [class.active]="row.id==bookingId"></mat-row>
      </mat-table>
    </div>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-title>
    Notes
  </mat-card-title>
  <mat-card-content>
    <app-note-list [pefaId]="request.id" view="summary"></app-note-list>
  </mat-card-content>
</mat-card>
