<mat-toolbar class="mat-accent d-flex justify-content-center">
  <mat-button-toggle-group multiple name="fontStyle" aria-label="Font Style" (change)="applyFilter($event)" #group>
    <mat-button-toggle value="TODO">
      <i class="pi pi-check"></i>
    </mat-button-toggle>
    <mat-button-toggle value="NOTE">
      <i class="pi pi-tag"></i>
    </mat-button-toggle>
    <mat-button-toggle value="MESSAGE">
      <i class="pi pi-envelope"></i>
    </mat-button-toggle>
    <mat-button-toggle value="FILE"><i class="pi pi-file"></i></mat-button-toggle>
    <mat-button-toggle value="MEDICAL"><i class="fas fa-ambulance" style="color:gray"></i></mat-button-toggle>
    <mat-button-toggle value="PAYMENT"><i class="pi pi-dollar"></i></mat-button-toggle>
    <mat-button-toggle value="BOOKING"><i class="pi pi-calendar"></i></mat-button-toggle>
  </mat-button-toggle-group>
</mat-toolbar>


<p-timeline [value]="filteredNoteViews" styleClass="smaller">
  <ng-template pTemplate="opposite" let-event styleClass="smaller">
    {{event.createdDate | date:dateFormat }}
  </ng-template>
  <ng-template pTemplate="marker" let-event>
    <span class="custom-marker p-shadow-2"  [ngClass]="{'red-marker' : event.important}" [style.backgroundColor]="event.color">
      <span *ngIf="event.type == 'TODO'">
        <i class="pi pi-check"></i>
      </span>
      <span *ngIf="event.type == 'NOTE'">
        <i class="pi pi-tag"></i>
      </span>
      <span *ngIf="event.type == 'MESSAGE'">
        <i class="pi pi-envelope"></i>
      </span>
      <span *ngIf="event.type == 'FILE'">
        <i class="pi pi-file"></i>
      </span>
      <span *ngIf="event.type == 'BOOKING'">
        <i class="pi pi-calendar"></i>
      </span>
      <span *ngIf="event.type == 'MEDICAL'">
        <i class="fas fa-ambulance" style="color:gray"></i>
      </span>
      <span *ngIf="event.type == 'PAYMENT'">
        <i class="pi pi-dollar"></i>
      </span>
    </span>
  </ng-template>
  <ng-template pTemplate="content" let-event>
    <ng-container [ngSwitch]="event.type">
      <app-note-view-attachment *ngSwitchCase="'FILE'" [noteView]="event" [requestID]="pefaRequest.id" (changed)="refresh()">
      </app-note-view-attachment>
      <app-note-view-message *ngSwitchCase="'MESSAGE'" [noteView]="event" [requestID]="pefaRequest.id" (changed)="refresh()">
      </app-note-view-message>
      <app-note-view-booking *ngSwitchCase="'BOOKING'" [noteView]="event" [pefa]="pefaRequest" (changed)="refresh()">
      </app-note-view-booking>
      <app-note-view-note *ngSwitchCase="'NOTE'" [noteView]="event" [requestID]="pefaRequest.id" (changed)="refresh()">
      </app-note-view-note>
      <app-note-view-payment *ngSwitchCase="'PAYMENT'" [noteView]="event" [requestID]="pefaRequest.id" (changed)="refresh()">
      </app-note-view-payment>
      <app-note-view-medical *ngSwitchCase="'MEDICAL'" [noteView]="event" [requestID]="pefaRequest.id" (changed)="refresh()">
      </app-note-view-medical>
      <app-note-view-todo *ngSwitchCase="'TODO'" [noteView]="event" [requestID]="pefaRequest.id" (changed)="refresh()">
      </app-note-view-todo>
      <ng-container *ngSwitchDefault>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{event.headline}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p *ngIf="event.type == 'NOTE'">
            {{event.details.content}}
          </p>
        </mat-expansion-panel>
      </ng-container>

    </ng-container>

  </ng-template>
</p-timeline>
