import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { PEFARequest } from '../shared/shared-component';

export class Company {
    constructor(
      ID: number,
      CompanyName: string,
      CompanyAddress: string,
      CompanyACNABN: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class OfficeAdminService {

    private client: HttpClient;
    private baseURL: string;

    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
        this.client = http;
        this.baseURL = baseUrl;
    }

    getAllCompany(): Observable<Company[]> {
        return this.client.get<Company[]>(this.baseURL + 'api/Company/GetAllCompany').pipe(
            retry(1),
            catchError(this.handleError)
        );
    };

    getPEFAForCompany(companyId: number): Observable<PEFARequest[]> {
        return this.client.get<PEFARequest[]>(this.baseURL + 'api/Company/GetPEFAForCompany/'+ companyId)
            .pipe(
            retry(1),
            catchError(this.handleError)
        );
    };

    public uploadPEFADoc(files, PEFAId) {
        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('Id', PEFAId);
        return this.client.post<any>(this.baseURL + 'api/PEFARequests/UploadPEFADoc', formData).pipe(
            catchError((res: HttpErrorResponse) => {
                switch (res.status) {
                    case 200: //upload successful
                        //do appropriate calls for success upload
                        console.log("File upload has been successful:" + res.statusText);
                        break;
                    case 404: //PEFAid not found!  redirect?
                        console.log(res.statusText);
                        break;
                    case 500: //Server error response failed 
                        console.log("Server error upload not successful:" + res.statusText);
                        break;
                }
                return throwError(res);
            })
        )
    };

    private handleError(error) {
        let errorMessage = '';
        //let errors: any = JSON.stringify(error.json());
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    };

    getAttachment(PEFAId: number, attachmentId: number) {
        return this.client.get(this.baseURL + 'api/PEFARequests/getAttachment/' + PEFAId + "/" + attachmentId)
            .pipe(
                catchError(this.handleError)
            );
    };

    downloadFile(PEFAId: number, attachmentId: number): Observable<Blob> {
        return this.client.get(this.baseURL + 'api/PEFARequests/getAttachment/' + PEFAId + "/" + attachmentId ,
            {
                responseType: 'blob'
            });
    };

    deletePEFAReport(PEFAId: number): Observable<PEFARequest> {
        return this.client.delete<any>(this.baseURL + 'api/PEFARequests/DeletePEFARequest/'+ PEFAId);
    }

}
