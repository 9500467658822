<div [formGroup]="locationForm">

  <div class="form-group form-row">
    <div class="col-md-2"><mat-label>Address</mat-label></div>
    <mat-form-field style="width:250px;">
      <textarea cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                required
                matInput type="text" formControlName="address"></textarea>

    </mat-form-field>
  </div>

  <div class="form-group form-row">
    <div class="col-md-2"><mat-label for="Line2">Region</mat-label></div>
    <div class="col-md-4">
      <mat-form-field>
        <input matInput type="text" formControlName="region" required />
      </mat-form-field>
    </div>
  </div>
  <div class="form-group form-row">
    <div class="col-md-2"><mat-label for="Line2">Phone Number</mat-label></div>
    <div class="col-md-4">
      <mat-form-field>
        <input matInput type="text" formControlName="phoneNumber" required />
      </mat-form-field>
    </div>
  </div>  <div class="form-group form-row">
    <div class="col-md-2"><mat-label for="Line2">ContactEmail</mat-label></div>
    <div class="col-md-4">
      <mat-form-field>
        <input matInput type="text" formControlName="emailAddress" required />
      </mat-form-field>
    </div>
  </div>
  <div class="form-group form-row">
    <div class="col-md-2"><mat-label>Type</mat-label></div>
    <div class="col-md-4">
      <mat-form-field>
        <mat-select formControlName="type" required>
          <mat-option value="physical">Physical</mat-option>
          <mat-option value="postal">Postal</mat-option>
          <mat-option value="other">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="form-group form-row">
    <mat-checkbox formControlName="headOffice">Head Office</mat-checkbox>
  </div>
</div>
<mat-divider></mat-divider>
