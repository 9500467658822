import { Component, Inject } from "@angular/core";
import { Provider, Location, Assessor, BookingType, JobDemand, BookingStatus, pefaBooking } from "./shared-component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MessageService } from "../message/message.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
  selector: 'dialog-modal-cancel-request',
  templateUrl: 'cancel-request.component.html',

})

export class CancelRequestDialog {

  public message: string;

  constructor(
    public dialogRef: MatDialogRef<CancelRequestDialog>,
    @Inject(MAT_DIALOG_DATA) public data: pefaBooking) {
  }

  public onNoClick(yn: boolean): void {
    this.dialogRef.close({ 'message': '', 'cancel': false });
  }

  public cancelRequest(): void {
    this.dialogRef.close({ 'message': this.message, 'cancel': true });

  }
}
