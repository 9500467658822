import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { MedicalClearance } from '../shared/shared-component';

@Injectable({
    providedIn: 'root'
})
export class MedicalClearanceService {

    private client: HttpClient;
    private baseURL: string;


    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
        this.client = http;
        this.baseURL = baseUrl;
    }

    private handleError(error) {
        let errorMessage = '';
        //let errors: any = JSON.stringify(error.json());
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    };
    //

  public addMedicalClearance(id: string, clearance: any): Observable<any> {
    return this.client.post(this.baseURL + 'api/MedicalClearances/' + id, clearance).pipe(
      catchError(this.handleError)
    );
  }

  public saveMedicalClearance(id: string, clearance: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/MedicalClearances/' + id, clearance).pipe(
      catchError(this.handleError)
    );
  }

  public delete(id: string, pefaId :string): Observable<MedicalClearance> {
    return this.client.delete<MedicalClearance>(this.baseURL + 'api/MedicalClearances/' + pefaId + "/" + id).pipe(
      catchError(this.handleError)
    );
  }

  public get(id: string): Observable<MedicalClearance> {
    return this.client.get<MedicalClearance>(this.baseURL + 'api/MedicalClearances/' + id).pipe(
      catchError(this.handleError)
    );
  }
}
