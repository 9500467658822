import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, concat, from } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators'
import { log } from 'util';
import { PEFARequest, Job, ReferralType, MessageTemplate, Person } from '../shared/shared-component';





@Injectable({
  providedIn: 'root'
})
export class SharedService {


  private client: HttpClient;
  private baseURL: string;
  private requestURL = 'api/office'

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.client = http;
    this.baseURL = baseUrl;
  }



  getReferralTypes(): Observable<any> {
    return this.client.get<ReferralType[]>(this.baseURL + 'api/Utility/GetReferralTypes/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  getPeople(): Observable<Person[]> {
    return this.client.get<Person[]>(this.baseURL + 'api/Utility/People/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  getPeopleLite(q:string): Observable<Person[]> {
    return this.client.get<Person[]>(this.baseURL + 'api/Utility/PeopleLite?q=' + q)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };  

  public getUsersByRole(role:string): Observable<Person[]> {
    return this.client.get<Person[]>(this.baseURL + 'api/User/Role/' + role).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getReportWriters(): Observable<Person[]> {
    return this.client.get<Person[]>(this.baseURL + 'api/User/ReportWriters/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };


  getMessageTemplates(): Observable<MessageTemplate[]> {
    return this.client.get<MessageTemplate[]>(this.baseURL + 'api/MessageTemplate/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  getMessageFromTemplates(templateId: string, objectId: string) {
    return this.client.get(this.baseURL + 'api/MessageTemplate/' + templateId + '/CreateMessage/' + objectId, { responseType: 'text' })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };



  public static downloadFile(response) {
    let header_content = response.headers.get('content-disposition');
    let files: string[] = header_content.split(';');
    let filename = files.find(p => p.trim().startsWith("filename="));
    filename = filename.split('=')[1];
    filename = filename.replace("\"", "");
    if (filename.endsWith("\"")) {
      filename = filename.substring(0, filename.length - 1);
    }
    console.log("Filename:" + filename);
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([response.body], { type: response.headers.get('content-type') })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 400)
  }
}
