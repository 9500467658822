<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{noteView.details.messageType == 'EMAIL' ? '' : noteView.details.messageType}} <span *ngIf="noteView.details.subject">{{noteView.details.subject}}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  
  <mat-card>
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-label>
              To
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.to}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              From
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.from}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Status
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.status}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Type
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.messageType}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Date
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdDate | date:dateFormat}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>{{noteView.details.text}}</p>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>

</mat-expansion-panel>
