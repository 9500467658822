import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Assessor, DemandType, PersonRelationship, JOBFIT_DATE_FORMAT, ProviderAudit } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { MessageService } from '../message/message.service';
import { AssessorBookingService } from './assessor-booking.service';
import moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { RelationshipDialogComponent } from '../shared/relationship-dialog.component';
import { ProviderAuditDialogComponent } from '../shared/provider-audit-dialog.component';

@Component({
  selector: 'app-assessor-detail',
  templateUrl: './assessor-detail.component.html',
})
export class AssessorDetailComponent implements AfterViewInit {

  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  public assessor: Assessor;
  AssessorForm = new FormGroup({
    id: new FormControl('', Validators.required),
    jfsiid: new FormControl('', Validators.required),
    qualifications: new FormControl('', Validators.required),
    title: new FormControl(''),
    location: new FormControl(''),
    industry: new FormControl(''),
    discipline: new FormControl(''),
    notes: new FormControl(''),
    status: new FormControl('ACTIVE'),  
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    training: new FormArray([]),
  });

  dataSource: MatTableDataSource<PersonRelationship> = new MatTableDataSource<PersonRelationship>();

  displayedColumns2 = [
    "name",
    "type",
    "status",
    "startDate",
    "endDate",
  ];

  displayedColumns = [
    "auditDate",
    "indeminityInsurancePolicyNo",
    "indeminityInsurancePolicyAmount",
    "indeminityInsurancePolicyExpiry",
    "liabilityInsurancePolicyNo",
    "liabilityInsurancePolicyAmount",
    "liabilityInsurancePolicyExpiry",
  ];
  auditsDataSource = new MatTableDataSource<ProviderAudit>();

  ngAfterViewInit(): void {

    const assessorId = this.route.snapshot.paramMap.get('id');
    if (assessorId) {
      this.loadAssessor(assessorId)
    }
  }

  messageService: MessageService;

  constructor(public dialog: MatDialog,
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public assessorService: AssessorBookingService,
  ) {
    this.messageService = messageService;
  }

  saveChanges() {
    if (this.assessor) {
      this.assessorService.saveAssessor(this.assessor.id, this.AssessorForm.value).subscribe(update => {
        this.loadAssessor(this.assessor.id);
        this.messageService.add("Assessor Updated");

      }
      
      );
    } else {
      const value = this.AssessorForm.value;
      delete value.id;
      this.assessorService.addAssessor(value).subscribe(update => {
          this.loadAssessor(update.id);
          this.messageService.add("Assessor Added");
        
      });
    }

}

loadAssessor(id:string) {
  this.assessorService.getAssessor(id).subscribe(results => {
    this.assessor = results;
    this.AssessorForm.patchValue(results);
    this.dataSource.data = this.assessor.relationships;
    this.auditsDataSource.data = this.assessor.audits;
  }
  );
}


  get training() {
    return this.AssessorForm.get('training') as FormArray;
  }


  openRelationshipDialog() {
    const dialogRef = this.dialog.open(RelationshipDialogComponent, {
      data: {
        personId: this.assessor.id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.relation) {
        this.assessor.relationships.push(result.relation);
        this.dataSource.data = this.assessor.relationships;

      }
    });
  }

  addInsurance() {
    const dialogRef = this.dialog.open(ProviderAuditDialogComponent, {
      data: {
        assessorId: this.assessor.id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.providerAudit) {
        this.assessor.audits.push(result.providerAudit);
        this.auditsDataSource.data = this.assessor.audits;

      }
    });
  }



}

