import { Component, OnInit, OnChanges, SimpleChanges, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, AbstractControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MedicalClearance, PEFARequest, Training, Assessor, DemandType, JobDemand } from "../shared/shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import moment from "moment";
import { JobService } from "../job/job.service";
import { from, of, zip } from "rxjs";
import { groupBy, mergeMap, toArray } from "rxjs/operators";


@Component({
  selector: 'app-demand-view',
  templateUrl: './demand-view.component.html',
  styleUrls: ['./demand-view.component.css']
})

export class DemandViewComponent implements OnInit, OnChanges {
  @Input() demandsForm: FormGroup;
  @Input() isLinkedToJod: boolean;  
  @Input() companyRules: any | null;

  demandTypes: DemandType[];

  demandFormArray: FormArray = new FormArray([]);
  groupedPTTypes: any[] = new Array();
  groupedMHTypes: any[] = new Array();

  constructor(public pefaService: PEFARequestService,
        public jobService: JobService
  ) {    
  }


  ngOnInit(): void {
    this.jobService.getDemandTypes().subscribe(results => {
      this.demandFormArray = this.demandsForm.get("demands") as FormArray;
      this.demandFormArray.clear();
      this.demandTypes = results;

      var resultsPT = results.sort((a, b) => a.order - b.order).filter(el => el.type !== "MH");
      var resultsMH = results.sort((a, b) => a.order - b.order).filter(el => el.type === "MH");

      const source = from(resultsPT);

      const pipeline = source.pipe(
        groupBy(
          demandTypes => demandTypes.group
        ),
        mergeMap(group => zip(of(group.key), group.pipe(toArray())))
      );

      pipeline.subscribe(response => {
        this.groupedPTTypes.push(response);
      });

      const sourceMH = from(resultsMH);

      const pipelineMH = sourceMH.pipe(
        groupBy(
          demandTypes => demandTypes.group
        ),
        mergeMap(group => zip(of(group.key), group.pipe(toArray())))
      );

      pipelineMH.subscribe(response => {
        this.groupedMHTypes.push(response);
      });      
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    // console.log('demand-view this.companyRules', this.companyRules);
  }  

  test(element: JobDemand, demandType: DemandType): boolean {
    return element.demandType.id === demandType.id
  }

  public getDemandForType(demandType: DemandType):JobDemand{
    var demand = this.demands.find(el => this.test(el, demandType));
    if (!demand) {
      demand = new JobDemand("","","NT", demandType);
    }

    return demand;
  }

  _demands: JobDemand[]

  @Input() public set demands(demands: JobDemand[]) {
    this.demandFormArray.clear();
    this._demands = demands;
  }

  public get demands(): JobDemand[] {
    return this._demands;
  }

  public getMHDemandsForType(demandType: DemandType): JobDemand[] {

    var demand = this.demands.filter(el => this.test(el, demandType));
    if (!demand || demand.length == 0) {
      demand = [new JobDemand("", "", "F", demandType),
      new JobDemand("", "", "C", demandType),
      new JobDemand("", "", "O", demandType),
      ];
    } else {
      if (demand.length < 3) {
        // fill in the missing freq
        let hasO = false;
        let hasF = false;
        let hasC = false;
        for(let d of demand) {
          if (d.frequency == "O") {
            hasO = true;
          }
          if (d.frequency == "F") {
            hasF = true;
          }
          if (d.frequency == "C") {
            hasC = true;
          }        
        }
        if (!hasO) {
          demand.push(new JobDemand("", "", "O", demandType));
        }
        if (!hasF) {
          demand.push(new JobDemand("", "", "F", demandType));
        }
        if (!hasC) {
          demand.push(new JobDemand("", "", "C", demandType));
        }
      }
    }
    return demand;
  }

}
