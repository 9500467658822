<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <h1 mat-dialog-title>KPI Details</h1>
    </div>
  </div>
</div>


<div mat-dialog-content class="container-fluid">
      <form [formGroup]="kpiForm">


        <div class="form-group row">
          <mat-label class="col" for="name">
            KPI
          </mat-label>
          <div class="col">
            <mat-form-field>

              <mat-select id="name" formControlName="name">
                <mat-option value="CONTACT">Contact</mat-option>
                <mat-option value="APPOINTMENT">Appointment</mat-option>
                <mat-option value="REPORT">Report</mat-option>
                <mat-option value="COMPLETE">Completion</mat-option>
              </mat-select>
              </mat-form-field>

          </div>
        </div>
        <div class="row">
          <mat-label class="col" for="reason">
            Reason
          </mat-label>
          <div class="col">
            <mat-form-field>
              <mat-select id="reason" formControlName="delayReason">
                <mat-option value="JFSI">JFSI</mat-option>
                <mat-option value="THERAPIST">Therapist</mat-option>
                <mat-option value="PROVIDER">Provider</mat-option>
                <mat-option value="MEDICAL">Medical</mat-option>
                <mat-option value="WORKER">Worker</mat-option>
                <mat-option value="CLIENT">Client</mat-option>
                <mat-option value="OTHER">Other</mat-option>
              </mat-select>
              </mat-form-field>
</div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label" for="notes">
            Notes
          </label>
          <div class="col">
            <mat-form-field>
              <input matInput id="notes" type="text" formControlName="notes" required>
            </mat-form-field>
          </div>
        </div>

      </form>


</div>

<div mat-dialog-actions style="margin-top: 2rem;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <button mat-raised-button color="primary" (click)="onNoClick(false)">Exit</button>
      </div>
      <div class="col-4 text-center">
      </div>
      <div class="col-4">
        <!--<button mat-raised-button color="warn" [mat-dialog-close]="reason" [disabled]="false" cdkFocusInitial (click)="onYesClick()">Cancel booking</button>-->
        <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="onSubmit()" [disabled]="!kpiForm.valid">Add KPI Detail</button>
      </div>
    </div>
  </div>
</div>
