import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
    public userName: Observable<string>;
    public profile: Observable<any>;
    public role: Observable<string[]>

  constructor(private authorizeService: AuthorizeService) { }

  ngOnInit() {
    this.authorizeService.checkSignIn();
    this.isAuthenticated = this.authorizeService.isAuthenticated();
      this.userName = this.authorizeService.getUser().pipe(map(u => u && u.profile.name));
      this.profile = this.authorizeService.getUser().pipe(map(u => u && u.profile));
    this.role = this.authorizeService.getUser().pipe(map(u => u && u.profile.role));
  }
}
