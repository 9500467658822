import { Component } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ConfigService } from './config/config.service';
import { MonitoringService } from './shared/logging.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'app';
    cookieMessage: string = "Staunch Technology Hub uses cookies. By using this Service. You have consented to the use of cookies.";
    cookieDismiss: string = "Dismiss";
    cookieLinkText: string = "More Information";
    themeClass: string = "theme";

    ngOnInit() {
        let cc = window as any;
        cc.cookieconsent.initialise({
            palette: {
                popup: {
                    background: "#164969"
                },
                button: {
                    background: "#ffe000",
                    text: "#164969"
                }
            },
            theme: "classic",
            content: {
                message: this.cookieMessage,
                dismiss: this.cookieDismiss,
                link: this.cookieLinkText,
                href: "/cookie_policy"
            }
        });

      this.configService.getConfigurationItem("theme").subscribe(results => {
        this.themeClass = results[0].value;
        this.overlayContainer.getContainerElement().classList.add(this.themeClass);              
      });
      

      //this.themeClass = 'jobfit-theme';

      //// remove old theme class and add new theme class
      //// we're removing any css class that contains '-theme' string but your theme classes can follow any pattern

  }


  constructor(
    private overlayContainer: OverlayContainer, public configService: ConfigService, private monitor: MonitoringService,
    private router: Router
  ) {
    /* disabled for now
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // ignore auth events
        if (event.url != '/' && event.url.indexOf('/authentication/') == -1) {
          let umami = (window as { [key: string]: any })["umami"] as any;

          if (umami) {
            // console.log(event.url);
            // pageview
            umami.track();
          }
        }
      } 
    });    
    */
  }

    // subscribe to some source of theme change events, then...
}
