<div class="container">
  <div class="row" style="border: 1px black;">
    <div class="col-1 p-1">
      <div class="{{contactStatus}}"><span [ngClass]="{'bubbleText':true, 'filled': contactStatusFill, 'big-text':big}" [matTooltip]="contactInfo">C</span> </div>
    </div>
    <div class="col-1 p-1">
      <div class="{{apptStatus}}"><span [ngClass]="{'bubbleText':true, 'filled': apptStatusFill, 'big-text':big}" matTooltip="{{apptInfo}}">A</span></div>
    </div>
    <div class="col-1 p-1">
      <div class="{{reportStatus}}"><span [ngClass]="{'bubbleText':true, 'filled': reportStatusFill, 'big-text':big}" matTooltip="{{reportInfo}}">R</span></div>
    </div>
    <div class="col-1 p-1">
      <div class="{{completeStatus}}"><span [ngClass]="{'bubbleText':true, 'filled': completeStatusFill, 'big-text':big}" matTooltip="{{completeInfo}}"><span>T</span></span></div>
    </div>
  </div>

  </div>
