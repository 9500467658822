
  

  <div class="container-fluid company-admin">
    <div class="row">

      <p *ngIf="!companyUsers"><em>Loading...</em></p>

      <form [formGroup]="roleCheckForm" (ngSubmit)="submit()" *ngIf="roleCheckForm">

        <div style="overflow-x: auto; overflow-y:auto;">

          <table class='table usertable' style="margin-left: 1rem;">

            <thead>
              <tr>
                <th class="text-center">User</th>
                <th>Referrer</th>
                <th>Receiver</th>
                <th>  Admin </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let blah of companyUsers; let i = index" formGroupName="{{blah.username}}">
                <td class="td_username">{{ blah.fName + " " + blah.lName}}</td>
                <td><input type="checkbox" formControlName="referrer"></td>
                <td><input type="checkbox" formControlName="receiver" /><!--[id]="receiver"--></td>
                <td><input type="checkbox" formControlName="companyAdmin"></td>
              </tr>
            </tbody>
          </table>

        </div>

        <div *ngIf="errorShow == false; else ShowError" class="text-center">
            <p style="color:green">{{saveStatus}}</p>
        </div>

        <ng-template #ShowError>
          <div class="text-center">
            <p style="color: red;">{{saveStatus}}</p>
          </div>
        </ng-template>

        <div class="text-center">
          <button class="btn btn-primary">Save Role Changes</button>
        </div>
      </form>

    </div>
  </div>
