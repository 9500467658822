import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit, Output, EventEmitter } from "@angular/core";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MessageService } from "../message/message.service";
import moment from "moment";
import { MatSelectChange } from "@angular/material/select";  
import { AssignPerson } from "./note-view/assign-todo/assign-dialog.component";
import { ToDo } from "./shared-component";


@Component({
  selector: 'dialog-modal-pefa-note',
  templateUrl: 'note-dialog.component.html',
})
export class NoteDialog implements OnInit {
  @Output() updated = new EventEmitter<boolean>();

  public noteForm = new FormGroup({
    id: new FormControl(''),
    content: new FormControl('', Validators.required),
    adminOnly: new FormControl(true),
    important: new FormControl(false),
    firstContact: new FormControl(false),
    createToDo: new FormControl(false),
    dueDate: new FormControl(moment()),
    status: new FormControl('ACTIVE'),
    reason: new FormControl()
  });

  showReason: boolean;
  todo: ToDo;

  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pefaService: PEFARequestService, public messageService: MessageService, public dialog: MatDialog) {
  }



  ngOnInit(): void {
    console.log(this.noteForm.value.adminOnly + "isAdmin")
    if (this.data.noteId) {
      this.pefaService.loadPefaNote(this.data.pefaId, this.data.noteId).subscribe(
        note => {
          this.noteForm.patchValue(note
          );
        },
        error => console.error(error));
    }

  };

  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(): void {
    var values = this.noteForm.value;
    if (!this.data.noteId || this.data.noteId == '' || this.data.noteId == 0) {
      delete values.id;
    }
    this.pefaService.savePefaNote(this.data.pefaId, values).subscribe(result => {
      this.messageService.add("Note successfully added");
      this.dialogRef.close(true);
    });
    
  }

  //fix
  openAssignPerson(companyId: string) {
    const dialogRef = this.dialog.open(AssignPerson, {
      data: {  }
    });

    console.log(companyId)

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });


  }

  updateContent(event: MatSelectChange) {
    
    this.noteForm.controls["content"].setValue(event.value);
    this.noteForm.controls["content"].markAsDirty();
    this.noteForm.updateValueAndValidity();

    
  }
}
