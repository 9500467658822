<div mat-dialog-content class="container">
  <div class="row">
    <div class="col text-center">
      <h1 mat-dialog-title>Upload Results</h1>
      <h6 *ngIf="request">{{request.worker.firstName}} {{request.worker.lastName}}</h6>
    </div>
  </div>

  <div class="col text-center">
    <div class="form-row" style="margin-top: 1rem;">
      <input hidden #fileInput type="file" id="file">
      <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
      &nbsp;
      <mat-label *ngIf="fileInput.files[0]">{{fileInput.files[0].name}}</mat-label>

    </div>
    <div class="form-row" style="margin-top: 1rem;">
      <mat-checkbox [(ngModel)]="complete">
        Were the results complete?
      </mat-checkbox>
    </div>
    <div class="form-row" style="margin-top: 1rem;">
      <mat-label>Comment</mat-label>
      <mat-form-field>
        <input matInput [(ngModel)]="comment" type="text">
      </mat-form-field>
    </div>
  </div>
</div>


<mat-dialog-actions>
  <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Cancel</button>
  <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onSubmit(fileInput.files)" type="submit">Upload</button>
</mat-dialog-actions>
