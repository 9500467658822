import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { ProviderPEFAService } from './provider-pefa.service';
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CancelBookingDialog, Booking } from "../shared/shared-component";
import { JOBFIT_DATETIME_FORMAT } from "../shared/shared-component";


@Component({
    selector: 'app-provider-pefa-list',
    templateUrl: './provider-pefa-list.component.html'
})

export class ProviderPEFAListComponent implements OnInit {

    public providerAdminService: ProviderPEFAService;
    public dataSource: MatTableDataSource<Booking>;
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

    displayedColumns = [
        "workerName",
        "appointmentdate",
        "status",
        "assessor",
        "job",
        "referralType",
        "notes",
      "actions",
        "paperwork",
    ];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    //Modal


    ngOnInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    constructor(public dialog: MatDialog, providerService: ProviderPEFAService, public fb: FormBuilder, public messageService: MessageService) {
        this.providerAdminService = providerService;
        this.dataSource = new MatTableDataSource();
        this.loadBookingList()
    }


    loadBookingList() {
        this.providerAdminService.getBookings().subscribe(
            bookings => {
                this.dataSource.data = bookings
            }, error => console.error(error)
        );
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    cancelBooking(id: string, cancelReason: string) {

        this.providerAdminService.cancelBooking(id, cancelReason).subscribe(r => {
            this.loadBookingList();
            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }
        )
    }


    recordNoShow(id: string) {
        this.providerAdminService.recordNoShowBooking(id).subscribe(r => {
            this.loadBookingList();
            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }
        )
    }

  openDialog(id: string): void {

        const dialogRef = this.dialog.open(CancelBookingDialog, {
          width: '250px',
          data: { pefaId: id }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.cancelBooking(id, result);
            }
        });
    }
}
