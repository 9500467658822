<div *ngIf="!loading" [formGroup]="personForm">



  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <input matInput placeholder="Title" type="text" formControlName="title" />
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <input matInput placeholder="Preferred Name" type="text" formControlName="preferredName" />
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <input matInput placeholder="First Name" type="text" formControlName="firstName" />
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <input matInput placeholder="Last Name" type="text" formControlName="lastName" />
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <input matInput placeholder="Email" type="text" formControlName="email" />
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <input matInput placeholder="Phone Number" type="text" formControlName="phoneNumber" />
      </mat-form-field>
    </div>
  </div>

  <div class="form-row" formGroupName="employer">
    <div class="col">
      <mat-form-field>
        <input id="name" formControlName="name" type="text" placeholder="Type or Select an Employer" matInput [matAutocomplete]="auto" (keyup)="onCompanyKey($event)" required>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setCompanyFormData($event)">
          <mat-option *ngFor="let company of filteredCompanies" [value]="company">
            {{company.name}}
          </mat-option>
        </mat-autocomplete>
        <input id="id" type="hidden" class="form-control" formControlName="id">
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Close</button>
    <button mat-raised-button color="warn" style="margin-top: 1rem;" mat-button (click)="savePerson()">Save</button>
  </mat-dialog-actions>
</div>
