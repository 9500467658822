import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators'
import { PEFARequest, Person, ToDo } from '../shared/shared-component';
import { MessageService } from '../message/message.service';
import { User } from 'oidc-client';
import { CompanyUserRole } from '../company-admin/company-admin-service';
import { TooltipComponent } from '@angular/material/tooltip';




@Injectable({
  providedIn: 'root'
})
export class ToDoService {


  private client: HttpClient;
  private baseURL: string;
  private requestURL = 'api/ToDoItems/'
  private pefaRequestURL = 'api/PEFARequests/'

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, public messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }


  public getTodos(): Observable<ToDo[]> {
    return this.client.get<ToDo[]>(this.baseURL + this.requestURL)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  public getTodo(id: string): Observable<ToDo> {
    return this.client.get<ToDo>(this.baseURL + this.requestURL + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  public getPEFATodos(id: string): Observable<ToDo[]> {
    return this.client.get<ToDo[]>(this.baseURL + this.pefaRequestURL + id + '/ToDo')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  public getPEFATodosLite(id: string): Observable<ToDo[]> {
    return this.client.get<ToDo[]>(this.baseURL + this.pefaRequestURL + id + '/ToDoLite')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };  

  public hasDownloadedReport(id: string): Observable<string> {
    return this.client.get<string>(this.baseURL + this.requestURL + 'hasDownloadedReport/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  public updateTodo(id: string, todo: ToDo): Observable<ToDo[]> {
    return this.client.put<ToDo[]>(this.baseURL + this.requestURL + id, todo)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  public getUsersByRole(role: string): Observable<Person[]> {
    return this.client.get<Person[]>(this.baseURL + 'api/User/Role/' + role).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  public assignTodo(todoID: string, person: Person): Observable<ToDo> {
    return this.client.put<ToDo>(this.baseURL + 'api/ToDoItems/' + todoID + '/Assign', person).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  public assignReportWriter(pefaRequestID: string, personID: string): Observable<any> {
    return this.client.post<any>(this.baseURL + 'api/User/AssignReportWriter?pefaRequestID=' + pefaRequestID + '&personID=' + personID, null).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  public createTodo(todo: ToDo): Observable<ToDo> {
    return this.client.post<ToDo>(this.baseURL + 'api/ToDoItems', todo)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  public getUsers(): Observable<Person> {
    return this.client.get<Person>(this.baseURL + this.requestURL)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }




  private handleError(error) {
    let errorMessage = '';
    //let errors: any = JSON.stringify(error.json());
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
    }
    this.messageService.add(errorMessage)
    return throwError(errorMessage);
  };

}
