import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationStart , Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { AuthorizeService } from '../../api-authorization/authorize.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  public navMenuItems: NavMenuItem[];
  isExpanded = false;
  public baseURL: string;
  navStart: Observable<NavigationStart>;
  public isAuthenticated: Observable<boolean>;

  @ViewChild(MatSidenavContainer, { static: true }) sidenavContainer: MatSidenavContainer;



  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, public authorizeService: AuthorizeService) {
    this.baseURL = baseUrl;
    this.navStart = router.events.pipe(
      filter(evt => evt instanceof NavigationStart)
    ) as Observable<NavigationStart>;
    this.loadMenu();
  }

  collapse() {
    this.isExpanded = !this.isExpanded; 
  }

  ngOnInit(): void {
    this.navStart.subscribe(evt => this.loadMenu());

    this.authorizeService.isAuthenticated().subscribe(
      result => this.loadMenu()
    );
    
  }

  public loadMenu(): void {
    this.http.get<NavMenuItem[]>(this.baseURL + 'api/NavMenu/GetNavMenus').subscribe(result => {
      //this.navMenuItems = result;

      this.navMenuItems = sortNavMenuItems(result);

    }, error => console.error(error));
  }

  public toggle(): void {

    

    if (this.sidenavContainer._drawers.first.opened) {
      this.sidenavContainer.close();
    } else {
      this.sidenavContainer.open();
    }
  }
}

//Sorting Menu items by sort number rather then its default of by db id number
/**
 * This is for now basic and does not account for sub menus.
 * 
 */
function sortNavMenuItems(result: NavMenuItem[]): NavMenuItem[] {

  let sortedNavMenuItem: NavMenuItem[] = result.sort(function (obj1, obj2) {
    return obj1.order - obj2.order;
  });

  return sortedNavMenuItem;
}

interface NavMenuItem {
  ID: number;
  order: number;
  //securityGroups: string;
  parent: NavMenuItem;
  link: string;
  label: string;
}
