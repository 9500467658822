import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { MessageService } from "../message/message.service";
import { Doctor, MedicalCondition, MedicalConditionItem, Practice } from "../shared/shared-component";
import { MedicalClearanceService } from "./medicalclearance.service";
import { DoctorService } from "../doctor/doctor.service";
import { PracticeService } from "../practice/practice.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";


@Component({
  selector: 'add-dialog-modal-medical-clearance',
  templateUrl: 'add-medical-clearance-dialog.component.html',
})
export class AddMedicalClearanceDialog implements OnInit {

  public medicalClearanceForm = new FormGroup({
    status: new FormControl('NEW'),
    condition: new FormControl('', Validators.required),
    outcome: new FormControl(''),
    otherInfo: new FormControl('', Validators.required),
    practice: new FormGroup({
      id: new FormControl('0'),
      name: new FormControl(''),
    }),
    doctor: new FormGroup({
      id: new FormControl('0'),
      name: new FormControl(''),
    })
  });

  doctors: Doctor[];
  filteredDoctors: Doctor[];
  practices: Practice[];
  filteredPractices: Practice[];

  uploadSuccess = "";
  healthConditions: MedicalConditionItem[] = []


  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<AddMedicalClearanceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public medService: MedicalClearanceService, public messageService: MessageService
    , public doctorService: DoctorService, public practiceService: PracticeService) {
  }



  ngOnInit(): void {
    this.doctorService.getDoctors().subscribe(result => {
      this.doctors = result
    }
    )

    this.practiceService.getPractices().subscribe(result => {
      this.practices = result
    }
    )

    if (this.data.clearanceId) {
      this.medService.get(this.data.clearanceId).subscribe(result => {
        this.medicalClearanceForm.patchValue(result);
      });
    }
    this.healthConditions = MedicalCondition.getMedicalConditions();
  };


  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(): void {
    if (this.data.clearanceId) {
      var formValue = this.medicalClearanceForm.value;
      formValue.id = this.data.clearanceId;
      this.medService.saveMedicalClearance(this.data.clearanceId, formValue).subscribe(() => {
        this.messageService.add("Medical Clearance successfully saved");
        this.dialogRef.close(true);
      });
    } else {
      this.medService.addMedicalClearance(this.data.pefaId, this.medicalClearanceForm.value).subscribe(() => {
        this.messageService.add("Medical Clearance successfully saved");
        this.dialogRef.close(true);
      }
      );
    }

  }
  onHealthConditionChange(event: any): void {
    this.medicalClearanceForm.patchValue({
      condition: event?.value
    });
  }
  displayFn(practice: Practice): string {
    return practice && practice.name ? practice.name : '';
  }

  onPracticeKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredPractices = this.practices.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
    var id = '0';

    if (this.filteredPractices.length == 1 && this.filteredPractices[0].name.toLowerCase() == filterValue) {
      id = this.filteredPractices[0].id;
    }
    this.medicalClearanceForm.patchValue({

      practice: {
        id: id
      }

    });
  }

  setPracticeFormData($event: MatAutocompleteSelectedEvent) {
    let companyId = $event.option.value.id;
    this.medicalClearanceForm.patchValue({

      practice: {
        id: companyId,
        name: $event.option.value.name

      }

    });
  }


  displayDoctorFn(doctor: Doctor): string {
    return doctor && doctor.name ? doctor.name : '';
  }

  onDoctorKey(event: KeyboardEvent) { // with type info

    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredDoctors = this.doctors.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);

    if (this.filteredDoctors.length == 1 && filterValue == this.filteredDoctors[0].name.toLowerCase()) {
      let doctorId = this.filteredDoctors[0].id;
      let doctorName = this.filteredDoctors[0].name;
      this.medicalClearanceForm.patchValue({
        doctor: {
          name: doctorName,
          id: doctorId
        }
      });
    } else {
      this.medicalClearanceForm.patchValue({
        doctor: {
            id: '0'
        }
      });


    }
  }

  setDoctorFormData($event: MatAutocompleteSelectedEvent) {
    let companyId = $event.option.value.id;
    this.medicalClearanceForm.patchValue({

      doctor: {
        id: companyId,
        name: $event.option.value.name
      }

    });
  }
}
