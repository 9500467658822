<ng-template #NoCompanyToList>
  <p *ngIf="loading == false">There are no companies </p>
</ng-template>

  <mat-toolbar class="mat-primary">Companies</mat-toolbar>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
  </mat-form-field>

  <!--<p *ngIf=""><em>Loading...</em></p>-->

  <div class="mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.name}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
    <mat-paginator [length]="dataSource?.data.length" [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]" [showFirstLastButtons]="true"></mat-paginator>
  </div>

  <ng-template #elseName>
    <span>&nbsp;</span>
  </ng-template>

