import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, Input, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { MessageService } from '../message/message.service';
import { ToDo, DialogUploadReport } from "../shared/shared-component";
import { PefaInvoiceDialog } from "../shared/invoice-pefa-dialog.component";
import { UploadResultsDialog } from "../shared/pefa-upload-results-dialog.component";
import { UpdateBookingDialog } from "../shared/update-booking-dialog.component";
import { ToDoService } from "./todo.service";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { AddNewPerson } from "../shared/person/person-dialog.component";
import { AssignPerson } from "../shared/note-view/assign-todo/assign-dialog.component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { SharedService } from "../shared/shared.service";


@Component({
  selector: 'app-todo-actions',
  templateUrl: './todo-action.component.html'

})

export class ToDoActionsComponent implements OnInit, AfterViewInit {

  public todoService: ToDoService;
  public assignPerson: AssignPerson;

  @Input() todo: ToDo;

  @Output() updated = new EventEmitter<boolean>();

  ngOnInit(): void {

  }

  ngAfterViewInit() {
  }

  constructor(public dialog: MatDialog, todoService: ToDoService, public messageService: MessageService, public pefaService: PEFARequestService) {
    this.todoService = todoService;
  }

  openResultsDialog(): void {
    const dialogRef = this.dialog.open(UploadResultsDialog, {
      data: {
        bookingId: this.todo.linkedId,
        pefaId: this.todo.pefaRequest.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  openUpdateBookingDialog(): void {
    const dialogRef = this.dialog.open(UpdateBookingDialog, {
      data: { bookingId: this.todo.linkedId }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  openBookingInvoiceDialog(): void {
    const dialogRef = this.dialog.open(PefaInvoiceDialog, {
      data: { pefaId: this.todo.pefaRequest.id, bookingId: this.todo.linkedId }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  openInvoiceDialog(): void {
    const dialogRef = this.dialog.open(PefaInvoiceDialog, {
      data: { pefaId: this.todo.pefaRequest.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });
  }

  markComplete(): void {
    if (this.todo.type.toLocaleLowerCase() == 'reminder' && 
        (this.todo.description.toLocaleLowerCase() == 'report ready to be sent' || this.todo.description.toLocaleLowerCase() == 'ready to send')) {
      // check has downloaded report at least once
      this.todoService.hasDownloadedReport(this.todo.pefaRequest.id).subscribe(result => {
        if (result == '1') {
          this.todo.status = "COMPLETE";
          this.todo.completedDate = moment().toDate();
      
          this.todoService.updateTodo(this.todo.id, this.todo).subscribe(result => {
            this.updated.emit(true);
          });
        } else {
          alert('Please download the report first before Marking as Complete.')
        }
      });
    } else {
      this.todo.status = "COMPLETE";
      this.todo.completedDate = moment().toDate();
  
      this.todoService.updateTodo(this.todo.id, this.todo).subscribe(result => {
        this.updated.emit(true);
      });
    }
  }
  openAssignPerson() {
    const dialogRef = this.dialog.open(AssignPerson, {
      data: { todoId: this.todo.id, pefarequestId: this.todo.pefaRequest.id, object: this.todo.object, type: this.todo.type, desc: this.todo.description, linkedId: this.todo.linkedId }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
    });


  }
  openReportDialog(): void {
    const dialogRef = this.dialog.open(DialogUploadReport, {
      data: { pefaId: this.todo.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(null, null, () => {
      this.updated.emit(true);
    });
  }

  public downloadReport() {
    if (this.todo.pefaRequest.report != null) {
      this.pefaService.downloadAttachment(this.todo.pefaRequest.report.id).subscribe(data => {
        SharedService.downloadFile(data);
      });
    }
  }
}
