import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { User } from "oidc-client";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { PEFA } from "../office-admin-pefa/office-admin-pefa-detail.component";
import { MessageService } from "../message/message.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { SharedService } from "./shared.service";
import moment, { Moment } from "moment";


export const JOBFIT_DATETIME_FORMAT = 'dd-MMM-yyyy hh:mm a';
export const JOBFIT_DATE_FORMAT = 'dd-MMM-yyyy';
export const JOBFIT_TIME_FORMAT = 'hh:mm a';




@Component({
  selector: 'dialog-modal-upload',
  templateUrl: 'Upload-Prompt.html',
  styleUrls: ['./office-admin-pefa.component.css']
})

export class DialogUploadReport implements OnInit {
  uploadForm = new FormGroup({
    score: new FormControl('0', Validators.required),
    reason: new FormControl(''),
  })
  filename: string = "No file selected";
  uploadSuccess = "";
  enable_btn: boolean = true;
  adminQA : boolean = true;
  smeQA: boolean = false;
  isUploading: boolean = false;
  reportWriters: Person[];
  dateOfReport = new FormControl();

  selectedWriter: Person;
  selectedWriterId: string;
  request: PEFARequest;
  enable_fileNameDisplay = true;

  constructor(
    public dialogRef: MatDialogRef<DialogUploadReport>,
    @Inject(MAT_DIALOG_DATA) public data: pefaBooking, public officeAdminService: OfficeAdminPEFAService, public sharedService: SharedService, public pefaService: PEFARequestService) { }


  ngOnInit(): void {
    this.sharedService.getReportWriters().subscribe(result => {
      this.reportWriters = result;
    })
    this.pefaService.getPEFARequest(this.data.pefaId).subscribe(pefa => {
      this.request = pefa;
      // pre-select the assign report writer
      this.selectedWriterId = (pefa.reportWriter != null) ? pefa.reportWriter.id : "";
    });
  }

  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  public enable_button() {
    this.enable_btn = false;
  }

  public uploadFile(files: File) {
    // we should only be able to click this once
    if (!this.isUploading) {
      this.isUploading = true;
      this.filename = "Uploading - " + files[0].name;

      // find selected report writer
      this.selectedWriter = this.reportWriters.find(obj => {
        return obj.id === this.selectedWriterId;
      });
  
      var reportDate = this.dateOfReport.value != null ? this.dateOfReport.value.format("YYYY-MM-DD") : "";
  
      this.officeAdminService.uploadPEFADoc(files, this.data.pefaId, this.uploadForm.value.score, this.adminQA, this.smeQA, this.uploadForm.value.reason, this.selectedWriter, reportDate).subscribe(
        Response => {
          this.enable_fileNameDisplay = false;
          this.isUploading = false;
          if (Response == true) {
            this.uploadSuccess = this.filename + " has been uploaded";
            this.onNoClick(true);
          }
          else {
            this.uploadSuccess = this.filename + " Upload has not been successful";
          }
        }
      );
    }
  }
}


@Component({
  selector: 'dialog-modal-change-PEFAStatus',
  templateUrl: 'Change-Status-Modal.html',
  styleUrls: ['./office-admin-pefa.component.css']
})
export class DialogStatusChange {

  selected: number = -1;
  enable_btn: boolean = true;
  status_note: string = "";
  status_return: any;
  statusEnum = PEFA_STATUS;


  constructor(
    public dialogRef: MatDialogRef<DialogStatusChange>,
    @Inject(MAT_DIALOG_DATA) public data: PEFA, public officeAdminService: OfficeAdminPEFAService, public messager: MessageService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeStatus(option: number): void {

    this.officeAdminService.changePEFAStatus(this.data.id, option, this.status_note).subscribe(
      Response => {
        if (Response == true) {
          this.dialogRef.close();
        }
        else {
          //Something went wrong update failed
          this.messager.add("Error - PEFA Status Update not complete, please try again");
          this.dialogRef.close();
        }
      }
    );
  }
}

@Component({
    selector: 'cancel-booking-dialog',
    templateUrl: 'cancelBooking.html',
})
export class CancelBookingDialog {

    bookingId: string;
    reason: string = null;
    noShow: boolean = false;
    statusText: string = "";
    enableStatus: boolean = false;
    enableCancelBtn: boolean = true;


    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CancelBookingDialog>, @Inject(MAT_DIALOG_DATA) public data: pefaBooking,
        public pefarequestService: PEFARequestService)
    {
      this.bookingId = this.data.bookingId;
    }

    onNoClick(yn: boolean): void {
        this.dialogRef.close(yn);
    }

    checkReasonEntered(): void {
        if (this.reason == null || this.reason.length <= 0) {
            this.enableCancelBtn = true;
        } else {
            this.enableCancelBtn = false;
        }
    }

    clearReason() {
        this.reason = null;
        this.checkReasonEntered();
    }

    onYesClick(): void {

        //mark reason as required, check it has been entered. disable cancel button until done.
        //show this and prevent continue

      this.pefarequestService.cancelPEFABooking(this.bookingId, this.reason, this.noShow).subscribe(
            result => {
                if (result == true) {
                    this.onNoClick(true);
                }
                else {
                    this.enableStatus = true;
                    this.statusText = "Error - Something went wrong the booking has not been cancelled";
                }

            });

    }
}

@Component({
  selector: 'booking-diagnostics-dialog',
  templateUrl: 'booking-diagnostics-dialog.html'
})
export class BookingDiagnosticsDialog {
  bookingId: string;
  last_page: string = "";
  last_submit: string = "";
  disableDownload: boolean = true;

  constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<BookingDiagnosticsDialog>, @Inject(MAT_DIALOG_DATA) public data: pefaBooking,
      public pefarequestService: PEFARequestService)
  {
    this.bookingId = this.data.bookingId;
    this.pefarequestService.getBookingDiagnostics(this.bookingId).subscribe(result => {
      // console.log(result);
      if (result) {
        this.last_page = result['last_page'];
        this.last_submit = result['last_submit'];
        this.disableDownload = this.last_submit == '';
      }
    });
  }

  onClose(): void {
      this.dialogRef.close();
  }

  onDownloadSnapshot(): void {
    this.pefarequestService.getResultsSnapshot(this.bookingId).subscribe(data => {
      if (data != null) {
        SharedService.downloadFile(data);
      } else {
        alert('Error creating PDF, try manually using jsreport.')
      }
    });
  }  
}

export class Utility {

  public static convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    let formData = form || new FormData();
    let formKey;

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof moment) {
        if (model[propertyName].toISOString()) {
          formData.append(formKey, model[propertyName].toISOString());
        }
      }
      else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertModelToFormData(element, formData, tempFormKey);
        });
      }
      else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
        this.convertModelToFormData(model[propertyName], formData, formKey);
      else
        formData.append(formKey, model[propertyName].toString());
    }
    return formData;
  }
}

export class Timezone {
  public static DEFAULT = 'Australia/Brisbane';

  public static getTimezone(): TimezoneItem[] {
    var timezones: TimezoneItem[] = [];
    var momentTimezones= moment.tz.zonesForCountry("au", true).filter(x=> x.name.indexOf("Australia") > -1);
    momentTimezones.forEach((tz) => {
      timezones.push(new TimezoneItem(tz.name, tz.name));
    });
    momentTimezones= moment.tz.zonesForCountry("nz", true);
    momentTimezones.forEach((tz) => {
      timezones.push(new TimezoneItem(tz.name, tz.name));
    });    

    return timezones;
  }
  public static convertHhMmFromToTZ(time, from_zone, to_zone): string {
    var format = 'h:mm A';
    var from_dt = moment.tz(time, format, from_zone);
    // console.log('from', from_dt.tz(), from_dt, from_dt.format(format));
    // console.log('to', from_dt.tz(to_zone).tz(), from_dt.tz(to_zone), from_dt.tz(to_zone).format(format));
    return from_dt.tz(to_zone).format(format);   
  }
  public static convertUtcToTZAsMomentObject(utc_datetime, to_zone): Moment {
    var format = 'h:mm A';
    // get UTC datetime stored
    var utc_dt = moment(utc_datetime);
    // convert component time component to the appointment timezone
    var to_dt = moment.tz(utc_dt.format(), to_zone);
    // console.log('from', from_dt.tz(), from_dt, from_dt.format(format));
    // console.log('to', from_dt.tz(to_zone).tz(), from_dt.tz(to_zone), from_dt.tz(to_zone).format(format));
    return to_dt;   
  }
  public static convertUtcToTZAsDateObject(utc_datetime, to_zone): Date {
    var format = 'h:mm A';
    // get UTC datetime stored
    var utc_dt = moment(utc_datetime);
    // convert component time component to the appointment timezone
    var to_dt = moment.tz(utc_dt.format(), to_zone);
    // console.log('from', from_dt.tz(), from_dt, from_dt.format(format));
    // console.log('to', from_dt.tz(to_zone).tz(), from_dt.tz(to_zone), from_dt.tz(to_zone).format(format));
    return new Date(to_dt.format('YYYY-MM-DD HH:mm:ss'));   
  }
  public static convertUtcToTZ(utc_datetime, to_zone): string {
    var format = 'h:mm A';
    // get UTC datetime stored
    var utc_dt = moment(utc_datetime);
    // convert component time component to the appointment timezone
    var to_dt = moment.tz(utc_dt.format(), to_zone);
    // console.log('from', from_dt.tz(), from_dt, from_dt.format(format));
    // console.log('to', from_dt.tz(to_zone).tz(), from_dt.tz(to_zone), from_dt.tz(to_zone).format(format));
    return to_dt.format(format);   
  }
}

export class TimezoneItem {
  constructor(
        public value : string,
        public name : string
  ) { }
}

export enum PEFA_STATUS {
  NEW = "new",
  PROCESSING = "processing",
  ACTIVE = "active",
  MC_REQUIRED = "mC_REQUIRED",
  ON_HOLD = "oN_HOLD",
  PENDING = "pending",
  COMPLETE = "complete",
  INVOICED = "invoiced",
  CANCELLED = "cancelled",
  INCOMPLETE = "incomplete",
};

export enum BookingStatus {
    NEW = "new",
    CONFIRMED = "confirmed",
    CANCELLED = "Cancelled",
    NOSHOW = "NoShow",
    COMPLETED = "completed",
    RESCHEDULED = "resheduled",
    CHECKEDIN = "checkedin"
}

export enum CompanyType {
    REGISTERED,
    UNREGISTERED
}

export class InvoicePricing {
  constructor(
    public currency: string,
    public total: number,
  ) { }
}



export class Person {
  constructor(
    public id: string,
    public type: string,
    public firstName: string,
    public lastName: string,
    public preferredName: string,
    public title: string,
    public email: string,
    public phoneNumber: string,
    public user: User,
    public relationships: PersonRelationship[],
    public externalId: string,

  ) { }

  displayName() : string {
      return this.firstName + ' ' + this.lastName;
  }
}

export class Assessor extends Person {
    constructor(
      public id: string,
      public jfsiid: string,
      public Audit: AuditInfo,
      public qualifications: string,
      public discipline: string,
      public trainingDate: Date,
      public assessmentCount,
      public type: string,
      public firstName: string,
      public lastName: string,
      public preferredName: string,
      public title: string,
      public email: string,
      public phoneNumber: string,
      public user: User,
      public relationships: PersonRelationship[],
      public training: Training[],
      public audits: ProviderAudit[],
      public externalId: string,
    ) {
      super(id, type, firstName, lastName, preferredName, title, email, phoneNumber, user, relationships, externalId);
  }
}

export class Training {
  constructor(
        public id : string,
        public courseName : string,
        public assessmentDate : Date,
        public completedDate : Date,
        public trainer : string,
        public location : string,
        public outcome : string,
        public assessor : string,

  ) { }

}

export class ResponseStatus {
    hasErrors: any;
    errors: any;
    error: any;
    successMessage: any;
    successMessages: any;
    constructor(
        HasError: boolean,
        Errors: string[],
        Error: string,
        SuccessMessage: string,
        SuccessMessages: string[]
    ) { }
}

export class Company {
  constructor(
    public id: string,
    public name: string,
    public address: Location,
    public companyNumber: string,
    public companyRegistrationStatus: string,
    public tier: string,
    public phoneNumber: string,
    public accountEmail: string,
    public faxNumber: string,
    public paymentTypes: PaymentType[],
    public people: PersonRelationship[],
    public audit: AuditInfo,
    public status: string,
    public externalId: string,
    public statusNote: string,
    public bpSys: number,
    public bpDia: number,
    public clearanceLocation,    
    public bookingNotes: string,
    public jodCompanyId: number | null,
    public updatedByBp: string,    
    public updatedByCl: string,    
    public updatedByBr: string,    
    public updatedByJod: string,
    public updatedAtBp: Date,
    public updatedAtCl: Date,
    public updatedAtBr: Date,
    public updatedAtJod: Date,
    public approvedByBp: string,
    public approvedByCl: string,
    public approvedByBr: string,
    public updatedByNmBp: string,
    public updatedByNmCl: string,
    public updatedByNmBr: string,
    public onUpdateNotifyEmail: string
  ) { }
}

export class Provider extends Company {
    constructor(
        public id: string,
        public name: string,
        public providerTier: string,
        public locations: Location[],
        public location: Location,
        public status: string,
        public statusNote: string,
        public ladderRatingKG: string,
        public contactEmail: string,
        public address: Location,
        public companyNumber: string,
        public companyRegistrationStatus: string,
        public tier: string,
        public phoneNumber: string,
        public faxNumber: string,
        public accountEmail: string,
        public paymentTypes: PaymentType[],
        public people: PersonRelationship[],
        public audit: AuditInfo,
      public providerAudits: ProviderAudit[],
      public bpSys: number,
      public bpDia: number,

      public consentLocation,
      public clearanceLocation,
      public externalId: string,
      public bookingNotes: string

    ) {
      super(id, name, address, companyNumber, companyRegistrationStatus, tier, phoneNumber, accountEmail, faxNumber, paymentTypes, people, audit, status, externalId, statusNote, bpSys, bpDia, clearanceLocation, bookingNotes, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    }
}

export class ProviderAudit {
  constructor(
        public id :string,
        public indeminityInsurancePolicyNo :string,
        public indeminityInsurancePolicyAmount :string,
        public indeminityInsurancePolicyExpiry: Date,

        public liabilityInsurancePolicyNo :string,
        public liabilityInsurancePolicyAmount :string,
        public liabilityInsurancePolicyExpiry :Date,
        public auditDate :Date,

        public workerCompPolicyNo :string,
        public workerCompPolicyAmount :string,
        public workerCompPolicyExpiry :Date,
        public submissionLink :string,

        public status :string,
  ) { }

}

export class PersonRelationship {
  constructor(
    public id: string,
    public startDate: Date,
    public endDate: Date,
    public status: string,
    public type: string,
    public person: Person,
    public personID: string,
    public company: Company,
    public companyID: string,

  ) {

  }
}

export class Location {
  constructor(
    public id: string,
    public address: string,
    public phoneNumber: string,
    public emailAddress: string,
    public type: string,
    public region: string,
    public headOffice: boolean,

  ) { };
}

export class Booking {
    constructor(
      public id: string,
      public pefArequest: PEFARequest,
      public pefArequestID: string,
      public provider: Provider,
      public appointmentDateTime: Date,
      public cancelDate: Date,
      public status: BookingStatus,
      public location: string,
      public workerStatus: BookingStatus,
      public cancelReason: string,
      public bookingType: BookingType,
      public notes: string,
      public active: boolean,
      public audit: AuditInfo,
      public assessor: Assessor,
      public tests: BookingJobDemand[],
      public bookingPayment: Payment,
      public providerPayment: Payment,
      public appointmentTimezone: string,
      public localtime: string
    ) { }
}
//

export class BookingJobDemand {
  constructor(
    public id: string,
    public bookingID: string,
    public booking: Booking,
    public demandTypeID: string,
    public demandType: DemandType,
  ) { }
}





export class PaymentType {
  constructor(
    public id :number,
    public type: string,
    public reference: string,
    public limit: number,
    public expiry : Date,
    public status: string,
  ) { }
}

export class PEFABooking {
    constructor(
        Id: string,
        ProviderId: string,
        AppointmentDateTime: Date,
        Status: BookingStatus,
        CancelReason: string,
        notes: string,
        bookingType: BookingType,
        public Audit: AuditInfo,
    ) { }
}

export class BookingType {
    constructor(
        ID: string,
        displayName: string,
        description: string
    ) { }
}

export class Worker {
    constructor(ID: string,
        public firstName: string,
        public lastName: string,
        public preferredName: string,
        public dateOfBirth: Date,
        public phoneNumber: string,
        public home: string,
        public work: string,
        public email: string,
        public site: string,
        public gender: string,
      public weight: string,
      public homeAddress: Location,

      public mailAddress: Location ,
      public employeeId: string,
      public availability: Availability[],

      public medicalClearanceQuestionsFormID: string ,

      public audit: AuditInfo ,

      public medicalQuestionStatus: string ,
      public medicalQuestionSubmissionId: string,
      public medicalClearances: MedicalClearance[] ,
      public preferredTestLocation: string,
      public payment: PaymentType,
      public externalId: string,
    ) {
    }

}

export class Availability {
  constructor(
    public ID :string,
    public StartTime: Date,
    public EndTime: Date,
    public status: string,
    public notes: string,
  ) { }

}

export class MedicalClearance {

  constructor(
    public id: string,
    public status:string,
    public condition: string,
    public outcome: string,
    public otherInfo: string,
    public doctor: Doctor,
    public medicalCertificate: Attachment,
    public referralLetter: Attachment,
    public outcomeDate : Date,
    public outcomeNotes: string,
    public audit: AuditInfo,

  ) {}
}

export class Doctor {
  constructor(
    public id :string,
    public name: string,
    public address: string,
    public phoneNumber: string,
    public faxNumber: string,
    public email: string,
    public preferredContactType: string,

  ) { }
}

export class Practice {
  constructor(
    public id: string,
    public name: string,
    public address: string,
    public phoneNumber: string,
    public faxNumber: string,
    public email: string,
    public preferredContactType: string,
    public doctors: Doctor[]
  ) { }
}



export class Status {
    constructor(
        ID: string,
        Status: string,
        Note: string,
        ChangeTime: Date,
    ) {
    }
}

export class PEFAAccept {
    constructor(
        ID: string,
        accepted: boolean,
        Accepted_Time: Date
    ) {
    }
}



export class ReferrerDetails {
    constructor(
      public ID: string,
      public name: string,
      public phone: string,
      public position: string,
      public email: string
    ) {
    }
}

export class CancelRequest {
    constructor(
        BookingID: string,
        Reason: string
    ) {
    }
}

export class Note {
  constructor(
    public id: string,
    public content: string,
    public adminOnly: boolean,
    public important: boolean,
    public status: string,
    public archiveReason: string,
    public editReason: string,
    public audit: AuditInfo,

  ) {
  }
}


export class Message {
  constructor(
    public ID: string,
    public text: string,
    public from: string,
    public to: string,
    public otherInfo: string,
    public status: string,
    public type: string,
    public subject: string,
    public audit: AuditInfo,
  ) {
  }
}

export class QualityControl {
  constructor(
    public id: string,
    public reason: string,
    public outcome: string,
    public auditDate: Date,
    public person: Person,
    public comments: string,
  ) { }

}

export class Payment {
  constructor(public id: string,
    public invoiceNumber: string,
    public invoiceDate: Date,
    public currency: string,
    public invoiceAmount: string,
    public paymentType: string,
    public paymentReference: string,
    public otherInfo: string,
    public audit: AuditInfo,
  ) { }
}

export class KPI {
  constructor(
    public id: string,
    public delayReason: string,
    public name: string,
    public notes: string,
  ) { }
}


export class NoteView {
  constructor(
    public id: string,
    public headline: string,
    public adminOnly: boolean,
    public important: boolean,
    public type: string,
    public createdDate: string,
    public details: any
  ) {
  }
}

export class MessageTemplate {
  constructor(
    public id: string,
    public code: string,
    public name: string,
    public content: string,
    public audit: AuditInfo,
    public object: string,

  ) {
  }
}

export class SearchStats {
  constructor(public ACTIVE: number,
    public PENDING: number,
    //public NEW: number,
    public COMPLETE: number,
    public ON_HOLD: number,
  ) {
  }
}

export class PageResults {
  constructor(
    public results: PEFARequest[],
    public count: number,
  ) { }

}

export class PEFASearchResults {
  constructor(
    public results: PageResults,
    public buckets: SearchStats,
  ) { }
}

//api/SampleData/WeatherForecasts
export class PEFARequest {

  constructor(
    public id: string,
    public requestedTime: Date,
    public completedTime: Date,
    public appointmentDate: Moment,
    public firstContact: Date,
    public PIConfirmationSent: Date,
    public PIConfirmationReceived: Date,
    public responseTime: string,
    public requesterEmail: string,
    public requesterName: string,
    public location: string,
    public company:Company,
    public paymentMethod: string,
    public recruiter: string,
    public status: PEFA_STATUS,
    public statusNote: string,
    public accepted: Date,
    public worker: Worker,
    public pefaJob: PEFAJob,
    public referralType: ReferralType,
    public score: string,
    public holdDate: Date,
    public messages: Message[],
    public referrerDetails: ReferrerDetails,
    public otherInfo: string,
    public bookings: Booking[],
    public notes: Note[],
    public type: string,
    public paymentStatus: string,
    public payments: Payment[],
    public audit: AuditInfo,
    public report: Attachment,
    public kpiNotes: string,
    public reportWriter: Person,
    public reportStatus: string,
    public qualityControl: QualityControl[],
    public showActions: boolean = false,
    public kpiDetails: KPI[],
    public toDoCount: Number = 0,
    public jhgFileNo: string,
    public jhgBookingRequestID: string
  ) {
  }
}



export class PEFAJob {
  constructor(
    public id: string,
    public site: string,
    public name: string,
    public description: string,
    public demands: JobDemand[] = [],
    public audit: AuditInfo,
    public job: Job) { }
}

export class Job {
  constructor(public id: string,
    public name: string,
    public description: string,
    public jODLinkID: string,
    public demands: JobDemand[] = [],
    isActive: boolean,
    isPublic: boolean,
    isJobfitted: boolean,
    public audit: AuditInfo,
    public owner: Company,
    public notes: string[],
    public jodJobId: number,
    public jodJobLastSync: Date | null,
    public source: number | null, //
    public sourceNotes: string,
    // convenience properties (readonly)
    public createdByUser: string | null,
    public createdByDate: Date | null,
    public updatedByUser: string | null,
    public updatedByDate: Date | null
  )
  { }
}

export class JodJob {
  constructor(public Id: number,
    public Name: string
  )
  { }
}

export class JobList {
  constructor(public id: string,
    public name: string,
    public isActive: boolean,
    public isPublic: boolean,
    public isJobfitted: boolean,
    public audit: AuditInfo,    
    public notes: string[],
    public jodJobId: number,
    public jodJobLastSync: Date,
    public companyID: string,
    public companyName: string,
    public lastModifiedDate: Date
  )
  { }
}

export class JobDemand {
  constructor(public id: string,
    public value: string,
    public frequency: string,
    public demandType: DemandType,
    public optional: boolean = false,
    public selected: boolean = false
  ) { }
}

export class DemandType {
  constructor(public id: string,
    public name: string,
    public type: string,
    public group: string,
    public order: number,
    public orderMedical: number,
    public orderAssessment: number,

  ) { }
}


export class ReferralType {
  constructor(public ID: string,
    public name: string
  ) {}
}

export class Attachment {

  constructor(
    public id : string,
    public filename: string,
    public mimetype: string,
    public attachmentDate : Date,
    public type: string,
    public status: string,
    public note: string,
    public audit: AuditInfo,
  ) { }

}

export class ToDo {
  public id: string;
  public description: string;
  public type: string;
  public pefaRequest: PEFARequest;
  public linkedId: string;
  public object: string;
  public status: string;
  public dueDate: Date;
  public completedDate: Date;
  public urgent: boolean;
  public assignedTo: User;
  public appointmentTimezone: string;
  public myTime: string;
}

export class AuditInfo {

  constructor(
    public createdBy: User,
    public lastModifiedBy: User,
    public createdDate: Date,
    public lastModifiedDate: Date,
  ) { }
}

export class Report {

  constructor(
    public id: string,
    public name: string,
    public description: string,
    public criteria: ReportCriteria[],
    public format:string,
  ) { }
}

export class Timing {
  constructor(
    public startTime: string,
    public endTime: string) { }

}

export class BusinessCalendar {

  constructor(
    public holidays: string[],
    public monday: Timing[],
    public tuesday: Timing[],
    public wednesday: Timing[],
    public thursday: Timing[],
    public friday: Timing[],
    public saturday: Timing[],
    public sunday: Timing[],
  ) { }

}

export class ReportCriteria {

  constructor(
    public id: string,
    public type: string,
    public displayName: string,
    public parameterName: string,
    public required: boolean,
    public order: number
  ) { }
}


export class ConfigurationItem {

  constructor(
    public user: User,
    public code: string,
    public value: string,
    public id: number,
  ) { }
}



export interface FileUploadData {
  file: File;
  pefaId: number;
  score: number;
  reportWriter: string;
}

export interface pefaBooking {
  bookingId: string;
  companyId: number;
  pefaId: string;
  holdDate: Date;
}

export class MedicalCondition {

  public static getMedicalConditions(): MedicalConditionItem[] {
    var medicalConditions: MedicalConditionItem[] = [];

    medicalConditions.push(new MedicalConditionItem("High blood Pressure", "High blood Pressure"));
    medicalConditions.push(new MedicalConditionItem("Low blood Pressure", "Low blood Pressure"));
    medicalConditions.push(new MedicalConditionItem("Pacemaker", "Pacemaker"));
    medicalConditions.push(new MedicalConditionItem("Heart Condition or Heart surgery", "Heart Condition or Heart surgery"));
    medicalConditions.push(new MedicalConditionItem("Bone or Joint Surgeries", "Bone or Joint Surgeries"));
    medicalConditions.push(new MedicalConditionItem("Fractures", "Fractures"));
    medicalConditions.push(new MedicalConditionItem("Dislocations", "Dislocations"));
    medicalConditions.push(new MedicalConditionItem("Hernias", "Hernias"));
    medicalConditions.push(new MedicalConditionItem("Any other Surgeries", "Any other Surgeries"));
    medicalConditions.push(new MedicalConditionItem("Arthritis", "Arthritis"));
    medicalConditions.push(new MedicalConditionItem("Cancer", "Cancer"));
    medicalConditions.push(new MedicalConditionItem("Asthma or other lung condition", "Asthma or other lung condition"));
    medicalConditions.push(new MedicalConditionItem("Epilepsy", "Epilepsy"));
    medicalConditions.push(new MedicalConditionItem("Neurological conditions", "Neurological conditions"));
    medicalConditions.push(new MedicalConditionItem("Diabetes", "Diabetes"));
    medicalConditions.push(new MedicalConditionItem("Infectious Condition", "Infectious Condition"));
    medicalConditions.push(new MedicalConditionItem("Pregnancy", "Pregnancy"));
    medicalConditions.push(new MedicalConditionItem("Injury in past 6wks with treatment", "Injury in past 6wks with treatment"));
    medicalConditions.push(new MedicalConditionItem("Current medical certificate with work restrictions", "Current medical certificate with work restrictions"));
    medicalConditions.push(new MedicalConditionItem("Other", "Other"));
    medicalConditions.push(new MedicalConditionItem("Suspected Injury", "Suspected Injury"));

    return medicalConditions;
  }
}

export class MedicalConditionItem {
  constructor(
        public value : string,
        public name : string
  ) { }
}


