<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{noteView.headline}} - {{this.apptDateTime | date:dateFormat}}<span *ngIf="noteView.details.appointmentTimezone">&nbsp;({{noteView.details.appointmentTimezone}}) - My Time: {{localtime}}</span><span *ngIf="noteView.details.status">&nbsp;- {{noteView.details.status}}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  
  <mat-card>
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-label>
              Provider
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.provider.name}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Assessor
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.assessor.firstName}} {{noteView.details.assessor.lastName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Provider Status
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.status}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Worker Status
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.workerStatus}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Appointment Type
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.bookingType.displayName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Booked by
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdBy.userName}}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <app-office-admin-booking-actions [booking]="noteView.details" [pefa]="pefa" (updated)="refresh()"></app-office-admin-booking-actions>
    </mat-card-actions>
  </mat-card>

</mat-expansion-panel>
