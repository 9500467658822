import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges, AfterViewInit } from "@angular/core";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Company, Person, Provider } from "../shared-component";
import { MessageService } from "../../message/message.service";
import { OfficeAdminPEFAService } from "../../office-admin-pefa/office-admin-pefa.service";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-new-person',
  templateUrl: './person-dialog.component.html'
})
export class AddNewPerson implements OnInit {
  personForm: FormGroup =
    new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      preferredName: new FormControl(''),
      title: new FormControl(''),   
      phoneNumber: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      employer: new FormGroup({
        id: new FormControl(),
        name: new FormControl(),

      })
    })
  person: Person[];
  provider: Provider[];
  companies: Company[];
  filteredCompanies: Company[];
  public id: string;
  public loading: boolean = true;

  constructor(public dialogRef: MatDialogRef<AddNewPerson>,
    @Inject(MAT_DIALOG_DATA) public data: any, public officeAdminService: OfficeAdminPEFAService, public messageService: MessageService) { }

  public ngOnInit() {
    this.loading = true;
    this.officeAdminService.getAllCompany().subscribe(res => {
      this.companies = res;
      this.filteredCompanies = res;
      this.loading = false;
    });

    if (this.data.personId) {
      this.id = this.data.personId;
      this.officeAdminService.getPerson(this.id).subscribe(person => {
        this.personForm.patchValue(person);
        }
      );
    } else {
      this.personForm.patchValue({
        employer: {
          id: this.data.companyId
        }
      });
    }

  }



  onCompanyKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredCompanies = this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);

    if (this.filteredCompanies.length == 1 && filterValue == this.filteredCompanies[0].name.toLowerCase()) {
      let companyId = this.filteredCompanies[0].id;
      this.personForm.patchValue({
        employer: {
          id: companyId
        }
      });
    } else {
      this.personForm.patchValue({
        employer: {
          id: 0
        }
      });
    }
  }

  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  savePerson(): void {
    if (this.id) {
      this.officeAdminService.updatePerson(this.id, this.personForm.value).subscribe(res => {
        this.messageService.add("Person updatead");
        this.dialogRef.close({ outcome: true, person: res });
      })

    } else {
      this.officeAdminService.addPerson(this.personForm.value).subscribe(res => {
        this.messageService.add("Person added");
        this.dialogRef.close({ outcome: true, person: res });
      })
    }
  }

  setCompanyFormData($event: MatAutocompleteSelectedEvent) {
    let companyId = $event.option.value.id;
    let companyName = $event.option.viewValue;
    this.personForm.patchValue({

      employer: {

        name: companyName,
        id: companyId
      }

    });
  }


}
