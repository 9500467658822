<div [formGroup]="relationshipForm">
  <div class="form-row h-50">
    <div class="col-md-2 form-group" formGroupName="person">
      <mat-form-field class="m-1">
        <mat-label>Position</mat-label>
        <input matInput type="text" formControlName="title" />
      </mat-form-field>
    </div>
    <div class="col-md-2 form-group" formGroupName="person">

      <mat-form-field class="m-1">
        <mat-label for="firstName">First Name</mat-label>
        <input matInput type="text" formControlName="firstName" />
      </mat-form-field>
    </div>
    <div class="col-md-2 form-group" formGroupName="person">
      <mat-form-field class="m-1">
        <mat-label for="lastName">Last Name</mat-label>
        <input matInput type="text" formControlName="lastName" />
      </mat-form-field>
    </div>

  </div>
  <div class="form-row">
    <div class="col-md-2 form-group" formGroupName="person">
      <mat-form-field class="m-1">
        <mat-label for="email">Email</mat-label>
        <input matInput type="text" formControlName="email" required />
      </mat-form-field>
    </div>
    <div class="col-md-2 form-group" formGroupName="person">
      <mat-form-field class="m-1">
        <mat-label for="phoneNumber">Phone</mat-label>
        <input matInput type="text" formControlName="phoneNumber" />
      </mat-form-field>
    </div>
    <div class="col-md-2 form-group">
      <mat-form-field class="m-1">
        <mat-label for="type">Type</mat-label>
        <mat-select formControlName="type">
          <mat-option value="REPORT_WRITER">Report Writer</mat-option>
          <mat-option value="EMPLOYEE">Employee</mat-option>
          <mat-option value="ASSESSOR">Assessor</mat-option>
          <mat-option value="REFERRER">Referrer</mat-option>
          <mat-option value="RECEIVER">Receiver</mat-option>
          <mat-option value="STATS">Stats</mat-option>
          <mat-option value="PIONLY">PI Only</mat-option>
          <mat-option value="QA">Quality Assurnce</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    </div>
    <div class="form-row">
      <div class="col-md-2 form-group">
        <mat-form-field class="m-1">
          <mat-label for="type">Status</mat-label>
          <mat-select formControlName="status">
            <mat-option value="ACTIVE">ACTIVE</mat-option>
            <mat-option value="PENDING">PENDING</mat-option>
            <mat-option value="CANCELLED">CANCELLED</mat-option>
            <mat-option value="EXPIRED">EXPIRED</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
        <div class="col-md-2 form-group">
          <mat-form-field class="m-1">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startpicker" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
          </mat-form-field>
      </div>
        <div class="col-md-2 form-group">
          <mat-form-field class="m-1">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="apptpicker" formControlName="endDate" />
            <mat-datepicker-toggle matSuffix [for]="apptpicker"></mat-datepicker-toggle>
            <mat-datepicker #apptpicker></mat-datepicker>
          </mat-form-field>
      </div>
  </div>
  <div class="form-row  form-group">
    <div class="col-md-2">
      <button (click)="deletePerson()" mat-raised-button class="mat-primary" type="button"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></button>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
