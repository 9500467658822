import { Component, Inject } from "@angular/core";
import { Provider, Location, Assessor, BookingType, JobDemand, BookingStatus, pefaBooking, Company, Timezone, TimezoneItem } from "./shared-component";
import { from, zip, of } from "rxjs";
import { groupBy, mergeMap, toArray } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MessageService } from "../message/message.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import moment from "moment-timezone";
import { P } from "@angular/cdk/keycodes";

@Component({
  selector: 'dialog-modal-booking',
  templateUrl: 'Booking-Prompt.html',
  styleUrls: ['./office-admin-pefa.component.css']

})

export class DialogBooking {

  providers: Provider[];
  assessors: Assessor[];
  bookingTypes: BookingType[];
  locations: Location[];

  selectedProvider: Provider;
  selectedBooking: number = 0;
  selectedStatus: string = BookingStatus.NEW;
  selectedLocation: string = "";
  selectedWorkerStatus: string = BookingStatus.NEW;
  selectedAssessor: number = 0;
  statusCancelReason: string = "";
  bookingNote: string = "";
  bookingDate = moment();
  bookingDateValue: any = null;
  sendMessage: boolean = true;
  groupedProviders: any[] = new Array();
  demands: JobDemand[] = new Array();
  company: Company = null;
  selectedTimeZone: any;
  minBookingDate = moment();
  time = moment.tz.guess();
  checked: boolean = true;

  //booking Time settings

  //Booking Hour
  bookHourTime: string = '9:00 AM';


  //Booking Status Dropdown
  bookStatusEnum = BookingStatus;

  //Timezone Dropdown
  timezones= Timezone.getTimezone();

  //localtime
  localTime: string = Timezone.convertHhMmFromToTZ('9:00 AM', moment.tz.guess(), moment.tz.guess());

  //message
  confirmText: string = '';
  showAll: boolean = false;
  showHasNoDemands: boolean = false;


  ngOnInit(): void {
    this.selectedTimeZone = this.time;

    if (this.data.holdDate) {
      this.minBookingDate = moment(this.data.holdDate);
    }
    this.officeAdminService.getActiveProviders().
      subscribe(
        Response => {

          this.providers = Response
          this.providers = this.providers.filter(p => DialogBooking.hasInsurance(p));

          this.generateGroupProviders(this.providers);

        }, error => console.error(error));


    this.pefaService.getJobDemands(this.data.pefaId).subscribe(
      Response => {
        this.demands = Response;
        this.demands.sort((a, b) => {
          return (a.demandType.type === 'MH' ? 10 : 0) - (b.demandType.type === 'MH' ? 10 : 0) + a.demandType.orderAssessment - b.demandType.orderAssessment
        });

        this.demands.forEach(function (item: JobDemand) {
          item.selected = !item.optional;
        });

        // check if we may be missing Job Demands
        try {
          this.showHasNoDemands = true;
          for(var d of this.demands) {
            if (d.demandType.type === 'MH' && parseFloat(d.value) > 0.0) {
              this.showHasNoDemands = false;
              break;
            }
            if ((d.demandType.type === 'ST' || d.demandType.type === 'DT') && d.frequency != 'NT') {
              this.showHasNoDemands = false;
              break;
            }          
          }
        } catch (e: any) { console.error(e); }
      }, error => console.error(error));



    this.officeAdminService.getAllBookingTypes().subscribe(
      Response => {
        this.bookingTypes = Response
      }, error => console.error(error));

    this.pefaService.getCompany(this.data.pefaId).subscribe(result => {
      this.company = result;
    });      

  };

  private generateGroupProviders(providers: Provider[]) {

    this.groupedProviders = new Array();
    var flatProviders: any[] = new Array();
    providers.forEach(function (provider) {
      provider.locations.forEach(function (location) {
        if (location.type == "physical") {
          var providerClone = Object.assign({}, provider);
          providerClone.location = location;
          flatProviders.push(providerClone);
        }

      });


    });

    const source = from(flatProviders);

    const pipeline = source.pipe(
      groupBy(
        providers => providers.location.region
      ),
      mergeMap(group => zip(of(group.key), group.pipe(toArray())))
    );

    pipeline.subscribe(response => {      
      this.groupedProviders.push(response);
    }
    );


  }


  constructor(
    public dialogRef: MatDialogRef<DialogBooking>,
    @Inject(MAT_DIALOG_DATA) public data: pefaBooking, public pefaService: PEFARequestService, public officeAdminService: OfficeAdminPEFAService, public messageService: MessageService) {
  }


  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  bookingDateChanged(event: any) {
    this.bookingDateValue = event.value;
    this.evaluateProviderStatus();    
  }
  
  evaluateProviderStatus() {
    if (this.bookingDateValue != null && this.selectedAssessor != 0) {
      this.selectedStatus = this.bookStatusEnum.CONFIRMED;
    } else {
      this.selectedStatus = this.bookStatusEnum.NEW;
    }
  }

  savebooking(): void {
    let apptDateTime = moment.tz(`${this.bookingDate.format('YYYY-MM-DD')} ${this.bookHourTime}`, 'YYYY-MM-DD hh:mm A', this.selectedTimeZone);

    this.officeAdminService.addBooking(this.data.pefaId.toString(),
      this.selectedProvider.id.toString(),
      apptDateTime,
      BookingStatus[this.selectedStatus.toUpperCase()],
      this.statusCancelReason,
      this.bookingNote,
      this.selectedBooking.toString(),
      this.selectedAssessor.toString(),
      this.selectedLocation,
      BookingStatus[this.selectedWorkerStatus.toUpperCase()],
      this.demands,
      this.sendMessage,
      this.selectedTimeZone
    ).subscribe(
      Response => {
        if (Response == true) {
          this.messageService.add("Booking successfully saved");
          this.onNoClick(true);
        }
        else {
          this.confirmText = "Error - Booking has been unsuccessful please reload and try again";
        }
      }
      , error => console.error(error));
  }
  onTimezoneChange(event: any) {
    this.selectedTimeZone = event.value;
    this.localTime = Timezone.convertHhMmFromToTZ(this.bookHourTime, this.selectedTimeZone, moment.tz.guess());
  }
  onTimeChange() {
    this.localTime = Timezone.convertHhMmFromToTZ(this.bookHourTime, this.selectedTimeZone, moment.tz.guess());
  }
  updateAssessorList($event: MatAutocompleteSelectedEvent): void {
    let providerId = $event.option.value.id;
    this.selectedProvider = $event.option.value;
    this.officeAdminService.getAssessorsForProvider(this.selectedProvider.id).subscribe(result => this.assessors = result);

    this.locations = this.providers.find(r => r.id == providerId.toString()).locations.filter(l => l.type == "physical");
    if (this.locations && this.locations.length > 0) {
      this.selectedLocation = this.locations[0].id;
    } else {
      this.selectedLocation = "";
    }
    this.evaluateProviderStatus();
  }

  displayFn(provider: Provider): string {
    return provider && provider.name ? provider.name : '';
  }

  onProviderKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var providers = this.providers.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0 ||
      option.locations.some(loc => loc.region.toLowerCase().indexOf(filterValue) >= 0));
    this.generateGroupProviders(providers);
  }

  setProviderFormData($event: MatAutocompleteSelectedEvent) {
    let providerId = $event.option.value.id;    
    this.selectedProvider = providerId;
  }

  classForProvider(provider: Provider): string {
    var color = 'active-provider';

    if (!DialogBooking.hasInsurance(provider)) {
      // color = 'red-provider';
      color = 'active-noinsurance-provider';
    } else if (provider.status == 'PENDING') {
      color = 'pending-provider';

    } else if (provider.status == 'ONHOLD') {
      color = 'hold-provider';

    } else if (provider.status == 'INACTIVE') {
      color = 'red-provider';
    }
    return color;

  }

  public static hasInsurance(provider: Provider): boolean {
    var result = false;
    if (provider.providerAudits && provider.providerAudits.length > 0) {
      var audit = provider.providerAudits.reduce((p, c) => p.auditDate > c.auditDate ? p : c);

      if (audit) {
        result = (moment().isBefore(moment(audit.indeminityInsurancePolicyExpiry)) && moment().isBefore(moment(audit.liabilityInsurancePolicyExpiry)));
      }
    }

    return result;
  }

  showAllItems() {
    this.showAll = !this.showAll;

    if (this.showAll) {
      this.officeAdminService.getAllProviders().
        subscribe(
          Response => {
            this.providers = Response

            this.generateGroupProviders(this.providers);

          }, error => console.error(error));

    } else {
      this.officeAdminService.getActiveProviders().
        subscribe(
          Response => {
            this.providers = Response
            this.providers = this.providers.filter(p => DialogBooking.hasInsurance(p));
            this.generateGroupProviders(this.providers);

          }, error => console.error(error));
    }
  }

  onChangeDemand(evt: any) {    
    // demandType.id (12 = Ladder Climb, 6 = Stair Climb)
    let ladder = this.demands.find(d => d.demandType.id == '12');
    let stair = this.demands.find(d => d.demandType.id == '6');

    if (ladder && stair) {
      if (evt.checked == true) {
        // if ladder climb is selected, also select stair climb
        if (ladder.selected == true && stair.selected == false) {        
          stair.selected = true;
        } 
      } else { // uncheck
        // if stair is deselected, also deselect stair climb
        if (stair.selected == false && ladder.selected == true) {
          ladder.selected = false;
        }  
      }
    }
  }

  onSelectAll() {
    this.demands.forEach(function (item: JobDemand) {
      item.selected = true;
    });    
  }

  onDeSelectAll() {
    this.demands.forEach(function (item: JobDemand) {
      item.selected = false;
    });    
  }
}
