<div *ngIf="pefarequest" class="container-fluid" style="overflow-y:unset;">
  <div class="container-fluid pefa-summary-container">
    <div class="row">
      <div class=" pb-0 col-6">
        <h2>{{pefarequest.worker.firstName}} {{pefarequest.worker.lastName}}</h2>
        <mat-chip-list>
          <mat-chip [class]="typeClass">{{pefarequest.referralType.name}}</mat-chip>
          <mat-chip [class]="responseClass">{{pefarequest.responseTime}}</mat-chip>
        </mat-chip-list>
        <h6 style="font-weight: normal;"><strong>Created:</strong>&nbsp;<span style="font-size: 0.9em;">{{pefarequest.audit.createdDate| date:dateFormat}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="jhgFileNo" *ngIf="pefarequest.jhgFileNo"><strong>JHG File No:</strong>&nbsp;<span style="font-size: 0.9em;">{{pefarequest.jhgFileNo}}</span></span></h6>        

        <app-kpi [pefaRequest]="pefarequest" [big]="true"></app-kpi>

      </div>
      <div class="col-6">
        <app-office-pefa-actions [pefaRequest]="pefarequest" [small]="true" (changed)="refreshPefa()"></app-office-pefa-actions>
      </div>
    </div>
    <div class="row pefa-summary p-0 m-0">
      <app-office-pefa-summary [pefaRequest]="pefarequest" (changed)="refreshPefa()" style="width:100%;"></app-office-pefa-summary>
    </div>
  </div>
  <div class="pefa-detail container-fluid">
    <mat-tab-group dynamicHeight="true" *ngIf="pefarequest" [selectedIndex]="selectedTab" (selectedIndexChange)="updateTab($event)">
      <mat-tab class="detailsTab" label="Details">
        <ng-template matTabContent>
          <app-pefa-detail #details [pefaRequest]="pefarequest" (changed)="refreshPefa()"></app-pefa-detail>
        </ng-template>
      </mat-tab>
      <mat-tab class="jobTab" label="Job">
        <ng-template matTabContent>
          <app-pefajob-detail #jobDetails (updated)="refreshPefa()" (changed)="refreshPefa()"></app-pefajob-detail>
        </ng-template>
      </mat-tab>
      <mat-tab class="todoTab">
        <ng-template matTabLabel>
          <span matBadge="{{todoCount$ | async}}" matBadgeOverlap="false">To Do</span>
        </ng-template>
        <ng-template matTabContent>
          <app-pefa-todo-list #todo [request]="pefarequest" (changed)="refreshPefa()"></app-pefa-todo-list>
        </ng-template>
      </mat-tab>
      <mat-tab class="timelineTab" label="Timeline">
        <ng-template matTabContent>
          <app-note-view-list [pefaRequest]="pefarequest" (changed)="refreshPefa()"></app-note-view-list>
        </ng-template>
      </mat-tab>
      <mat-tab class="bookingsTab" label="Bookings">
        <ng-template matTabContent>
          <app-booking-list #bookings [pefaRequest]="pefarequest" (changed)="refreshPefa()"></app-booking-list>
        </ng-template>
      </mat-tab>
      <mat-tab class="medicalTab" label="Medical">
        <ng-template matTabContent>
          <app-medical-list #medical [pefaRequest]="pefarequest" (changed)="refreshPefa()"></app-medical-list>
        </ng-template>
      </mat-tab>
      <mat-tab class="kpisTab" label="KPIs">
        <ng-template matTabContent>
          <app-kpi-list #kpis [pefaRequest]="pefarequest" (changed)="refreshPefa()"></app-kpi-list>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
