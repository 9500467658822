<mat-toolbar>
  <mat-toolbar-row class="mat-action-row mat-body-2">
    <span>
      <input [(ngModel)]="filterText" type="text" #filter />
      <button (click)="loadRequestPage()" mat-button>Search</button>
    </span>
    <span>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(value)]="selectedStatus" #group>
        <mat-button-toggle style="padding:5px" value="new"><span matBadge="{{newCount}}" matBadgePosition="after" matBadgeOverlap="true">New</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="mc_required"><span matBadge="{{mcCount}}" matBadgePosition="after" matBadgeOverlap="true">Medical</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="active"><span matBadge="{{activeCount}}" matBadgePosition="after" matBadgeOverlap="true">Active</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="on_hold"><span matBadge="{{holdCount}}" matBadgePosition="after" matBadgeOverlap="true">On Hold</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="pending"><span matBadge="{{pendingCount}}" matBadgePosition="after" matBadgeOverlap="true">Pending</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value="complete"><span matBadge="{{completeCount}}" matBadgePosition="after" matBadgeOverlap="true">Completed</span></mat-button-toggle>
        <mat-button-toggle style="padding:5px" value=""><span matBadge="{{allCount}}" matBadgePosition="after" matBadgeOverlap="true">All</span></mat-button-toggle>
      </mat-button-toggle-group>
    </span>
    <span class="fill-remaining-space"></span>
    <span class="align-right">
      <button mat-stroked-button style="padding:5px" (click)="loadRequestPage()"><span>Refresh</span></button>
    </span>
    <span class="fill-remaining-space"></span>
    <span class="align-right">
      <button mat-stroked-button style="padding:5px" (click)="toggleAction()"><span>Show Actions</span></button>
    </span>
    <span class="fill-remaining-space"></span>
  </mat-toolbar-row>



</mat-toolbar>
<!--<p *ngIf=""><em>Loading...</em></p>-->
<mat-divider></mat-divider>
<div *ngIf="dataSource.loading$ | async" class="spinner-container">
  <mat-spinner></mat-spinner>
</div>

<table mat-table class="mat-elevation-z8" [dataSource]="dataSource" matSort multiTemplateDataRows>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="worker.lastName">Name</th>
    <td *matCellDef="let row"[ngClass]="{highlight : row.responseTime == 'Urgent'}"><span *ngIf="row.worker" class="bigText"><a [routerLink]="['/office-admin-pefa-detail', row.id]">{{ row.worker.firstName}} {{ row.worker.lastName}}</a>
        </span><app-kpi [pefaRequest]="row"></app-kpi></td>
  </ng-container>
  <ng-container matColumnDef="contact">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="worker.mobile">Mobile #</th>
    <td *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}"><span *ngIf="row.worker">{{ row.worker.phoneNumber}}</span></td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="pefaJob.job.name">Job Title</th>
    <td *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}"><span *ngIf="row.pefaJob else elseName">{{ row.pefaJob.name }}</span></td>
  </ng-container>
  <ng-container matColumnDef="site">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="pefaJob.site">Job Site</th>
    <td *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}"><span *ngIf="row.pefaJob else elseName">{{ row.pefaJob.site }}</span></td>
  </ng-container>
  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="company.name">Company</th>
    <td *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}"><span *ngIf="row.company else elseName"><a [routerLink]="['/office-company-detail', row.company.id]">{{ row.company.name }}</a></span>&nbsp;<span *ngIf="row.company != null && row.company.jodCompanyId" class="jod-icon"><img src="/assets/jod.png" height="17px" title="Linked to JOD Subscription"/></span>  <p *ngIf="row.pefaJob" class="row-site">{{ row.pefaJob.site }}</p></td>
  </ng-container>
  <ng-container matColumnDef="referrerDetails.name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="referrerDetails.name">Requested By</th>
    <td *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}"><span *ngIf="row.referrerDetails else elseName">{{ row.referrerDetails.name }}</span></td>
  </ng-container>
  <ng-container matColumnDef="appointmentdate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="appointmentDate" title="Appointment Due Date">Due Date</th>
    <td *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}">{{ row.appointmentDate| date:dateFormat }}</td>
  </ng-container>
  <ng-container matColumnDef="responseTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="responseTime" >Report Priority</th>
    <td mat-cell *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}">{{ row.responseTime}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="statusNote">Status</th>
    <td mat-cell *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}"><span style="text-transform: uppercase;">{{ row.status }}</span> - {{row.statusNote}}</td>
  </ng-container>
  <ng-container matColumnDef="referralType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="referralType.name" class="w-auto">Referral Type</th>
    <td mat-cell *matCellDef="let row" class="w-auto" [ngClass]="{highlight : row.responseTime == 'Urgent'}">{{ row.referralType.name }}</td>
  </ng-container>
  <ng-container matColumnDef="modifiedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="audit.lastModifiedDate" class="w-auto">Last Activity</th>
    <td mat-cell *matCellDef="let row" class="w-auto" [ngClass]="{highlight : row.responseTime == 'Urgent'}" style="margin-right: 8px;"><span *ngIf="row.audit">{{ row.audit.lastModifiedDate |   date:dateTimeFormat }}</span></td>
  </ng-container>
  <ng-container matColumnDef="paymentMethod">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-auto">Payment</th>
    <td mat-cell *matCellDef="let row" class="w-auto">{{ row.paymentMethod }}</td>
  </ng-container>
  <ng-container matColumnDef="otherInfo">
    <th mat-header-cell *matHeaderCellDef class="w-auto">Referrer Notes</th>
    <td mat-cell *matCellDef="let row" class="text-truncate" [ngClass]="{highlight : row.responseTime == 'Urgent'}" style="margin-right: 8px;"><span class="d-inline-block" style="max-width:100px">{{ row.otherInfo }}</span></td>
  </ng-container>
  <ng-container matColumnDef="kpi">
    <th mat-header-cell *matHeaderCellDef class="w-auto">KPI Status</th>
    <td mat-cell *matCellDef="let row" class="w-auto align-bottom"><app-kpi [pefaRequest]="row"></app-kpi></td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="actions" class="w-auto">Actions</th>
    <td mat-cell *matCellDef="let row" [ngClass]="{highlight : row.responseTime == 'Urgent'}" class="w-auto">
      <span *ngIf="row.toDoCount == 0 && !actionsView()" class="badge rounded-pill bg-warning text-dark" title="No ToDos">
        &nbsp;!&nbsp;
      </span>
      <span *ngIf="actionsView()">
        <app-office-pefa-actions [pefaRequest]="row" [small]="true" (changed)="loadRequestPage()"></app-office-pefa-actions>
      </span>
    </td>
  </ng-container>

<!--
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns2.length">
      <div class="element-detail"
           [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
        <div *ngIf="expandedElement" class="w-100">
          <table mat-table #innerTable [dataSource]="row.bookings">
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef class="w-auto">Type</th>
              <td *matCellDef="let element">{{element.bookingType.displayName}}</td>
            </ng-container>
            <ng-container matColumnDef="provider">
              <th mat-header-cell *matHeaderCellDef class="w-auto">Provider</th>
              <td *matCellDef="let element">{{element.provider.name}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="w-auto">Status</th>
              <td *matCellDef="let element"><span style="text-transform: uppercase;">{{element.status}}</span></td>
            </ng-container>
            <ng-container matColumnDef="appointmentDateTime">
              <th mat-header-cell *matHeaderCellDef class="w-auto">Appointment</th>
              <td *matCellDef="let element">{{element.appointmentDateTime | date:dateFormat }}</td>
            </ng-container>
            <ng-container matColumnDef="assessor">
              <th mat-header-cell *matHeaderCellDef class="w-auto">Assessor</th>
              <td *matCellDef="let element"><span *ngIf="element.assessor else elseName">{{element.assessor.firstName}} {{element.assessor.lastName}}</span></td>
            </ng-container>
            <ng-container matColumnDef="bookingActions">
              <th mat-header-cell *matHeaderCellDef class="w-auto">Booking Actions</th>
              <td *matCellDef="let element"><app-office-admin-booking-actions [booking]="element" [pefa]="row" (updated)="reloadListCheck($event)"></app-office-admin-booking-actions></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns3"
                class="element-row"></tr>

          </table>

        </div>
      </div>
    </td>
  </ng-container>
-->
<ng-container matColumnDef="expandedDetail">
  <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns2.length">
    <div class="element-detail"
         [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
      <div *ngIf="expandedElement" class="w-100"></div>
    </div>
  </td>
</ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky:true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns2"
      class="element-row"
      [class.expanded-row]="expandedElement === row"
      (click)="expandedElement = expandedElement === row ? null : row"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
</table>

<ng-template #elseName>
  <span>&nbsp;</span>
</ng-template>
<ng-template #showActions>Show Actions</ng-template>
<mat-paginator [length]="dataSource?.length$ | async" [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]" [showFirstLastButtons]="true"></mat-paginator>

