<h1 mat-dialog-title>Change PEFA Status</h1>
<div mat-dialog-content>

  <div class="container-fluid">

    <form class="material-form">

      <div class="row mt-2">
        <div class="col-2"></div>
        <div class="col-8">
          <mat-form-field>
            <mat-label>Select Status</mat-label>
            <mat-select [(value)]="selected">
              <!--(selectionChange)="enable_button()"-->
              <!--<mat-option value=0>None</mat-option>-->
              <!--<mat-option value=1 style="background: green; color: white;">NEW</mat-option>-->
              <mat-option value="NEW">NEW</mat-option>
   
            </mat-select>
          </mat-form-field>
          <!--<p style="color: red;">{{score_not_selected}}</p>-->
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row mt-2">
        <div class="col-2"></div>
        <div class="col-8">

          <mat-form-field class="text-area-full-width">
            <textarea matInput matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5  placeholder="Add Note" name="statusNote" [(ngModel)]="status_note"></textarea>
          </mat-form-field>

        </div>
        <div class="col-2"></div>
      </div>

    </form>

  </div>

</div>

  <div mat-dialog-actions>
    <div class="container-fluid">

      <div class="row">

        <div class="col-2">
          <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick()">Close</button>
        </div>
        <div class="col">

        </div>
        <div class="col-2">
          <button mat-raised-button color="warn" style="margin-top: 1rem;" mat-button (click)="changeStatus(selected)">Save</button>
        </div>

      </div>

    </div>

  </div>

