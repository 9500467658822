import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";

@Component({
  selector: 'app-jod-job-dialog',
  templateUrl: './jod-job-dialog.component.html',
  styleUrls: ['./jod-job-dialog.component.css']
})

export class JodJobSelectorDialog {

  private _jobs: any[] = [];
  private _data: any = null;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  displayedColumns = [
    "name",
    //"desc",
    "select",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //Modal
  constructor(public dialogRef: MatDialogRef<JodJobSelectorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public officeAdminService: OfficeAdminPEFAService) {
    this._data = data;
    this.dataSource = new MatTableDataSource<any>();    
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.officeAdminService.getJodJobs(this._data.companyId).subscribe(result => {
      this._jobs = result;
      //console.log(this._jobs);
      this.dataSource.data = this._jobs;
    });
  }

  public get jobs() {
    return this._jobs;
  }

  public selectJob(job: any) {
    this.dialogRef.close({ "outcome": true, "job": job });
  }

  public clearJob() {
    this.dialogRef.close({ "outcome": true, "job": null });
  }  

  onCancelClick(): void {
    this.dialogRef.close({"outcome":false});
  }

  //Material Table
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
