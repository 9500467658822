import { Component, OnInit, Inject, ViewChild, Input, AfterViewInit } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Report, PEFARequest } from "../shared/shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { JOBFIT_DATETIME_FORMAT } from "../shared/shared-component";
import { ReportService } from "./report.service";


@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html'
})

export class ReportListComponent implements OnInit {

  private _reports: Report[] = [];
  dataSource = new MatTableDataSource<Report>();
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;


  displayedColumns = [
    "name",
    "description",

  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(public reportService: ReportService) {
    this.dataSource = new MatTableDataSource<Report>();
    this.reportService = reportService;

  }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.reportService.getReports().subscribe(results => {
      this.dataSource.data = results;
      console.log(results);
    })


    
  }


  //Material Table
  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  public get reports() {
    return this._reports;
  }
}
