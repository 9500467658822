import { Component, AfterViewInit, Input, OnInit } from "@angular/core";
import { Report, JOBFIT_DATE_FORMAT, ReportCriteria } from "../shared/shared-component";
import { FormBuilder, FormGroup, FormControl, RequiredValidator, Validators } from "@angular/forms";
import { ReportService } from "./report.service";

@Component({
  selector: 'app-criteria-textArea',
  templateUrl: './textArea-criteria.component.html',
})

export class TextAreaCriteriaComponent implements OnInit {

  @Input() criteria: ReportCriteria;
  public loading: boolean = true;

  @Input() criteriaForm: FormGroup

  public name:string;

  constructor(
    private fb: FormBuilder,
    public reportService: ReportService,
  ) {

  }

  public ngOnInit() {
    

    if (!this.criteria.parameterName) {
      this.name = this.criteria.displayName;
    } else {
      this.name = this.criteria.parameterName;
    }
    if (this.criteria.required) {
      this.criteriaForm.addControl(this.name, new FormControl('', Validators.required));
    } else {
      this.criteriaForm.addControl(this.name, new FormControl(''));
    }
  }

}

