import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { PrimeIcons } from "primeng/api";
import { map } from "rxjs/operators";
import { MessageService } from "../../message/message.service";
import { PEFARequestService } from "../../pefarequest/pefarequest.service";
import { ConfirmDialog } from "../confirm-dialog.component";
import { NoteDialog } from "../note-dialog.component";
import { JOBFIT_DATETIME_FORMAT, NoteView, PEFARequest } from "../shared-component";
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-note-view-note',
  templateUrl: './note-view-note.component.html',
  styleUrls: ['./note-view-list.css']
})

export class NoteViewNoteComponent {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  @Input()
  noteView: NoteView;

  @Input()
  requestID: string;

  @Output() changed = new EventEmitter<boolean>();

  constructor(public pefaService: PEFARequestService, public dialog: MatDialog, public messageService:MessageService) { }

  public deleteNote() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: { message: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        var values = {
          id: this.noteView.id,
          status: "ARCHIVE",
          content: this.noteView.details.content,
          reason: result.message,
          important: this.noteView.details.important,
          adminOnly: this.noteView.details.adminOnly,
        }
        this.pefaService.savePefaNote(this.requestID, values).subscribe(result => {
          this.messageService.add("Note Archived");
          this.changed.emit(true);
        });
      }
    });
  }

  public editNote() {
    const dialogRef = this.dialog.open(NoteDialog, {
      data: { message: true, noteId: this.noteView.id, pefaId: this.requestID }
    });

    dialogRef.afterClosed().subscribe(null, null, () => {
      this.messageService.add("Note Updated");
      this.changed.emit(true);
    }
    );
  }      

}
