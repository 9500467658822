import { Component, OnInit, Inject, ViewChild, Input, EventEmitter, Output } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PEFARequest, JOBFIT_DATE_FORMAT, KPI } from "../shared/shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";


@Component({
  selector: 'app-kpi-list',
  templateUrl: './kpi-list.component.html'
})

export class KPIListComponent {

  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  @Input()
  public pefaRequest: PEFARequest;

  public dataSource: MatTableDataSource<KPI> = new MatTableDataSource<KPI>();

  private _kpis: KPI[] = [];

  @Output() changed = new EventEmitter<boolean>();


  displayedColumns = [    
    'name',
    'delayReason',
    'notes',
    'actions'
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //Modal

  constructor(public pefaService: PEFARequestService) {

  }


  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pefaService.getPEFAKPIs(this.pefaRequest.id).subscribe(response => {
      this.dataSource.data = response;
      this._kpis = response;
    }
    );
  }

  public get kpis() {
    return this._kpis;
  }

  public delete(id: string) {
    this.pefaService.deletePEFAKPI(id, this.pefaRequest.id).subscribe(result => {
      this.dataSource.data.splice(this.dataSource.data.findIndex(r => r.id === id));
      this._kpis.splice(this.dataSource.data.findIndex(r => r.id === id));
      this.changed.emit();
    });
  }
}
