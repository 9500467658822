<div *ngIf="todo.status !== 'COMPLETE' else completed">
  <button *ngIf="todo.type  == 'Booking'" mat-raised-button matTooltip="Update Current Booking" (click)="openUpdateBookingDialog()"><mat-icon class="mat-icon-small" aria-hidden="false" aria-label="Update Booking">event_available</mat-icon></button>
  <button *ngIf="todo.type == 'Pending' && todo.object == 'Booking'" mat-raised-button matTooltip="Upload Results" (click)="openResultsDialog()"><mat-icon class="mat-icon-small" aria-hidden="false" aria-label="Upload Results">assignment</mat-icon></button>
  <button *ngIf="todo.type == 'Pending' && todo.object == 'PEFARequest' && todo.description != 'Report Ready'" mat-raised-button matTooltip="Upload Report" (click)="openReportDialog()"><mat-icon class="mat-icon-small" aria-hidden="false" aria-label="Upload Report">note_add</mat-icon></button>
  <a *ngIf="todo.type == 'Pending' && todo.description == 'Report Ready'" mat-raised-button matTooltip="Download Report" (click)="downloadReport()" target="_blank"><mat-icon class="mat-icon-small" aria-hidden="false" aria-label="Download">system_update</mat-icon></a>
  <button *ngIf="todo.type == 'Payment' && todo.object == 'Booking'" matTooltip="Generate Invoice" mat-raised-button href="" (click)="openBookingInvoiceDialog()"><mat-icon aria-hidden="false" aria-label="Generate Invoice">attach_money</mat-icon></button>
  <button *ngIf="todo.type == 'Payment' && todo.object == 'PEFARequest'" matTooltip="Generate Invoice" mat-raised-button href="" (click)="openInvoiceDialog()"><mat-icon aria-hidden="false" aria-label="Generate Invoice">attach_money</mat-icon></button>
  <button *ngIf="todo.id != '0'" matTooltip="Mark Complete" mat-raised-button href="" (click)="markComplete()"><mat-icon aria-hidden="false" aria-label="Mark Complete">check</mat-icon></button>
  <button matTooltip="assign" mat-raised-button href="" (click)="openAssignPerson()"><mat-icon aria-hidden="false" aria-label="Assign">Assign</mat-icon></button>
</div>
<ng-template #completed>
  COMPLETED
</ng-template>
