import { Component, Inject, OnInit } from '@angular/core';
import { AnonPEFARequestService } from './anon-pefarequest.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferralType } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-anon-pefarequest-entry',
  templateUrl: './anon-pefarequest-entry.component.html'
})

export class AnonPEFARequestEntryComponent implements OnInit {
  pefaRequestService: AnonPEFARequestService;
  sharedService: SharedService;

  referralTypeOptions: ReferralType[];


  pefaRequestForm = new FormGroup({
    Worker: new FormGroup({
      firstName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(new Date()),
      lastName: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      home: new FormControl(''),
      gender: new FormControl(''),
      work: new FormControl(''),
      site: new FormControl(''),
      emailAddress: new FormControl('', Validators.required)
    }),
    RequestedTime: new FormControl(new Date()),
    AppointmentDate: new FormControl(new Date()),
    Recruiter: new FormControl(''),
    ResponseTime: new FormControl(''),
    PefaJob: new FormGroup({
      Site: new FormControl(''),
      Job: new FormGroup({
        Name: new FormControl(''),
        id: new FormControl('0'),
      })
    }),
    PaymentMethod: new FormControl('', Validators.required),
    //Status: new FormControl(''),
    ReferralType: new FormGroup(
      {
        id: new FormControl('', Validators.required),
      }
    ),
    otherInfo: new FormControl(''),
    ReferrerDetails: new FormGroup({
      Name: new FormControl('', Validators.required),
      Site: new FormControl(''),
      Phone: new FormControl('', Validators.required),
      Position: new FormControl('', Validators.required),
      Email: new FormControl('', Validators.required),
    }),

    Company: new FormGroup({
      name: new FormControl('', Validators.required)
      })
  });

  constructor(
    pefaRequestService: AnonPEFARequestService,
    sharedService: SharedService,
    private fb: FormBuilder,
  private router: Router) {
    this.pefaRequestService = pefaRequestService;
    this.sharedService = sharedService;
  }

  ngOnInit() {
    this.sharedService.getReferralTypes().subscribe(result =>
      this.referralTypeOptions = result);
  }

  onSubmit() {

    this.pefaRequestService.createNewPEFARequest(this.pefaRequestForm.value).subscribe();    
    this.router.navigateByUrl("/anonthanks");
  }

}
