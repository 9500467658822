import { Component, Inject } from '@angular/core';

@Component({
  templateUrl: './thankyou.html'
})
export class OfficeThankyouComponent {


  constructor() {
  }
}
