<div *ngIf="loading == true">
  <div class="row">
    <div class="col-2 col-sm-4 col-md-4"></div>
    <div class="col-10 col-sm-8 col-md-8">
      <div class="progress-loader" style="overflow:visible; margin:0 auto;">
        <mat-progress-spinner [mode]="'indeterminate'" [diameter]="40">
        </mat-progress-spinner>
      </div>
    </div>
  </div>
</div>

<ng-template #NoCompanyToList>
  <p *ngIf="loading == false">There are no Doctors</p>
</ng-template>

<div *ngIf="doctors.length > 0; else NoCompanyToList">

  <mat-toolbar class="mat-primary">
    Doctors
  </mat-toolbar>

  <div style="margin: 10px 0 10px 0;">
    <a [routerLink]="['/doctor']"><button mat-raised-button class="mat-accent" type="button">Add New Doctor</button></a>
  </div>  

  <!--<p *ngIf=""><em>Loading...</em></p>-->

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
  </mat-form-field>

  <div class="mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"><span><a [routerLink]="['/doctor', row.id]">{{ row.name}}</a></span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.address }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.email }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="fax">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Fax</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.faxNumber }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.phoneNumber }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="preferredContactType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Preferred Contact Type</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.preferredContactType }}</span></mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns2; sticky:true;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>

    </mat-table>
  </div>

  <ng-template #elseName>
    <span>&nbsp;</span>
  </ng-template>

  <!---->
</div>
<mat-paginator></mat-paginator>



