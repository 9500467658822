import { Component, Inject, Input, OnInit, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Provider, Assessor, JOBFIT_DATE_FORMAT, ProviderAudit, ConfigurationItem, Booking, PersonRelationship, Company } from '../../shared/shared-component';
import { SharedService } from '../../shared/shared.service';
import { ProviderBookingListComponent } from '../provider/provider-booking-list.component';
import { OfficeAdminPEFAService } from '../../office-admin-pefa/office-admin-pefa.service';
import { MessageService } from '../../message/message.service';
import moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ConfigService } from '../../config/config.service';
import { RelationshipDialogComponent } from '../../shared/relationship-dialog.component';
import { CompanyDetailComponent } from '../company/company-detail.component';
import { RelationshipDetailComponent } from '../../shared/relationship-detail.component';
import { validateVerticalPosition } from '@angular/cdk/overlay';
import { PersonSelectorDialog } from '../../shared/person-dialog.component';
import { config } from 'rxjs';
import { ConfirmDialog } from '../../shared/confirm-dialog.component';
import { AddNewPersonEnhanced } from '../../shared/person/person-dialog-enhanced.component';


@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html',
})
export class ProviderDetailComponent implements AfterViewInit, OnInit {
  public provider: Provider;
  public company: Company;
  public loading: boolean = true;
  public assessors: Assessor[];
  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  public companyDetail: CompanyDetailComponent;
  public policyExpiry: ProviderAudit;
  private env: ConfigurationItem;
  private updateDetails: ConfigurationItem;
  public jotFormUrl: string;

  dataSource = new MatTableDataSource<ProviderAudit>();
  peopleDataSource = new MatTableDataSource<PersonRelationship>();
  providerBookingsDate = new MatTableDataSource<Booking>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(RelationshipDetailComponent) private relationshipForm: RelationshipDetailComponent;
  @Output() changed = new EventEmitter<boolean>();


  providerForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.required),
    providerTier: new FormControl('', Validators.required),
    status: new FormControl('PENDING', Validators.required),
    statusNote: new FormControl(''),
    ladderRatingKG: new FormControl('', Validators.required),
    companyNumber: new FormControl('', Validators.required),
    accountEmail: new FormControl(''),
    faxNumber: new FormControl(''),
    contactEmail: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    notes: new FormControl(''),
    consentLocation: new FormControl('ASSESSOR'),
    locations: new FormArray([
    ]),
    people: new FormArray([
    ]),

    providerBooking: new FormArray([
    ]),

  });

  displayedColumns = [
    "auditDate",
    "indeminityInsurancePolicyNo",
    "indeminityInsurancePolicyAmount",
    "indeminityInsurancePolicyExpiry",
    "liabilityInsurancePolicyNo",
    "liabilityInsurancePolicyAmount",
    "liabilityInsurancePolicyExpiry",
    "workerCompPolicyExpiry",
    "workerCompPolicyNo",
    "workerCompPolicyAmount",
    "attachment",

  ];

  providerAppointments = [
    "assessor",
    "appointmentData",
    "status",
    "assessor",
    "job",
    "referralType",
  ];

  peopleDisplayedColumns = [
    "name",
    "type",
    "status",
    "startDate",
    "endDate",
    "email",
    "delete",
  ];

  ngOnInit():void {
    this.configService.getConfigurationItem("jotform.providerAudit").subscribe(r => {
      this.updateDetails = r[0];
    });
    
    this.configService.getConfigurationItem("jotform.env").subscribe(r => {
      if (r.length > 0) {
        this.env = r[0];
      }
    });
      }



  ngAfterViewInit(): void {
    if (this.route.snapshot.paramMap.get('id')) {
      this.officeAdminService.getProvider(this.route.snapshot.paramMap.get('id')).subscribe(results => {
        this.officeAdminService.getAllAssessors().subscribe(results => {
          this.assessors = results;
        })
        this.provider = results;
        this.dataSource.data = this.provider.providerAudits;
        this.peopleDataSource.data = this.provider.people;
        for (let entry of this.provider.people) {
         // this.addSavedPerson();
        }
        for (let entry of this.provider.locations) {
         
          this.addSavedLocation();
        };

        this.providerForm.patchValue(results);
        this.buildJotFormUrl();

        this.loading = false;
      }
      );
    } else {
      this.officeAdminService.getAllAssessors().subscribe(results => {
        this.assessors = results;
        this.loading = false;
      })
    }
  }

  sharedService: SharedService;

  messageService: MessageService;

  constructor(
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public officeAdminService: OfficeAdminPEFAService,
    sharedService: SharedService,
    public configService: ConfigService

  ) {
    this.messageService = messageService;
    this.sharedService = sharedService;
  }


  saveChanges() {

    if (this.route.snapshot.paramMap.get('id')) {
      this.officeAdminService.saveProvider(this.provider.id, this.providerForm.value).subscribe(update => {
        this.officeAdminService.getProvider(this.route.snapshot.paramMap.get('id')).subscribe(results => {
          this.provider = results;
          this.dataSource.data = this.provider.providerAudits;

          for (let entry of this.provider.people) {
          }

          this.providerForm.patchValue(results);
          this.buildJotFormUrl();

          this.messageService.add("Provider Updated");
          this.loading = false;

        });

      }
      );
    } else {
      var providerFormValue = this.providerForm.value;
      delete providerFormValue.id;
      this.officeAdminService.addProvider(providerFormValue).subscribe(update => {
        this.messageService.add("Provider Added");
        this.router.navigate([update.id], { relativeTo: this.route });
      })      
    }

  }

  get locations() {
    return this.providerForm.get('locations') as FormArray;
  }

  get people() {
    return this.providerForm.get('people') as FormArray;
  }

  get providerBookings() {
    return this.providerForm.get('providerBookings') as FormArray;
  }

  addLocation() {
    this.locations.push(
      new FormGroup({
        address: new FormControl('', Validators.required),
        region: new FormControl('', Validators.required),
        emailAddress: new FormControl('', Validators.required),
        phoneNumber: new FormControl('', Validators.required),
        type: new FormControl('PHYSICAL', Validators.required),
        headOffice: new FormControl(false, Validators.required),
      })
    );
  }

  public addSavedLocation() {
    this.locations.push(
      new FormGroup({
          address: new FormControl('', Validators.required),
          region: new FormControl('', Validators.required),
          emailAddress: new FormControl('', Validators.required),
          phoneNumber: new FormControl('', Validators.required),
          type: new FormControl('', Validators.required),
          id: new FormControl('', Validators.required),
          headOffice: new FormControl(false, Validators.required),
      })
    );
  }

  public deletePerson(index: string) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        this.officeAdminService.removePerson(this.provider.id, index).subscribe(result => {
          this.provider.people.pop();
          this.peopleDataSource.data = this.provider.people;
          this.messageService.add("Person Removed");          
        });
        }
    });
  }

  public deleteLocation(index: number): void {
    this.locations.removeAt(index);
  }

  private getJotFormAnswer(answers, key): string {
    for (const prop in answers) {
      if (answers[prop].name == key) {
        return answers[prop].answer ?? '';
      }      
    }
    return '';
  }

  public openFormBasedOnSubmission(submissionId) {
    if (submissionId == null) {
      alert('Sorry, there is no JotForm SubmissionId associated with this record. Unable to copy.');
      return;
    }
    
    this.officeAdminService.getJotFormSubmission(submissionId).subscribe(results => {
      var url = this.updateDetails.value + "?providerId=" + this.provider.id;

      // pre-fill
      let f = results['content'];
      if (f.answers) {
        url += this.jotFormAnswersToUrlParams(f.answers);
      }

      if (this.env) {
        url += "&environment=" + this.env.value;
      } else {
        url += "&environment=PROD";
      }      
      // console.log(encodeURI(url));
      window.open(encodeURI(url), '_blank').focus();
    });    
  }

  private jotFormAnswersToUrlParams(answers): string {
    let url = '';
    if (answers) {
      url += '&businessName=' + this.getJotFormAnswer(answers, 'businessName');
      url += '&abn=' + this.getJotFormAnswer(answers, 'abn');
      url += '&tradingName=' + this.getJotFormAnswer(answers, 'tradingName');
      url += '&practiceName=' + this.getJotFormAnswer(answers, 'practiceName');
      url += '&businessHours=' + this.getJotFormAnswer(answers, 'businessHours');
      url += '&afterHours=' + this.getJotFormAnswer(answers, 'afterHours');
      url += '&emailFor62=' + this.getJotFormAnswer(answers, 'emailFor62');
      url += '&email=' + this.getJotFormAnswer(answers, 'email');
      url += '&emailFor=' + this.getJotFormAnswer(answers, 'emailFor');
      url += '&emailFor64=' + this.getJotFormAnswer(answers, 'emailFor64');
      url += '&wellVentilated=' + this.getJotFormAnswer(answers, 'wellVentilated');
      url += '&temperatureControlled=' + this.getJotFormAnswer(answers, 'temperatureControlled');
      url += '&freeOf=' + this.getJotFormAnswer(answers, 'freeOf');
      url += '&fromThe=' + this.getJotFormAnswer(answers, 'fromThe');
      url += '&fromOther21=' + this.getJotFormAnswer(answers, 'fromOther21');
      url += '&howDo=' + this.getJotFormAnswer(answers, 'howDo');
      url += '&whatAre=' + this.getJotFormAnswer(answers, 'whatAre');
      url += '&ifNot=' + this.getJotFormAnswer(answers, 'ifNot');
      url += '&doYou27=' + this.getJotFormAnswer(answers, 'doYou27');
      url += '&doYou=' + this.getJotFormAnswer(answers, 'doYou');
      url += '&isYour29=' + this.getJotFormAnswer(answers, 'isYour29');
      url += '&isYour30=' + this.getJotFormAnswer(answers, 'isYour30');
      url += '&isYour31=' + this.getJotFormAnswer(answers, 'isYour31');
      url += '&isYour32=' + this.getJotFormAnswer(answers, 'isYour32');
      url += '&ladderRating=' + this.getJotFormAnswer(answers, 'ladderRating');
      url += '&areYour=' + this.getJotFormAnswer(answers, 'areYour');
      url += '&doYou34=' + this.getJotFormAnswer(answers, 'doYou34');
      url += '&isYour35=' + this.getJotFormAnswer(answers, 'isYour35');
      url += '&ifNo=' + this.getJotFormAnswer(answers, 'ifNo');
      url += '&isYour39=' + this.getJotFormAnswer(answers, 'isYour39');
      url += '&doesYour=' + this.getJotFormAnswer(answers, 'doesYour');
      url += '&doesYour41=' + this.getJotFormAnswer(answers, 'doesYour41');
      url += '&doesYour42=' + this.getJotFormAnswer(answers, 'doesYour42');
      url += '&whatDo=' + this.getJotFormAnswer(answers, 'whatDo');
      url += '&isIt=' + this.getJotFormAnswer(answers, 'isIt');
      url += '&doesYour45=' + this.getJotFormAnswer(answers, 'doesYour45');
      url += '&canYou=' + this.getJotFormAnswer(answers, 'canYou');
      url += '&areThe=' + this.getJotFormAnswer(answers, 'areThe');
      url += '&duringThe49=' + this.getJotFormAnswer(answers, 'duringThe49');
      url += '&inThe50=' + this.getJotFormAnswer(answers, 'inThe50');
      url += '&doYou51=' + this.getJotFormAnswer(answers, 'doYou51');
      url += '&nameAnd=' + this.getJotFormAnswer(answers, 'nameAnd');
    }
    return url;
  }

  public buildJotFormUrl() {
    if (this.updateDetails) {
      this.officeAdminService.getProviderAuditJotFormPreFill(this.route.snapshot.paramMap.get('id')).subscribe(results => {
        if (results) {
          var url = this.updateDetails.value + "?providerId=" + this.provider.id;
          // pre-fill
          let f = results['content'];
          if (f.answers) {
            url += this.jotFormAnswersToUrlParams(f.answers);
          }
    
          if (this.env) {
            url += "&environment=" + this.env.value;
          } else {
            url += "&environment=PROD";
          }
          this.jotFormUrl = encodeURI(url);  
        } else {
          // new audit
          var url = this.updateDetails.value + "?providerId=" + this.provider.id;
          if (this.env) {
            url += "&environment=" + this.env.value;
          } else {
            url += "&environment=PROD";
          }
          this.jotFormUrl = encodeURI(url);
        }
      });
    }
  }

  editPerson(index: string) {
    const dialogRef = this.dialog.open(AddNewPersonEnhanced, {
      width: '600px',  
      data: {
        personRelationshipId: index,
        companyId: this.provider.id,
      }
    });
    // reload people list
    dialogRef.afterClosed().subscribe(result => {
      this.officeAdminService.getProvider(this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '0').subscribe(results => {
          this.provider = results;        
          this.peopleDataSource.data = this.provider.people;
          this.providerForm.patchValue(results);
        }
      );  
    });    
  }

  openAddPerson() {
    const dialogRef = this.dialog.open(AddNewPersonEnhanced, {
      width: '600px',      
      data: {
        companyId: this.provider.id,
      }
    });
    // reload people list
    dialogRef.afterClosed().subscribe(result => {
      this.officeAdminService.getProvider(this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '0').subscribe(results => {
          this.provider = results;        
          this.peopleDataSource.data = this.provider.people;
          this.providerForm.patchValue(results);
        }
      );  
    });
  }

  openRelationshipDialog() {
    const dialogRef = this.dialog.open(RelationshipDialogComponent, {
      data: {
        companyId: this.provider.id,
        //peopleType: 'ASSESSOR'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.relation) {
        this.provider.people.push(result.relation);
        this.peopleDataSource.data = this.provider.people;
      }
    });
  }
}

