<ng-template #NoJobToList>
    <p *ngIf="loading == false">There are no jobs</p>
  </ng-template>
  
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  
  
  <div class="container-fluid" *ngIf="jobs.length > 0; else NoJobToList">
    <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"><img src="/assets/jod.png" style="height: 24px;"/>&nbsp;<span>{{ row.Name }}</span></mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="desc">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Desc</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let row"><button mat-raised-button color="primary" (click)="selectJob(row)">Select</button></mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  
  </div>
  <mat-paginator [pageSizeOptions]="[10, 25]"></mat-paginator>
  <button mat-raised-button color="primary" (click)="clearJob()">Clear Selection</button>
  