<div *ngIf="loading == true">
  <div class="row">
    <div class="col-2 col-sm-4 col-md-4"></div>
    <div class="col-10 col-sm-8 col-md-8">
      <div class="progress-loader" style="overflow:visible; margin:0 auto;">
        <mat-progress-spinner [mode]="'indeterminate'" [diameter]="40">
        </mat-progress-spinner>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
   <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="assessor">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"><a [routerLink]="['/office-admin-pefa-detail', row.pefArequestID]"> <span> {{ row.assessor.firstName }} {{ row.assessor.lastName }}</span></a></mat-cell>
      </ng-container>
      <ng-container matColumnDef="appointmentDateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Appointment Date</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.appointmentDateTime | date : dateFormat}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="providerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Assessor</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.provider.name }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="bookingType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Booking Type</mat-header-cell>
        <mat-cell *matCellDef="let row"><span *ngIf="row.bookingType"> {{ row.bookingType.displayName }}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let row"><span>{{ row.status }}</span></mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="appointmentColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: appointmentColumns"></mat-row>
    </mat-table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50]" class="container-fluid"></mat-paginator>
</div>
