
<mat-label>
    Assign To
  </mat-label>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <input id="assignedTo" [(ngModel)]="person" matInput placeholder="Type or Select a Person" [matAutocomplete]="autoPerson" (keyup)="onPersonKey($event)" required>
        <mat-autocomplete #autoPerson="matAutocomplete" [displayWith]="displayPersonName" (optionSelected)="setPersonData($event)">
          <mat-option *ngFor="let person of filteredPeople" [value]="person">
            {{person.firstName}} {{person.lastName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <mat-label>
    Type
  </mat-label>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <mat-select id="Type" [(ngModel)]="todo.type"  >
          <mat-option value="Reminder">Reminder</mat-option>
          <mat-option value="QA">QA</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-label>
    Priority
  </mat-label>
  <div class="form-row">
    <mat-form-field>
      <mat-select id="Priority" [(ngModel)]="todo.urgent">
        <mat-option value="true">Urgent</mat-option>
        <mat-option value="false">Standard</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-label>
    Due Date/Time
  </mat-label>
  <div class="row mt-4">
    <div>
      <mat-form-field>
        <input matInput id="dueDate" [(ngModel)]="todo.dueDate" [ngxMatDatetimePicker]="picker" type="text" placeholder="appointmentDateTime" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [stepMinute]="15" enableMeridian="true">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </div>


  <mat-label>
    Description
  </mat-label>
  <mat-form-field id="description">
    <textarea matInput
              [(ngModel)]="todo.description"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              #content
              cdkAutosizeMinRows="10"
              class="form-control"
              cols="100"
              required></textarea>
  </mat-form-field>


  <mat-dialog-actions>
    <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Close</button>
    <button mat-raised-button color="warn" style="margin-top: 1rem;" mat-button (click)="saveTodo(true)">Save</button>
  </mat-dialog-actions>


 
