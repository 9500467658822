  <div class="row" *ngFor="let time of times">
  <div class="col">
      <mat-label>{{label}}</mat-label>
    </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Start Time</mat-label>
          <input type="text" [(ngModel)]="time.startTime" matInput />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>End Time</mat-label>
          <input type="time" [(ngModel)]="time.endTime"  matInput />
        </mat-form-field>
      </div>
  </div>
