<form [formGroup]="uploadForm">
  <div class="container-fluid">

    <div class="row">
      <div class="col text-center">
        <h1 mat-dialog-title>Upload New Report</h1>
        <h6>{{request?.worker.firstName}} {{request?.worker.lastName}}</h6>
      </div>
    </div>

    <div mat-dialog-content>
      <div class="row">
        <div class="col text-center">
          <mat-form-field>
            <mat-label>Select PEFA Score</mat-label>
            <mat-select #score formControlName="score" (selectionChange)="enable_button()">
              <!--<mat-option value=0>None</mat-option>-->
              <mat-option value=1>PEFA 1</mat-option>
              <mat-option value=2>PEFA 2</mat-option>
              <mat-option value=3>PEFA 3</mat-option>
              <mat-option style="background: red; color: white;" value=4>PEFA 4</mat-option>
            </mat-select>
            <mat-error></mat-error>
          </mat-form-field>
        </div>
      </div>


      <div class="row">
        <div class="col text-center">

          <mat-form-field>
            <mat-label>Who will write the report</mat-label>
            <mat-select #writer [(value)]="selectedWriterId">
              <mat-option *ngFor="let writer of reportWriters" [value]="writer.id">{{writer.firstName}} {{writer.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <mat-form-field>
            <mat-label>Date of the Report</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="dateOfReport" name="picker" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <mat-checkbox #sme [(ngModel)]="smeQA" [ngModelOptions]="{standalone: true}">
            SME QA Required?
          </mat-checkbox>
          <mat-error></mat-error>
        </div>
      </div>

      <div *ngIf="sme.checked" class="row">
        <div class="col text-center">
          <mat-form-field>
            <mat-label>SME QA Reason</mat-label>
            <mat-select formControlName="reason">
              <!--<mat-option value=0>None</mat-option>-->
              <mat-option value="Volunteering">Volunteering</mat-option>
              <mat-option value="PI referral">PI referral</mat-option>
              <mat-option value="Complex Referral">Complex Referral</mat-option>
              <mat-option value="Incomplete PT impacting score">Incomplete PT impacting score</mat-option>
              <mat-option value="Reported Pain">Reported Pain</mat-option>
              <mat-option value="Heart Rate impacts the PT rating and score">Heart Rate impacts the PT rating and score</mat-option>
              <mat-option value="Incomplete Fitness and impacts score">Incomplete Fitness and impacts score</mat-option>
            </mat-select>
            <mat-error></mat-error>
          </mat-form-field>
          <mat-error></mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <mat-checkbox [(ngModel)]="adminQA" [ngModelOptions]="{standalone: true}">
            Admin QA Completed?
          </mat-checkbox>
          <mat-error></mat-error>
        </div>
      </div>

      <div class="row">

        <div class="col text-center" style="margin-top: 1rem;">
          <input hidden #fileInput type="file" id="file">
          <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
          &nbsp;
          <mat-label *ngIf="fileInput.files[0]">{{fileInput.files[0].name}}</mat-label>
        </div>
      </div>

    </div>
  </div>
  <div mat-dialog-actions style="display: flex; justify-content: space-around">
    <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Close</button>
    <button mat-raised-button color="warn" style="margin-top: 1rem;" [disabled]="!(fileInput.files.length>0 && score.value != null)" mat-button (click)="uploadFile(fileInput.files)">Upload</button>
  </div>

</form>
