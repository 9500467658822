import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { Practice, Doctor } from '../shared/shared-component';


@Injectable({
  providedIn: 'root'
})
export class PracticeService {
  private client: HttpClient;
  private baseURL: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }


  getPractices(): Observable<Practice[]> {
    return this.client.get<Practice[]>(this.baseURL + 'api/Practices')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };


  getPractice(id:string): Observable<Practice> {
    return this.client.get<Practice>(this.baseURL + 'api/Practices/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  getDoctors(id: string): Observable<Doctor[]> {
    return this.client.get<Doctor[]>(this.baseURL + 'api/Practices/' + id + '/Doctors')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };


  public savePractice(id: string, practice: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/Practices/' + id, practice).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public addPractice(practice: any): Observable<Practice> {
    return this.client.post<Practice>(this.baseURL + 'api/Practices/', practice).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  private handleError(error) {
    let errorMessage = '';
    //let errors: any = JSON.stringify(error.json());
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
    }
    this.messageService.add(errorMessage)
    return throwError(errorMessage);
  };
}
