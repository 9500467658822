<div class="mat-elevation-z8 col-md-12">
  <form [formGroup]="PracticeForm" (ngSubmit)="saveChanges()">
    <h2>Practice Details</h2>

    <div class="row">
      <div>
        <input type="hidden" formControlName="id" />

        <div class="form-group">
          <mat-form-field>
            <mat-label for="name">Name</mat-label>
            <input matInput type="text" formControlName="name" required />
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label for="address">Address</mat-label>
            <input matInput type="text" formControlName="address">
          </mat-form-field>

        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label for="phoneNumber">Phone Number</mat-label>
            <input matInput type="text" formControlName="phoneNumber">
          </mat-form-field>

        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label for="tier">Fax Number</mat-label>
            <input matInput type="text" formControlName="faxNumber">
          </mat-form-field>

        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label for="tier">Email</mat-label>
            <input matInput type="text" formControlName="email">
          </mat-form-field>

        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label for="tier">Preferred Contact Method</mat-label>
            <mat-select matInput formControlName="preferredContactType" required>
              <mat-option value="PHONE">Phone</mat-option>
              <mat-option value="FAX">Fax</mat-option>
              <mat-option value="EMAIL">Email</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </div>
    </div>
    <button mat-raised-button class="mat-primary" type="submit">Save Changes</button>
  </form>
</div>
