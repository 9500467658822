import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Practice, Assessor, DemandType } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { MessageService } from '../message/message.service';
import { PracticeService } from './practice.service';

@Component({
  selector: 'app-practice-detail',
  templateUrl: './practice-detail.component.html',
})
export class PracticeDetailComponent implements AfterViewInit {
  public practice: Practice;
  PracticeForm = new FormGroup({
    id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    phoneNumber: new FormControl(''),
    faxNumber: new FormControl(''),
    email: new FormControl(''),
    preferredContactType: new FormControl('PHONE'),
  });

  ngAfterViewInit(): void {

    const practiceId = this.route.snapshot.paramMap.get('id');

    if (practiceId) {
      this.practiceService.getPractice(practiceId).subscribe(results => {
        this.practice = results;
        this.PracticeForm.patchValue(this.practice);
      });
    }
    

  }

  messageService: MessageService;

  constructor(
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public practiceService: PracticeService,
  ) {
    this.messageService = messageService;
  }

  saveChanges() {

    if (this.practice) {


      this.practiceService.savePractice(this.practice.id, this.PracticeForm.value).subscribe(update => {
        this.practiceService.getPractice(this.practice.id).subscribe(results => {
          this.practice = results;
          this.PracticeForm.patchValue(results);
          this.messageService.add("Practice Updated");
        });

      }
      );
    } else {
      const value = this.PracticeForm.value;
      delete value.id;
      this.practiceService.addPractice(value).subscribe(update => {
        this.practiceService.getPractice(update.id).subscribe(results => {
          this.practice = results;
          this.PracticeForm.patchValue(results);
          this.messageService.add("Practice Added");
        });

      });
    }

  }

}

