import { Component, OnInit, Inject, ViewChild, Provider, Output, EventEmitter, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Company, PEFARequest, Person, ToDo } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MatDialog } from "@angular/material/dialog";
import { ToDoService } from "./todo.service";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { JOBFIT_DATE_FORMAT, JOBFIT_TIME_FORMAT, Timezone  } from "../shared/shared-component";
import { CompanyUserRole, UserAdminService } from "../company-admin/company-admin-service";
import { CreateTodo } from "./todo-dialog.component";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { SharedService } from "../shared/shared.service";
import moment from "moment";

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
})

export class ToDoListComponent implements OnInit {
  @Input() todo: ToDo;

  @Output() updated = new EventEmitter<boolean>();
  public todoService: ToDoService;
  public todos: ToDo[] = [];
  pefarequest: PEFARequest;
  company: Company;
  role: CompanyUserRole[];
  person: Person;
  people: Person[] = [];
  filteredPeople: Person[] = [];
  dateColTitle: String = 'Appt. Date';
  loading: boolean = true;

  //Material Table
  displayedColumns2 = [
    "urgent",
    "description",
    "type",
    "worker",
    "company",
    "priority",
    "assignedTo",
    "dueDate",
    "actions",
  ];
  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  public readonly timeFormat = JOBFIT_TIME_FORMAT;

  dataSource: MatTableDataSource<ToDo>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.getAdminUsers();
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);

    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      var nameFound = false;
      filter.split(",").forEach(function (value: string, index: number, array: string[]) {
        if ('urgent' == value.toLowerCase()) {
          nameFound = nameFound || data.urgent;
        } else {
          nameFound = nameFound || data.type.toLowerCase() == value.toLowerCase();
        }
      });
      //console.log(nameFound);
      return nameFound;
      
    }
    this.loadTodos();
  }
  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )
  constructor(public dialog: MatDialog, todoService: ToDoService, public fb: FormBuilder, public messageService: MessageService, public roles: UserAdminService, public sharedService: SharedService) {
    this.todoService = todoService;
    this.dataSource = new MatTableDataSource();
  }

  //Material Table
  //applyFilter(event: Event) {

  //  const filterValue = (event.target as HTMLInputElement).value;
  //  this.dataSource.filter = filterValue.trim().toLowerCase();

  //  if (this.dataSource.paginator) {
  //    this.dataSource.paginator.firstPage();
  //  }
  //}

  newTodo() {
    this.getAdminUsers();
    const dialogRef = this.dialog.open(CreateTodo, {
      
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated.emit(true);
      this.loadTodos();
    });


  }
  public loadTodos() {
    this.todoService.getTodos().subscribe(
      result => {
        this.todos = result;

        // set my time and time at appointment location
        this.todos.forEach((x) => {
          if (x.type.toLowerCase() == 'pending' || x.type.toLowerCase() == 'waiting') {
            // is previously hasn't been set choose default (Australia/Brisbane)
            x.appointmentTimezone =  x.appointmentTimezone ?? Timezone.DEFAULT;
            x.myTime =  Timezone.convertUtcToTZ(x.dueDate, moment.tz.guess());
            // convert to the time at appointment location
            x.dueDate = Timezone.convertUtcToTZAsDateObject(x.dueDate, x.appointmentTimezone);
          }
        });        

        this.dataSource.data = this.todos;
        this.loading = false;
      }) 
  }
  applyFilter(event: MatButtonToggleChange) {
    this.dataSource.filter = event.value.toString();
    if (this.dataSource.filter == 'Reminder') {
      this.dateColTitle = 'Reminder Date';
    } else {
      this.dateColTitle = 'Appt. Date';
    }
  }
  setPersonData($event: MatAutocompleteSelectedEvent) {
    this.person = $event.option.value;
  }

  getAdminUsers() {
    this.sharedService.getUsersByRole("OFFICE ADMIN").subscribe(results => {
      this.people = results;
      this.filteredPeople = results;
    })
  }
  displayPersonName(person: Person): string {
    return person ? person.firstName + ' ' + person.lastName : '';
  }

  onPersonKey(event: KeyboardEvent) {
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredPeople = this.people.filter(a => a.firstName.toLowerCase().startsWith(filterValue) ||
      a.lastName.toLowerCase().startsWith(filterValue)
    );
    // console.log(this.person.firstName)
  }
}
