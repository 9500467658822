import { Inject, Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { PEFARequest, Company, BookingStatus, Booking, BookingType, Job, JobList, Assessor, InvoicePricing, Provider, JobDemand, Person, PEFASearchResults, PersonRelationship } from '../shared/shared-component';
import moment, { Moment } from "moment-timezone";

@Injectable({
    providedIn: 'root'
})
export class OfficeAdminPEFAService {



    private client: HttpClient;
    private baseURL: string;

    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
        this.client = http;
        this.baseURL = baseUrl;
    }

    getAllCompany(): Observable<any> {
        return this.client.get<Company[]>(this.baseURL + 'api/Company/GetAllCompany').pipe(
            retry(1),
            catchError(this.handleError)
        );
    };

    getAllActiveCompany(): Observable<any> {
      return this.client.get<Company[]>(this.baseURL + 'api/Company/GetAllActiveCompany').pipe(
          retry(1),
          catchError(this.handleError)
      );
    };    

  deleteCompany(id: string): Observable<Company> {
    return this.client.delete<Company>(this.baseURL + 'api/Company/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

/*  emailProviderSignUp(providerId: string): any {
    return this.client.post(this.baseURL + 'api/Provider/' + providerId +'/sendProviderSignUp', new FormData());
  }
*/
  removePerson(id: string, personId: string) : Observable<Company> {
    return this.client.delete<Company>(this.baseURL + 'api/Company/' + id + '/People/' +personId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addPerson(person: Person): Observable<Person> {
    return this.client.post<Person>(this.baseURL + 'api/Person/', person).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updatePerson(id:string, person: Person): Observable<Person> {
    return this.client.put<Person>(this.baseURL + 'api/Person/' + id, person).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updatePersonWithRelationship(id:string, person: Person): Observable<Person> {
    return this.client.put<Person>(this.baseURL + 'api/Person/UpdateWithRelationship/' + id, person).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPerson(id: string): Observable<Person> {
    return this.client.get<Person>(this.baseURL + 'api/Person/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPersonWithRelationship(id: string): Observable<any> {
    return this.client.get<any>(this.baseURL + 'api/Person/WithRelationship/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  } 

  getPersonByName(name: string): Observable<Person> {
    return this.client.get<Person>(this.baseURL + 'api/Person/ByName/' + name).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  


  updateJobDemands(id: string) {
    return this.client.put(this.baseURL + 'api/PEFARequests/' + id + '/UpdateDemands/', id);
  }

  getCompany(id :string): Observable<Company> {
    return this.client.get<Company>(this.baseURL + 'api/Company/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  getJodSubscriptions(): Observable<any> {
    return this.client.get<any>(this.baseURL + 'api/mesh/JodSubscriptions').pipe(
      retry(1),
      catchError(this.handleError)
    );
  };  

  setJodSubscription(companyId: string, subId: string): Observable<any> {
    return this.client.post<any>(this.baseURL + `api/mesh/UpdateJodSub/${companyId}`, subId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };    

  getJodJobs(companyId:string): Observable<any> {
    return this.client.get<any>(this.baseURL + 'api/mesh/JodJobs/' + companyId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };    

  linkJodJobs(jobId:string, jodJobId:string): Observable<any> {
    return this.client.post<any>(this.baseURL + `api/mesh/LinkJodJob/${jobId}`, jodJobId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };   

  unlinkJodJobs(jobId:string): Observable<any> {
    return this.client.post<any>(this.baseURL + `api/mesh/UnlinkJodJob/${jobId}`, null).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };  

  getConsoleCompanies(jodSubId:number): Observable<any> {
    return this.client.get<any>(this.baseURL + `api/mesh/ConsoleCompanies/${jodSubId}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };   

  getConsoleJobsLinkedToJod(companyId:string): Observable<any> {
    return this.client.get<any>(this.baseURL + `api/mesh/ConsoleJobsLinkedToJod/${companyId}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  
  getJodCompanyRules(jodCompanyId:string): Observable<any> {
    return this.client.get<any>(this.baseURL + `api/mesh/JodCompanyRules/${jodCompanyId}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  getJodPefaTestCriteria(jodJobId:string): Observable<any> {
    return this.client.get<any>(this.baseURL + `api/mesh/JodPefaTestCriteria/${jodJobId}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };  

  getConsolePefaTestCriteria(consoleJobId:string): Observable<any> {
    return this.client.get<any>(this.baseURL + `api/mesh/ConsolePefaTestCriteria/${consoleJobId}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };   

  syncPefaJobDemands(jodJobId:string, data: any): Observable<any> {
    return this.client.post<any>(this.baseURL + `api/mesh/SyncPefaJobDemands/${jodJobId}`, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };   
  

  getAllBookingTypes(): Observable<any> {
      return this.client.get<BookingType[]>(this.baseURL + 'api/BookingTypes/').pipe(
          retry(1),
          catchError(this.handleError)
      );
  }


  getAllPEFA(filter: string, status: string, sort:string, sortDirection: string, pageNumber: number, pageSize: number): Observable<PEFASearchResults> {

    var formData = new FormData();
    formData.append("term", filter);
    formData.append("status", status);
    formData.append("sort", sort);
    formData.append("sortDirection", sortDirection);
    formData.append("pageNumber", pageNumber.toString());
    formData.append("pageLength", pageSize.toString());
    return this.client.post<PEFASearchResults>(this.baseURL + 'api/PEFARequests/GetAllPEFA/', formData)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    };

    getPEFAForCompany(companyId: number): Observable<any> {
        return this.client.get<PEFARequest[]>(this.baseURL + 'api/Company/GetPEFAForCompany/' + companyId)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
  };

  getJobs(): Observable<any> {
    return this.client.get<Job[]>(this.baseURL + 'api/Office/GetJobs/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  getJobsForCompany(companyId:string): Observable<any> {
    return this.client.get<Job[]>(this.baseURL + `api/Office/GetJobsForCompany/${companyId}`)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };  

  GetJobsForCompanyOfJob(companyId:number, jobId:string | null): Observable<any> {
    let url = this.baseURL + `api/Office/GetJobsForCompanyOfJob/${companyId}`;
    if (jobId != null) {
      url += `?jobId=${jobId}`;
    }
    return this.client.get<Job[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };
  
  getJobsNotForCompany(companyId:string, q:string): Observable<any> {
    return this.client.get<Job[]>(this.baseURL + `api/Office/GetJobsNotForCompany/${companyId}?q=${q}`)
      .pipe(
        catchError(this.handleError)
      );
  };

  getJobsLinkedToJod(companyId:number, jodJobId:number): Observable<any> {
    return this.client.get<Job[]>(this.baseURL + `api/Office/GetJobsLinkedToJod/${companyId}?jodJobId=${jodJobId}`)
      .pipe(
        catchError(this.handleError)
      );
  };  

  getAllJobs(): Observable<Job[]> {
    return this.client.get<Job[]>(this.baseURL + 'api/Office/GetAllJobs/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };

  getJobsForList(companyID:string): Observable<JobList[]> {
    let url = this.baseURL + `api/Office/GetJobsForList`;
    if (companyID) {
      url = this.baseURL + `api/Office/GetJobsForList/${companyID}`;
    }
    return this.client.get<JobList[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };  

  getAllAssessors(): Observable<Assessor[]> {
    return this.client.get<Assessor[]>(this.baseURL + 'api/Office/GetAssessors/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };


    getPEFABooking(pEFAId: string) {
        return this.client.get<Booking>(this.baseURL + 'api/Office/getPEFABooking/' + pEFAId)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

  public uploadPEFADoc(files: File, PEFAId, PEFAScore, adminQA, smeQA, reason, selectedWriter:Person, dateOfReport) {
        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('Id', PEFAId);
    formData.append('smeQA', smeQA);
    formData.append('adminQA', adminQA);
    formData.append('reason', reason);
    formData.append('Score', PEFAScore);
    formData.append('reportWriter', selectedWriter.id);
    formData.append('DateOfReport', dateOfReport);
        return this.client.post<any>(this.baseURL + 'api/PEFARequests/UploadPEFADoc', formData).pipe(
            catchError((res: HttpErrorResponse) => {
                switch (res.status) {
                    case 200: //upload successful
                        //do appropriate calls for success upload
                        this.messageService.add("Report document '" + fileToUpload.name + "' Upload was Successful");
                        break;
                    case 404: //PEFAid not found!  redirect?
                        console.log(res.statusText);
                        break;
                    case 500: //Server error response failed
                        this.messageService.add("Report " + fileToUpload.name + " Upload was unsuccessful");
                        break;
                }
                return throwError(res);
            })
        )
    };

    public acceptPEFA(pEFAId: string): Observable<any> {
        const formData = new FormData();
      formData.append('pEFAId', pEFAId);
      return this.client.post<any>(this.baseURL + 'api/PEFARequests/acceptPEFA', formData);
  }



  public rejectPEFA(pEFAId: string): Observable<any> {
    return this.client.delete<any>(this.baseURL + 'api/PEFARequests/' + pEFAId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  public calculatePEFAAmount(pEFAId: string): Observable<InvoicePricing> {
    return this.client.get<InvoicePricing>(this.baseURL + 'api/PEFARequests/getRecommendedCompanyPrice/' + pEFAId);
  }

  public raisePEFAInvoice(invoiceForm: any): Observable<any> {
    return this.client.post<any>(this.baseURL + 'api/PEFARequests/RaiseCompanyInvoice/', invoiceForm);
  }

    public changePEFAStatus(pEFAId: string, status: number, note: string): Observable<any> {
        const formData = new FormData();
        formData.append('id', pEFAId);
        formData.append('status', status.toString())
        formData.append('note', note)
        return this.client.post<any>(this.baseURL + 'api/PEFARequests/changePEFAStatus', formData)
            .pipe(
            catchError((res: HttpErrorResponse) => {
                switch (res.status) {
                    case 200: //upload successful
                        //do appropriate calls for success upload
                        this.messageService.add("PEFA status has been updated ");
                        break;
                    case 404: //PEFAid not found!  redirect?
                        console.log(res.statusText);
                        break;
                    case 500: //Server error response failed
                        this.messageService.add("PEFA status update was unsuccessful - change has not been made");
                        break;
                }
                return throwError(res);

            })
        )
    }

  public addBooking(Id: string, ProviderId: string, AppointmentDateTime: Moment, Status: BookingStatus, CancelReason: string,
    notes: string, bookingType: string, assessorId: string, locationId: string, workerStatus: BookingStatus, tests: JobDemand[], sendMessage:boolean, 
    timezone: string): Observable<any> {
    //let PEFAbook = new PEFABooking(Id, ProviderId, AppointmentDateTime, Status, CancelReason, notes);
    const formData = new FormData();
    formData.append('Id', Id);
    formData.append('ProviderId', ProviderId);
    formData.append('AssessorId', assessorId);
    formData.append('LocationId', locationId);
    formData.append('AppointmentDateTime', AppointmentDateTime.toJSON());
    formData.append('Status', Status);
    formData.append('WorkerStatus', workerStatus);
    formData.append('CancelReason', CancelReason);
    formData.append('notes', notes);
    formData.append('bookingTypeId', bookingType);
    formData.append('sendMessage', sendMessage.toString());
    formData.append('AppointmentTimezone', timezone);
    for (let i = 0; i < tests.length; i++) {
      formData.append("tests[" + i + "][id]", tests[i].demandType.id);
      formData.append("tests[" + i + "][selected]", tests[i].selected.toString());
    }
        
    return this.client.post<any>(this.baseURL + 'api/Office/bookPEFA', formData)
  }

  public getAllProviders(): Observable<any> {
    return this.client.get<Provider[]>(this.baseURL + 'api/Office/GetAllProviders/').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getActiveProviders(): Observable<any> {
    return this.client.get<Provider[]>(this.baseURL + 'api/Office/GetAllProviders/ACTIVE').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getProvider(id: string): Observable<Provider> {
    return this.client.get<Provider>(this.baseURL + 'api/Provider/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getProviderAuditJotFormPreFill(id: string): Observable<object> {
    return this.client.get<object>(this.baseURL + 'api/Provider/AuditJotFormPreFill/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getJotFormSubmission(id: string): Observable<object> {
    return this.client.get<object>(this.baseURL + 'api/Provider/JotFormSubmission/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  

  public saveProvider(id: string, provider: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/Provider/' + id, provider).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  public addProvider(provider: Provider): Observable<Provider> {
    return this.client.post<Provider>(this.baseURL + 'api/Provider/', provider).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public saveBooking(id: string, booking: any): Observable<any> {
    return this.client.post(this.baseURL + 'api/Office/UpdateBooking/' + id, booking).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public saveCompany(id: string, company: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/Company/' + id, company).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      if (error.status == 404) {
        return throwError(
          'The requested object was not found.');
      }

    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

    getAttachment(PEFAId: number, attachmentId: number) {
        return this.client.get(this.baseURL + 'api/PEFARequests/getAttachment/' + PEFAId + "/" + attachmentId)
            .pipe(
                catchError(this.handleError)
            );
    };

    //this may not be used anymore, check and remove if not!
    downloadFile(PEFAId: number, attachmentId: number): Observable<Blob> {
        return this.client.get(this.baseURL + 'api/PEFARequests/getAttachment/' + PEFAId + "/" + attachmentId,
            {
                responseType: 'blob'
            });
  };

  downloadPaperwork(bookingId): any {
    return this.client.get(this.baseURL + 'api/PEFARequests/downloadPaperwork/' + bookingId,
      {
        responseType: 'blob',
        observe: 'response'
      });

  }

  downloadConfirmation(bookingId, result:boolean): any {
    var data = new FormData();
    data.append("sendEmail", result.toString());
    return this.client.post(this.baseURL + 'api/PEFARequests/downloadConfirmation' + "/" + bookingId, data,
      {
        observe: 'response',
        responseType: 'blob'
      });
  }

  public createNewPEFARequest(pefaRequest: PEFARequest): Observable<PEFARequest> {

    return this.client.post<PEFARequest>(this.baseURL + 'api/Office/newPEFA' , pefaRequest)
      .pipe(
        catchError(this.handleError)
      );

  }

  public getAssessorsForProvider(providerId: string): Observable<Assessor[]> {
    return this.client.get<Assessor[]>(this.baseURL + 'api/Office/GetAssessors/' + providerId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public addKPIDetail(pefaId: string, value: any): Observable<any> {
    return this.client.post(this.baseURL + 'api/PEFARequests/' + pefaId + '/KPI', value)
      .pipe(
        catchError(this.handleError)
      );
  }


  refreshMedical(id: string): Observable<any> {
    return this.client.get(this.baseURL + 'api/JotForm/' + id + '/RefreshMedical').pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  saveRelationship(id: string, form: any): Observable<PersonRelationship> {
    return this.client.put<PersonRelationship>(this.baseURL + 'api/PersonRelationship/' + id, form)
      .pipe(
        catchError(this.handleError)
      );

  }

  addRelationship(form: any): Observable<PersonRelationship> {

    return this.client.post<PersonRelationship>(this.baseURL + 'api/PersonRelationship/', form)
      .pipe(
        catchError(this.handleError)
      );

  }
}
