import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges, AfterViewInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CompanyUserRole } from "../company-admin/company-admin-service";
import { MessageService } from "../message/message.service";
import { Person, ToDo } from "../shared/shared-component";
import { SharedService } from "../shared/shared.service";
import { ToDoService } from "./todo.service";



@Component({
  selector: 'create-todo',
  templateUrl: './todo-dialog.component.html'
})

export class CreateTodo implements OnInit {
  // @Input() todo: ToDo;
  todo: ToDo = new ToDo();
  //todo: ToDo;
  role: CompanyUserRole[];
  person: Person;
  people: Person[] = [];
  filteredPeople: Person[] = [];
  todoService: ToDoService;
  public todos: ToDo[] = [];

  constructor(todoService: ToDoService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateTodo>, public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public messageService: MessageService) {
    this.todoService = todoService
  }

  ngOnInit() {
    this.getAdminUsers();
  }

  setPersonData($event: MatAutocompleteSelectedEvent) {
    let assignedTo = $event.option.value;
  }
  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }
  saveTodo(yn: boolean): void {
    this.todo.assignedTo = this.person?.user;
    this.todo.status = 'ACTIVE';
    this.todoService.createTodo(this.todo).subscribe(results => {
      this.todo = results
    })
    this.dialogRef.close(yn);
  }

  getAdminUsers() {
    this.sharedService.getUsersByRole("OFFICE ADMIN").subscribe(results => {
      this.people = results;
      this.filteredPeople = results;
    })
  }
  displayPersonName(person: Person): string {
    return person ? person.firstName + ' ' + person.lastName : '';
  }

  onPersonKey(event: KeyboardEvent) {
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredPeople = this.people.filter(a => a.firstName.toLowerCase().startsWith(filterValue) ||
      a.lastName.toLowerCase().startsWith(filterValue)
    );
  }

  onClose(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )
}
