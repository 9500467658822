<h1>Booked Appointments</h1>

<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>

<div class="container-fluid">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="workerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.pefArequest.worker">{{ row.pefArequest.worker.firstName}} {{ row.pefArequest.worker.lastName}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="provider">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Provider</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.assessor else elseName">{{ row.provider.name }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.status}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="appointmentdate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Appointment Date</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.appointmentDateTime| date:dateFormat }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="referralType">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Referral Type</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.pefArequest.referralType.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="job">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Job</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.pefArequest.pefaJob.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="notes">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Notes</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.notes}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row"><button mat-mini-fab (click)="openDialog(row.pefArequest.id)">cancel</button>&nbsp;<button mat-mini-fab (click)="recordNoShow(row.id)">no show</button></mat-cell>
    </ng-container>
    <ng-container matColumnDef="paperwork">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Paperwork</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <a mat-raised-button style="text-decoration: none;" href="/api/PefaRequests/downloadPaperwork/{{row.id}}" download>Paperwork</a>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
</div>

<ng-template #elseName>
  <span>&nbsp;</span>
</ng-template>
