<h1 mat-dialog-title>Cancel Request</h1>
<div mat-dialog-content>

  <mat-label>Reason for Cancelling</mat-label>
  <input type="text" [(ngModel)]="message" />
</div>

<mat-dialog-actions>
  <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Exit</button>
  <button mat-raised-button color="warn" style="margin-top: 1rem;" mat-button (click)="cancelRequest()">Cancel Request</button>
</mat-dialog-actions>
