<mat-expansion-panel>
  <mat-expansion-panel-header [ngClass]="{'highlight' : noteView.details.urgent}">
    <mat-panel-title>
      <span>{{noteView.details.urgent?"URGENT":""}} {{noteView.headline}} - {{noteView.details.status}}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  
  <mat-card>
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-label>
              Task
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.description}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-label>
              Due Date
            </mat-label>
          </div>
          <div class="col">
            <span *ngIf="noteView.details.dueDate">{{noteView.details.dueDate | date:dateFormat}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Completed Date
            </mat-label>
          </div>
          <div class="col">
            <span *ngIf="noteView.details.completedDate">{{noteView.details.completedDate | date:dateFormat}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Assigned to
            </mat-label>
          </div>
          <div class="col">
            <span *ngIf="noteView.details.assignedTo">{{noteView.details.assignedTo?.userName}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-label>
              Created By
            </mat-label>
          </div>
          <div class="col">
            <span *ngIf="noteView.details.audit">{{noteView.details.audit?.createdBy?.userName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Created Date
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdDate | date:dateFormat}}</span>
          </div>
        </div>

      </div>
    </mat-card-content>
    <mat-card-actions>
      <app-todo-actions [todo]="noteView.details" (updated)="refresh()"></app-todo-actions>
    </mat-card-actions>
  </mat-card>

</mat-expansion-panel>
