import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { AppComponent } from './app.component';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';

import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { PEFARequestDetailComponent } from './pefarequest/pefarequest-detail.component';
import { PEFARequestEntryComponent } from './pefarequest/pefarequest-entry.component';
import { ThankyouComponent } from './pefarequest/thankyou.component';
import { PEFARequestListComponent } from './pefarequest/pefarequest-list.component';
import { AnonPEFARequestEntryComponent } from './anon-pefarequest/anon-pefarequest-entry.component';
import { NgxMatDatetimePickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';

import { UserAdminComponent } from './company-admin/company-admin.component';
import { UserAdminListComponent } from './company-admin/company-admin-list.component';

import { OfficeAdminPEFAComponent } from './office-admin-pefa/office-admin-pefa.component';
import { OfficeAdminPEFAListComponent } from './office-admin-pefa/office-admin-pefa-list.component';
import { OfficeAdminPEFADetailComponent } from './office-admin-pefa/office-admin-pefa-detail.component';

import { PEFAUploadComponent } from './pefa-upload-admin/pefa-upload-admin.component';
import { PEFAUploadListComponent } from './pefa-upload-admin/pefa-upload-admin-list.component';

import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module'; 
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';

import { CookiePolicyComponent } from './cookie_policy/cookie-policy.component';

//Importing material modules, if need more add to file please
import { MaterialModule } from 'src/app/material-module';


import { FormsModule } from '@angular/forms';


//Services
//import { CompanyAdminService } from './company-admin/company-admin-service';
import { MessagesComponent } from './message/message.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//dialog popup declare here
import { AnonThankyouComponent } from './anon-pefarequest/thankyou.component';
import { MatTableModule } from '@angular/material/table';
import { ProviderPEFAListComponent } from './provider/provider-pefa-list.component';
import { CancelBookingDialog, DialogUploadReport, DialogStatusChange } from './shared/shared-component';
import { AssessorBookingListComponent } from './assessor/assessor-booking-list.component';
import { OfficeThankyouComponent } from './office-admin-pefarequest/thankyou.component';
import { OfficePEFARequestEntryComponent } from './office-admin-pefarequest/office-admin-pefarequest-entry.component';
import { OfficeCompanyListComponent } from './offce-admin/office-company-list.component';
import { OfficeProviderListComponent } from './offce-admin/office-provider-list.component';
import { OfficeJobListComponent } from './offce-admin/office-job-list.component';
import { BookingRowComponent } from './shared/booking-row.component';
import { BookingListComponent } from './shared/booking-list.component';
import { NoteListComponent } from './shared/note-list.component';
import { AttachmentListComponent } from './shared/attachment-list.component';
import { MatSortModule } from '@angular/material/sort';
import { PEFADetailViewComponent } from './shared/pefa-detail-view.component';
import { PefaInvoiceDialog } from './shared/invoice-pefa-dialog.component';
import { OfficePEFAActionsComponent } from './office-admin-pefa/office-admin-pefa-actions.component';
import { ProviderDetailComponent } from './offce-admin/provider/provider-detail.component';
import { CompanyDetailComponent } from './offce-admin/company/company-detail.component';
import { AcceptPefaDialog } from './shared/accept-pefarequest-dialog.component';
import { NoteDialog } from './shared/note-dialog.component';
import { UpdateBookingDialog } from './shared/update-booking-dialog.component';
import { OfficePefaSummaryComponent } from './office-admin-pefa/pefa-summary.component';
import { MedicalListComponent } from './medicalclearance/medical-list.component';
import { MedicalClearanceDialog } from './medicalclearance/medical-clearance-dialog.component';
import { AddMedicalClearanceDialog } from './medicalclearance/add-medical-clearance-dialog.component';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { UploadResultsDialog } from './shared/pefa-upload-results-dialog.component';
import { AddAttachmentDialog } from './shared/pefa-add-attachment-dialog.component';
import { MedicalClearanceLetterDialog } from './medicalclearance/medical-clearance-letter-dialog.component';
import { JobDetailComponent } from './job/job-detail.component';
import { DoctorListComponent } from './doctor/doctor-list.component';
import { DoctorDetailComponent } from './doctor/doctor-detail.component';
import { DialogBooking } from './shared/booking-dialog.component';
import { OfficeAdminBookingActionsComponent } from './office-admin-pefa/office-admin-booking-actions.component';
import { KPIComponent } from './shared/kpi.component';
import { PefaMessageListComponent } from './pefamessage/pefa-message-list.component';
import { SendMessageDialog } from './pefamessage/pefa-message-send-dialog.component';
import { PracticeDetailComponent } from './practice/practice-detail.component';
import { PracticeListComponent } from './practice/practice-list.component';
import { PEFAJobDetailComponent } from './pefajob/pefajob-detail.component';
import { CancelRequestDialog } from './shared/cancel-request.component';
import { AssessorListComponent } from './assessor/assessor-list.component';
import { AssessorDetailComponent } from './assessor/assessor-detail.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import moment from 'moment'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PaymentListComponent } from './shared/payment-list.component';
import { ConfirmDialog } from './shared/confirm-dialog.component';
import { MessageDialog } from './shared/message-dialog.component';
import { ToDoListComponent } from './todo/todo-list.component';
import { ToDoActionsComponent } from './todo/todo-action.component';
import { RelationshipDetailComponent } from './shared/relationship-detail.component';
import { LocationDetailComponent } from './shared/location-detail.component';
import { PersonSelectorDialog } from './shared/person-dialog.component';
import { TrainingListComponent } from './assessor/training-list.component';
import { QADialog } from './shared/qa-dialog.component';
import { CompanySelectorDialog } from './shared/company-dialog.component';
import { WorkerViewComponent } from './shared/worker-view.component';
import { BookingsViewComponent } from './shared/bookings-view.component';
import { PaymentTypeEntryComponent } from './shared/paymenttype-entry.component';
import { PEFADemandViewComponent } from './pefajob/pefa-demand-view.component';
import { PEFADemandMHItemViewComponent } from './pefajob/pefa-demand-mh-item.component';
import { ReportListComponent } from './reports/report-list.component';
import { ReportDetailComponent } from './reports/report-detail.component';
import { DateCriteriaComponent } from './reports/date-criteria.component';
import { TextCriteriaComponent } from './reports/text-criteria.component';
import { ErrorService } from './shared/ErrorService';
import { MultiCompanyCriteriaComponent } from './reports/multicompany-criteria.component';
import { PEFAToDoListComponent } from './shared/pefa-todolist.component';
import { KPIDetailDialog } from './shared/kpi-detail-dialog.component';
import { ProviderAssessorDetailComponent } from './offce-admin/provider/provider-assessor-detail.component';
import { RelationshipDialogComponent } from './shared/relationship-dialog.component';
import { BusinessCalendarComponent } from './admin/business-calendar.component';
import { BusinessCalendarTimeComponent } from './admin/business-calendar-time.component';
import { ProviderAuditDialogComponent } from './shared/provider-audit-dialog.component';
import { TextAreaCriteriaComponent } from './reports/textArea-criteria.component';
import { KPIListComponent } from './kpis/kpi-list.component';
import { ProviderBookingListComponent } from './offce-admin/provider/provider-booking-list.component';
import { AddNewPerson } from './shared/person/person-dialog.component';
import { AddNewPersonEnhanced } from './shared/person/person-dialog-enhanced.component';
import { DemandMHItemViewComponent } from './job/demand-mh-item.component';
import { DemandPTItemViewComponent } from './job/demand-pt-item.component';
import { DemandViewComponent } from './job/demand-view.component';
import { NoteViewListComponent } from './shared/note-view/note-view-list.component';
import { NoteViewAttachmentComponent } from './shared/note-view/note-view-attachment.component';
import { NoteViewMessageComponent } from './shared/note-view/note-view-message.component';
import { NoteViewBookingComponent } from './shared/note-view/note-view-booking.component';
import { NoteViewNoteComponent } from './shared/note-view/note-view-note.component';
import { NoteViewPaymentComponent } from './shared/note-view/note-view-payment.component';
import { NoteViewMedicalComponent } from './shared/note-view/note-view-medical.component';
import { NotificationsComponent } from '../app/notifications/notifications.component';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NoteViewToDoComponent } from './shared/note-view/note-view-todo.component';
import { AssignPerson } from './shared/note-view/assign-todo/assign-dialog.component';
import { CreateTodo } from './todo/todo-dialog.component';
import { JodJobSelectorDialog } from './shared/jod-job-dialog.component';
import { JodSettingsComponent } from './admin/jod-settings.component';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MMM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        TopMenuComponent,
        HomeComponent,
        CookiePolicyComponent,
        CounterComponent,
        FetchDataComponent,
        PEFARequestDetailComponent,
        PEFARequestEntryComponent,
        AnonPEFARequestEntryComponent,
        PEFARequestListComponent,
        PEFAUploadComponent,
        PEFAUploadListComponent,
        OfficeAdminPEFAComponent,
        OfficeAdminPEFAListComponent,
        OfficeAdminPEFADetailComponent,
        OfficePEFARequestEntryComponent,
        OfficeThankyouComponent,
        UserAdminComponent,
        ProviderPEFAListComponent,
        UserAdminListComponent,
        MessagesComponent,
        ThankyouComponent,
        AnonThankyouComponent,
        DialogUploadReport,
        DialogBooking,
        DialogStatusChange,
    UpdateBookingDialog,
        CancelBookingDialog,
    AssessorBookingListComponent,
    OfficeAdminBookingActionsComponent,
    KPIComponent,
    OfficeCompanyListComponent,
    OfficeProviderListComponent,
    OfficeJobListComponent,
    BookingListComponent,
    NoteListComponent,
    PaymentListComponent,
    MedicalListComponent,
    AttachmentListComponent,
    PEFADetailViewComponent,
    PefaInvoiceDialog,
    OfficePEFAActionsComponent,
    ProviderDetailComponent,
    CompanyDetailComponent,
    BookingRowComponent,
    AcceptPefaDialog,
    MedicalClearanceDialog,
    MedicalClearanceLetterDialog,
    AddMedicalClearanceDialog,
    NoteDialog,
    OfficePefaSummaryComponent,
    UploadResultsDialog,
    AddAttachmentDialog,
    DoctorListComponent,
    DoctorDetailComponent,
    AssessorListComponent,
    AssessorDetailComponent,
    JobDetailComponent,
    PEFAJobDetailComponent,
    PefaMessageListComponent,
    SendMessageDialog,
    PracticeListComponent,
    PracticeDetailComponent,
    ConfirmDialog,
    MessageDialog,
    CancelRequestDialog,
    ToDoListComponent,
    ToDoActionsComponent,
    RelationshipDetailComponent,
    LocationDetailComponent,
    PersonSelectorDialog,
    CompanySelectorDialog,
    JodJobSelectorDialog,
    TrainingListComponent,
    QADialog,
    WorkerViewComponent,
    BookingsViewComponent,
    PEFADemandViewComponent,
    DemandViewComponent,
    DemandPTItemViewComponent,
    DemandMHItemViewComponent,
    PEFADemandMHItemViewComponent,
    PaymentTypeEntryComponent,
    ReportListComponent,
    ReportDetailComponent,
    DateCriteriaComponent,
    TextCriteriaComponent,
    TextAreaCriteriaComponent,
    MultiCompanyCriteriaComponent,
    PEFAToDoListComponent,
    KPIDetailDialog,
    ProviderAssessorDetailComponent,
    RelationshipDialogComponent,
    BusinessCalendarComponent,
    BusinessCalendarTimeComponent,
    ProviderAuditDialogComponent,
    KPIListComponent,
    ProviderBookingListComponent,
    AddNewPerson,
    AddNewPersonEnhanced,
    NoteViewListComponent,
    NoteViewAttachmentComponent,
    NoteViewMessageComponent,
    NoteViewBookingComponent,
    NoteViewNoteComponent,
    NoteViewPaymentComponent,
    NoteViewMedicalComponent,
    NotificationsComponent,
    NoteViewToDoComponent,
    AssignPerson,
    CreateTodo,
    JodSettingsComponent,

    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        ReactiveFormsModule,
        MaterialModule,
      MatTableModule,
      MatSortModule,
        FormsModule,
        BrowserAnimationsModule,
      ApiAuthorizationModule,    
      MatMomentDateModule,
      NgxMatDatetimePickerModule,
      NgxMatMomentModule,
      TimelineModule,
      CardModule,
      BadgeModule,
      ButtonModule,
      SidebarModule,
      MessagesModule,
      MessageModule,
      OverlayPanelModule,
        RouterModule.forRoot([
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'cookie_policy', component: CookiePolicyComponent },
          { path: 'user-admin', component: UserAdminComponent, canActivate: [AuthorizeGuard]  },
          { path: 'user-admin-list', component: UserAdminListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'pefarequest', component: PEFARequestListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'newpefarequest', component: PEFARequestEntryComponent, canActivate: [AuthorizeGuard]  },
          { path: 'officepefarequest', component: OfficePEFARequestEntryComponent, canActivate: [AuthorizeGuard]  },
          { path: 'newanonpefarequest', component: AnonPEFARequestEntryComponent },
          { path: 'office-admin-pefa', component: OfficeAdminPEFAComponent, canActivate: [AuthorizeGuard]  },
          { path: 'pefaupload-admin-list', component: PEFAUploadListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'thanks', component: ThankyouComponent, canActivate: [AuthorizeGuard]  },
    { path: 'anonthanks', component: AnonThankyouComponent },
          { path: 'officethanks', component: OfficeThankyouComponent, canActivate: [AuthorizeGuard]  },
          { path: 'pefarequest/:id', component: PEFARequestDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'pefarequest/:id/PEFAJob', component: PEFAJobDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-admin-pefa-detail/:id', component: OfficeAdminPEFADetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-provider-detail/:id', component: ProviderDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-provider-detail', component: ProviderDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-job-detail/:id', component: JobDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-job-detail', component: JobDetailComponent, canActivate: [AuthorizeGuard] },
          { path: 'office-company-detail/:id', component: CompanyDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-company-detail', component: CompanyDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'provider-booking-list', component: ProviderPEFAListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'assessor-booking-list', component: AssessorBookingListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-company-list', component: OfficeCompanyListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-provider-list', component: OfficeProviderListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'doctor-list', component: DoctorListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'doctor/:id', component: DoctorDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'doctor', component: DoctorDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'assessor-list', component: AssessorListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'assessor/:id', component: AssessorDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'assessor', component: AssessorDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'practice-list', component: PracticeListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'practice/:id', component: PracticeDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'practice', component: PracticeDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'office-job-list', component: OfficeJobListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'report-list', component: ReportListComponent, canActivate: [AuthorizeGuard]  },
          { path: 'business-calendar', component: BusinessCalendarComponent, canActivate: [AuthorizeGuard] },
          { path: 'report-detail/:id', component: ReportDetailComponent, canActivate: [AuthorizeGuard]  },
          { path: 'jod-settings', component: JodSettingsComponent, canActivate: [AuthorizeGuard] },
], { relativeLinkResolution: 'legacy' })
  ],
  entryComponents: [ConfirmDialog, NoteDialog, MedicalClearanceLetterDialog, MedicalClearanceDialog, AddAttachmentDialog, UploadResultsDialog, AddMedicalClearanceDialog, PefaInvoiceDialog, DialogUploadReport, DialogBooking, DialogStatusChange, PEFAUploadListComponent, UpdateBookingDialog, CancelBookingDialog], //dialog popup required declare here - DialogOverviewExampleDialog is a test dialog that shows in dev page
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: ErrorHandler, useClass: ErrorService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

