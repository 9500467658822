import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MessageService } from "../message/message.service";
import { AuditService } from "../shared/audit.service";
import { ProviderAudit } from "../shared/shared-component";

type NewType = ProviderAudit;

@Injectable({
  providedIn: 'root'
})

export class AssessorService implements AuditService {

  private client: HttpClient;
  private baseURL: string;


  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }
  saveProviderAudit(id: any, audit: any): Observable<ProviderAudit> {
//    formData.append('pEFAId', pEFAId);
    return this.client.post<ProviderAudit>(this.baseURL + 'api/Assessor/' + id + '/Audit', audit);
  }
    
  addProviderAudit(id: any, audit: any): Observable<ProviderAudit>{
    return this.client.post<ProviderAudit>(this.baseURL + 'api/Assessor/' + id + '/Audit', audit);
  }

}
