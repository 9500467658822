import { ValidatorFn, FormGroup, ValidationErrors } from "@angular/forms";

export const sameInputValueValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const name = control.get('passWord');
  const alterEgo = control.get('confirmPassword');

  if (alterEgo.errors && !alterEgo.errors.mustMatch) {
    // return if another validator has already found an error on the matchingControl
    return;
  }

  // set error on matchingControl if validation fails
  if (name.value !== alterEgo.value) {
    alterEgo.setErrors({ mustMatch: true });
  } else {
    alterEgo.setErrors(null);
  }
};
