<div *ngIf="report">
  <div *ngFor="let crit of report.criteria" [formGroup]="criteriaForm">
    <div *ngIf="crit.type == 'Date'">
      <app-criteria-date [criteriaForm]="criteriaForm" [criteria]="crit"></app-criteria-date>
    </div>
    <div *ngIf="crit.type == 'Text'">
      <app-criteria-text [criteriaForm]="criteriaForm" [criteria]="crit"></app-criteria-text>
    </div>
    
    <div *ngIf="crit.type == 'TextArea'">
      <app-criteria-textArea [criteriaForm]="criteriaForm" [criteria]="crit"></app-criteria-textArea>
    </div>

    <div *ngIf="crit.type == 'MultiCompany'">
      <app-criteria-multicompany [criteriaForm]="criteriaForm" [criteria]="crit"></app-criteria-multicompany>
    </div>
  </div>
  <div [formGroup]="criteriaForm">
    <mat-form-field>
      <mat-label>Format</mat-label>
      <mat-select formControlName="format" name="format" placeholder="Select Report Format">
        <mat-option *ngFor="let formatter of formats" [value]="formatter">{{formatter}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <button mat-raised-button class="mat-primary" (click)="generateReport()">Generate Report</button><div *ngIf="loading"><mat-spinner diameter="30"></mat-spinner></div>
  </div>
</div>
