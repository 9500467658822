import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Company, PersonRelationship, Job } from '../../shared/shared-component';
import { SharedService } from '../../shared/shared.service';
import { OfficeAdminPEFAService } from '../../office-admin-pefa/office-admin-pefa.service';
import { MessageService } from '../../message/message.service';
import moment from 'moment';
import { ConfirmDialog } from '../../shared/confirm-dialog.component';
import { PersonSelectorDialog } from '../../shared/person-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { RelationshipDialogComponent } from '../../shared/relationship-dialog.component';
import { AddNewPersonEnhanced } from '../../shared/person/person-dialog-enhanced.component';
import { JOBFIT_DATETIME_FORMAT } from "../../shared/shared-component";
import { AuthorizeService } from '../../../api-authorization/authorize.service';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})
export class CompanyDetailComponent implements OnInit {
  public readonly dateTimeFormat = JOBFIT_DATETIME_FORMAT;
  public company: Company;
  public loading: boolean = true;
  peopleDataSource = new MatTableDataSource<PersonRelationship>();
  public isStaunchAdmin: boolean = false;
  public jodSubsDataSource: any[];
  public jodJobsDataSource: any[];
  //public jodJobsNames: any = null
  public jobsDataSource: Job[] = [];
  public jobDisplayedColumns: string[] = ['console', 'spacer', 'jod'];

  companyForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.required),
    tier: new FormControl('', Validators.required),
    companyNumber: new FormControl(''),
    accountEmail: new FormControl(''),
    faxNumber: new FormControl(''),
    phoneNumber: new FormControl(''),
    status: new FormControl('', Validators.required),
    statusNote: new FormControl(''),
    notes: new FormControl(''),
    clientConsentTxtName: new FormControl(''),
    bpDia: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(95), Validators.max(100)]),
    bpSys: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(145), Validators.max(170)]),
    clearanceLocation: new FormControl('', [Validators.required]),
    bookingNotes: new FormControl(''),
    approvedByBp: new FormControl(''),
    approvedByCl: new FormControl(''),
    approvedByBr: new FormControl(''),
    address: new FormGroup({
      id: new FormControl(''),
      address: new FormControl(''),
      region: new FormControl(''),
      type: new FormControl('PHYSICAL'),
      headOffice: new FormControl(true),
    }),
    paymentTypes: new FormArray([]),
    people: new FormArray([]),
    jodCompanyId: new FormControl(''),
  });

  errors: string[];

  peopleDisplayedColumns = [
    "name",
    "type",
    "status",
    "startDate",
    "endDate",
    "email",
    "delete",
  ];
  ngOnInit(): void {
    this.officeAdminService.getCompany(this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '0').subscribe(results => {
      this.company = results;
      // to local
      if (results.updatedAtBp != null) {
        this.company.updatedAtBp = moment(results.updatedAtBp).toDate();
      }
      if (results.updatedAtCl != null) {
        this.company.updatedAtCl = moment(results.updatedAtCl).toDate();
      }
      if (results.updatedAtBr != null) {
        this.company.updatedAtBr = moment(results.updatedAtBr).toDate();
      }
      if (results.updatedAtJod != null) {
        this.company.updatedAtJod = moment(results.updatedAtJod).toDate();
      }
      this.peopleDataSource.data = this.company.people;
      for (let entry of this.company.paymentTypes) {
        this.addSavedPaymentType();
      };
      this.peopleDataSource.data = this.company.people;
      for (let entry of this.company.people) {
        // this.addSavedPerson();
      };

      this.companyForm.get('jodCompanyId').valueChanges.subscribe(val => {
        // need to save changes first
        this.officeAdminService.setJodSubscription(this.company.id, val).subscribe(e => {
          if (val != null && val != '') {
            this.officeAdminService.getJodJobs(val).subscribe(jobs => {
              this.jodJobsDataSource = jobs;
              // create jod job name dict
              // this.jodJobsNames = {'':''};
              // for(let i=0; i < jobs.length; i++) {
              //   let j = jobs[i];
              //   this.jodJobsNames[j['Id']] = j['Name'];
              // }            
            });
          } else {
            //this.jodJobsNames = null;
            this.jodJobsDataSource = [];
          }
        });
      });

      this.companyForm.patchValue(results);

      // load jobs
      this.officeAdminService.getJobsForCompany(this.company.id).subscribe(result => {
        this.jobsDataSource = result;
      });

      // load jod jobs
      if (this.companyForm.get('jodCompanyId').value) {
        this.officeAdminService.getJodJobs(this.companyForm.get('jodCompanyId').value).subscribe(jobs => {
          this.jodJobsDataSource = jobs;
          // create jod job name dict
          // this.jodJobsNames = {'':''};
          // for(let i=0; i < jobs.length; i++) {
          //   let j = jobs[i];
          //   this.jodJobsNames[j['Id']] = j['Name'];
          // }
        });
      }

      this.loading = false;
    });

    this.authorizeService.getUser().subscribe(result => {
      this.isStaunchAdmin = result.profile.role.filter(r => r == "STAUNCH_ADMIN").length > 0;
    });

    this.officeAdminService.getJodSubscriptions().subscribe(subs => {
      this.jodSubsDataSource = subs;
      // console.log(subs);
    });

    // Approved by Validations: only require if changed from default values
    this.companyForm.controls['bpSys'].valueChanges.subscribe(result => {
      if (result != 145) {
          // clear require person to re-enter approved by
          this.companyForm.controls['approvedByBp'].reset();

        if (this.companyForm.controls['approvedByBp'].validator == null) {
          // add required validation
          this.companyForm.controls['approvedByBp'].setValidators([Validators.required]);
          this.companyForm.controls['approvedByBp'].markAsTouched();
          this.companyForm.controls['approvedByBp'].updateValueAndValidity();          
        }
      } else {
        this.companyForm.controls['approvedByBp'].clearValidators();
        this.companyForm.controls['approvedByBp'].markAsTouched();
        this.companyForm.controls['approvedByBp'].updateValueAndValidity();
      }
    });
    this.companyForm.controls['bpDia'].valueChanges.subscribe(result => {
      if (result != 95) {
          // clear require person to re-enter approved by
          this.companyForm.controls['approvedByBp'].reset();

        if (this.companyForm.controls['approvedByBp'].validator == null) {
          // add required validation
          this.companyForm.controls['approvedByBp'].setValue(''); // clear require person to re-enter approved by
          this.companyForm.controls['approvedByBp'].setValidators([Validators.required]);
          this.companyForm.controls['approvedByBp'].markAsTouched();
          this.companyForm.controls['approvedByBp'].updateValueAndValidity();
        }
      } else {
        this.companyForm.controls['approvedByBp'].clearValidators();
        this.companyForm.controls['approvedByBp'].markAsTouched();
        this.companyForm.controls['approvedByBp'].updateValueAndValidity();
      }
    });
    this.companyForm.controls['clearanceLocation'].valueChanges.subscribe(result => {
      if (result == 'NR') {
          // clear require person to re-enter approved by
          this.companyForm.controls['approvedByCl'].reset();

        if (this.companyForm.controls['approvedByCl'].validator == null) {
          // add required validation
          this.companyForm.controls['approvedByCl'].setValidators([Validators.required]);
          this.companyForm.controls['approvedByCl'].markAsTouched();
          this.companyForm.controls['approvedByCl'].updateValueAndValidity();
        }
      } else {
        this.companyForm.controls['approvedByCl'].clearValidators();
        this.companyForm.controls['approvedByCl'].markAsTouched();
        this.companyForm.controls['approvedByCl'].updateValueAndValidity();
      }
    });
    this.companyForm.controls['bookingNotes'].valueChanges.subscribe(result => {
      if (result != null && result != '') {
          // clear require person to re-enter approved by
          this.companyForm.controls['approvedByBr'].reset();

        if (this.companyForm.controls['approvedByBr'].validator == null) {
          // add required validation
          this.companyForm.controls['approvedByBr'].setValidators([Validators.required]);
          this.companyForm.controls['approvedByBr'].markAsTouched();
          this.companyForm.controls['approvedByBr'].updateValueAndValidity();
        }
      } else {
        this.companyForm.controls['approvedByBr'].clearValidators();
        this.companyForm.controls['approvedByBr'].markAsTouched();
        this.companyForm.controls['approvedByBr'].updateValueAndValidity();
      }
    });

    this.companyForm.statusChanges.subscribe(result => {

      this.errors = [];
      if (result == "INVALID") {
        Object.keys(this.companyForm.controls).forEach((key) => {
          if (this.companyForm.get(key) instanceof FormGroup) {
            var group = this.companyForm.get(key) as FormGroup
            Object.keys(group.controls).forEach((key) => {
              if (group.get(key).errors) {
                var result = key.replace(/([A-Z])/g, " $1");
                var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                this.errors.push(finalResult + " is required");
              }
            });

          } else if (this.companyForm.get(key) instanceof FormArray) {
            var arr = this.companyForm.get(key) as FormArray;
            Object.keys(arr.controls).forEach((key2) => {
              if (arr.get(key2) instanceof FormGroup) {
                var group = arr.get(key2) as FormGroup
                Object.keys(group.controls).forEach((key3) => {
                  if (group.get(key3).errors) {
                    var result = key3.replace(/([A-Z])/g, " $1");
                    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    this.errors.push(finalResult + " is required");
                  }
                });
              }
              else if (arr.get(key2).errors) {
                var result = key2.replace(/([A-Z])/g, " $1");
                var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                this.errors.push(finalResult + " is required");
              }
            });
          } else if (this.companyForm.get(key).errors) {
            var result = key.replace(/([A-Z])/g, " $1");
            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
            switch (key) {
              case 'bpSys':
                this.errors.push("Systolic limit range is (145 to 170 mmHg)");
                break;
              case 'bpDia':
                this.errors.push("Diastolic limit range is (95 to 100 mmHg)");
                break;
              case 'approvedByBp':
                this.errors.push("Blood Pressure Limits Approved By is required.");
                break;
              case 'approvedByCl':
                this.errors.push("Pre Test Medical Clearance Approved By is required.");
                break;
              case 'approvedByBr':
                this.errors.push("Booking Rules Approved By is required.");
                break;
              default:
                var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                this.errors.push(finalResult + " is required");
                break;
            }
          }
        });
      }
    });
  }

  sharedService: SharedService;

  messageService: MessageService;

  constructor(
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public officeAdminService: OfficeAdminPEFAService,
    private authorizeService: AuthorizeService,
    sharedService: SharedService
  ) {
    this.messageService = messageService;
    this.sharedService = sharedService;
  }

  saveChanges() {
    this.loading = true;
    this.officeAdminService.saveCompany(this.company.id, this.companyForm.value).subscribe(results => {

      this.company = results;
      this.paymentTypes.clear();
      this.people.clear();
      for (let entry of this.company.paymentTypes) {
        this.addSavedPaymentType();
      };

      for (let entry of this.company.people) {
        this.addSavedPerson();
      };
      this.companyForm.patchValue(results);
      this.messageService.add("Company Saved Successfully")
      this.loading = false;
    }
    );

  }

  get paymentTypes() {
    return this.companyForm.get('paymentTypes') as FormArray;
  }


  get people() {
    return this.companyForm.get('people') as FormArray;
  }

  addPaymentType() {
    this.paymentTypes.push(
      new FormGroup({

        reference: new FormControl('', Validators.required),
        limit: new FormControl('0', Validators.required),
        type: new FormControl('CC', Validators.required),
        expiry: new FormControl(moment()),
        status: new FormControl('ACTIVE', Validators.required),
      })
    );
  }

  public addSavedPaymentType() {
    this.paymentTypes.push(
      new FormGroup({
        id: new FormControl('', Validators.required),
        reference: new FormControl('', Validators.required),
        limit: new FormControl('0', Validators.required),
        type: new FormControl('CC', Validators.required),
        expiry: new FormControl(moment()),
        status: new FormControl('ACTIVE', Validators.required),
      })
    );
  }

  public addSavedPerson() {
    this.people.push(
      new FormGroup({
        id: new FormControl(''),
        type: new FormControl(''),
        status: new FormControl('ACTIVE'),
        startDate: new FormControl(moment()),
        endDate: new FormControl(''),
        person: new FormGroup({
          id: new FormControl(''),
          title: new FormControl('',),
          email: new FormControl(''),
          phoneNumber: new FormControl(''),
          firstName: new FormControl(''),
          lastName: new FormControl(''),
          preferredName: new FormControl('',),

        }),

      })
    );
  }

  public deletePerson(index: number) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        this.officeAdminService.removePerson(this.company.id, index.toString()).subscribe((results) => {
          this.messageService.add("Person Removed");
          this.company = results;
          this.peopleDataSource.data = this.company.people;
          this.companyForm.patchValue(results);
        });
      }
    });
  }


  editPerson(index: string) {
    const dialogRef = this.dialog.open(AddNewPersonEnhanced, {
      width: '600px',
      data: {
        personRelationshipId: index,
        companyId: this.company.id,
      }
    });
    // reload people list
    dialogRef.afterClosed().subscribe(result => {
      this.officeAdminService.getCompany(this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '0').subscribe(results => {
        this.company = results;
        this.peopleDataSource.data = this.company.people;
        this.companyForm.patchValue(results);
      }
      );
    });
  }

  openAddPerson() {
    const dialogRef = this.dialog.open(AddNewPersonEnhanced, {
      width: '600px',
      data: {
        companyId: this.company.id,
      }
    });
    // reload people list
    dialogRef.afterClosed().subscribe(result => {
      this.officeAdminService.getCompany(this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '0').subscribe(results => {
        this.company = results;
        this.peopleDataSource.data = this.company.people;
        this.companyForm.patchValue(results);
      }
      );
    });
  }

  openRelationshipDialog() {
    const dialogRef = this.dialog.open(RelationshipDialogComponent, {
      data: {
        companyId: this.company.id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.relation) {
        this.company.people.push(result.relation);
        this.peopleDataSource.data = this.company.people;
      }
    });
  }

  public loadCompany() {
    this.officeAdminService.getCompany(this.route.snapshot.paramMap.get('id')).subscribe(results => {
      this.company = results;
      this.paymentTypes.clear();
      this.people.clear();
      for (let entry of this.company.paymentTypes) {
        this.addSavedPaymentType();
      };

      for (let entry of this.company.people) {
        this.addSavedPerson();
      };

      this.companyForm.patchValue(this.company);

    });
  }

  onJodJobChange(evt, consoleJobId) {
    let jodJobId = evt.value;
    if (jodJobId) {
      //console.log(jodJobId, consoleJobId);
      // save
      this.officeAdminService.linkJodJobs(consoleJobId, jodJobId).subscribe(result => {
        // console.log(result);
      });
    } else {
      // clear the binding
      this.officeAdminService.unlinkJodJobs(consoleJobId).subscribe(result => {
        // console.log(result);
      });
    }
  }

}

