import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Company, Job, ReferralType } from '../shared/shared-component';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SharedService } from '../shared/shared.service';
import moment from 'moment';
import { PaymentTypeEntryComponent } from '../shared/paymenttype-entry.component';

@Component({
  selector: 'app-office-pefarequest-entry',
  templateUrl: './office-admin-pefarequest-entry.component.html'
})

export class OfficePEFARequestEntryComponent implements OnInit {
  officeRequestService: OfficeAdminPEFAService;
  sharedService: SharedService;
  referralTypeOptions: ReferralType[];
  companyOptions: Company[];
  filteredCompanies: Company[];
  jobOptions: Job[];
  filteredJobs: Job[];
  filteredReferralTypes: ReferralType[];



  pefaRequestForm = new FormGroup({
    Worker: new FormGroup({
      firstName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(moment()),
      lastName: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      home: new FormControl(''),
      work: new FormControl(''),
      gender: new FormControl(''),
      site: new FormControl(''),
      email: new FormControl('', Validators.required)
    }),
    RequestedTime: new FormControl(moment()),
    AppointmentDate: new FormControl(moment()),
    Recruiter: new FormControl(''),
    ResponseTime: new FormControl(''),
    PefaJob: new FormGroup({
      Site: new FormControl(''),
      Name: new FormControl(''),
      Job: new FormGroup({
        id: new FormControl('0'),
      })
    }),
    paymentMethod: new FormControl(''),
    //Status: new FormControl(''),
    ReferralType: new FormGroup(
      {
        id: new FormControl('', Validators.required),
      }
      ),
    otherInfo: new FormControl(''),
    ReferrerDetails: new FormGroup({
      Name: new FormControl('', Validators.required),
      Site: new FormControl(''),
      Phone: new FormControl('', Validators.required),
      Position: new FormControl('', Validators.required),
      Email: new FormControl('', Validators.required),
    }),

    Company: new FormGroup({
      id: new FormControl('0'),
      name: new FormControl('')
      })
  });

  minDate = moment();

  errors: string[];


  @ViewChild(PaymentTypeEntryComponent)
  private paymentComponent: PaymentTypeEntryComponent;

  constructor(
    pefaRequestService: OfficeAdminPEFAService,
    private fb: FormBuilder,
    private router: Router,
    sharedService: SharedService
    )
  {
    this.officeRequestService = pefaRequestService;
    this.sharedService = sharedService;
  }

  ngOnInit() {
    this.officeRequestService.getAllCompany().subscribe(result =>
      this.companyOptions = result);
    this.officeRequestService.getJobs().subscribe(result =>
      this.jobOptions = result);
    this.sharedService.getReferralTypes().subscribe(result =>
      this.referralTypeOptions = result);


    this.pefaRequestForm.statusChanges.subscribe(result => {

      this.errors = [];
      if (result == "INVALID") {
        Object.keys(this.pefaRequestForm.controls).forEach((key) => {
          if (this.pefaRequestForm.get(key) instanceof FormGroup) {
            var group = this.pefaRequestForm.get(key) as FormGroup
            Object.keys(group.controls).forEach((key) => {
              if (group.get(key).errors) {
                var result = key.replace(/([A-Z])/g, " $1");
                var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                this.errors.push(finalResult + " is required");
              }
            });

          } else if (this.pefaRequestForm.get(key) instanceof FormArray) {
            var arr = this.pefaRequestForm.get(key) as FormArray;
            Object.keys(arr.controls).forEach((key2) => {
              if (arr.get(key2) instanceof FormGroup) {
                var group = arr.get(key2) as FormGroup
                Object.keys(group.controls).forEach((key3) => {
                  if (group.get(key3).errors) {
                    var result = key3.replace(/([A-Z])/g, " $1");
                    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    this.errors.push(finalResult + " is required");
                  }
                });
              }
              else if (arr.get(key2).errors) {
                var result = key2.replace(/([A-Z])/g, " $1");
                var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                this.errors.push(finalResult + " is required");
              }
            });
          } else if (this.pefaRequestForm.get(key).errors) {
            var result = key.replace(/([A-Z])/g, " $1");
            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
            this.errors.push(finalResult + " is required");
          }
        });
      }


    });
  }


  onSubmit() {

    var requestValue = this.pefaRequestForm.value;

    if (requestValue.paymentMethod == 'Worker' || requestValue.paymentMethod == 'IPO' || requestValue.paymentMethod == 'ICC') {
      requestValue.Worker.payment = this.paymentComponent.paymentForm.value;
    }

    this.officeRequestService.createNewPEFARequest(requestValue).subscribe();    
    this.router.navigateByUrl("/officethanks");
  }

  onCompanyKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredCompanies = this.companyOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);

    if (this.filteredCompanies.length == 1 && filterValue == this.filteredCompanies[0].name.toLowerCase()) {
      let companyId = this.filteredCompanies[0].id;
      let companyName = this.filteredCompanies[0].name;
      this.pefaRequestForm.patchValue({
        Company: {
          Name: companyName,
          id: companyId         
        }
      });
    } else {
      this.pefaRequestForm.patchValue({
        Company: {
            id: '0'          
        }
      });


    }
  }


  setCompanyFormData($event: MatAutocompleteSelectedEvent) {
    let companyId = $event.option.value.id;
    let companyName = $event.option.viewValue;
    this.pefaRequestForm.patchValue({

      Company: {
        
        name: companyName,
        id: companyId
        }
      
    });
  }

  onJobKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredJobs = this.jobOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);

    if (this.pefaRequestForm.value.Company.id > 0) {
      let companyId = this.pefaRequestForm.value.Company.id;
      this.filteredJobs = this.filteredJobs.filter(option => option.owner.id == companyId);
    }

    if (this.filteredJobs.length == 1 && filterValue == this.filteredJobs[0].name.toLowerCase()) {
      let jobId = this.filteredJobs[0].id;
      let jobName = this.filteredJobs[0].name;
      this.pefaRequestForm.patchValue({
        PefaJob: {
          Name: jobName,
          Job: {
            id: jobId
          }
        }
      });
    } else {
      this.pefaRequestForm.patchValue({
        PefaJob: {
          Job: {
            id: '0'
          }
        }
      });


    }

  }

  setJobFormData($event: MatAutocompleteSelectedEvent) {
    let jobId = $event.option.value.id;
    let jobName = $event.option.value.name;    
    this.pefaRequestForm.patchValue({

      PefaJob: {
        Name: jobName,
        Job: {
          id: jobId
        }
      }
    });
  }
}
