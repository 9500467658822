<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{noteView.headline}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  
  <mat-card>
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-label>
              Condition Notes
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.otherInfo}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              MC Status
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.status}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Doctor
            </mat-label>
          </div>
          <div class="col">
            <span *ngIf="noteView.details.doctor">{{ noteView.details.doctor.name}} <span *ngIf="noteView.details.doctor.phoneNumber">P: {{noteView.details.doctor.phoneNumber}}</span></span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              MC Advice
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.outcome}}</span><span *ngIf="noteView.details.outcomeNotes"> - {{noteView.details.outcomeNotes}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-label>
              Outcome Date
            </mat-label>
          </div>
          <div class="col">
            <span *ngIf="noteView.details.outcomeDate">{{noteView.details.outcomeDate | date:dateFormat}}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <span><a mat-raised-button matTooltip="MC Not Required" style="text-decoration: none;" (click)="complete()"><mat-icon aria-hidden="false" aria-label="Not Required">block</mat-icon></a></span>
      <span><a mat-raised-button matTooltip="Edit MC" style="text-decoration: none;" (click)="edit()"><mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon></a></span>
      <span><a mat-raised-button matTooltip="Delete MC" style="text-decoration: none;" (click)="delete()"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></a></span>
      <span *ngIf="noteView.details.medicalCertificate"><a mat-raised-button matTooltip="Download Certificate" style="text-decoration: none;" href="/api/PEFARequests/getReport/{{noteView.details.medicalCertificate.id}}" download><mat-icon aria-hidden="false" aria-label="Download">system_update</mat-icon></a></span>
    </mat-card-actions>
  </mat-card>

</mat-expansion-panel>
