<div class="row">
  <div class="col-3" [formGroup]="demandForm">
    <mat-label>{{demand.demandType.name}}</mat-label>&nbsp;
    <mat-icon *ngIf="companyRule != null && companyRule != 'J'" class="link-warn" (click)="onWarnClick()" title="Company rules may apply and override this setting. Contact your administrator if you want more information.">warning</mat-icon>
  </div>
  <div class="col-5 radio-label-vertical-wrapper" style="min-width: 295px;" [formGroup]="demandForm">
    <mat-radio-group formControlName="frequency">
      <mat-label class="radio-label-vertical"><mat-radio-button class="smallRadio" value="N" [disabled]="isDisabled"></mat-radio-button>N</mat-label>
      <mat-label class="radio-label-vertical"><mat-radio-button value="O" [disabled]="isDisabled"></mat-radio-button>O</mat-label>
      <mat-label class="radio-label-vertical"><mat-radio-button value="F" [disabled]="isDisabled"></mat-radio-button>F</mat-label>
      <mat-label class="radio-label-vertical"><mat-radio-button value="C" [disabled]="isDisabled"></mat-radio-button>C</mat-label>
      <mat-label class="radio-label-vertical"><mat-radio-button value="NT" [disabled]="isDisabled"></mat-radio-button>NT</mat-label>
    </mat-radio-group>
  </div>
  <div class="col-1" [formGroup]="demandForm">
    <mat-checkbox *ngIf="demand.demandType.name != 'Balance'" formControlName="optional" labelPosition="before" [disabled]="isDisabled">Optional</mat-checkbox>
  </div>
</div>
