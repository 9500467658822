import { Component, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Notice, NotificationService } from './notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})

export class NotificationsComponent implements OnInit, OnDestroy {

  notices: Notice[] = [];
  display: boolean = false;

  @ViewChild(OverlayPanel)
  private overlay!: OverlayPanel;

  constructor(private hubService: NotificationService) {

  }

  public ngOnInit() {
    this.hubService.getNotice().subscribe(notice => {
      this.notices.push(notice);
    });
  }

  ngOnDestroy(): void {
    this.hubService.disconnect();    
  }

  dismissNotification(index: number) {
    this.notices.splice(index, 1);
    if (this.notices.length == 0) {
      this.overlay.hide();
    }
  }
}
