<ng-template #NoCompanyToList>
  <p *ngIf="loading == false">There are no Bookings from this request</p>
</ng-template>

<div class="container-fluid" *ngIf="bookings.length > 0; else NoCompanyToList">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="provider">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Provider</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.provider else elseName">{{ row.provider.name }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="assessor">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Assessor</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="row.assessor?.user; then app else notUsingApp"></div>
        <ng-template #app>{{row.assessor.firstName}} {{row.assessor.lastName}} - (PA)</ng-template>
        <ng-template #notUsingApp>{{row.assessor.firstName}} {{row.assessor.lastName}}</ng-template>

      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.status}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.bookingType.displayName}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="workerStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Worker Status</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.workerStatus}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="appointmentdate">
      <mat-header-cell *matHeaderCellDef mat-sort-header ng-model="time">Appointment Date</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          {{ row.appointmentDateTime | date:dateFormat }}<br/>
          {{ row.appointmentDateTime | date:timeFormat }} {{ row.appointmentTimezone}}<br/>
          <div style="font-size: 0.8em; background-color: #e1e1e1; border-radius: 4px; padding: 2px; display: inline-block;">My Time: {{ row.localtime }}</div>
        </div>        
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="region">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Booking Location</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.bookingLocation.region}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="notes">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Notes</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.notes}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header class="w-auto">Actions</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row"><app-office-admin-booking-actions [booking]="row" [pefa]="pefaRequest" (updated)="reloadList()"></app-office-admin-booking-actions></mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
