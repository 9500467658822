  <div class="row">
    <div class="col-6"></div>
    <div class="col-3" style="font-size: 0.9em; text-align: center;">Job Demands</div>
    <div class="col-3" style="font-size: 0.9em; text-align: center;">Test Criteria</div>
  </div>
  <div class="row" [formGroup]="occasionalForm">
    <div class="col-6">
      <strong>{{demandType.name}}</strong>&nbsp;<mat-icon *ngIf="companyRule != null && companyRule != 'J'" class="link-warn" (click)="onWarnClick()" title="Company rules have been applied.">warning</mat-icon>
    </div>
    <div class="col-3" style="text-align: center;">
      <div>O</div>
      <mat-form-field>
        <input style="text-align: center;" *ngIf="companyMax != null" matInput type="text" [readonly]="isDisabled" [value]="(occasionalCalcNum > companyMaxNum ? companyMax : occasionalCalc)">
        <input style="text-align: center;" *ngIf="companyMax == null" matInput type="text" formControlName="value" [readonly]="isDisabled">
        <!-- <mat-hint align="end" *ngIf="occasionalCalc != null && occasionalCalc != '' && companyRule != null && companyRule != 'J' && occasionalCalcNum > companyMaxNum">Occasional Equivalent = {{occasionalCalc}}</mat-hint> -->
      </mat-form-field>        
    </div>
    <div class="col-3" style="text-align: center;"><div class="calc-test-criteria" *ngIf="occasionalCalc != null && occasionalCalc != ''">{{occasionalCalc}}</div></div>
  </div>
  <div class="row" [formGroup]="frequentForm">
    <div class="col-6">
    </div>
    <div class="col-3" style="text-align: center;">
      <div>F</div>
      <mat-form-field>
        <input style="text-align: center;" matInput type="text" formControlName="value" [readonly]="isDisabled">
      </mat-form-field>        
    </div>
    <div class="col-3"></div>
  </div>
  <div class="row" [formGroup]="continuousForm">
    <div class="col-6">
    </div>
    <div class="col-3" style="text-align: center;">
      <div>Cont</div>
      <mat-form-field>
        <input style="text-align: center;" matInput type="text" formControlName="value" [readonly]="isDisabled"> 
      </mat-form-field>        
    </div>
    <div class="col-3"></div>
  </div>
