import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BusinessCalendar, Timing } from '../shared/shared-component';
import { ConfigService } from '../config/config.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-business-calendar-time',
  templateUrl: './business-calendar-time.component.html',
})
export class BusinessCalendarTimeComponent {

  @Input()
  public times: Timing[];

  @Input()
  public label: string;

  ngOnInit(): void {
    if (!this.times) {
      this.times = [];
    }
    if (this.times.length == 0) {
      this.addTiming();
    }
  }

  ngDoCheck() {
    if (!this.times) {
      this.times = [];
    }
    if (this.times.length == 0) {
      this.addTiming();
    }
  }

  addTiming(): void {
    
      this.times.push(new Timing("", ""));
        
  }

  removeTiming(time: Timing): void {
    const index = this.times.indexOf(time);

    if (index >= 0) {
      this.times.splice(index, 1);
    }
  }

  constructor(
    public sharedService: ConfigService,
  ) {
  }

}

