<mat-tab-group>
    <mat-tab label="Mapping"> 
        <mat-progress-bar mode="determinate" *ngIf="loadPercent < 100.0" value="{{loadPercent}}"></mat-progress-bar>
        <div class="container-fluid jod-settings">
            <div *ngIf="isStaunchAdmin" class="row">
                <div class="col-12">                    
                    <div style="margin-top: 10px;margin-bottom: 5px;">
                        <strong><img src="/assets/jod.png" height="16px"/> Subscriptions by <img src="/assets/console.svg" height="16px"/> Company / Jobs:</strong>
                    </div>                    
                    <ul *ngIf="jodSubs">
                        <li *ngFor="let sub of jodSubs;">
                            <span class="{{sub.companies != null && sub.companies.length > 0 ? '' : 'disabled'}}">{{sub.Name}}</span>&nbsp;<mat-icon *ngIf="sub.companies == null" class="link-ref" (click)="onLoadSubscription(sub)">autorenew</mat-icon><mat-icon *ngIf="sub.companies != null && sub.companies.length >= 0" class="link-ok">check</mat-icon>
                            <ul>
                                <li *ngFor="let company of sub.companies">
                                    <a [routerLink]="['/office-company-detail', company.id]">{{company.name}}</a>
                                    <ul>
                                        <li *ngFor="let job of company.jobs">
                                            <a [routerLink]="['/office-job-detail', job.id]">{{job.name}}</a> <span *ngIf="jodJobs[job.jodJobId] != null">&nbsp;<mat-icon class="link-jod">link</mat-icon>{{jodJobs[job.jodJobId]}}</span>
                                        </li>
                                    </ul>                            
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="!isStaunchAdmin" class="row">
                <div class="col-12">
                    Access denied.
                </div>
            </div>
        </div>        
    </mat-tab>
    <mat-tab label="Sync">
        <mat-progress-bar mode="determinate" *ngIf="loadPercent < 100.0" value="{{loadPercent}}"></mat-progress-bar>
        <div class="container-fluid jod-settings">
            <div *ngIf="isStaunchAdmin" class="row">
                <div class="col-12">                    
                    <div style="margin-top: 10px;margin-bottom: 5px;">
                        <div class="alert alert-secondary">Syncing with JOD here will take the JOD Job Demands values as the Test Criteria.  To change this use JOD and update the Job PEFA Test Criteria manually.</div>

                        <strong><img src="/assets/jod.png" height="16px"/> Subscriptions by <img src="/assets/jod.png" height="16px"/> Jobs linked to <img src="/assets/console.svg" height="16px"/> Company / Jobs:</strong>
                    </div>                                         
                </div>
                <div class="col-6">
                    <ul *ngIf="jodSubs">
                        <li *ngFor="let sub of jodSubs;">
                            <span class="{{sub.companies != null && sub.companies.length > 0 ? '' : 'disabled'}}">{{sub.Name}}</span>&nbsp;<mat-icon *ngIf="sub.companies == null" (click)="onLoadSubscription(sub)" class="link-ref">autorenew</mat-icon><mat-icon *ngIf="sub.companies != null && sub.companies.length >= 0" class="link-ok">check</mat-icon>
                            <ul>
                                <li *ngFor="let jod_job of sub['jod_jobs']">
                                    <span class="{{consoleJobs[jod_job.Id] != null && consoleJobs[jod_job.Id].length > 0 ? 'clickable' : 'disabled'}} {{selectedJodJob && jod_job.Id == selectedJodJob.Id ? 'selected' : ''}}" (click)="onSelectJob(jod_job, consoleJobs[jod_job.Id] != null && consoleJobs[jod_job.Id].length)">{{jod_job['Name']}}</span>&nbsp;&nbsp;
                                    <mat-icon *ngIf="consoleJobs[jod_job.Id] != null && consoleJobs[jod_job.Id].length > 0 && !jodJobsSync[jod_job.Id]" title="There are some linked jobs that have not been synchronised" class="link-ref2">warning</mat-icon>
                                    <mat-icon *ngIf="consoleJobs[jod_job.Id] != null && consoleJobs[jod_job.Id].length > 0 && jodJobsSync[jod_job.Id]" title="All linked jobs synchronised" class="link-ok">check</mat-icon>
                                    <ul>
                                        <li class="li-blank clickable" (click)="onSelectConsoleJob(console_job)" *ngFor="let console_job of consoleJobs[jod_job.Id]">
                                            <span class="{{selectedConsoleJob && console_job.id == selectedConsoleJob.id ? 'selected' : ''}}"><mat-icon class="link-con">link</mat-icon><a [routerLink]="['/office-company-detail', console_job.owner.id]">{{console_job.owner.name}}</a> / <a [routerLink]="['/office-job-detail', console_job.id]">{{console_job.name}}</a></span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="col-6">
                    <div *ngIf="selectedJodJob">
                        <span style="font-weight: bold;"><img src="/assets/jod.png" height="16px"/>&nbsp; {{ selectedJodJob ? selectedJodJob.Name : '-' }}</span><br/>
                        <a class="link" (click)="onSyncUsingJod()">Sync using this criteria</a>
                        <div class="alert alert-secondary2">
                            <table *ngIf="selectedJodDemands">
                                <tr>
                                    <th colspan="4">PT</th>
                                    <th colspan="2">MH</th>
                                </tr>
                                <tr>
                                    <td>Reach Extended</td>
                                    <td class="td-val">{{selectedJodDemands.data[0].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[0].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Floor Lift</td>
                                    <td class="td-val">{{selectedJodDemands.data[9].Value}}</td>
                                </tr>
                                <tr>
                                    <td>Reach Side</td>
                                    <td class="td-val">{{selectedJodDemands.data[1].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[1].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Bench Lift</td>
                                    <td class="td-val">{{selectedJodDemands.data[10].Value}}</td>
                                </tr>               
                                <tr>
                                    <td>Reach Forward</td>
                                    <td class="td-val">{{selectedJodDemands.data[2].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[2].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Shoulder Lift</td>
                                    <td class="td-val">{{selectedJodDemands.data[11].Value}}</td>
                                </tr>     
                                <tr>
                                    <td>Squat</td>
                                    <td class="td-val">{{selectedJodDemands.data[3].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[3].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Above Shoulder Lift</td>
                                    <td class="td-val">{{selectedJodDemands.data[12].Value}}</td>
                                </tr>
                                <tr>
                                    <td>Kneel</td>
                                    <td class="td-val">{{selectedJodDemands.data[4].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[4].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Carry Bilateral</td>
                                    <td class="td-val">{{selectedJodDemands.data[13].Value}}</td>
                                </tr>
                                <tr>
                                    <td>Stair Climb</td>
                                    <td class="td-val">{{selectedJodDemands.data[5].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[5].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Single Carry Left</td>
                                    <td class="td-val">{{selectedJodDemands.data[14].Value}}</td>
                                </tr>              
                                <tr>
                                    <td>Ladder Climb</td>
                                    <td class="td-val">{{selectedJodDemands.data[6].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[6].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Single Carry Right</td>
                                    <td class="td-val">{{selectedJodDemands.data[15].Value}}</td>
                                </tr>               
                                <tr>
                                    <td>Crawl</td>
                                    <td class="td-val">{{selectedJodDemands.data[7].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[7].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td></td>
                                    <td class="td-val"></td>
                                </tr>        
                                <tr>
                                    <td>Stoop</td>
                                    <td class="td-val">{{selectedJodDemands.data[8].Frequency}}</td>
                                    <td class="td-opt">{{selectedJodDemands.data[8].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td></td>
                                    <td class="td-val"></td>
                                </tr>                                                                                                                                                   
                            </table>
                        </div>                        
                    </div>
                    
                    <div *ngIf="selectedConsoleJob">
                        <span style="font-weight: bold;"><img src="/assets/console.svg" height="16px"/>&nbsp; {{ selectedConsoleJob ? selectedConsoleJob.owner.name + ' / ' + selectedConsoleJob.name : '-' }}</span><br/>
                        <a class="link" (click)="onSyncUsingConsole()">Sync using this criteria</a>
                        <div class="alert alert-secondary2">
                            <table *ngIf="selectedConsoleDemands">
                                <tr>
                                    <th colspan="4">PT</th>
                                    <th colspan="2">MH</th>
                                </tr>
                                <tr>
                                    <td>Reach Extended</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[0].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[0].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Floor Lift</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[9].Value}}</td>
                                </tr>
                                <tr>
                                    <td>Reach Side</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[1].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[1].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Bench Lift</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[10].Value}}</td>
                                </tr>               
                                <tr>
                                    <td>Reach Forward</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[2].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[2].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Shoulder Lift</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[11].Value}}</td>
                                </tr>     
                                <tr>
                                    <td>Squat</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[3].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[3].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Above Shoulder Lift</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[12].Value}}</td>
                                </tr>
                                <tr>
                                    <td>Kneel</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[4].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[4].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Carry Bilateral</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[13].Value}}</td>
                                </tr>
                                <tr>
                                    <td>Stair Climb</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[5].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[5].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Single Carry Left</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[14].Value}}</td>
                                </tr>              
                                <tr>
                                    <td>Ladder Climb</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[6].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[6].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td>Single Carry Right</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[15].Value}}</td>
                                </tr>               
                                <tr>
                                    <td>Crawl</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[7].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[7].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td></td>
                                    <td class="td-val"></td>
                                </tr>        
                                <tr>
                                    <td>Stoop</td>
                                    <td class="td-val">{{selectedConsoleDemands.data[8].Frequency}}</td>
                                    <td class="td-opt">{{selectedConsoleDemands.data[8].Optional == 0 ? '' : '(O)'}}</td>
                                    <td class="td-opt">&nbsp;</td>
                                    <td></td>
                                    <td class="td-val"></td>
                                </tr>                                                                                                                                                   
                            </table>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

    </mat-tab>
  </mat-tab-group>
