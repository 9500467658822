import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Provider, Doctor } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MatDialog } from "@angular/material/dialog";
import { DoctorService } from "./doctor.service";


@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
})

export class DoctorListComponent implements OnInit {

  public doctorService: DoctorService;
  public doctors: Doctor[] = [];

  loading: boolean = true;

  //Material Table
  displayedColumns2 = [
    "name",
    "address",
    "email",
    "phone",
    "fax",
    "preferredContactType",
  ];

  dataSource: MatTableDataSource<Doctor>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadDoctors();
  }

  constructor(public dialog: MatDialog, doctorService: DoctorService, public fb: FormBuilder, public messageService: MessageService) {
    this.doctorService = doctorService;
    this.dataSource = new MatTableDataSource();
  }

  //Material Table
  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadDoctors() {
    this.doctorService.getDoctors().subscribe(
      result => {
        this.doctors = result;
        this.dataSource.data = this.doctors;
        this.loading = false;
      })
  }
}
