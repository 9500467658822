<div [formGroup]="demandsForm">
  <div class="row" formArrayName="demands">
    <div class="col-7">
      <h3>Postural Tolerances</h3>
    </div>
    <div class="col-5">
      <h3>Material Handling</h3>
      <div class="alert alert-secondary" style="font-size: 0.9em">A formula has been applied to the job demands to create the PEFA test weight.</div>
    </div>
  </div>
  <div class="row" formArrayName="demands">
    <div class="col-7">
      <div class="container-fluid">
        <div *ngFor="let demandTypeList of groupedPTTypes; let j=index">
          <div *ngFor="let demandType of demandTypeList[1]; let i=index">
            <app-demand-pt-item [demand]="getDemandForType(demandType)" [demandsFormArray]="demandFormArray" [companyRules]="companyRules" [isDisabled]="isLinkedToJod"></app-demand-pt-item>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="container">
        <div *ngFor="let demandTypeMHList of groupedMHTypes; let j=index">
          <div *ngFor="let demandTypeMH of demandTypeMHList[1]; let i=index" style="border-bottom: 1px solid #ddd;margin-bottom: 10px;">
            <app-demand-mh-item [demands]="getMHDemandsForType(demandTypeMH)" [demandsFormArray]="demandFormArray" [companyRules]="companyRules" [demandType]="demandTypeMH" [isDisabled]="isLinkedToJod"></app-demand-mh-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
