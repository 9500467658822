<div [formGroup]="paymentForm" >
  <div class="col">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" required>
        <mat-option value="CC">Credit Card</mat-option>
        <mat-option value="PO">Purchase Order</mat-option>
        <mat-option value="ACCT">Account</mat-option>
        <mat-option value="other">Other</mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div class="col">
    <mat-form-field>
      <mat-label for="reference">Reference</mat-label>
      <input matInput type="text" formControlName="reference" required />
    </mat-form-field>
  </div>
  <div class="col" *ngIf="!small">
    <mat-form-field>
      <mat-label for="limit">Limit</mat-label>
      <input matInput type="text" formControlName="limit" required />
    </mat-form-field>
  </div>
  <div class="col" *ngIf="!small">
    <mat-form-field>
      <mat-label for="expiry">Expiry</mat-label>
      <input matInput type="text" formControlName="expiry" [matDatepicker]="apptpicker" />
      <mat-datepicker-toggle matSuffix [for]="apptpicker"></mat-datepicker-toggle>
      <mat-datepicker #apptpicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col" *ngIf="!small">
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select formControlName="status" required>
        <mat-option value="ACTIVE">ACTIVE</mat-option>
        <mat-option value="PENDING">PENDING</mat-option>
        <mat-option value="COMPLETED">COMPLETED</mat-option>
        <mat-option value="EXPIRED">EXPIRED</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
