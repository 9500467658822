<ng-template #NoCompanyToList>
  <p *ngIf="!loading">There are no kpis this request</p>
</ng-template>

<div class="container-fluid" *ngIf="kpis.length > 0; else NoCompanyToList">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="delayReason">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Delay Reason</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.delayReason}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.name}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="notes">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Notes</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.notes}}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span><a mat-raised-button matTooltip="Delete KPI" style="text-decoration: none;" (click)="delete(row.id)"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></a></span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

</div>
