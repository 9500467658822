<div [formGroup]="criteriaForm">

  <mat-form-field>
    <mat-label>{{criteria.displayName}}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="{{criteria.parameterName}}" name="picker" placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>


</div>
