import { Component, Inject, Input, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Job, JodJob, DemandType, PEFAJob, JOBFIT_DATETIME_FORMAT } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { MessageService } from '../message/message.service';
import { PEFAJobService } from './pefajob.service';
import { JobService } from '../job/job.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-pefajob-detail',
  templateUrl: './pefajob-detail.component.html',
  styleUrls: ['./pefajob-detail.component.css']
})
export class PEFAJobDetailComponent implements AfterViewInit {
  public pefaJob: PEFAJob;
  public loading: boolean = true;
  public isLinkedToJod: boolean = false;
  public warningNoMatch: boolean = false;
  public warningNoSync: boolean = false;
  public warningNoDemands: boolean = false;
  public successSync: boolean = false;
  public demandTypes: DemandType[];
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;
  JobForm = new FormGroup({
    id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    site: new FormControl('', Validators.required),
    job: new FormGroup({
      id: new FormControl(''),
    name: new FormControl(''),
    }),
  });
  private filterSubject: Subject<string> = new Subject();   
  sharedService: SharedService;

  messageService: MessageService;
  jobOptions: Job[];
  jobOptionsByCompany: Job[];
  filteredJobs: Job[];
  allJodJobs: JodJob[] | null = null;
  filteredJodJobs: JodJob[] | null = null;

  companyId: number = 0;
  jodCompanyId: number | null = null;
  job: Job | null = null;

  selectedJodJob: any | null = null;
  selectedJob: Job | null = null;

  @Output() updated = new EventEmitter<boolean>();

  constructor(
    messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public pefaJobService: PEFAJobService,
    public jobService: JobService,
    private pefaRequestService: OfficeAdminPEFAService,

  ) {
    this.messageService = messageService;
  }

  ngAfterViewInit(): void {
    this.pefaJobService.getPefaJob(this.route.snapshot.paramMap.get('id')).subscribe(results => {
      this.pefaJob = results;
      if (this.pefaJob != null) {
        if (!this.pefaJob.job) {
          delete this.pefaJob.job;
        }
        this.selectedJob = this.pefaJob.job;
        this.JobForm.patchValue(this.pefaJob);
        // this.job = this.pefaJob.job;
        // console.log(this.job);
        //console.log('company', this.pefaJob['pefaRequest']?.company);
        this.companyId = this.pefaJob['pefaRequest']?.company?.id;
        this.jodCompanyId = this.pefaJob['pefaRequest']?.company?.jodCompanyId;
        this.isLinkedToJod = this.pefaJob['pefaRequest']?.company?.jodCompanyId != null && this.pefaJob['pefaRequest']?.company?.jodCompanyId > 0;
        
        //console.log('this.companyId', this.companyId);      
        this.pefaRequestService.GetJobsForCompanyOfJob(this.companyId, this.selectedJob != null ? this.selectedJob.id : null)
          .subscribe(result => {              
            //console.log(result);
            this.jobOptions = result;
            if (this.companyId > 0) {
              this.jobOptionsByCompany = result.filter(e => e.owner && e.owner.id == this.companyId);          
              this.jobOptions = result.filter(e => (e.owner && e.owner.id != this.companyId) || e.owner == null);          
            } else {
              this.jobOptionsByCompany = [];
            }        
            this.filteredJobs = this.jobOptionsByCompany;
          });
  
        this.loading = false;      
  
        if (this.isLinkedToJod && this.jodCompanyId != null) {
          this.pefaRequestService.getJodJobs(this.jodCompanyId.toString()).subscribe(result => {
            this.allJodJobs = result;
            this.filteredJodJobs = this.allJodJobs;
          });      
        }

        // check if Job has Demands?
        this.pefaJobService.hasJobDemands(this.selectedJob != null ? this.selectedJob.id : '-1').subscribe(results => {
          this.warningNoDemands = !results;
        });        
      }      
    });

    // debounce the filtering so we don't overwhelm the server
    this.filterSubject.pipe(debounceTime(800)).subscribe(searchTextValue => {
      this.handleSearch(searchTextValue);
    });    
  }


  saveChanges() {

    var jobValue = this.JobForm.value;
    //console.log(jobValue);

    this.pefaJobService.saveJob(this.route.snapshot.paramMap.get('id'), jobValue).subscribe(update => {
      this.pefaJobService.getPefaJob(this.route.snapshot.paramMap.get('id')).subscribe(results => {
        this.pefaJob = results;
        this.JobForm.patchValue(results);
        this.loading = false;
        this.messageService.add("Job Updated");
        this.updated.emit(true);
      });

    }
    );

  }


  get demands() {
    return this.JobForm.get('demands') as FormArray;
  }


  displayFn(user?: Job): string | undefined {
    return user ? user.name : undefined;
  }

  onJodJobKey(event: KeyboardEvent) {
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (this.allJodJobs != null) {
      this.filteredJodJobs = this.allJodJobs.filter(option => option.Name && option.Name.toLowerCase().indexOf(filterValue) >= 0);
    }    
  }

  onJobKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filterSubject.next(filterValue);
  }

  handleSearch(q) {
    let filterValue = q.toLowerCase();

    // clear search
    if (filterValue == '') {
      this.selectedJob = null;
      this.JobForm.patchValue({
        job: {
          id: '0',
          name: ''
        }
      });
    }

    // first pass - put company related jobs first
    this.filteredJobs = this.jobOptionsByCompany.filter(option => option.name && option.name.toLowerCase().indexOf(filterValue) >= 0);

    if (this.filteredJobs.length == 1 && this.filteredJobs[0].name == filterValue) {
      let jobId = this.filteredJobs[0].id;
      let jobName = this.filteredJobs[0].name;
      this.pefaJob.job = this.filteredJobs[0];

      this.JobForm.patchValue({
          job: {
            id: jobId,
            name: jobName
        },
      });
    } else {
      // second pass - put rest of results then search all company jobs
      this.pefaRequestService.getJobsNotForCompany(this.companyId.toString(), filterValue)        
        .subscribe(results => {
          this.jobOptions = results;

          this.filteredJobs = this.filteredJobs.concat(this.jobOptions);
          if (this.filteredJobs.length == 1 && this.filteredJobs[0].name == filterValue) {
            let jobId = this.filteredJobs[0].id;
            let jobName = this.filteredJobs[0].name;
            this.pefaJob.job = this.filteredJobs[0];
      
            this.JobForm.patchValue({
                job: {
                  id: jobId,
                  name: jobName
              },
            });
          }
      });
    }
  }

  setJodJobData($event: MatAutocompleteSelectedEvent) {
    this.selectedJodJob = $event.option.value;
    // console.log(this.selectedJodJob);

    // now try and auto select the Console Job based on this subscription Job
    this.pefaRequestService.getJobsLinkedToJod(this.companyId, this.selectedJodJob.Id).subscribe(results => {
      if (results.length > 0) {
        // now preselect Console Job
        this.filteredJobs = results;
        let jobId = this.filteredJobs[0].id;
        let jobName = this.filteredJobs[0].name;      
        this.selectedJob = this.filteredJobs[0];
        this.pefaJob.job = this.selectedJob;
        this.warningNoMatch = false;
        this.warningNoSync = this.selectedJob.jodJobLastSync == null;
        this.successSync = this.selectedJob.jodJobLastSync != null;
        this.JobForm.patchValue({
            job: {
              id: jobId,
              name: jobName
          },
        });        
      } else {
        // no linked jobs, display warning
        this.warningNoMatch = true;
        this.warningNoSync = false;
        this.successSync = false;
        this.selectedJob = null;
        this.JobForm.patchValue({
          job: {
            id: '0',
            name: ''
          }
        });        
      }
    });
  }

  setJobFormData($event: MatAutocompleteSelectedEvent) {
    this.selectedJob = $event.option.value;
    let jobId = $event.option.value.id;
    let jobName = $event.option.viewValue;

    //console.log(jobId, jobName);

    this.pefaJob.job = $event.option.value;

    this.JobForm.patchValue({
        job: {
          id: jobId,
          name: jobName
      },      
    });

    // check if Job has Demands?
    this.pefaJobService.hasJobDemands(jobId).subscribe(results => {
      this.warningNoDemands = !results;
    });
  }

  getJodOptionText(option) {
    return option.Name;
  }
}

