import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from "@angular/material/dialog";
import { Assessor } from "../shared/shared-component";
import { AssessorBookingService } from "./assessor-booking.service";


@Component({
  selector: 'app-assessor-list',
  templateUrl: './assessor-list.component.html',
})

export class AssessorListComponent implements OnInit {

  public assessors: Assessor[] = [];

  loading: boolean = true;

  //Material Table
  displayedColumns2 = [
    "name",
  ];

  dataSource: MatTableDataSource<Assessor>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadAssessors();
  }

  constructor(public dialog: MatDialog, public fb: FormBuilder, public messageService: MessageService, public assessorService: AssessorBookingService) {
    this.dataSource = new MatTableDataSource();
  }

  //Material Table
  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadAssessors() {
    this.assessorService.getAssessors().subscribe(
      result => {
        this.assessors = result;
        this.dataSource.data = this.assessors;
        this.loading = false;
      })
  }
}
