<div class="container-fluid p-0 m-0">
  <div class="row p-1 m-0" *ngIf="isJobUpdated">
     <div class="col"><mat-toolbar class="mat-warn">The Job Demands for the underlying job have been updated. Would you like to update the referral demands? &nbsp; <button mat-flat-button matTooltip="Update Demands" [disabled]="refreshing" (click)="updateDemands()">Update Now</button> &nbsp;<button mat-flat-button matTooltip="Dismiss" [disabled]="refreshing" (click)="dismiss()">Dismiss</button></mat-toolbar></div>
  </div>
    <div class="row p-1 m-0">
      <div class="col-12">
        <table style="width: 100%;">
          <tr>
            <td style="width: 140px; font-weight: bold;">Report:</td>
            <td style="width: 10%;"><span>{{pefaRequest.reportStatus?pefaRequest.reportStatus:"No Report"}}</span></td>
            <td style="width: 140px; font-weight: bold;">Company: 
              <span *ngIf="pefaRequest.company != null && pefaRequest.company.bookingNotes != null" class="badge rounded-pill bg-warning text-dark" style="cursor: pointer;" title="See Booking Rules..." (click)="openCompanyBookingNotesDialog()">&nbsp;!&nbsp;</span>&nbsp;
              <span *ngIf="pefaRequest.company != null && pefaRequest.company.jodCompanyId != null" style="cursor: pointer;position: relative;top: -1px;" title="Linked to JOD Subscription" (click)="openCompanyJODNotesDialog()"><img src="/assets/jod.png" height="17px"/></span>
            </td>
            <td><a [routerLink]="['/office-company-detail', (pefaRequest.company != null ? pefaRequest.company.id : -1)]">{{(pefaRequest.company != null ? pefaRequest.company.name : '')}}</a></td>
            <td style="width: 140px; font-weight: bold;">MC Status:</td>
            <td style="width: 10%;"><span>{{pefaRequest.worker.medicalQuestionStatus?pefaRequest.worker.medicalQuestionStatus:'PENDING'}}</span>&nbsp;</td>
            <td style="width: 50px;">
              <span>
                <button mat-flat-button matTooltip="Reload Medical" [disabled]="refreshing" (click)="updateStatus()">
                  <mat-spinner *ngIf="refreshing" diameter="30"></mat-spinner>
                  <mat-icon *ngIf="!refreshing" aria-hidden="false" aria-label="Reload Medical">cached</mat-icon>
                </button>
              </span>              
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Phone:</td>
            <td><span>{{pefaRequest.worker.phoneNumber}}</span></td>
            <td style="font-weight: bold;">Status:</td>
            <td><span style="text-transform:uppercase">{{pefaRequest.status}}</span> - {{pefaRequest.statusNote}}</td>
            <td style="font-weight: bold;">Payment:</td>
            <td colspan="2">{{pefaRequest.paymentStatus}}</td>
          </tr>
        </table>
      </div>
  </div>

  <ng-template #companyBookingNotesTemplate>
    <h3 mat-dialog-title>Booking Rules</h3>
    <mat-dialog-content>
      <div>{{pefaRequest.company.bookingNotes}}</div>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: center;">
      <button mat-button color="primary" mat-dialog-close>OK</button>
    </mat-dialog-actions>
  </ng-template>

  <ng-template #companyJODNotesTemplate>
    <h3 mat-dialog-title>JOD Subscription</h3>
    <mat-dialog-content>
      <div>This company is linked to a JOD Subscription and may have Subscription level PEFA Rules applied to Test Criteria.</div>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: center;">
      <button mat-button color="primary" mat-dialog-close>OK</button>
    </mat-dialog-actions>
  </ng-template>  
