import { Component, Inject, Injectable, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'message-dialog-modal',
  templateUrl: 'message-dialog.component.html',

})
@Injectable()
export class MessageDialog {
  title: string = '';
  message: string = '';

  constructor (
    public dialogRef: MatDialogRef<MessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data.title;
      this.message = data.message;
  }
  public onOkClick() {
    this.dialogRef.close({ outcome: false });
  }
}
