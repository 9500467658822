  <span class="mat-tab-label" *ngIf="isAuthenticated | async">
    <a class="mat-tab-link" [routerLink]='["/authentication/profile"]' title="Manage">Hello {{ userName | async }}</a>
    &nbsp;
    <a class="mat-tab-link" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">Logout</a>
  </span>

  <span class="mat-tab-label" *ngIf="!(isAuthenticated | async)">
    <a class="mat-tab-link" [routerLink]='["/authentication/register"]'>Register</a>
    &nbsp;
    <a class="mat-tab-link" [routerLink]='["/authentication/login"]'>Login</a>
  </span>
