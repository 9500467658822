import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { PrimeIcons } from "primeng/api";
import { map } from "rxjs/operators";
import { MessageService } from "../../message/message.service";
import { PEFARequestService } from "../../pefarequest/pefarequest.service";
import { ConfirmDialog } from "../confirm-dialog.component";
import { NoteDialog } from "../note-dialog.component";
import { JOBFIT_DATETIME_FORMAT, NoteView, PEFARequest } from "../shared-component";
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-note-view-payment',
  templateUrl: './note-view-payment.component.html',
  styleUrls: ['./note-view-list.css']
})

export class NoteViewPaymentComponent {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  @Input()
  noteView: NoteView;

  @Input()
  requestID: string;

  @Output() changed = new EventEmitter<boolean>();

  constructor(public pefaService: PEFARequestService, public dialog: MatDialog, public messageService:MessageService) { }
   

}
