<div class="mat-elevation-z8">
  <mat-table #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.description}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="worker">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pefaRequest.worker.lastName">Worker</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.pefaRequest"><a [routerLink]="['/office-admin-pefa-detail', row.pefaRequest.id]">{{ row.pefaRequest.worker.firstName}} {{ row.pefaRequest.worker.lastName}}</a></span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="company">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pefaRequest.company.name">Company</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.pefaRequest">{{ row.pefaRequest.company.name}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.type}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pefaRequest.responseTime">Priority</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.pefaRequest.responseTime }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="urgent">
      <mat-header-cell *matHeaderCellDef mat-sort-header="urgent">Urgent</mat-header-cell>
      <mat-cell *matCellDef="let row"><span><mat-icon *ngIf="row.urgent" matTooltipClass="class-tooltip" class="mat-warn" aria-hidden="false" aria-label="Important">priority_high</mat-icon></span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.dueDate | date:dateFormat }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="assignedTo">
      <mat-header-cell *matHeaderCellDef mat-sort-header="assignedTo">Assigned To</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.assignedTo?.userName }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
      <mat-cell *matCellDef="let row"><app-todo-actions [todo]="row" (updated)="loadTodos(true)"></app-todo-actions></mat-cell>

    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>

  </mat-table>
</div>

<ng-template #elseName>
  <span>&nbsp;</span>
</ng-template>
