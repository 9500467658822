import { Input, Component, OnInit } from "@angular/core";
import { JOBFIT_DATE_FORMAT, PEFARequest, Provider } from "./shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { PEFAJobService } from "../pefajob/pefajob.service";

@Component({
  selector: 'app-worker-view',
  templateUrl: './worker-view.component.html',
})
export class WorkerViewComponent implements OnInit {

  @Input()
  public requestId: string;

  @Input()
  public provider: Provider;

  public request: PEFARequest;

  public dateFormatting = JOBFIT_DATE_FORMAT;

  constructor(public pefaService: PEFARequestService, public pefaJobService : PEFAJobService
  ) {

  }


  public ngOnInit() {
    this.pefaService.getPEFARequest(this.requestId).subscribe(result => {
      this.request = result;
      this.pefaJobService.getPefaJob(this.requestId).subscribe(job => {
        this.request.pefaJob = job;
      });
      
    })

  }


}
