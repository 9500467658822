<form [formGroup]="pefaRequestForm" (ngSubmit)="onSubmit()">


  <div class="container-fluid" formGroupName="ReferrerDetails">
    <div class="col-sm-12">
      <h2>Referrer Details</h2>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="referrerName">
        Referrer Name
      </label>
      <div class="col-sm-3">
        <input id="referrerName" class="form-control" type="text" formControlName="Name" required>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="form-group row" formGroupName="Company">
      <label class="col-sm-2 col-form-label" for="CompanyName">
        Referrer Company
      </label>
      <div class="col-sm-3">

        <mat-form-field>
          <input id="name" type="text" placeholder="Type or Select a Company" formControlName="name" matInput [matAutocomplete]="auto" (keyup)="onCompanyKey($event)" required>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setCompanyFormData($event)">
            <mat-option *ngFor="let company of filteredCompanies" [value]="company">
              {{company.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <input id="id" type="hidden" class="form-control" formControlName="id">
      </div>
    </div>
  </div>
  <div class="container-fluid" formGroupName="ReferrerDetails">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="referrerSite">
        Referrer Site
      </label>
      <div class="col-sm-3">
        <input id="referrerSite" class="form-control" type="text" formControlName="Site">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="Position">
        Referrer Position
      </label>
      <div class="col-sm-3">
        <input id="Position" class="form-control" type="text" formControlName="Position" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="Phone">
        Best Phone
      </label>
      <div class="col-sm-3">
        <input id="Phone" class="form-control" type="text" formControlName="Phone" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="RequesterEmail">
        Referrer Email
      </label>
      <div class="col-sm-3">
        <input id="RequesterEmail" class="form-control" type="email" formControlName="Email" required>
      </div>
    </div>
  </div>


  <div class="container-fluid" formGroupName="Worker">
    <div class="col-sm-12">
      <h2>Participant Details</h2>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="FirstName">
        First Name
      </label>
      <div class="col-sm-3">
        <input id="FirstName" type="text" class="form-control" formControlName="firstName" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="LastName">
        Last Name
      </label>
      <div class="col-sm-3">
        <input id="LastName" type="text" class="form-control" formControlName="lastName" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="emailAddress">
        Email
      </label>
      <div class="col-sm-3">
        <input id="emailAddress" type="text" class="form-control" formControlName="email" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="dateOfBirth">
        Date Of Birth
      </label>
      <div class="col-sm-3">

        <mat-form-field>
          <input matInput id="dateOfBirth" [max]="minDate" class="form-control" type="text" [matDatepicker]="dobPicker" formControlName="dateOfBirth">
          <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
          <mat-datepicker #dobPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="gender">
        Sex at Birth
      </label>
      <div class="col-sm-3">
        <select id="gender" class="form-control" formControlName="gender" required>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="mobile">
        Mobile
      </label>
      <div class="col-sm-3">
        <input id="mobile" type="text" class="form-control" formControlName="phoneNumber" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="home">
        Home Phone Number
      </label>
      <div class="col-sm-3">
        <input id="home" type="text" class="form-control" formControlName="home">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="work">
        Work Phone Number
      </label>
      <div class="col-sm-3">
        <input id="work" type="text" class="form-control" formControlName="work">
      </div>
    </div>
  </div>

  <div class="container-fluid" formGroupName="PefaJob">


    <div class="col-sm-12">
      <h2>Job Details</h2>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="JobSite">
        Job Site
      </label>
      <div class="col-sm-3">
        <input id="JobSite" type="text" class="form-control" formControlName="Site">
      </div>
    </div>

    <div class="form-group row">

      <label class="col-sm-2 col-form-label" for="name">
        Job Title
      </label>
      <div class="col-sm-3">
        <mat-form-field>
          <input id="name" type="text" placeholder="Type or Select a job" formControlName="Name" matInput [matAutocomplete]="autoJob" size="50" (keyup)="onJobKey($event)">
          <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="setJobFormData($event)" class="bigPanel">
            <mat-option *ngFor="let job of filteredJobs" [value]="job">
              <ng-container>{{job.name}} | {{job.owner.name}}</ng-container>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row" formGroupName="Job">
      <input id="id" type="hidden" class="form-control" formControlName="id">

    </div>

  </div>


  <div class="container-fluid">
    <div class="col-sm-12">
      <h2>Referral Details</h2>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="Recruiter">
        Recruiter
      </label>
      <div class="col-sm-3">
        <input id="Recruiter" class="form-control" type="text" formControlName="Recruiter">
      </div>
    </div>
    <div class="form-group row">
      <mat-label class="col-sm-2 col-form-label" for="AppointmentDate">
        Appointment Due Date
      </mat-label>
      <div class="col-sm-3">
        <mat-form-field>
          <input matInput id="AppointmentDate" [min]="minDate" class="form-control" type="text" [matDatepicker]="appointmentPicker" formControlName="AppointmentDate">
          <mat-datepicker-toggle matSuffix [for]="appointmentPicker"></mat-datepicker-toggle>
          <mat-datepicker #appointmentPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="ResponseTime">
        Report Due Date
      </label>
      <div class="col-sm-3">
        <select id="ResponseTime" class="form-control" formControlName="ResponseTime" required>
          <option value="Standard">Standard - 2 Business Days from Appt</option>
          <option value="Urgent">Urgent - Same Business Day as Appt (extra $56 charge)</option>
        </select>
      </div>
    </div>

    <div class="form-group row" formGroupName="ReferralType">
      <label class="col-sm-2 col-form-label" for="ReferralType">
        Referral Type
      </label>
      <div class="col-sm-3">
        <select id="ReferralType" class="form-control" formControlName="id" required>
          <option *ngFor="let type of referralTypeOptions" value="{{type.id}}">{{type.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="PaymentMethod">
        Payment Method
      </label>
      <div class="col-sm-3">
        <mat-form-field appearance="outline">
          <mat-select #paymentMethod matInput id="PaymentMethod" formControlName="paymentMethod">
            <mat-option id="WorkerPay" value="Worker">Worker Pay</mat-option>
            <mat-option id="CC" value="CC">Company Credit Card</mat-option>
            <mat-option id="PO" value="PO">Company Purchase Order</mat-option>
            <mat-option id="ICC" value="ICC">Company Individual Credit Card</mat-option>
            <mat-option id="IPO" value="IPO">Company Individual Purchase Order</mat-option>
            <mat-option id="ACCT" value="ACCT">Account</mat-option>
            <mat-option id="Other" value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="paymentMethod.selected?.value == 'Worker' || paymentMethod.selected?.value == 'ICC' || paymentMethod.selected?.value == 'IPO'" class="form-group row">
      <label class="col-sm-2 col-form-label" for="PaymentMethod">
        Payment Details
      </label>
      <div class="col-md-6">
        <app-paymenttype-entry #workerPayment></app-paymenttype-entry>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="otherInfo">
        Other Notes
      </label>
      <div class="col-sm-3">
        <textarea id="otherInfo" class="form-control" formControlName="otherInfo">
        </textarea>
      </div>
    </div>

    <ul>
      <li class="mat-error" *ngFor="let error of errors">{{error}}</li>
    </ul>

    <div class="form-group row col-sm-12">
      <button class="mat-button mat-primary" mat-raised-button [disabled]="!pefaRequestForm.valid" type="submit">Request</button>
    </div>

  </div>
</form>
