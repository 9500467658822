<div style="margin-top: 15px;" *ngIf="!loading">
  <form [formGroup]="JobForm" (ngSubmit)="saveChanges()" class="w-100">

    <div class="row">
      <div class="col-5">
        <input type="hidden" formControlName="id" />
        <div class="col form-group">
          <mat-form-field>
            <mat-label for="name">Referred Job</mat-label>
            <input matInput type="text" formControlName="name" required />
          </mat-form-field>
        </div>
      </div>
      <div class="col-5">
        <div class="col form-group">
          <mat-form-field>
            <mat-label for="description">Description</mat-label>
            <input matInput type="text" formControlName="description">
          </mat-form-field>
        </div>
      </div>
      <div class="col-2"></div>      
    </div>
    <div class="row">
      <div class="col-5">
        <div class="col form-group">
          <mat-form-field>
            <mat-label for="site">Site</mat-label>
            <input matInput type="text" formControlName="site">
          </mat-form-field>
        </div>
      </div>
      <div class="col-5">
        <div class="col form-group" formGroupName="job">
          <div *ngIf="isLinkedToJod" class="warn-message">
            <mat-icon class="nonlink-help">help</mat-icon> Please set the Console Job that matches the Referred Job.<br/>The Subscription Job List can assist in narrowing down your options.
          </div>           
          <div *ngIf="isLinkedToJod && this.filteredJodJobs == null" style="margin-bottom: 15px;">
            <span style="position: relative;top: -1px;font-size: 0.8em;color: #999;" title="Linked to JOD Subscription"><img src="/assets/jod.png" height="17px"/>&nbsp;Subscription Job list is loading, please wait...</span>
          </div>
          <div *ngIf="isLinkedToJod && this.filteredJodJobs != null">
            <span style="position: relative;top: -1px;font-size: 0.8em;" title="Linked to JOD Subscription"><img src="/assets/jod.png" height="17px"/>&nbsp;Choose from subscription Job list:</span>
            <div>
              <mat-form-field class="bigPanel">
                <mat-label for="jodname">Subscription Job</mat-label>
                <input id="jodname" type="text" placeholder="Type or Select a job" matInput [matAutocomplete]="autoJodJob" (keyup)="onJodJobKey($event)" class="bigPanel">
                <mat-autocomplete #autoJodJob="matAutocomplete" (optionSelected)="setJodJobData($event)" [displayWith]="getJodOptionText" panelWidth="700px">
                  <mat-option *ngFor="let job of filteredJodJobs" [value]="job">{{job.Name}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div style="font-size: 0.8em;margin-bottom: 10px;">
              or explore other Console Jobs:
            </div>
          </div>
  
          <input type="hidden" formControlName="id">
          <mat-form-field class="bigPanel">
            <mat-label for="name">Console Job</mat-label>
            <input id="name" type="text" placeholder="Type or Select a job" formControlName="name" matInput [matAutocomplete]="autoJob" (keyup)="onJobKey($event)" class="bigPanel">
            <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="setJobFormData($event)" panelWidth="700px">
              <mat-option *ngFor="let job of filteredJobs" [value]="job" style="height:60px;line-height: 1.0em;">
                <div style="font-size: 0.9em;font-weight: bold;">{{job.name}} <span *ngIf="job.jodJobId != null"><img src="/assets/jod.png" height="17px"/></span></div><div style="font-size: 0.8em;">{{job.owner?job.owner.name:''}}</div>
                <div style="font-size: 0.7em;color:#747474;">Last Updated {{ job.audit?.lastModifiedDate | date:dateFormat }}</div>
              </mat-option>
            </mat-autocomplete>
            <div *ngIf="selectedJob != null" style="text-align: right;">
              <a [routerLink]="['/office-job-detail', selectedJob.id]" target="_blank">open...</a>
            </div>
          </mat-form-field>
          <div class="alert alert-danger" *ngIf="warningNoDemands" style="font-size: 0.9em; padding: 8px;">Warning: <strong>Missing Job Demands</strong>.  Please confirm Job is correct.</div>
          <div *ngIf="warningNoMatch" class="warn-message">
            <mat-icon class="nonlink-warn">warning</mat-icon> No link was found between JoD and Console.
          </div>
          <div *ngIf="warningNoSync" class="warn-message">
            <mat-icon class="nonlink-warn">warning</mat-icon> This Console Job has not been synced with JoD.
          </div>      
          <div *ngIf="successSync" class="warn-message">
            <mat-icon class="nonlink-ok">check</mat-icon> This Console Job was synced with JoD at {{this.selectedJob.jodJobLastSync | date:dateFormat}}.
          </div>              
        </div>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-5">
        <div class="col form-group">
          <button mat-raised-button class="mat-primary" type="submit">Save Changes</button>
        </div>
      </div>
      <div class="col-5"></div>
      <div class="col-2"></div>
    </div>
  </form>
</div>
