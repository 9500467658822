import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizeService } from '../../api-authorization/authorize.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  public isAuthenticated: Observable<boolean>;
  public isOfficeAdmin: boolean;


  constructor(private authorizeService: AuthorizeService) { }

  ngOnInit() {
      this.isAuthenticated = this.authorizeService.isAuthenticated();

    this.authorizeService.getUser().subscribe(result => {
      this.isOfficeAdmin = result.profile.role.filter(r => r == "OFFICE ADMIN").length > 0;      
    });

  }
}
