<!--<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0">-->


  <!--<nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-dark flex-md-nowrap p-0" id="Head-Navbar">-->
  <mat-toolbar id="Head-Navbar">

    <!--<button  class="btn btn-info"  mat-button (click)="toggle()">
    <span>Admin</span>
  </button>-->
    <mat-toolbar-row>
      <button class="btn pull-xs-left" id="sidebarCollapse" type="button" (click)="toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <span class="mat-background-primary mat-h2 mat-list-text" style="color: #d03338;width: 135px;" href="#"><img src="/assets/console.svg" style="height: 24px;"/> Console</span>
      <span></span>
      <mat-list style="display:flex;">
        <a mat-list-item [routerLink]='["/"]'>Home</a>
          <a mat-list-item href="https://www.jobfitsystem.com">Website</a>
          <a mat-list-item href="https://www.jobfitsystemondemand.com">JOD</a>
          <a mat-list-item href="https://training.jobfitsystem.com">Training</a>
      </mat-list>
      <span style="flex: 1 1 auto;"></span>
      <!--Temp placeholder for Office-Admin link-->
      <!--<li class="navbar-nav"  *ngIf="(isAuthenticated | async)">
    <a class="nav-link" [routerLink]='["/pefaupload-admin"]' (click)='collapse()'>dev test page</a>
  </li>-->
      <!---->
      <app-notifications></app-notifications>
      <app-login-menu></app-login-menu>
    </mat-toolbar-row>
  </mat-toolbar>

