import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, concat, from, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators'
import { log } from 'util';
import { PEFARequest, Job, Note, JobDemand, Message, Booking, Attachment, MedicalClearance, Payment, QualityControl, KPI, Company } from '../shared/shared-component';
import { MessageService } from '../message/message.service';


  /*
ApplicationUser RequestBy
Worker Worker
Company Company
Job Job
*/



@Injectable({
  providedIn: 'root'
})
export class PEFARequestService {


  
  

  private client: HttpClient;
  private baseURL: string;
  private messageService: MessageService;
  private requestURL = 'api/PEFARequests'
  private jobURL = 'api/Company/GetJobs/'

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, messageService:MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
    this.messageService = messageService;
  }

  getReportReadyToDo(id: any): Observable<string> {
    return this.client.get<string>(this.baseURL + this.requestURL + '/getReportReadyTodo/' + id)
      .pipe(
        catchError(this.handleError)
      );
  }

  downloadAttachment(id: any): any {

    return this.client.get(this.baseURL + this.requestURL + '/getReport/' + id, { responseType: 'blob', observe:'response' })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteAttachment(pefaId: any, id: any, message: string): any {
    return this.client.delete(this.baseURL + this.requestURL + '/' + pefaId + '/Attachment/' + id + '?message=' + message)
      .pipe(
        catchError(this.handleError)
      );
  }

  generateForm(key: string): Observable<string> {
    var formId = this.client.post<string>(this.baseURL + this.requestURL + "/GenerateForm", key)
      .pipe(
        catchError(this.handleError)
      );

    this.messageService.add("Referral Created");

    return formId;
  }


  public createNewPEFARequest(pefaRequest: PEFARequest): Observable<PEFARequest> {
    var pefa = this.client.post<PEFARequest>(this.baseURL + this.requestURL, pefaRequest)
      .pipe(
        catchError(this.handleError)
    );

    this.messageService.add("Referral Created");

    return pefa;

  }

  releaseReport(pefaId: string) {
    return this.client.put<PEFARequest>(this.baseURL + this.requestURL + "/" + pefaId + "/ReleaseReport", "")
      .pipe(
        catchError(this.handleError)
    );
  }


  sendMessage(pefaId: any, value: any) : Observable<Message> {

    var message = this.client.post<Message>(this.baseURL + this.requestURL + '/' + pefaId + '/Message/', value).pipe(
        catchError(this.handleError)
      );

    this.messageService.add("Message Sent");

    return message;

  }

  removeNote(pefaId: string, noteId: string, message:string): Observable<any> {
    return this.client.delete<any>(this.baseURL + this.requestURL + "/" + pefaId + "/Note/" + noteId)
      .pipe(
        catchError(this.handleError)
      );
  
  }

  saveQA(pefaId: any, values: any) {
    return this.client.post<QualityControl>(this.baseURL + this.requestURL + '/' + pefaId + '/QA/', values)
      .pipe(
        catchError(this.handleError)
      );
  }

  loadLatestQA(pefaId: any, qcId): Observable<QualityControl> {
    return this.client.get<QualityControl>(this.baseURL + this.requestURL + '/' + pefaId + '/QA/' + qcId)
      .pipe(
        catchError(this.handleError)
      );
  }


  getPEFAMessages(id: string) : Observable<Message[]> {
    return this.client.get<Message[]>(this.baseURL + this.requestURL + "/" + id + "/Message")
      .pipe(
        catchError(this.handleError)
      );  }

  getPEFABookings(id: string): Observable<Booking[]> {
    return this.client.get<Booking[]>(this.baseURL + this.requestURL + "/" + id + "/Booking")
      .pipe(
        catchError(this.handleError)
      );
  }

  getPEFAPayments(id: string): Observable<Payment[]> {
    return this.client.get<Payment[]>(this.baseURL + this.requestURL + "/" + id + "/Payments")
      .pipe(
        catchError(this.handleError)
      );
  }


  getPEFANotes(id: string): Observable<Note[]> {
    return this.client.get<Note[]>(this.baseURL + this.requestURL + "/" + id + "/Note")
      .pipe(
        catchError(this.handleError)
      );
  }

  getPEFAClearances(id: string): Observable<MedicalClearance[]> {
    return this.client.get<MedicalClearance[]>(this.baseURL + this.requestURL + "/" + id + "/Clearance")
      .pipe(
        catchError(this.handleError)
      );
  }


  getPEFAKPIs(id: string): Observable<KPI[]> {
    return this.client.get<KPI[]>(this.baseURL + this.requestURL + "/" + id + "/Kpi")
      .pipe(
        catchError(this.handleError)
      );
  }

  deletePEFAKPI(id: string, pefaId: string): Observable<KPI> {

    return this.client.delete<KPI>(this.baseURL + this.requestURL + "/" + pefaId + "/Kpi/" + id)
      .pipe(
        catchError(this.handleError)
      );

  
  }


  getPEFAAttachments(id: string): Observable<Attachment[]> {
    return this.client.get<Attachment[]>(this.baseURL + this.requestURL + "/" + id + "/Attachment")
      .pipe(
        catchError(this.handleError)
      );
  }


  cancelRequest(pefaId: string, message: string) {
    var formData = new FormData();
    formData.append('message', message);
    return this.client.put<PEFARequest>(this.baseURL + this.requestURL + "/" + pefaId + "/Cancel/", formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  activateRequest(pefaId: string) {
    return this.client.put<PEFARequest>(this.baseURL + this.requestURL + "/" + pefaId + "/Active", "")
      .pipe(
        catchError(this.handleError)
      );
  }

  public getRequests(): Observable<PEFARequest[]> {
    return this.client.get<PEFARequest[]>(this.baseURL + this.requestURL)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getJobs(): Observable<Job[]> {
    return this.client.get<Job[]>(this.baseURL + this.jobURL)
      .pipe(
        catchError(this.handleError)
    );


  }

  public loadPefaNote(pefaId: string , noteId: string ): Observable<Note> {
    return this.client.get<Note>(this.baseURL + this.requestURL + '/' + pefaId + '/Note/' + noteId)
      .pipe(
        catchError(this.handleError)
      );


  }


  public savePefaNote(pefaId: string, note: any): Observable<Note> {
    return this.client.post<Note>(this.baseURL + this.requestURL + '/' + pefaId + '/Note/', note)
      .pipe(
        catchError(this.handleError)
      );


  }

  public savePEFARequest(pefaRequest: PEFARequest, id:string): Observable<PEFARequest> {
    console.log("Saving request " + JSON.stringify(pefaRequest));

    return this.client.put<PEFARequest>(this.baseURL + this.requestURL + '/' + id, pefaRequest)
      .pipe(
        catchError(this.handleError)
      );

  }

  public saveMedicalClearance(form: FormData) {
    //formData.append('clearances', JSON.stringify(form.clearances));
    return this.client.post<PEFARequest>(this.baseURL + this.requestURL + "/uploadMedicalClearance", form).pipe(
      catchError(this.handleError)
    );
  }

  public getPEFARequest(id: string): Observable<PEFARequest> {
    const url = this.baseURL + this.requestURL + "/" + id;
    return this.client.get<PEFARequest>(this.baseURL + this.requestURL + "/" + id)
      .pipe(
        catchError(this.handleError)
    );
  }

  public getJobDemands(id: string): Observable<JobDemand[]> {
    const url = this.baseURL + this.requestURL + "/" + id;
    return this.client.get<JobDemand[]>(this.baseURL + this.requestURL + "/" + id + "/JobDemands")
      .pipe(
        catchError(this.handleError)
      );
  }

  public getCompany(pefaId: string): Observable<Company> {
    return this.client.get<Company>(this.baseURL + this.requestURL + '/' + pefaId + '/Company/')
      .pipe(
        catchError(this.handleError)
    );
  }

  public getJotFormDetails(pefaId: string): Observable<any> {
    return this.client.get<any>(this.baseURL + this.requestURL + '/' + pefaId + '/JotForm/')
      .pipe(
        catchError(this.handleError)
    );
  }  

  public getBookingDiagnostics(bookingId: string): Observable<any> {
    return this.client.get<any>(this.baseURL + this.requestURL + '/' + bookingId + '/BookingDiagnostics/')
      .pipe(
        catchError(this.handleError)
    );
  }    

  public getResultsSnapshot(bookingId: string): any {
    return this.client.get(this.baseURL + this.requestURL + '/downloadSnapshot/' + bookingId, {
      responseType: 'blob',
      observe: 'response'
    });
  }     

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    };

    public cancelPEFABooking(bookingId: string, reason: string, noshow: boolean): Observable<any> {
        const formData = new FormData();
      formData.append('Id', bookingId);
        formData.append('Reason', reason);
        formData.append('NoShow', JSON.stringify(noshow));
        return this.client.post<any>(this.baseURL + 'api/PEFARequests/cancelPEFABooking', formData)
  }





  public uploadResults(files: File, pefaId: string, bookingId: string, complete: boolean, comment: string) {
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('comment', comment);
    formData.append('complete', complete.toString());

    return this.client.post<any>(this.baseURL + 'api/PEFARequests/' + pefaId + "/Results/" + bookingId, formData).pipe(
      catchError((res: HttpErrorResponse) => {
        switch (res.status) {
          case 200: //upload successful
            //do appropriate calls for success upload
            this.messageService.add("Results '" + fileToUpload.name + "' Upload was Successful");
            console.log("File upload has been successful:" + res.statusText);
            break;
          case 404: //PEFAid not found!  redirect?
            console.log(res.statusText);
            break;
          case 500: //Server error response failed
            this.messageService.add("Result " + fileToUpload.name + " Upload was unsuccessful");
            console.log("Server error upload not successful:" + res.statusText);
            break;
        }
        return throwError(res);
      })
    )
  };

  addAttachment(pefaId: string, fileToUpload: File, filename:string, type:string) {

    var form = new FormData();

    form.append("pefaId", pefaId);
    form.append('file', fileToUpload, filename);
    form.append('type', type);

    return this.client.post<PEFARequest>(this.baseURL + this.requestURL + "/" + pefaId + "/Attachments", form).pipe(
      catchError(this.handleError)
    );

  }

  generateMedicalClearanceLetter(pefaId: string, formData: FormData): any {
    return this.client.post(this.baseURL + this.requestURL + "/" + pefaId + "/MedicalClearance/Generate", formData, {
      responseType: 'blob' as 'json', observe: 'response'}).pipe(
      catchError(this.handleError)
    );
    
  }

  downloadPIReport(id: any): any {

    return this.client.get(this.baseURL + this.requestURL + '/' + id + '/PIReport', { responseType: 'blob', observe: 'response' })
      .pipe(
        catchError(this.handleError)
      );
  }



}
