import { FormControl, Validators, FormGroup, FormArray } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MessageService } from "../message/message.service";
import { SharedService } from "./shared.service";
import { PEFARequest, Person } from "./shared-component";


@Component({
  selector: 'dialog-modal-update-results',
  templateUrl: 'pefa-upload-results-dialog.component.html',
})
export class UploadResultsDialog implements OnInit {


  comment: string;
  complete: boolean = true;
  request: PEFARequest;
  isUploading: boolean = false;

  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<UploadResultsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pefaService: PEFARequestService, public messageService: MessageService, public sharedService: SharedService) {
  }



  ngOnInit(): void {

    this.pefaService.getPEFARequest(this.data.pefaId).subscribe(pefa => this.request = pefa);

  };

 
  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(files: File): void {
    // we should only be able to click this once
    if (!this.isUploading) {
      this.isUploading = true;
      var pefaId = this.data.pefaId;

      console.log("Filename :" + files[0].name);
      console.log(this.complete);
      console.log(this.comment);
  
      let fileToUpload = <File>files[0];
  
      this.pefaService.uploadResults(files, pefaId, this.data.bookingId, this.complete, this.comment).subscribe(() => {
          this.messageService.add("Results successfully saved");
          this.dialogRef.close();
          this.isUploading = false;
        }
      );
  
    }
  }

}
