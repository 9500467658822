<div class="container-fluid" *ngIf="calendar">
  <div>
    <div>
      <h1>Business Calendar</h1>
    </div>
  </div>
  <div class="row">

    <div class="col">
      <mat-form-field>
        <mat-label>Holidays</mat-label>
        <mat-chip-list #holidayChips>
          <mat-chip *ngFor="let holiday of holidays" selectable="true"
                    removable="true" (removed)="removeHoliday(holiday)">
            {{holiday}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input type="date"
                 [matChipInputFor]="holidayChips"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addHoliday($event)">
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>

  <app-business-calendar-time #monday [times]="calendar.monday" label="Monday"></app-business-calendar-time>
  <app-business-calendar-time #tuesday [times]="calendar.tuesday" label="Tuesday"></app-business-calendar-time>
  <app-business-calendar-time #wednesday [times]="calendar.wednesday" label="Wednesday"></app-business-calendar-time>
  <app-business-calendar-time #thursday [times]="calendar.thursday" label="Thursday"></app-business-calendar-time>
  <app-business-calendar-time #friday [times]="calendar.friday" label="Friday"></app-business-calendar-time>
  <app-business-calendar-time #saturday [times]="calendar.saturday" label="Saturday"></app-business-calendar-time>
  <app-business-calendar-time #sunday [times]="calendar.sunday" label="Sunday"></app-business-calendar-time>
  <div>
    <button mat-button (click)="saveChanges()">Save</button>
  </div>

</div>
