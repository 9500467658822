  <div class="row">
    <div class="col text-center">
      <h1 mat-dialog-title>Generate Medical Clearance Letter</h1>
    </div>
  </div>

  <div mat-dialog-content class="container">
    <form [formGroup]="medicalClearanceForm">
      <div>
        <mat-label>Select Conditions for this clearance</mat-label>
        <div class="text-left" formArrayName="clearances">
          <div class="row">
            <div class="col-3">
            </div>
            <div class="col-3">
              <mat-label>Doctor</mat-label>
            </div>
            <div class="col-3">
              <mat-label>Condition Notes</mat-label>
            </div>
          </div>

          <div class="row" *ngFor="let clearance of clearances.controls; let j=index" [formGroupName]="j">
            <div class="col-3">
              <mat-checkbox formControlName="selected"
                            [labelPosition]="after">
                {{clearance.controls.condition.value}}
              </mat-checkbox>
              <input type="hidden" formControlName="id" />
            </div>
            <div class="col-3">
              <p>{{clearance.controls.doctor.controls.name.value}}</p>
            </div>
            <div class="col-3">
              <p>{{clearance.controls.otherInfo.value}}</p>
            </div>
          </div>
          <mat-divider></mat-divider>

        </div>
      </div>

      <div class="row">


      </div>

    </form>

  </div>

      <mat-dialog-actions>
        <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Cancel</button>
        <button mat-raised-button color="primary" style="margin-top: 1rem;" [disabled]="generating" mat-button (click)="onSubmit()" type="submit">Generate</button>
        <div *ngIf="generating" class="spinner-container">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
</mat-dialog-actions>
