<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <h1 mat-dialog-title>Invoice {{data.bookingId?"Booking":"PEFA Request"}}</h1>
    </div>
  </div>
</div>


<div mat-dialog-content class="container-fluid">
  <div class="row">
    <div class="col">
      <form [formGroup]="invoiceForm">

        <input id="PefaID" class="form-control" type="hidden" formControlName="PefaID" required>
        <div *ngIf="data.bookingId" class="form-group row">
          <label class="col col-form-label" for="PaymentParty">
            Payment Party
          </label>
          <div class="col">
            <mat-select id="PaymentParty" class="form-control" formControlName="PaymentParty">
              <mat-option value="Client">Client</mat-option>
              <mat-option value="Provider">Provider</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col col-form-label" for="InvoiceRef">
            Invoice Reference
          </label>
          <div class="col">
            <input id="InvoiceRef" class="form-control" type="text" formControlName="InvoiceRef" required>
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label" for="InvoiceDate">
            Invoice Date
          </label>
          <div class="col">
            <mat-form-field>
              <input id="InvoiceDate" matInput [matDatepicker]="dp" class="form-control" type="text" formControlName="InvoiceDate" required>
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label" for="PaymentRef">
            Payment Reference
          </label>
          <div class="col">
            <input id="PaymentRef" class="form-control" type="text" formControlName="PaymentReference" required>
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label" for="PaymentType">
            Payment Type
          </label>
          <div class="col">
            <mat-select id="PaymentType" class="form-control" formControlName="PaymentType">
              <mat-option value="CC">Credit Card</mat-option>
              <mat-option value="PO">Purchase Order</mat-option>
              <mat-option value="OTHER">Other</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label" for="Amount">
            Amount
          </label>
          <div class="col">
            <input id="Amount" class="form-control" type="text" formControlName="Amount" required>
          </div>
        </div>

        <div class="form-group row">
          <label class="col col-form-label" for="Currency">
            Currency
          </label>
          <div class="col">
            <input id="Currency" class="form-control" type="text" formControlName="Currency" required>
          </div>
        </div>

        <div class="form-group row">
          <label class="col col-form-label" for="Reason">
            Other Information
          </label>
          <div class="col">
            <input id="Reason" class="form-control" type="text" formControlName="Reason">
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label" for="partial">
            Partial Payment
          </label>
          <div class="col">
            <mat-checkbox id="partial" class="form-control" formControlName="IsPartial"></mat-checkbox>
          </div>
        </div>

      </form>
    </div>
    <div class="col">
      <app-bookings-view [requestId]="data.pefaId" [bookingId]="data.bookingId"></app-bookings-view>
    </div>
  </div>


</div>

<div mat-dialog-actions style="margin-top: 2rem;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <button mat-raised-button color="primary" (click)="onNoClick(false)">Exit</button>
      </div>
      <div class="col-6 text-center">
      </div>
      <div class="col-4">
        <!--<button mat-raised-button color="warn" [mat-dialog-close]="reason" [disabled]="false" cdkFocusInitial (click)="onYesClick()">Cancel booking</button>-->
        <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="onSubmit()"  [disabled]="!invoiceForm.valid">Raise Invoice</button>
      </div>
    </div>
  </div>
</div>
