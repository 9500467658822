<form [formGroup]="pefaRequestForm" (ngSubmit)="saveRequest()">
  <mat-accordion *ngIf="pefaRequest" class="container-fluid" multi="true">

    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <h4>Participant Details</h4>
      </mat-expansion-panel-header>
      <input id="status" type="hidden" formControlName="status">
      <input id="type" type="hidden" formControlName="type">
      <input id="companyFeesInc" type="hidden" formControlName="companyFeesInc">
      <input id="companyFeesCurrency" type="hidden" formControlName="companyFeesCurrency">
      <input id="medicalQuestionStatus" type="hidden" formControlName="medicalQuestionStatus">
      <input id="requestedTime" type="hidden" formControlName="requestedTime">
      <input id="completedTime" type="hidden" formControlName="completedTime">
      <input id="location" type="hidden" formControlName="location">
      <input id="requesterName" type="hidden" formControlName="requesterName">


      <div class="container-fluid" formGroupName="worker">
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              First Name
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <input matInput id="FirstName" type="text" formControlName="firstName" required>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Last Name
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="LastName" type="text" formControlName="lastName">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Email
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="emailAddress" type="text" formControlName="email">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Date Of Birth
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="dobpicker" id="dateOfBirth" formControlName="dateOfBirth">
              <mat-datepicker-toggle matSuffix [for]="dobpicker"></mat-datepicker-toggle>
              <mat-datepicker #dobpicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Biological Sex
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-select id="gender" class="form-control" formControlName="gender">
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Weight
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <input matInput id="weight" type="text" formControlName="weight">
            </mat-form-field>
          </div>
        </div>


        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Mobile
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="mobile" type="text" formControlName="phoneNumber">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Home Phone Number
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="home" type="text" formControlName="home">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Work Phone Number
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="work" type="text" formControlName="work">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Preferred Test Location
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="work" type="text" formControlName="preferredTestLocation">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Availability
            </mat-label>
          </div>
          <div class="col" formGroupName="availability">
            <mat-form-field appearance="outline">

              <input matInput id="work" type="text" formControlName="notes">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <h4>Referrer Details</h4>
      </mat-expansion-panel-header>
      <div class="container-fluid" formGroupName="referrerDetails">

        <input id="id" type="hidden" formControlName="id">

        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Name
            </mat-label>
          </div>

          <div class="col">
            <mat-form-field appearance="outline">
              <input matInput id="name" type="text" formControlName="name">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Email
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="email" type="text" formControlName="email">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="container-fluid" >
        <div class="form-row" formGroupName="company">
          <input id="id" type="hidden" formControlName="id">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Company
            </mat-label>
          </div>
          <div class="col">
            <mat-label>
              {{(pefaRequest.company!= null ? pefaRequest.company.name : '')}}
            </mat-label>
            <button (click)="changeCompany()" mat-raised-button class="mat-accent" type="button">Change Company</button>            
          </div>
        </div>
      </div>
      <div class="container-fluid" >
        <div class="row">
          <div class="col-2">
          </div>
          <div class="col">
            <div class="alert alert-secondary" style="font-size: 0.9em; margin-top: 8px; padding: 8px;">
              <strong>Referral details:</strong> <br/>
              <strong>Job:</strong> {{pefaRequest.pefaJob.name}} <br/>
              <strong>Company:</strong> {{pefaRequest.pefaJob.description}} <br/>
              <strong>Site:</strong> {{pefaRequest.pefaJob.site}}
            </div>
          </div>
        </div>
      </div>
      <div  class="container-fluid" formGroupName="referrerDetails">
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Site
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="site" type="text" formControlName="site">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Position
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="Position" type="text" formControlName="position">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-2" style="align-self:center">
            <mat-label>
              Phone
            </mat-label>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">

              <input matInput id="Phone" type="text" formControlName="phone">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <h4>Referral Details</h4>
      </mat-expansion-panel-header>

      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            JHG File No (if known)
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput id="jhgFileNo" type="text" formControlName="jhgFileNo">
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            JHG BookingRequestID (if known)
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput id="jhgBookingRequestID" type="text" formControlName="jhgBookingRequestID">
          </mat-form-field>
        </div>
      </div>      
      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Recruiter
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput id="Recruiter" type="text" formControlName="recruiter">
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Appointment Due Date
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field>
            <input matInput date="true" [matDatepicker]="apptpicker" id="AppointmentDate" formControlName="appointmentDate">
            <mat-datepicker-toggle matSuffix [for]="apptpicker"></mat-datepicker-toggle>
            <mat-datepicker #apptpicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Report Due Date
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">

            <mat-select matInput id="ResponseTime" formControlName="responseTime">
              <mat-option value="Standard">Standard - 2 Business Days from Appt</mat-option>
              <mat-option value="Urgent">Urgent - Same Business Day as Appt (extra $56 charge)</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row" formGroupName="referralType">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Referral Type
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-select matInput id="ReferralType" formControlName="id">
              <mat-option *ngFor="let type of referralTypeOptions" [value]="type.id">{{type.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Payment Method
          </mat-label>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-select #paymentMethod matInput id="PaymentMethod" formControlName="paymentMethod">
              <mat-option id="WorkerPay" value="Worker">Worker Pay</mat-option>
              <mat-option id="CC" value="CC">Company Credit Card</mat-option>
              <mat-option id="PO" value="PO">Company Purchase Order</mat-option>
              <mat-option id="CC" value="ICC">Company Individual Credit Card</mat-option>
              <mat-option id="CC" value="IPO">Company Individual Purchase Order</mat-option>
              <mat-option id="ACCT" value="ACCT">Account</mat-option>
              <mat-option id="Other" value="Other">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="paymentMethod.selected?.value == 'Worker' || paymentMethod.selected?.value == 'ICC' || paymentMethod.selected?.value == 'IPO'" class="form-group row">
        <label class="col-sm-2 col-form-label" for="PaymentMethod">
          Payment Details
        </label>
        <div class="col-md-6">
          <app-paymenttype-entry #workerPayment [payment]="pefaRequest.worker.payment"></app-paymenttype-entry>
        </div>
      </div>

      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Status Note
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput id="statusNote" type="text" formControlName="statusNote">
          </mat-form-field>
        </div>

      </div>
      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Report Score
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput id="score" type="text" formControlName="score">
          </mat-form-field>
        </div>
      </div>

      <div class="form-row" formGroupName="reportWriter">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Report Writer
          </mat-label>
        </div>
        <div class="col">
          <input id="reportWriter" type="hidden" formControlName="id">
          <mat-label>
            <span *ngIf="pefaRequest.reportWriter">
              {{pefaRequest.reportWriter.firstName}} {{pefaRequest.reportWriter.lastName}}
            </span>
            <button (click)="addExistingPerson()" mat-raised-button class="mat-accent" type="button">Select Writer</button>
          </mat-label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            KPI Notes
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput id="kpi" type="text" formControlName="kpiNotes">
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Medical Consent Received
          </mat-label>
        </div>
        <div class="col">
          <mat-checkbox matInput id="medicalConsent" type="text" formControlName="medicalConsent"></mat-checkbox>
        </div>
      </div>

      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Medical Consent Received Date
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="medicalConsentReceivedpicker" id="medicalConsentReceived" formControlName="medicalConsentReceived">
            <mat-datepicker-toggle matSuffix [for]="medicalConsentReceivedpicker"></mat-datepicker-toggle>
            <mat-datepicker #medicalConsentReceivedpicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>


      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            PI Confirmation Sent
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="piConfirmationSentpicker" id="piConfirmationSent" formControlName="piConfirmationSent">
            <mat-datepicker-toggle matSuffix [for]="piConfirmationSentpicker"></mat-datepicker-toggle>
            <mat-datepicker #piConfirmationSentpicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            PI Confirmation Received
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="piConfirmationReceivedpicker" id="piConfirmationReceived" formControlName="piConfirmationReceived">
            <mat-datepicker-toggle matSuffix [for]="piConfirmationReceivedpicker"></mat-datepicker-toggle>
            <mat-datepicker #piConfirmationReceivedpicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Hold Until Date
          </mat-label>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="holdDatepicker" id="holdDate" formControlName="holdDate">
            <mat-datepicker-toggle matSuffix [for]="holdDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #holdDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>


      <div class="form-row">
        <div class="col-2" style="align-self:center">
          <mat-label>
            Other Notes
          </mat-label>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <textarea matInput id="otherInfo" formControlName="otherInfo">
            </textarea>
          </mat-form-field>
        </div>
      </div>


    </mat-expansion-panel>
  </mat-accordion>


  <div class="form-group row col-sm-12">
    <button class="mat-primary" mat-raised-button [disabled]="!pefaRequestForm.valid" type="submit">Update</button>
  </div>
</form>
