import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, concat, from } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators'
import { log } from 'util';
import { PEFARequest } from '../shared/shared-component';





@Injectable({
  providedIn: 'root'
})
export class AnonPEFARequestService {


  private client: HttpClient;
  private baseURL: string;
  private requestURL = 'api/anonpefarequest'

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.client = http;
    this.baseURL = baseUrl;    
  }


  public createNewPEFARequest(pefaRequest: PEFARequest): Observable<PEFARequest> {

    return this.client.post<PEFARequest>(this.requestURL, pefaRequest)
      .pipe(
        catchError(this.handleError)
      );

  }

  public getPEFARequest(id: string): Observable<PEFARequest> {
    const url = this.baseURL + this.requestURL + "/" + id;
    return this.client.get<PEFARequest>(this.baseURL + this.requestURL + "/" + id)
      .pipe(
        catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}
