import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MessageService } from "../message/message.service";
import moment from "moment";
import { QualityControl } from "./shared-component";
import { PersonSelectorDialog } from "./person-dialog.component";


@Component({
  selector: 'dialog-modal-pefa-qa',
  templateUrl: 'qa-dialog.component.html',
})
export class QADialog implements OnInit {

  public qaForm = new FormGroup({
    id: new FormControl(''),
    reason: new FormControl(''),
    outcome: new FormControl('', Validators.required),
    comments: new FormControl(''),
    auditDate: new FormControl(moment(), Validators.required),
    person: new FormGroup({
      id: new FormControl('', Validators.required),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
    }),
  });

  public qualityControl: QualityControl;


  constructor(
    public dialogRef: MatDialogRef<QADialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pefaService: PEFARequestService, public messageService: MessageService,
    private dialog: MatDialog,
) {
  }



  ngOnInit(): void {
    if (this.data.qaId) {
      this.pefaService.loadLatestQA(this.data.pefaId, this.data.qaId).subscribe(
        qa => {
          this.qualityControl = qa;
          console.log(JSON.stringify(qa));
          this.qaForm.patchValue(qa);
        },
        error => console.error(error));
    } else {
      this.pefaService.loadLatestQA(this.data.pefaId, 'latest').subscribe(
        qa => {
          this.qualityControl = qa;
          console.log(JSON.stringify(qa));
          this.qaForm.patchValue(qa
          );
        },
        error => console.error(error));
    }

  };

  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(): void {
    var values = this.qaForm.value;
    if (!this.data.qaId || this.data.qaId == '' || this.data.qaId == 0) {
      delete values.id;
    }
    this.pefaService.saveQA(this.data.pefaId, values).subscribe(result => {
      this.messageService.add("QA completed");
      this.dialogRef.close(true);
    });

  }


  public addExistingPerson() {
    const dialogRef = this.dialog.open(PersonSelectorDialog, {
      data: { type: "REPORT_WRITER" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        var person = result.person;
        this.qualityControl.person = person;
        this.qaForm.patchValue(
          {
            person: {
              id: person.id,
              firstName: person.firstName,
              lastName: person.lastName,
            },

          });

      }
    });

  }
}
