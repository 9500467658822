import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges, AfterViewInit } from "@angular/core";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Company, Person, Provider } from "../shared-component";
import { MessageService } from "../../message/message.service";
import { OfficeAdminPEFAService } from "../../office-admin-pefa/office-admin-pefa.service";
import { ActivatedRoute, Router } from "@angular/router";
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-new-person-enhanced',
  templateUrl: './person-dialog-enhanced.component.html',
  styleUrls: ['./person-dialog-enhanced.component.css']
})
export class AddNewPersonEnhanced implements OnInit {
  personForm: FormGroup =
    new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      preferredName: new FormControl(''),
      title: new FormControl(''),   
      type: new FormControl('Person'),   
      phoneNumber: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      employer: new FormGroup({
        id: new FormControl(),
        name: new FormControl(),
      }),
      relationships: 
        new FormArray([
          new FormGroup({
            type: new FormControl('', Validators.required),
            status: new FormControl('ACTIVE', Validators.required),
            startDate: new FormControl(moment(), Validators.required),
            endDate: new FormControl('')              
          })
        ])
    });
  person: Person[];
  provider: Provider[];
  public id: string;  
  public loading: boolean = true;
  public foundPerson: Person = null;
  public createNew: boolean = false;
  subject: Subject<string> = new Subject();

  constructor(public dialogRef: MatDialogRef<AddNewPersonEnhanced>,
    @Inject(MAT_DIALOG_DATA) public data: any, public officeAdminService: OfficeAdminPEFAService, public messageService: MessageService) { }

  public ngOnInit() {
    this.loading = true;

    if (this.data.personRelationshipId) {
      this.id = this.data.personRelationshipId;
      this.officeAdminService.getPersonWithRelationship(this.id).subscribe(person => {          
          if (person.employer == null) {
            person.employer = {
              id: this.data.companyId
            };
          }
          this.person = person;
          this.personForm.patchValue(this.person);
          this.loading = false;
        }
      );
    } else {
      this.personForm.patchValue({
        employer: {
          id: this.data.companyId
        }
      });
      this.loading = false;
    }

    // existing person check
    this.subject.pipe(debounceTime(400),
    ).subscribe(name => {
      let ss = name.split(' ');
      // both first and last names need to be entered first
      if (ss.length > 1 && ss[0] != '' && ss[1] != ''){
        this.officeAdminService.getPersonByName(name).subscribe(res => {
          this.foundPerson = res;
        });
      } else {
        this.foundPerson = null;
      }
    });
  }

  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onNameKey(event: KeyboardEvent) { // with type info
    let fullName = this.personForm.value['firstName'] + ' ' + this.personForm.value['lastName'];
    this.subject.next(fullName);    
  }

  savePerson(): void {
    if (this.id) {
      this.officeAdminService.updatePersonWithRelationship(this.id, this.personForm.value).subscribe(res => {
        this.messageService.add("Person updatead");
        this.dialogRef.close({ outcome: true, person: res });
      })

    } else {
      this.officeAdminService.addPerson(this.personForm.value).subscribe(res => {
         this.messageService.add("Person added");
         this.dialogRef.close({ outcome: true, person: res });
      });
    }
  }
}

