/*
This is a developer component for testing and doing some developer functions off site pages
*/

import { Component, OnInit, Inject } from "@angular/core";
import { OfficeAdminService, Company } from './pefa-upload-admin.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PEFARequest, JOBFIT_DATE_FORMAT } from "../shared/shared-component";

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'app-pefa-upload-admin-list',
    templateUrl: './pefa-upload-admin-list.component.html',
    styleUrls: ['./pefa-upload-admin.component.css']
})

export class PEFAUploadListComponent implements OnInit {
  public readonly dateFormat = JOBFIT_DATE_FORMAT;
    public officeAdminService: OfficeAdminService;
    public companypefas: PEFARequest[];
    contents: Object;

    //test dialog
    animal: string;
    name: string;
    companyId: number = -1;

    ngOnInit(): void {
        //throw new Error("Method not implemented.");
    }


    constructor(public dialog: MatDialog, officeAdminService: OfficeAdminService, public fb: FormBuilder) {
        this.officeAdminService = officeAdminService;
    }



    loadPEFAList(Id: number) {
        this.officeAdminService.getPEFAForCompany(Id).subscribe(
            companypefas => {
                this.companypefas = companypefas
            }, error => console.error(error)
        );
        this.companyId = Id;
    }

    public uploadFile(files, PEFAId) {
        this.officeAdminService.uploadPEFADoc(files, PEFAId).subscribe();
    }

    public download(PEFAId: number, attachmentId: number) {
        this.officeAdminService.downloadFile(PEFAId, attachmentId).subscribe(
            results => this.contents = results
        );
    }

    deletePEFARequest(PEFAId: number): void {
        this.officeAdminService.deletePEFAReport(PEFAId).subscribe(
            (data) => {
                this.loadPEFAList(this.companyId);
            }
        );
    }

}

