import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PEFARequestService } from './pefarequest.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PEFARequest } from '../shared/shared-component';
//import { catchError } from 'rxjs/operators';
//import { throwError } from 'rxjs';

@Component({
  selector: 'app-pefarequests',
  templateUrl: './pefarequest-list.component.html'
})
export class PEFARequestListComponent implements OnInit {
  public pefarequests: PEFARequest[];
  baseURL: string;
  displayedColumns = ["name",
    "job.title",
    "site",
    "referrerDetails.name",
    "appointmentdate",
    "responseTime",
    "status",
    "score",
    "messages",
    "report"
  ];

  dataSource: MatTableDataSource<PEFARequest>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private pefaRequestService: PEFARequestService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      this.baseURL = baseUrl;
      this.dataSource = new MatTableDataSource();
      this.loadRequests();
  }

  public loadRequests() {
    this.http.get<PEFARequest[]>(this.baseURL + 'api/PEFARequests/').subscribe(result => {
      this.dataSource.data = result;
    }, error => console.error(error));
  }


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
