import { Component, OnInit, Inject, ViewChild, Input, EventEmitter, Output } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CancelBookingDialog, PEFARequest, DialogUploadReport, BookingStatus, Booking, ConfigurationItem } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "./office-admin-pefa.service";
import { PefaInvoiceDialog } from "../shared/invoice-pefa-dialog.component";
import { NoteDialog } from "../shared/note-dialog.component";
import { DatePipe, getLocaleDateFormat, formatDate } from "@angular/common";
import { UpdateBookingDialog } from "../shared/update-booking-dialog.component";
import { MedicalClearanceDialog } from "../medicalclearance/medical-clearance-dialog.component";
import { AddMedicalClearanceDialog } from "../medicalclearance/add-medical-clearance-dialog.component";
import { UploadResultsDialog } from "../shared/pefa-upload-results-dialog.component";
import { AddAttachmentDialog } from "../shared/pefa-add-attachment-dialog.component";
import { MedicalClearanceLetterDialog } from "../medicalclearance/medical-clearance-letter-dialog.component";
import { DialogBooking } from "../shared/booking-dialog.component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { SendMessageDialog } from "../pefamessage/pefa-message-send-dialog.component";
import { CancelRequestDialog } from "../shared/cancel-request.component";
import moment from "moment";
import { QADialog } from "../shared/qa-dialog.component";
import { ConfigService } from "../config/config.service";
import { SharedService } from "../shared/shared.service";
import { KPIDetailDialog } from "../shared/kpi-detail-dialog.component";
import { ConfirmDialog } from "../shared/confirm-dialog.component";
import { Company } from "../pefa-upload-admin/pefa-upload-admin.service";


@Component({
  selector: 'app-office-pefa-actions',
  templateUrl: './office-admin-pefa-actions.component.html',
  
})
export class OfficePEFAActionsComponent implements OnInit {

  private _pefaRequest: PEFARequest;
  private updateDetails: ConfigurationItem;
  private firstContact: ConfigurationItem;
  private env: ConfigurationItem;
  officeAdminService: OfficeAdminPEFAService;
  importantNote: string;
  private _jotFormUsername: string;
  private _jotFormBpSys: string;
  private _jotFormBpDia: string;
  private _jotFormPreMc: string;

  @Input() small: boolean;

  @Output() changed = new EventEmitter<boolean>();

  get disableAccept(): boolean {
    // https://dev.azure.com/JobFitSystemsInternational/JobfitOnDemand/_workitems/edit/3753
    // return this._pefaRequest.pefaJob.demands.length == 0 || !this.checkPayment();
    return this._pefaRequest.pefaJob != null ? this._pefaRequest.pefaJob.demands.length == 0 : true;
  }

  get disableAcceptMessage(): string {
    if (this._pefaRequest.pefaJob != null && this._pefaRequest.pefaJob.demands.length == 0) {
      return 'Job Demands Required';
    // https://dev.azure.com/JobFitSystemsInternational/JobfitOnDemand/_workitems/edit/3753
    // } else if (!this.checkPayment()) {
    //   return 'No valid Payment Method';
    // } else {
    //   return '';
    }
    return '';
  }

  // https://dev.azure.com/JobFitSystemsInternational/JobfitOnDemand/_workitems/edit/3753
  // checkPayment(): boolean {
  //   var paymentNeeded = this._pefaRequest.paymentMethod;
  //   if (paymentNeeded == 'CC' || paymentNeeded == 'PO') {
  //     var goodpayment = this._pefaRequest.company.paymentTypes.find(p => p.type == paymentNeeded && p.status == 'ACTIVE' && moment(p.expiry).isAfter(moment()));
  //     return goodpayment != null;
  //   } else if (paymentNeeded == 'Worker' || paymentNeeded == 'ICC' || paymentNeeded == 'IPO') {
  //     return this._pefaRequest.worker.payment != null && this._pefaRequest.worker.payment.reference != null && this._pefaRequest.worker.payment.reference != '';
  //   }
  //   return true;
  // }


  constructor(public dialog: MatDialog, officeAdminService: OfficeAdminPEFAService, public pefaAdminService: PEFARequestService, public messageService: MessageService, public configService: ConfigService) {
    this.officeAdminService = officeAdminService;
  }

    ngOnInit(): void {
      const notes = this._pefaRequest.notes.reverse();

      this.configService.getConfigurationItem("jotform.firstContact").subscribe(r => {
        this.firstContact = r[0];
      });

      this.configService.getConfigurationItem("jotform.updateDetails").subscribe(r => {
        this.updateDetails = r[0];
      });

      this.configService.getConfigurationItem("jotform.env").subscribe(r => {
        if (r.length > 0) {
          this.env = r[0];
        }
      });

      // get information for jotforms
      this.pefaAdminService.getJotFormDetails(this._pefaRequest.id).subscribe(r => {
        if (r != null) {
          this._jotFormUsername = r['yourName'];
          this._jotFormBpSys = r['bpsys'];
          this._jotFormBpDia = r['bpdia'];
          this._jotFormPreMc = r['premc'];
        }
      });

      const n = notes.filter(n => n.important == true);
      n.forEach(note => {
        if (this.importantNote) {
          this.importantNote = note.content + "\n" + this.importantNote;

        } else {
          this.importantNote = note.content;
        }
        
      }
      )

      //this.configService.getConfigurationItem("medical.form").subscribe(result => {

      //  this.medicalBase = result[0];
      //});

      //this.configService.getConfigurationItem("contact.form").subscribe(result => {
      //  this.contactBase = result[0];
      //});

    }

  @Input()
  set pefaRequest(pefaRequest: PEFARequest) {
    this._pefaRequest = pefaRequest;

  }

  get pefaRequest() {
    return this._pefaRequest;
  }

  isNew(): boolean {
      return this.pefaRequest.status.toString() === 'new';
  }
  isPaid(): boolean {
    return this.pefaRequest.paymentStatus == 'PAID';
  }

  isProcessing(): boolean {
    return this.pefaRequest.status.toString() == 'processing';
  }

  isMCRequired(): boolean {
    if (this.pefaRequest.company.clearanceLocation && this.pefaRequest.company.clearanceLocation.toString() == "PreTest") {
      return this.pefaRequest.worker.medicalQuestionStatus == "PENDING" || this.pefaRequest.worker.medicalQuestionStatus == "NEW" || this.pefaRequest.status.toLowerCase().toString() == 'mc_required';
    }
    return false;
  }
  isComplete(): boolean {
    return this.pefaRequest.status.toString() == 'complete';
  }

  isActive(): boolean {
    return this.pefaRequest.status.toString() == 'active';
  }

  isPending(): boolean {
    return this.pefaRequest.status.toString() == 'pending';
  }

  isInvoiced(): boolean {
    return this.pefaRequest.status.toString() == 'invoiced';
  }

  isCancelled(): boolean {
    return this.pefaRequest.status.toString() == 'cancelled';
  }

  isOnHold(): boolean {
    return this.pefaRequest.status.toString().toLowerCase() == 'on_hold';
  }

  isDraftReport(): boolean {
    return this.pefaRequest.report && this.pefaRequest.reportStatus == 'DRAFT';
  }

  isAdminReviewReport(): boolean {
    return this.pefaRequest.report && this.pefaRequest.reportStatus == 'ADMIN QA';
  }

  releaseReport(): void {
    this.pefaAdminService.releaseReport(this.pefaRequest.id).subscribe(() =>
      this.changed.emit(true)
    );
  }
  //

  //Modal
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogUploadReport, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(null,null,() => {
      this.changed.emit(true);
    });
  }


  //Modal

  openBookingDialog(): void {
    const dialogRef = this.dialog.open(DialogBooking, {
      data: {
        pefaId: this._pefaRequest.id,
        holdDate: this._pefaRequest.holdDate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(true);
    });

  }

  openAttachmentDialog(): void {
    const dialogRef = this.dialog.open(AddAttachmentDialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(true);
    });
  }


  public cancelRequest() {

    const dialogRef = this.dialog.open(CancelRequestDialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.cancel) {
        this.pefaAdminService.cancelRequest(this.pefaRequest.id, result.message).subscribe(() => {
          this.changed.emit(true)
        });
      }
    });


  }


  public activateRequest() {
    this.pefaAdminService.activateRequest(this.pefaRequest.id).subscribe(() => {
      this.changed.emit(true)
    });
  }


  getMedicalFormURL(): string {

    const baseString = !(this._pefaRequest.worker.medicalQuestionStatus) || this._pefaRequest.worker.medicalQuestionStatus === "PENDING" ? this.firstContact.value : this.updateDetails.value;

    //string submissionId = this.pefaAdminService.generateForm(key);
    var url = baseString + '?pefaRequest=' + this.pefaRequest.id + '&fullName[first]=' + this.pefaRequest.worker.firstName
      + '&fullName[last]=' + this.pefaRequest.worker.lastName + '&referralType=' + this.pefaRequest.referralType.name + '&companyName=' + (this.pefaRequest.company != null ? this.pefaRequest.company.name : '')
      + '&site=' + this.pefaRequest.referrerDetails['site'] + '&mobile=' + this.pefaRequest.worker.phoneNumber + '&weight=' + this.pefaRequest.worker.weight + '&email_address=' + this.pefaRequest.worker.email;

    if (this.pefaRequest.worker.dateOfBirth) {
      url += '&dob=' + formatDate( this.pefaRequest.worker.dateOfBirth, "dd/MM/yyyy", "en_AU");
    } else {
      url += '&dob=[TBC]'
    }

    url += '&gender=' + this.pefaRequest.worker.gender;
    if (this.pefaRequest.pefaJob) {
      url += '&jobTitle=' + this.pefaRequest.pefaJob.name;            
    } else {
      url += '&jobTitle=[TBC]';      
    }
    
    if (this.pefaRequest.referrerDetails) {
      url += '&refName=' + this.pefaRequest.referrerDetails.name;      
    } else {
      url += '&refName=[Referrer\'s Name]'
    }

    url += this._jotFormUsername != null ? '&yourName=' + this._jotFormUsername : '&yourName=[Your Name]';
    url += this._jotFormBpSys != null ? '&bpsys=' + this._jotFormBpSys : '&bpsys=145';
    url += this._jotFormBpDia != null ? '&bpdia=' + this._jotFormBpDia : '&bpdia=95';
    url += '&apptduedate=' + moment(this.pefaRequest.appointmentDate).local().format('DD/MM/yyyy');
    url += '&responsetime=' + this.pefaRequest.responseTime;
    url += '&premc=' + this._jotFormPreMc;
    
    if (this.env) {
      url += "&environment=" + this.env.value;
    } else {
      url += "&environment=PROD";
    }
     return encodeURI(url);
  }

  openMCDialog(): void {
    const dialogRef = this.dialog.open(MedicalClearanceDialog, {
      data: { pefaId: this.pefaRequest.id },
      width: '1080px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }

  openMCLetterDialog(): void {
    const dialogRef = this.dialog.open(MedicalClearanceLetterDialog, {
      data: { pefaId: this.pefaRequest.id },
      width: '720px',

    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }


  openAddMCDialog(): void {
    const dialogRef = this.dialog.open(AddMedicalClearanceDialog, {
      data: { pefaId: this.pefaRequest.id },
      width: '520px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }


  openNoteDialog(): void {
    const dialogRef = this.dialog.open(NoteDialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }

  openQADialog(): void {
    const dialogRef = this.dialog.open(QADialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }

  openInvoiceDialog(): void {
    const dialogRef = this.dialog.open(PefaInvoiceDialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.changed.emit(result);
    });
  }

  openSendMessage(): void {
    const dialogRef = this.dialog.open(SendMessageDialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }


  accept(): void {
    this.officeAdminService.acceptPEFA(this.pefaRequest.id).subscribe(result => {
      this.changed.emit(true);
    });
  }

  reject(): void {

    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) { 
        this.officeAdminService.rejectPEFA(this.pefaRequest.id).subscribe(result => {
          this.messageService.add("Referral Rejected");
        }, err => this.messageService.add("Referral Failed to Reject " + err),
          () => this.changed.emit(true)
        );
      }
    });


  }


  public getReportURL(): string {
    if (this.pefaRequest.report != null) {
      return '/api/PEFARequests/getReport/' + this.pefaRequest.report.id;
    } else {
      return '';
    }  
  }

  public hasImportantNote(): boolean {
    return this._pefaRequest.notes.findIndex(n => n.important == true) != -1;
  }

  public disableMC(): boolean {
    return this._pefaRequest.worker.medicalQuestionStatus == "COMPLETED"
  }

  public downloadReport() {
    if (this.pefaRequest.report != null) {
      this.pefaAdminService.downloadAttachment(this.pefaRequest.report.id).subscribe(data => {
        SharedService.downloadFile(data);
      });
    }    
  }


  public downloadPIReport() {

    this.pefaAdminService.downloadPIReport(this.pefaRequest.id).subscribe(data => {

      SharedService.downloadFile(data);
    })


  }

  reportKPI(): void {
    const dialogRef = this.dialog.open(KPIDetailDialog, {
      data: { pefaId: this.pefaRequest.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }

  public createReportReadyToDo() {
    if (this.pefaRequest != null) {
      this.pefaAdminService.getReportReadyToDo(this.pefaRequest.id).subscribe(data => {
        // check network tab 
      });
    }   
  }

}
