<div [formGroup]="providerAuditForm">

  <div class="form-row">
    <div class="col">

      <mat-form-field>
        <mat-label>PI Insurance No</mat-label>
        <input id="name" type="text" placeholder="PI Insurance No" matInput formControlName="indeminityInsurancePolicyNo">
      </mat-form-field>
    </div>
    <div class="col">

      <mat-form-field>
        <mat-label>PI Insurance Amount</mat-label>
        <input id="name" type="text" placeholder="PI Insurance Amount" matInput formControlName="indeminityInsurancePolicyAmount">
        <mat-error>Somethigns</mat-error>
      </mat-form-field>
    </div>
    <div class="col">

      <mat-form-field>
        <mat-label>PI Insurance Expiry</mat-label>
        <input id="name" type="text" placeholder="PI Insurance Expiry" matInput [matDatepicker]="pipicker" formControlName="indeminityInsurancePolicyExpiry">
        <mat-datepicker-toggle matSuffix [for]="pipicker"></mat-datepicker-toggle>
        <mat-datepicker #pipicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="col">

      <mat-form-field>
        <mat-label>PL Insurance No</mat-label>
        <input id="name" type="text" placeholder="PL Insurance No" matInput formControlName="liabilityInsurancePolicyNo">
      </mat-form-field>
    </div>
    <div class="col">

      <mat-form-field>
        <mat-label>PL Insurance Amount</mat-label>
        <input id="name" type="text" placeholder="PL Insurance Amount" matInput formControlName="liabilityInsurancePolicyAmount">
      </mat-form-field>
    </div>
    <div class="col">

      <mat-form-field>
        <mat-label>PL Insurance Expiry</mat-label>
        <input id="name" type="text" placeholder="PL Insurance Expiry" matInput [matDatepicker]="plpicker" formControlName="liabilityInsurancePolicyExpiry">
        <mat-datepicker-toggle matSuffix [for]="plpicker"></mat-datepicker-toggle>
        <mat-datepicker #plpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>


  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <mat-label for="type">Status</mat-label>
        <mat-select formControlName="status">
          <mat-option value="ACTIVE">ACTIVE</mat-option>
          <mat-option value="PENDING">PENDING</mat-option>
          <mat-option value="CANCELLED">CANCELLED</mat-option>
          <mat-option value="EXPIRED">EXPIRED</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <mat-form-field>
        <mat-label>Audit Date</mat-label>
        <input matInput [matDatepicker]="auditpicker" formControlName="auditDate" required />
        <mat-datepicker-toggle matSuffix [for]="auditpicker"></mat-datepicker-toggle>
        <mat-datepicker #auditpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions style="margin-top: 2rem;">
  <!--<button mat-raised-button color="warn" [mat-dialog-close]="reason" [disabled]="false" cdkFocusInitial (click)="onYesClick()">Cancel booking</button>-->
  <button mat-raised-button color="mat-primary" (click)="saveProviderAudit()" [disabled]="!providerAuditForm.valid">Save</button>
</div>
