import { ErrorHandler, Injectable } from "@angular/core";
import {MonitoringService} from "./logging.service";

@Injectable()
export class ErrorService extends ErrorHandler {

  constructor(private myMonitoringService: MonitoringService) {
    super();
  }

  handleError(error: Error) {
    console.error(error);
    this.myMonitoringService.logException(error); // Manually log exception
  }
}
