import { Component, OnInit, ViewChild, Inject } from "@angular/core";

@Component({
    selector: 'app-cookie-policy',
    templateUrl: './cookie-policy.component.html',
    styleUrls: ['./cookie-policy.component.css']
})

export class CookiePolicyComponent implements OnInit {

    policyContent: string = "this is a placeholder for the legal cookie policy text"
    policyContent2: string = "How do i get this to not open in new tab??";

    ngOnInit(): void {

    }

    constructor() {
        //go get and fill out all policyContent from db and assign to variables for display
    }

}
