<ng-template #NoCompanyToList>
  <p *ngIf="!loading">There are no medical conditions for this request</p>
</ng-template>

<div class="container-fluid" *ngIf="medicals.length > 0; else NoCompanyToList">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="condition">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Condition</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.condition}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="outcome">
      <mat-header-cell *matHeaderCellDef mat-sort-header>MC Advice</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.outcome}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>MC Status</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.status}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="doctor">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Doctor</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.doctor else elseName">{{ row.doctor.name}} <span *ngIf="row.doctor.phoneNumber">P: {{row.doctor.phoneNumber}}</span></span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="otherInfo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Condition Notes</mat-header-cell>
      <mat-cell *matCellDef="let row"><span style="white-space: pre-line;">{{ row.otherInfo}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="outcomeNotes">
      <mat-header-cell *matHeaderCellDef mat-sort-header>MC Advice</mat-header-cell>
      <mat-cell *matCellDef="let row"><span style="white-space: pre-line;">{{ row.outcomeNotes}}</span></mat-cell>
    </ng-container>
    <!--createdDate-->
    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Sent Date</mat-header-cell>
      <mat-cell *matCellDef="let row"><span style="white-space: pre-line;">{{row.audit.createdDate | date:dateFormat}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="outcomeDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Outcome Date</mat-header-cell>
      <mat-cell *matCellDef="let row"><span style="white-space: pre-line;">{{row.outcomeDate | date:dateFormat }}</span></mat-cell>
      <!--
    <mat-cell *matCellDef="let row"><span *ngIf="row.outcomeDate else elseName">{{ row.audit.createdDate | date:dateFormat }}</span></mat-cell>-->
    </ng-container>

    <ng-container matColumnDef="attachment">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Medical Certificate</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span><a mat-raised-button matTooltip="MC Not Required" style="text-decoration: none;" (click)="complete(row)"><mat-icon aria-hidden="false" aria-label="Not Required">block</mat-icon></a></span>
        <span><a mat-raised-button matTooltip="Edit MC" style="text-decoration: none;" (click)="edit(row.id)"><mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon></a></span>
        <span><a mat-raised-button matTooltip="Delete MC" style="text-decoration: none;" (click)="delete(row.id)"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></a></span>
        <span *ngIf="row.medicalCertificate"><a mat-raised-button matTooltip="Download Certificate" style="text-decoration: none;" href="/api/PEFARequests/getReport/{{row.medicalCertificate.id}}" download><mat-icon aria-hidden="false" aria-label="Download">system_update</mat-icon></a></span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

</div>
<mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
