import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges, AfterViewInit, Injectable, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { User } from "oidc-client";
import { AnonThankyouComponent } from "../../../anon-pefarequest/thankyou.component";
import { CompanyUserRole, UserAdminService } from "../../../company-admin/company-admin-service";
import { UserAdminComponent } from "../../../company-admin/company-admin.component";
import { MessageService } from "../../../message/message.service";
import { Notice, NotificationService } from "../../../notifications/notification.service";
import { CreateTodo } from "../../../todo/todo-dialog.component";
import { ToDoListComponent } from "../../../todo/todo-list.component";
import { ToDoService } from "../../../todo/todo.service";
import { Company, JOBFIT_DATETIME_FORMAT, PEFARequest, Person, ToDo } from "../../shared-component";
import { SharedService } from "../../shared.service";


@Component({
  selector: 'assign-person',
  templateUrl: './assign-dialog.component.html'
})


export class AssignPerson implements OnInit {

  //todo: ToDo = new ToDo();
  todoService: ToDoService;
  notificationService: NotificationService;
  person: Person;
  user: User;
  notice: Notice;
  people: Person[] = [];
  filteredPeople: Person[] = [];
  loading: boolean = true;
  todo: ToDo;

  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  constructor(public dialogRef: MatDialogRef<AssignPerson>, @Inject(MAT_DIALOG_DATA) public data: any, todoService: ToDoService, public messageService: MessageService, public sharedService: SharedService) {
    this.todoService = todoService;
  }

  ngOnInit(): void {
    if (this.data.type.toLowerCase() == 'pending') {
      this.getReportWriters();
    } else {
      this.getAdminUsers();
    }
    this.getTodo();
  }

  getTodo(): void {
    this.todoService.getTodo(this.data.todoId).subscribe(results => {
      this.todo = results
    })
  }

  setPersonData($event: MatAutocompleteSelectedEvent) {
    this.person = $event.option.value;
  }

  getAdminUsers() {
    this.sharedService.getUsersByRole("OFFICE ADMIN").subscribe(results => {
      this.people = results;
      this.filteredPeople = results;
    })
  }

  getReportWriters() {
    this.sharedService.getReportWriters().subscribe(results => {
      this.people = results;
      this.filteredPeople = results;
    })
  }

  displayPersonName(person: Person): string {
    return person ? person.firstName + ' ' + person.lastName:'';
  }

  onPersonKey(event: KeyboardEvent) {
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredPeople = this.people.filter(a => a.firstName.toLowerCase().startsWith(filterValue) ||
      a.lastName.toLowerCase().startsWith(filterValue)
    );
    console.log(this.person.firstName)
  }

  onClose(yn: boolean): void {
    this.dialogRef.close(yn);
    console.log(this.todo.id)
  }

  assignTodo(yn: boolean): void {
    if (this.data.type.toLowerCase() == 'pending') {
      this.todoService.assignReportWriter(this.data.pefarequestId, this.person.id).subscribe({
        next: res => {
          this.todo = null;
          this.messageService.add("Booking has been assigned to report writer:" + this.person.firstName + " " + this.person.lastName)
        },
        complete: () => {
          this.dialogRef.close(yn)
        }
      });
    } else {
      // todos
      this.todoService.assignTodo(this.data.todoId, this.person).subscribe({
        next: res => {
          this.todo = res;
          this.messageService.add("ToDo has been assigned to:" + this.person.firstName + " " + this.person.lastName)
        },
        complete: () => {
          this.dialogRef.close(yn)
        }
      });
    }
  }
}
