import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { Booking, CancelRequest, Assessor } from '../shared/shared-component';

@Injectable({
    providedIn: 'root'
})
export class AssessorBookingService {


    private client: HttpClient;
    private baseURL: string;


    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
        this.client = http;
        this.baseURL = baseUrl;
    }

    getBookings(): Observable<Booking[]> {
        return this.client.get<Booking[]>(this.baseURL + 'api/Assessor/GetBookings/').pipe(
                catchError(this.handleError)
            );
    };


  getAssessors(): Observable<Assessor[]> {
    return this.client.get<Assessor[]>(this.baseURL + 'api/Assessor/').pipe(
      catchError(this.handleError)
    );
  };

  getAssessor(assessorId:string): Observable<Assessor> {
    return this.client.get<Assessor>(this.baseURL + 'api/Assessor/' + assessorId).pipe(
      catchError(this.handleError)
    );
  };


    public uploadPEFADoc(files: File, BookingId, PEFAScore) {
        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('Id', BookingId);
        return this.client.post<any>(this.baseURL + 'api/Assessor/UploadPaperwork', formData).pipe(
            catchError((res: HttpErrorResponse) => {
                switch (res.status) {
                    case 200: //upload successful
                        //do appropriate calls for success upload
                        this.messageService.add("Report document '" + fileToUpload.name + "' Upload was Successful");
                        break;
                    case 404: //PEFAid not found!  redirect?
                        console.warn(res.statusText);
                        break;
                    case 500: //Server error response failed
                        this.messageService.add("Report " + fileToUpload.name + " Upload was unsuccessful");
                        console.error("Server error upload not successful:" + res.statusText);
                        break;
                }
                return throwError(res);
            })
        )
    };

    private handleError(error) {
        let errorMessage = '';
        //let errors: any = JSON.stringify(error.json());
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    };
    //

  cancelBooking(id: string, cancelReason: string) {
      const formData = new FormData();
      formData.append('BookingID', id);
      formData.append('Reason', cancelReason);
      var httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
          })
      };

    var cancelRequest = new CancelRequest(id, cancelReason);

          return this.client.put(this.baseURL + 'api/Provider/cancelBookings/' + id, '{ "BookingID": ' + id + ', "Reason": "' + cancelReason + '"}', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

    recordNoShowBooking(id: string) {

        return this.client.put(this.baseURL + 'api/Provider/noShowBookings/' + id, id)
      .pipe(
        catchError(this.handleError)
      );
  }


  public saveAssessor(id: string, assessor: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/Assessor/' + id, assessor).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public addAssessor(assessor: any): Observable<Assessor> {
    return this.client.post<Assessor>(this.baseURL + 'api/Assessor/', assessor).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

}
