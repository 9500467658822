import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  //isExpanded = false;

  @Output() menuToggle = new EventEmitter<boolean>();

  constructor(private authorizeService: AuthorizeService) {

  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
  }

  collapse() {
    let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  }

  public toggle() :void {
    this.menuToggle.emit(true);
  }

}
