import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";

import moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports

@Component({
  selector: 'dialog-modal-kpi-detail',
  templateUrl: 'kpi-detail-dialog.component.html',
})
export class KPIDetailDialog implements OnInit {

  public kpiForm = new FormGroup({
    name: new FormControl('', Validators.required),
    delayReason: new FormControl('', Validators.required),
    notes: new FormControl('', Validators.required),
    
  });

  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<KPIDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public officeAdminService: OfficeAdminPEFAService) {
  }



  ngOnInit(): void {
  };


  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(): void {
    this.officeAdminService.addKPIDetail(this.data.pefaId, this.kpiForm.value).subscribe(
      () => this.dialogRef.close(true)
    );

  }
}
