import { Component, Inject, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PEFARequestService } from '../pefarequest/pefarequest.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from '../message/message.service';

import { Availability, PEFARequest, PEFA_STATUS, ReferralType } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import moment from 'moment';
import { PersonSelectorDialog } from './person-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanySelectorDialog } from './company-dialog.component';
import { PaymentTypeEntryComponent } from './paymenttype-entry.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-pefa-detail',
  templateUrl: './pefa-detail-view.component.html',
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {
        useUtc: true,        
      }
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD-MMM-YYYY',
        },
        display: {
          dateInput: 'DD-MMM-YYYY',
        },
      }
    }
  ]
})
export class PEFADetailViewComponent implements OnInit {

  @Input()
  public pefaRequest: PEFARequest;
  referralTypeOptions: ReferralType[];
  sharedService: SharedService;
  @Output() changed = new EventEmitter<boolean>();

  @ViewChild(PaymentTypeEntryComponent)
  private paymentComponent: PaymentTypeEntryComponent;



  pefaRequestForm = this.fb.group({
    worker: this.fb.group({
      id: [''],
      firstName: ['', Validators.required],
      dateOfBirth: [moment()],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      home: [''],
      weight: [''],
      gender: ['', Validators.required],
      preferredName: [''],
      preferredTestLocation: [''],
      work: [''],
      site: [''],
      email: ['', Validators.required],
      availability: this.fb.group({
        notes: ['']
      })
    }),
    requestedTime: [moment()],
    completedTime: [Date.now()],
    appointmentDate: [Date.now()],
    jhgFileNo: [''],
    jhgBookingRequestID: [''],
    recruiter: [''],
    responseTime: [''],
    requesterEmail: [''],
    score: [''],
    location: [''],
    type: [''],
    invoiceNumber: [''],
    companyFeesInc: [''],
    companyFeesCurrency: [''],
    medicalQuestionStatus: [''],
    requesterName: [''],
    status: [''],
    statusNote: [''],
    reportWriter: this.fb.group({
      id: [''],
      firstName: [''],
      lastName: [''],
    }),
    piConfirmationSent: [moment()],
    piConfirmationReceived: [moment()],
    medicalConsentReceived: [moment()],
    medicalConsent: [false],
    holdDate: [moment()],

    kpiNotes: [''],
    paymentMethod: [''],
    referralType: new FormGroup(
      {
        id: new FormControl('', Validators.required),
      }
    ),
    company: new FormGroup(
      {
        id: new FormControl(''),
      }
    ),
    referrerDetails: new FormGroup(
      {
        id: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        email: new FormControl(''),
        site: new FormControl(''),
        position: new FormControl(''),
        phone: new FormControl(''),
      }
    ),
    otherInfo: [''],
    id: ['']
  });

  constructor(private pefaRequestService: PEFARequestService,
    private messageService: MessageService,
    private fb: FormBuilder,
    sharedService: SharedService,
    private dialog: MatDialog,
    private _adapter: DateAdapter<any>,
  ) {
    this.sharedService = sharedService;    
  }

  ngOnInit() {
    if (!this.pefaRequest.reportWriter) {
      delete this.pefaRequest.reportWriter;
    }
    this.pefaRequestForm.patchValue(this.pefaRequest);

    if (this.pefaRequest.worker.availability && this.pefaRequest.worker.availability.length > 0) {
      this.pefaRequestForm.patchValue({
        worker: {
          availability: {
            notes: this.pefaRequest.worker.availability[0].notes
          }
        }
      });
    }

    this.sharedService.getReferralTypes().subscribe(result =>
      this.referralTypeOptions = result);
  }




  public saveRequest() {

    var requestValue = this.pefaRequestForm.value;

    //move the notes into an array
    delete requestValue.worker.availability.notes;

    if (this.pefaRequest.worker.availability && this.pefaRequest.worker.availability.length > 0) {
      requestValue.worker.availability = this.pefaRequest.worker.availability;
      requestValue.worker.availability[0].notes = this.pefaRequestForm.get('worker').get('availability').get('notes').value;
    } else {
      requestValue.worker.availability = new Array();
      var avail = new Availability('', new Date(), new Date(), 'AVAILABLE', this.pefaRequestForm.get('worker').get('availability').get('notes').value)
      delete avail.ID;
      requestValue.worker.availability.push(avail);
    }
      

    if (requestValue.paymentMethod == 'Worker' || requestValue.paymentMethod == 'IPO' || requestValue.paymentMethod == 'ICC') {
      requestValue.worker.payment = this.paymentComponent.paymentForm.value;
    }

    if (!this.pefaRequestForm.value.reportWriter.id) {
      delete this.pefaRequestForm.value.reportWriter
    }

    if (this.pefaRequestForm.value.piConfirmationSent) {
      requestValue.piConfirmationSent = moment(this.pefaRequestForm.value.piConfirmationSent).local().format('yyyy-MM-DD');
    }


    if (this.pefaRequestForm.value.worker.dateOfBirth) {
      requestValue.worker.dateOfBirth = moment(this.pefaRequestForm.value.worker.dateOfBirth).local().format('yyyy-MM-DD');
    }

    if (this.pefaRequestForm.value.piConfirmationReceived) {
      requestValue.piConfirmationReceived = moment(this.pefaRequestForm.value.piConfirmationReceived).local().format('yyyy-MM-DD');
    }
    var appointment = moment(requestValue.appointmentDate);

    requestValue.appointmentDate = appointment.local().format('yyyy-MM-DD');
    this.pefaRequestService.savePEFARequest(requestValue, this.pefaRequest.id).subscribe(() => {
      this.messageService.add("Details successfuly updated");
      this.changed.emit(true);
    });
  }


  public addExistingPerson() {
    const dialogRef = this.dialog.open(PersonSelectorDialog, {
      data: {type: "REPORT_WRITER"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        var person = result.person;
        this.pefaRequest.reportWriter = person;
        this.pefaRequestForm.patchValue(
          {
            reportWriter: {
              id: person.id,
              firstName: person.firstName,
              lastName: person.lastName,
            },

          });
        
      }
    });

  }

  public changeCompany() {

    const dialogRef = this.dialog.open(CompanySelectorDialog, {
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        var company = result.company;
        this.pefaRequest.company = company;
        this.pefaRequestForm.patchValue(
          {
            company : {
              id: company.id,
            },
          });
      }
    });

  }

}
