import { MessageService } from '../message/message.service';
import { ToDoService } from '../todo/todo.service';
import { JOBFIT_DATETIME_FORMAT, PEFARequest, ToDo } from './shared-component';
import { Component, OnInit, Inject, ViewChild, Provider, Output, EventEmitter, Input } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';


@Component({
  selector: 'app-pefa-todo-list',
  templateUrl: './pefa-todolist.component.html',
})

export class PEFAToDoListComponent implements OnInit {

  public todoService: ToDoService;
  public todos: ToDo[] = [];

  loading: boolean = true;
  @Output() changed = new EventEmitter<boolean>();

  //Material Table
  displayedColumns2 = [
    "urgent",
    "description",
    "type",
    "worker",
    "company",
    "priority",
    "assignedTo",
    "dueDate",
    "actions",
  ];

  @Input() request: PEFARequest;

  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  public dataSource: MatTableDataSource<ToDo>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);

    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      var nameFound = false;
      filter.split(",").forEach(function (value: string, index: number, array: string[]) {
        if ('urgent' == value.toLowerCase()) {
          nameFound = nameFound || data.urgent;
        } else {
          nameFound = nameFound || data.type.toLowerCase() == value.toLowerCase();
        }
      });
      return nameFound;
    }
    this.loadTodos(false);
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  constructor(public dialog: MatDialog, todoService: ToDoService, public fb: FormBuilder, public messageService: MessageService) {
    this.todoService = todoService;
    this.dataSource = new MatTableDataSource();
  }

  //Material Table
  //applyFilter(event: Event) {

  //  const filterValue = (event.target as HTMLInputElement).value;
  //  this.dataSource.filter = filterValue.trim().toLowerCase();

  //  if (this.dataSource.paginator) {
  //    this.dataSource.paginator.firstPage();
  //  }
  //}


  public loadTodos(update : boolean) {
    this.todoService.getPEFATodos(this.request.id).subscribe(
      result => {
        this.todos = result;
        this.dataSource.data = this.todos;
        if (update) {
          this.changed.emit();
        }
        this.loading = false;
      })
  }

  applyFilter(event: MatButtonToggleChange) {
    console.log(JSON.stringify(event.value));

    this.dataSource.filter = event.value.toString();
  }
}
