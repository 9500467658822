import { Input, Component, OnInit } from "@angular/core";
import { PEFARequest, Booking } from "./shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { JOBFIT_DATETIME_FORMAT } from "../shared/shared-component";

@Component({
  selector: 'app-bookings-view',
  templateUrl: './bookings-view.component.html',
})
export class BookingsViewComponent implements OnInit {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  @Input()
  public requestId: string;

  @Input()
  public bookingId: string;

  public dataSource: MatTableDataSource<Booking> = new MatTableDataSource<Booking>();

  public request: PEFARequest;

  public bookings: Booking[];

  displayedColumns = [
    "appointmentdate",
    "cancelDate",
    "status",
    "payment",
    "type",
    "notes",
  ];

  constructor(public pefaService: PEFARequestService
  ) {

  }


  public ngOnInit() {
    this.pefaService.getPEFARequest(this.requestId).subscribe(result => {
      this.request = result;
    })

    this.pefaService.getPEFABookings(this.requestId).subscribe(response => {
      this.bookings = response;
      this.dataSource.data = response;
    })
  }

  public getPaymentId(): string {

    var paymentNeeded = this.request.paymentMethod;
    if (paymentNeeded == 'CC' || paymentNeeded == 'PO' || paymentNeeded == 'Other') {
      var goodpayment = this.request.company.paymentTypes.find(p => p.type == paymentNeeded && p.status == 'ACTIVE');
      if (goodpayment) {
        return goodpayment.reference + " (" + goodpayment.expiry + ")";
      } else {
        return "No valid payment method";
      }

    } else {
      if (this.request.worker.payment) {
        return this.request.worker.payment.reference;
      } else {
        return "No valid payment method";
      }
    }

    
    
  }


}
