import { Component, AfterViewInit } from "@angular/core";
import { Report, JOBFIT_DATE_FORMAT, Utility } from "../shared/shared-component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ReportService } from "./report.service";
import { SharedService } from "../shared/shared.service";

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
})
export class ReportDetailComponent implements AfterViewInit {
  public report: Report;
  public loading: boolean = true;
  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  public formats: string[] = [];

  public criteriaForm = new FormGroup({
    'format': new FormControl()
  });

  ngAfterViewInit(): void {
    this.reportService.getReport(this.route.snapshot.paramMap.get('id')).subscribe(results => {
      this.report = results;
      this.formats = results.format.split(",");
      this.loading = false;
    });
  }



  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public reportService: ReportService,
  ) {
  }

  public generateReport() {

    var form = new FormData();

    form = Utility.convertModelToFormData(this.criteriaForm.value, form);

    this.loading = true;

    this.reportService.generateReport(this.report.id, form).subscribe(result => {

      SharedService.downloadFile(result);
      this.loading = false;

    })

  }

}

