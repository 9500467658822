<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <h1 mat-dialog-title>Add QA</h1>
    </div>
  </div>
</div>


<div mat-dialog-content>

  <form [formGroup]="qaForm">

    <div class="form-group row">
      <div class="col-4">
        <mat-label>
          Reason
        </mat-label>
      </div>
      <div class="col-8">
        <mat-label>
          <span *ngIf="qualityControl">
            {{qualityControl.reason}}
          </span>
        </mat-label>
      </div>
    </div>


    <div class="form-group row">
      <div class="col-4">
        <mat-label>
          Outcome
        </mat-label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline" style="width:500px">
          <mat-select matInput
                      class="form-control"
                      formControlName="outcome" required>
            <mat-option value="OK">OK</mat-option>
            <mat-option value="REWORK">Rework - Same Report Writer</mat-option>
            <mat-option value="EXTRA">Need extras</mat-option>
            <mat-option value="MINOR">Minor changes</mat-option>
            <mat-option value="TYPO">Typos</mat-option>
            <mat-option value="REWRITE">Rewrite - Alternate Writer</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <mat-label>
          QA Person
        </mat-label>
      </div>
      <div class="col-8">
        <span *ngIf="qualityControl && qualityControl.person">
          {{qualityControl.person.firstName}} {{qualityControl.person.lastName}}
        </span>

        <button (click)="addExistingPerson()" mat-raised-button class="mat-accent" type="button">Select Writer</button>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <mat-label>
          Audit Date
        </mat-label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="auditDatePicker" id="auditDate" formControlName="auditDate">
          <mat-datepicker-toggle matSuffix [for]="auditDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #auditDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-4">
        <mat-label>
          Comments
        </mat-label>
      </div>
      <div class="col-8">
        <mat-form-field appearance="outline">
          <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="10"
                  class="form-control"
                  cols="100"
                    id="comments" formControlName="comments">
            </textarea>
        </mat-form-field>
      </div>
    </div>

  </form>
</div>

<div mat-dialog-actions style="margin-top: 2rem;">
  <button mat-raised-button color="primary" (click)="onNoClick(false)">Cancel</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="onSubmit()" [disabled]="!qaForm.valid">Save QA</button>
</div>
