import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { PrimeIcons } from "primeng/api";
import { filter, map } from "rxjs/operators";
import { MessageService } from "../../message/message.service";
import { PEFARequestService } from "../../pefarequest/pefarequest.service";
import { ToDoService } from "../../todo/todo.service";
import { JOBFIT_DATETIME_FORMAT, NoteView, PEFARequest } from "../shared-component";

@Component({
  selector: 'app-note-view-list',
  templateUrl: './note-view-list.component.html',
  styleUrls: ['./note-view-list.css']

})

export class NoteViewListComponent implements OnInit {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  @Input() pefaRequest: PEFARequest;

  @Output() changed = new EventEmitter<boolean>();

  public noteViews: NoteView[] = [];
  public filteredNoteViews: NoteView[] = [];

  public constructor(public pefaService: PEFARequestService, public messageService: MessageService, public dialog: MatDialog, private todoService: ToDoService) {

  }

  public ngOnInit() {
    this.loadData();
  }

  public refresh() {
    this.changed.emit(true);

  }

  public loadData() {

    this.pefaService.getPEFANotes(this.pefaRequest.id).subscribe(results => {
      var filteredResponse = results.filter(d => d.status == "ACTIVE");
      this.noteViews = this.noteViews.concat(filteredResponse.map(note => new NoteView(note.id, note.content.slice(0, 60), note.adminOnly, note.important, "NOTE", note.audit?.createdDate.toLocaleString(), note)));
      this.noteViews.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
      this.filteredNoteViews = this.noteViews;
    }
    );

    this.pefaService.getPEFAMessages(this.pefaRequest.id).subscribe(response => {
      this.noteViews = this.noteViews.concat(response.map(message => new NoteView(message.ID, message.subject, false, false, "MESSAGE", message.audit?.createdDate.toLocaleString(), message)));
      this.noteViews.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
      this.filteredNoteViews = this.noteViews;
    });


    this.pefaService.getPEFAAttachments(this.pefaRequest.id).subscribe(response => {
      var filteredResponse = response.filter(d => d.status == "ACTIVE");
      this.noteViews = this.noteViews.concat(filteredResponse.map(attachment => new NoteView(attachment.id, attachment.type, true, false, "FILE", attachment.audit?.createdDate.toLocaleString(), attachment)));
      this.noteViews.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
      this.filteredNoteViews = this.noteViews;
    }
    );


    this.pefaService.getPEFABookings(this.pefaRequest.id).subscribe(response => {
      this.noteViews = this.noteViews.concat(response.map(attachment => new NoteView(attachment.id, 'Appointment Created', true, false, "BOOKING", attachment.audit?.createdDate.toLocaleString(), attachment)));
      this.noteViews = this.noteViews.concat(response.map(attachment => new NoteView(attachment.id, 'Appointment Time', true, false, "BOOKING", attachment.appointmentDateTime.toLocaleString(), attachment)));
      this.noteViews.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
      this.filteredNoteViews = this.noteViews;
    }
    );

    this.pefaService.getPEFAClearances(this.pefaRequest.id).subscribe(response => {
      this.noteViews = this.noteViews.concat(response.map(attachment => new NoteView(attachment.id, attachment.condition, true, false, "MEDICAL", attachment.audit?.createdDate.toLocaleString(), attachment)));
      this.noteViews.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
      this.filteredNoteViews = this.noteViews;
    }
    );

    this.pefaService.getPEFAPayments(this.pefaRequest.id).subscribe(response => {
      this.noteViews = this.noteViews.concat(response.map(attachment => new NoteView(attachment.id, attachment.paymentType, true, false, "PAYMENT", attachment.audit?.createdDate.toLocaleString(), attachment)));
      this.noteViews.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
      this.filteredNoteViews = this.noteViews;
    }
    );

    this.todoService.getPEFATodos(this.pefaRequest.id).subscribe(response => {
      this.noteViews = this.noteViews.concat(response.map(attachment => new NoteView(attachment.id, 'TODO - ' + attachment.description, true, attachment.urgent, "TODO", attachment.dueDate.toLocaleString(), attachment)));
      this.noteViews.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
      this.filteredNoteViews = this.noteViews;
    }
    );

  }


  applyFilter(event: MatButtonToggleChange) {
    console.log("Filter : " + JSON.stringify(event.value));
    if (event.value.length == 0) {
      this.filteredNoteViews = this.noteViews;
    } else {
      this.filteredNoteViews = this.noteViews.filter(d => event.value.some(a => a == d.type));
    }
  }
}
