import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { Job, DemandType } from '../shared/shared-component';


@Injectable({
  providedIn: 'root'
})
export class JobService {
  private client: HttpClient;
  private baseURL: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private messageService: MessageService) {
    this.client = http;
    this.baseURL = baseUrl;
  }


  getJobs(): Observable<Job[]> {
    return this.client.get<Job[]>(this.baseURL + 'api/Jobs')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };



  getDemandTypes(): Observable<DemandType[]> {
    return this.client.get<DemandType[]>(this.baseURL + 'api/Jobs/DemandTypes')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };


  public addJob(job: any): Observable<any> {
    return this.client.post(this.baseURL + 'api/Jobs/', job).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public saveJob(id: string, job: any): Observable<any> {
    return this.client.put(this.baseURL + 'api/Jobs/' + id, job).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getJob(id: string): Observable<Job> {
    return this.client.get<Job>(this.baseURL + 'api/Jobs/' + id).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getJodJob(id: number): Observable<any> {
    return this.client.get<any>(this.baseURL + `api/mesh/JodJob/${id}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
  public getJobsLinked(id: number): Observable<Job[]> {
    return this.client.get<Job[]>(this.baseURL + `api/mesh/JobsLinked/${id}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  

  public getJodCompanyRules(id: number): Observable<any> {
    return this.client.get<any>(this.baseURL + `api/mesh/JodCompanyRules/${id}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  private handleError(error) {
    let errorMessage = '';
    //let errors: any = JSON.stringify(error.json());
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error}\nPlease Try again.`;
    }
    this.messageService.add(errorMessage)
    return throwError(errorMessage);
  };
}
