import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BusinessCalendar, ConfigurationItem } from '../shared/shared-component';
import { throwError, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  private client: HttpClient;
  private baseURL: string;
  private requestURL = 'api/ConfigurationItems'
  private configItems: ConfigurationItem[];
  private calendar: BusinessCalendar;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.client = http;
    this.baseURL = baseUrl;
  }




  getConfigurationItem(key: string): Observable<ConfigurationItem[]> {
    if (!this.configItems) {
      return this.client.get<ConfigurationItem[]>(this.baseURL + this.requestURL)
        .pipe(
          tap(result => this.configItems = result),
          map(items => items.filter(function (item) {
            return item.code === key;
          })))
        ;
    } else {
      return of(this.configItems.filter(r => r.code === key));
    }

  }

  getBusinessCalendarSettings(refresh:boolean): Observable<BusinessCalendar> {

    if (!this.calendar || refresh) {
      return this.client.get<BusinessCalendar>(this.baseURL + this.requestURL + "/BusinessCalendar")
        .pipe(
          tap(result => this.calendar = result),
         );
    } else {
      return of(this.calendar);
    }

  }

  saveBusinessCalendarSettings(calendar: BusinessCalendar): Observable<BusinessCalendar> {
    return this.client.put<BusinessCalendar>(this.baseURL + this.requestURL + "/BusinessCalendar", calendar).pipe(catchError(this.handleError));
  }
  

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
