<!-- Add/Edit Existing Person -->
<div *ngIf="!loading" class="form-group" [formGroup]="personForm">
    <div class="form-row">
      <div class="col-md-2">
        <mat-form-field class="formFieldWidth80">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Title" type="text" formControlName="title" />
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput placeholder="First Name" type="text" formControlName="firstName" required (keyup)="onNameKey($event)" />
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Last Name" type="text" formControlName="lastName" required (keyup)="onNameKey($event)" />
        </mat-form-field>
      </div>      
    </div>
    <div *ngIf="foundPerson != null && foundPerson.id > 0" class="form-row">
      <div class="col-md-12">
        <div class="alert alert-warning" style="font-size: 0.8em;">
          <strong>Person Already Exists!</strong><br/>You are about to create New Person with the same name.  
          If you want to add an existing person, close this dialog and choose <strong>"Add Existing Person"</strong>.
        </div>
      </div>
    </div>    
    <div class="form-row">
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>Preferred Name</mat-label>
          <input matInput placeholder="Preferred Name" type="text" formControlName="preferredName" />
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" type="text" formControlName="email" required />
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>Phone</mat-label>
          <input matInput placeholder="Phone" type="text" formControlName="phoneNumber" required />
        </mat-form-field>
      </div>
    </div>

    <div formArrayName="relationships">
      <div *ngFor="let r of this.personForm.get('relationships').controls; let i = index" [formGroupName]="i">
        <div class="form-row">
          <div class="col-md-6">
            <mat-form-field>
              <mat-label for="type">Type</mat-label>
              <mat-select formControlName="type" required>
                <mat-option value="REPORT_WRITER">Report Writer</mat-option>
                <mat-option value="EMPLOYEE">Employee</mat-option>
                <mat-option value="ASSESSOR">Assessor</mat-option>
                <mat-option value="REFERRER">Referrer</mat-option>
                <mat-option value="RECEIVER">Receiver</mat-option>
                <mat-option value="STATS">Stats</mat-option>
                <mat-option value="PIONLY">PI Only</mat-option>
                <mat-option value="QA">Quality Assurnce</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <mat-label for="type">Status</mat-label>
              <mat-select formControlName="status">
                <mat-option value="ACTIVE">ACTIVE</mat-option>
                <mat-option value="PENDING">PENDING</mat-option>
                <mat-option value="CANCELLED">CANCELLED</mat-option>
                <mat-option value="EXPIRED">EXPIRED</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="startpicker" formControlName="startDate" required />
              <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
              <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="apptpicker" formControlName="endDate" />
              <mat-datepicker-toggle matSuffix [for]="apptpicker"></mat-datepicker-toggle>
              <mat-datepicker #apptpicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
        <div class="col-md-12">
            <mat-dialog-actions>
                <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Close</button>
                <button mat-raised-button color="warn" style="margin-top: 1rem;" [disabled]="!personForm.valid" mat-button (click)="savePerson()">Save</button>
              </mat-dialog-actions>            
        </div>
    </div>    
  </div>
  