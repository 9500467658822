<form [formGroup]="pefaRequestForm" (ngSubmit)="onSubmit()">

  <div class="container-fluid" formGroupName="Worker">
    <div class="col-sm-12">
      <h2>Participant Details</h2>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="FirstName">
        First Name
      </label>
      <div class="col-sm-3">
        <input id="FirstName" type="text" class="form-control" formControlName="firstName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="LastName">
        Last Name
      </label>
      <div class="col-sm-3">
        <input id="LastName" type="text" class="form-control" formControlName="lastName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="emailAddress">
        Email Address
      </label>
      <div class="col-sm-3">
        <input id="emailAddress" type="text" class="form-control" formControlName="emailAddress">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="dateOfBirth">
        Date Of Birth
      </label>
      <div class="col-sm-3">
        <input id="dateOfBirth" type="date" class="form-control" formControlName="dateOfBirth">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="gender">
        Sex at Birth
      </label>
      <div class="col-sm-3">
        <select id="gender" class="form-control" formControlName="gender">
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="mobile">
        Mobile
      </label>
      <div class="col-sm-3">
        <input id="mobile" type="text" class="form-control" formControlName="mobile">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="home">
        Home Phone Number
      </label>
      <div class="col-sm-3">
        <input id="home" type="text" class="form-control" formControlName="home">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="work">
        Work Phone Number
      </label>
      <div class="col-sm-3">
        <input id="work" type="text" class="form-control" formControlName="work">
      </div>
    </div>
  </div>

  <div class="container-fluid" formGroupName="PefaJob">


    <div class="col-sm-12">
      <h2>Job Details</h2>
    </div>

    <label class="col-sm-2 col-form-label" for="name">
      Job Title
    </label>




    <div class="col-sm-3">
      <mat-form-field>
        <input id="name" type="text" placeholder="Type or Select a job" formControlName="Name" matInput [matAutocomplete]="autoJob" (keyup)="onJobKey($event)">
        <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="setJobFormData($event)">
          <mat-option *ngFor="let job of filteredJobs" [value]="job">
            {{job.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="form-group row" formGroupName="Job">
      <input id="id" type="hidden" class="form-control" formControlName="id">

    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="JobSite">
        Job Site
      </label>
      <div class="col-sm-3">
        <input id="JobSite" type="text" class="form-control" formControlName="Site">
      </div>
    </div>
  </div>


  <div class="container-fluid">
    <div class="col-sm-12">
      <h2>Referral Details</h2>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="Recruiter">
        Recruiter
      </label>
      <div class="col-sm-3">
        <input id="Recruiter" class="form-control" type="text" formControlName="Recruiter">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="AppointmentDate">
        Appointment Due Date
      </label>
      <div class="col-sm-3">
        <input id="AppointmentDate" class="form-control" type="date" formControlName="AppointmentDate">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="ResponseTime">
        Report Due Date
      </label>
      <div class="col-sm-3">
        <select id="ResponseTime" class="form-control" formControlName="ResponseTime">
          <option value="Standard">Standard - 2 Business Days from Appt</option>
          <option value="Urgent">Urgent - Same Business Day as Appt (extra $56 charge)</option>
        </select>
      </div>
    </div>

    <div class="form-group row" formGroupName="ReferralType">
      <label class="col-sm-2 col-form-label" for="ReferralType">
        Referral Type
      </label>
      <div class="col-sm-3">
        <select id="ReferralType" class="form-control" formControlName="id">
          <option *ngFor="let type of referralTypeOptions" value="{{type.id}}">{{type.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="PaymentMethod">
        Payment Method
      </label>
      <div class="col-sm-3">
        <select id="PaymentMethod" class="form-control" formControlName="PaymentMethod">
          <option id="WorkerPay" value="Worker">Worker Pay</option>
          <option id="CC" value="CC">Company Credit Card</option>
          <option id="PO" value="PO">Company Purchase Order</option>
          <option id="Other" value="Other">Other</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 col-form-label" for="otherInfo">
        Other Notes
      </label>
      <div class="col-sm-3">
        <textarea id="otherInfo" class="form-control" formControlName="otherInfo">
        </textarea>
      </div>
    </div>

    <div class="form-group row col-sm-12">
      <button class="btn btn-primary" [disabled]="!pefaRequestForm.valid" type="submit">Request</button>
    </div>

  </div>

</form>
