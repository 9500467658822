import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PEFARequestService } from '../pefarequest/pefarequest.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map, retry, share, switchMap, takeUntil } from 'rxjs/operators';
import { MessageService } from '../message/message.service';

import { OfficeAdminPEFAService } from './office-admin-pefa.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PEFARequest, PEFA_STATUS, ReferralType, DialogStatusChange, JOBFIT_DATETIME_FORMAT } from '../shared/shared-component';
import { SharedService } from '../shared/shared.service';
import { ToDoService } from '../todo/todo.service';
import { Observable, Subject, timer } from 'rxjs';

export interface PEFA {
    pefarequest: PEFARequest;
    id: string;
}



@Component({
    selector: 'app-office-admin-pefa-detail',
    templateUrl: './office-admin-pefa-detail.component.html',
    styleUrls: ['./office-admin-pefa.component.css']
})
export class OfficeAdminPEFADetailComponent implements OnInit, OnDestroy {
    public pefarequest: PEFARequest;
    public pefaRequestService: PEFARequestService;
    currentPEFAID: string = "";
    accepted: boolean;
  loading: boolean;
  responseClass : string = "mat-accent"
  typeClass: string = "mat-accent"
  selectedTab = 3;
  messageService: MessageService;
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  private todoCount$ : Observable<Number>;
  private stopPolling = new Subject();

    constructor(pefaRequestService: PEFARequestService,
        messageService: MessageService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
      public officeAdminService: OfficeAdminPEFAService,
      private todoService: ToDoService,
      sharedService: SharedService
) {
        this.pefaRequestService = pefaRequestService;
      this.messageService = messageService;


    }

    //Modal
    openDialog(): void {
        const dialogRef = this.dialog.open(DialogStatusChange, {
            width: '500px',
            height: '350px',
            data: { pefarequest: this.pefarequest, id: this.currentPEFAID }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
        });
    }
    //

    ngOnInit() {
      this.loadRequest();
 
      this.currentPEFAID = this.route.snapshot.paramMap.get('id');
      this.todoCount$ = timer(1, 30000).pipe(
        switchMap(() => this.todoService.getPEFATodosLite(this.currentPEFAID)),
        map(result => result.filter(a => a.status != 'COMPLETE').length),
        retry(1),
        share(),
        takeUntil(this.stopPolling)
      );
  }

  public refreshPefa() {
      this.loadRequest();  
  }


  accept() {
        this.officeAdminService.acceptPEFA(this.route.snapshot.paramMap.get('id')).subscribe(
            Response => {
                if (Response == true) {
                    this.loadRequest();
                }
                else {
                    this.messageService.add("Error - PEFA Accept failed, please try again");
                    this.ngOnInit();
                }
            }
        );

  }


  ngOnDestroy() {
    this.stopPolling.next();
  }

  private loadRequest() {
    this.pefarequest = null;
    this.loading = true;
    this.pefaRequestService.getPEFARequest(this.route.snapshot.paramMap.get('id')).subscribe(

      request => {
        this.pefarequest = request;
        if (request.responseTime == "Urgent") {
          this.responseClass = "urgent"
        }

        if (request.referralType.name.startsWith("Period")) {
          this.typeClass = "pi"
        }
        this.loading = false;
      }

    );

  }


  updateTab(tabNumber: number) {
    this.selectedTab = tabNumber;
  }

}
