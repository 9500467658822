<div class="row">
  <div class="col text-center">
    <h1 mat-dialog-title>Upload Attachment</h1>
  </div>
</div>

<div mat-dialog-content>
  <div class="form-group row">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select type="text" [(ngModel)]="type">
        <mat-option value="AMENDED_REPORT">Amended Report</mat-option>
        <mat-option value="LETTER">Letter</mat-option>
        <mat-option value="MEDICAL">Medical</mat-option>
        <mat-option value="OTHER">Other</mat-option>        
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-group row">
    <input hidden #fileInput type="file" id="file" (change)="filename=fileInput.files[0].name">
    <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
    &nbsp;
    <mat-label *ngIf="fileInput.files[0]">{{fileInput.files[0].name}}</mat-label>
  </div>

  <div class="form-group row">
    <mat-form-field>
      <mat-label>File Name</mat-label>
      <input matInput type="text" [(ngModel)]="filename">
    </mat-form-field>
  </div>

</div>


<mat-dialog-actions>
  <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Cancel</button>
  <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onSubmit(fileInput.files)" type="submit">Upload</button>
</mat-dialog-actions>
