import { Component, Input, AfterViewInit, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { AssessorService } from '../assessor/assessor.service';
import { MessageService } from '../message/message.service';
import { OfficeAdminPEFAService } from '../office-admin-pefa/office-admin-pefa.service';
import { ProviderPEFAService } from '../provider/provider-pefa.service';
import { Company, Person, ProviderAudit } from './shared-component';
import { SharedService } from './shared.service';

@Component({
  selector: 'app-provideraudit-dialog',
  templateUrl: './provider-audit-dialog.component.html',
})
export class ProviderAuditDialogComponent implements OnInit {
  currentId: string;
  assessorId: string;
  providerAudit: ProviderAudit;
  providerAuditForm =
    new FormGroup({
      auditDate: new FormControl(moment(), Validators.required),      
      indeminityInsurancePolicyNo: new FormControl(''),
      indeminityInsurancePolicyAmount: new FormControl('', Validators.pattern('[0-9]*')),
      indeminityInsurancePolicyExpiry: new FormControl(''),

      liabilityInsurancePolicyNo: new FormControl(''),
      liabilityInsurancePolicyAmount: new FormControl('', Validators.pattern('[0-9]*')),
      liabilityInsurancePolicyExpiry: new FormControl(''),
      status: new FormControl('ACTIVE', Validators.required),

    })



  constructor(public dialogRef: MatDialogRef<ProviderAuditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private providerService: ProviderPEFAService, private messageService: MessageService, public assessorService: AssessorService) {
  }

  ngOnInit() {


    this.providerAudit = this.data.providerAudit
    this.assessorId = this.data.assessorId;
    this.currentId = this.data.assessorId;

    var type = this.data.peopleType;

    if (this.providerAudit) {
      this.providerAuditForm.patchValue(this.providerAudit);
    }
  }


  saveProviderAudit() {

    var auditService = this.getService();

    var form = this.providerAuditForm.value;

    if (form.indeminityInsurancePolicyNo == '') {
      delete form.indeminityInsurancePolicyNo;
      delete form.indeminityInsurancePolicyAmount;
      delete form.indeminityInsurancePolicyExpiry;
    }

    if (form.liabilityInsurancePolicyNo == '') {
      delete form.liabilityInsurancePolicyNo;
      delete form.liabilityInsurancePolicyAmount;
      delete form.liabilityInsurancePolicyExpiry;
    }

    if (this.providerAudit) {
      auditService.saveProviderAudit(this.currentId, form).subscribe(res => {
        this.messageService.add("Provider Audit Updated");
        this.dialogRef.close({ outcome: true, providerAudit: res });
      })
    } else {
      auditService.addProviderAudit(this.currentId, form).subscribe(res => {
        this.messageService.add("Provider Audit add");
        console.log(JSON.stringify(res));
        this.dialogRef.close({ outcome: true, providerAudit: res });
      })

    } 
  }

  getService() {
    if (this.assessorId) {
      return this.assessorService;
    } else {
      return this.providerService;
    }
  }

}
