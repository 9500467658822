<div class="container-fluid">

  <div class="row">

    <div class="col-md-12 text-center">
      <h4>Cookie Policy</h4>
    </div>

  </div>

  <div class="row topTextRow">

    <div class="col-md-1"></div>
    <div class="col-md-10 text-center">
      <p>{{policyContent}}</p>
    </div>
    <div class="col-md-1"></div>

  </div>

  <div class="row">

    <div class="col-md-1"></div>
    <div class="col-md-10 text-center">
      <p style="color: red; font-size: 14px;">{{policyContent2}}</p>
    </div>
    <div class="col-md-1"></div>

  </div>

</div>



