<div class="container-fluid">
  <h3>Roles</h3>
</div>

<!--Page content insertion-->
<div id="content">

  <app-user-admin-list [companyUsers]="companyRoleUsers"></app-user-admin-list>

  <div class="container-fluid">
    <h3> Add - Company User</h3>
  </div>

  <div class="container-fluid company-admin">
    <div class="row">

      <div class="col-1 col-sm-2 col-md-3">

      </div>

      <div class="col-10 col-sm-8 col-md-6">

        <form [formGroup]="adminNewUserForm" autocomplete="off" (ngSubmit)="onSubmitNewUser()">

          <div class="form-group">
            <label class="font-weight-bold">First Name</label><label class="font-weight-bold required-mark">*</label>
            <input type="text" class="form-control" autocomplete="off" formControlName="fName" required>
          </div>

          <div *ngIf="f.fName.invalid && (f.fName.dirty || f.fName.touched)" class="alert alert-danger">
            <div *ngIf="f.fName.errors.required" class="validation-error-message">
              First Name is required.
            </div>
            <div *ngIf="f.fName.errors.minlength" class="validation-error-message">
              First Name must be at least 2 characters long.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">Last Name</label><label class="font-weight-bold required-mark">*</label>
            <input type="text" class="form-control" autocomplete="off" formControlName="lName" required>
          </div>

          <div *ngIf="f.lName.invalid && (f.lName.dirty || f.lName.touched)" class="alert alert-danger">
            <div *ngIf="f.lName.errors.required" class="validation-error-message">
              Last Name is required.
            </div>
            <div *ngIf="f.lName.errors.minlength" class="validation-error-message">
              Last Name must be at least 2 characters long.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">Site Name</label><label class="font-weight-bold required-mark">*</label>
            <input type="text" class="form-control" autocomplete="off" formControlName="siteName" required>
          </div>

          <div *ngIf="f.siteName.invalid && (f.siteName.dirty || f.siteName.touched)" class="alert alert-danger">
            <div *ngIf="f.siteName.errors.required" class="validation-error-message">
              Site Name is required.
            </div>
            <div *ngIf="f.siteName.errors.minlength" class="validation-error-message">
              Site Name must be at least 2 characters long.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">Mobile/Cell Number</label><label class="font-weight-bold required-mark">*</label>
            <input type="text" class="form-control" autocomplete="off" formControlName="mobNum" required>
          </div>

          <div *ngIf="f.mobNum.invalid && (f.mobNum.dirty || f.mobNum.touched)" class="alert alert-danger">
            <div *ngIf="f.mobNum.errors.required" class="validation-error-message">
              Mobile/Cell Number is required.
            </div>
            <div *ngIf="f.mobNum.errors.pattern" class="validation-error-message">
              Mobile/Cell can only be numerical.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">Work Number</label><label class="font-weight-bold required-mark">*</label>
            <input type="text" class="form-control" autocomplete="off" formControlName="workNum" required>
          </div>

          <div *ngIf="f.workNum.invalid && (f.workNum.dirty || f.workNum.touched)" class="alert alert-danger">
            <div *ngIf="f.workNum.errors.required" class="validation-error-message">
              Work Number is required.
            </div>
            <div *ngIf="f.workNum.errors.pattern" class="validation-error-message">
              Work can only be numerical.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">User Name</label><label class="font-weight-bold required-mark">*</label>
            <input type="text" class="form-control" autocomplete="off" formControlName="userName" required>
          </div>

          <div *ngIf="f.userName.invalid && (f.userName.dirty || f.userName.touched)" class="alert alert-danger">
            <div *ngIf="f.userName.errors.required" class="validation-error-message">
              User Name is required.
            </div>
            <div *ngIf="f.userName.errors.minlength" class="validation-error-message">
              User Name must be at least minimum length of 6.
            </div>
            <div *ngIf="f.userName.errors.maxlength" class="validation-error-message">
              User Name has a maximum length of 100.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">Email</label><label class="font-weight-bold required-mark">*</label>
            <input type="text" class="form-control" autocomplete="off" formControlName="eMail" required>
          </div>

          <div *ngIf="f.eMail.invalid && (f.eMail.dirty || f.eMail.touched)" class="alert alert-danger">
            <div *ngIf="f.eMail.errors.required" class="validation-error-message">
              Email address is required.
            </div>
            <div *ngIf="f.eMail.errors.email" class="validation-error-message">
              A valid Email address is required.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">Password</label><label class="font-weight-bold required-mark">*</label>
            <input type="password" class="form-control" autocomplete="off" formControlName="passWord" required>
          </div>

          <div *ngIf="f.passWord.invalid && (f.passWord.dirty || f.passWord.touched)" class="alert alert-danger">
            <div *ngIf="f.passWord.errors.required" class="validation-error-message">
              A Password is required
            </div>
            <div *ngIf="f.passWord.errors.pattern" class="validation-error-message">
              Password must contain 1 Capital, 1 Symbol @$!%*?&, at least 1 digit.
            </div>
            <div *ngIf="f.passWord.errors.minlength" class="validation-error-message">
              Password must be at least minimum length of 6.
            </div>
            <div *ngIf="f.passWord.errors.maxlength" class="validation-error-message">
              Password has a maximum length of 100.
            </div>
          </div>

          <div class="form-group">
            <label class="font-weight-bold">Password Repeated</label><label class="font-weight-bold required-mark">*</label>
            <input type="password" class="form-control" autocomplete="off" formControlName="confirmPassword" required>
          </div>

          <div *ngIf="f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)" class="alert alert-danger">
            <div *ngIf="f.confirmPassword.errors.required" class="validation-error-message">
              A Confirm Password is required
            </div>
            <div *ngIf="f.confirmPassword.errors.mustMatch" class="validation-error-message">
              Passwords do not match.
            </div>
          </div>

          <!--<app-messages></app-messages>-->

          <div *ngIf="errorShow == false; else ShowError" class="text-center">
            <p style="color:green">{{saveStatus}}</p>
          </div>

          <ng-template #ShowError>
            <div class="text-center">
              <p style="color: red;">{{saveStatus}}</p>
              <ul>
                <li style="color: red;" *ngFor="let err of errorList">
                  {{ err }}
                </li>
              </ul>
            </div>
          </ng-template>

          <div class="text-center">
            <button type="submit" class="btn btn-primary" [disabled]="!adminNewUserForm.valid">Add New User</button>
            <button type="reset" class="btn btn-secondary" (click)="onReset()" style="margin-left: 1rem;">Clear</button>
          </div>

        </form>

      </div>

      <div class="col-1 col-sm-2 col-md-3">

      </div>

    </div>
    <!--This is a development test button remove later-->
    <!--<div class="row">
      <button type="submit" class="btn btn-danger" (click)="testbuttoncall()">Test Call save RoleChange</button>
    </div>-->
    <!---    -->

  </div>
</div>


