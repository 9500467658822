import { Component, OnInit, OnChanges, SimpleChanges, Input } from "@angular/core";
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from "@angular/forms";
import { DemandType, JobDemand } from "../shared/shared-component";
import { Observable } from "rxjs";
import { filter, debounceTime } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MessageDialog } from '../shared/message-dialog.component';


@Component({
  selector: 'app-demand-mh-item',
  templateUrl: './demand-mh-item.component.html',
  styleUrls: ['./demand-view.component.css']
})

export class DemandMHItemViewComponent implements OnInit, OnChanges {
  @Input() demands: JobDemand[];
  @Input() demandType: DemandType;
  @Input() demandsFormArray: FormArray;
  @Input() isDisabled: boolean;
  @Input() companyRules: any | null;

  companyRule: string | null = null;
  companyMax: string | null = null;
  companyMaxNum: number | null = null;

  public occasionalFromFrequent;
  public occasioinalFromContinous;

  frequentForm: FormGroup;// = new FormGroup({ id: new FormControl() });
  frequentCalc: string = '';
  occasionalForm: FormGroup;// = new FormGroup({ id: new FormControl() });
  occasionalCalc: string = '';
  occasionalCalcNum: number | null = null;
  continuousForm: FormGroup;//= new FormGroup({ id: new FormControl() });
  continuousCalc: string = '';

  private _dontAllowChangeEventFromForm = false;

  // id to key
  private _demandMap: any = {
    7: 'FloorLift',
    8: 'ShoulderLift',
    9: 'CarryBilateral',
    10: 'SingleCarryLeft',
    11: 'SingleCarryRight',
    14: 'BenchLift',
    18: 'AboveShoulderLift'
  };

  constructor(
    private dialog: MatDialog
  ) { }

  _getControlValueChangesWithRestrictions(control: AbstractControl): Observable<any> {
    return control.valueChanges.pipe(
      filter(_ => !this._dontAllowChangeEventFromForm)
    ).pipe(debounceTime(800));
  }

  _roundToNearestPoint5(val:number)
  {
      // round up to nearest .5
      if ((val % 1.0) > 0.5)
      {
          return (val - (val % 1.0)) + 1.0;
      }
      else
      {
        if ((val % 0.5) >= 0.25) {
          return val - (val % 0.5) + 0.5;
        } else {
          return val - (val % 0.5);
        }
      }
  }  

  // disabled
  // _calcOccGuidance(val:string) {
  //   if (val != null && val != '') {
  //     this.occasionalCalc = '';

  //     let freqValue = this.frequentForm.get('value').value;     
  //     if (freqValue == null || freqValue == '') {
  //       this.frequentCalc = Number(Number(val) * 0.75).toFixed(1);
  //     }      

  //     let contValue = this.continuousForm.get('value').value;  
  //     if (contValue == null || contValue == '') {
  //       this.continuousCalc = Number(Number(val) * 0.57).toFixed(1);
  //     }
  //   } else {
  //     this.occasionalCalc = '';
  //     this.frequentCalc = '';
  //     this.continuousCalc = '';
  //   }    
  // }

  // _calcFreqGuidance(val:string) {
  //   if (val != null && val != '') {
  //     this.frequentCalc = '';
  //     let occas = Number(Number(val) / 0.75).toFixed(1);
  //     let cont = Number(Number(occas) * 0.57).toFixed(1);

  //     let occValue = this.occasionalForm.get('value').value;     
  //     if (occValue == null || occValue == '') {
  //       this.occasionalCalc = occas;
  //     }            
      
  //     let contValue = this.continuousForm.get('value').value;  
  //     if (contValue == null || contValue == '') {
  //       this.continuousCalc = cont;
  //     }      
  //   } else {
  //     this.occasionalCalc = '';
  //     this.frequentCalc = '';
  //     this.continuousCalc = '';
  //   }    
  // }
  
  // _calcContGuidance(val:string) {
  //   if (val != null && val != '') {
  //     this.continuousCalc = '';
  //     let occas = Number(Number(val) / 0.57).toFixed(1);
  //     let freq = Number(Number(occas) * 0.75).toFixed(1);

  //     let occValue = this.occasionalForm.get('value').value;     
  //     if (occValue == null || occValue == '') {
  //       this.occasionalCalc = occas;
  //     } 

  //     let freqValue = this.frequentForm.get('value').value;  
  //     if (freqValue == null || freqValue == '') {
  //       this.frequentCalc = freq;
  //     } 
  //   } else {
  //     this.occasionalCalc = '';
  //     this.frequentCalc = '';
  //     this.continuousCalc = '';
  //   }   
  // }  

  _calcOccFromFreq(val:string) {
    if (val != null && val != '' && !isNaN(Number(val))) {
      //let contValue = this.continuousForm.get('value').value;  
      //if (contValue == null || contValue == '' || Number(val) >= Number(contValue)) {
        // console.log('freq[O]', Number(Number(val) / 0.75));
        // console.log('freq[O]', this._roundToNearestPoint5(Number(Number(val) / 0.75)));
        // let occas = this._roundToNearestPoint5(Number(Number(val) / 0.75)).toFixed(1);

        // let occValue = this.occasionalForm.get('value').value;     
        // if (occValue == null || occValue == '' || Number(occas) > Number(occValue)) {
        //   this.occasionalCalcNum = Number(occas);
        //   this.occasionalCalc = occas;
        //   this.occasionalForm.patchValue({
        //     value: occas
        //   });
        // }        
      //}
    }   
  }
  
  _calcOccFromCont(val:string) {
    if (val != null && val != '' && !isNaN(Number(val))) {
      //let freqValue = this.frequentForm.get('value').value;  
      //if (freqValue == null || freqValue == '' || Number(val) >= Number(freqValue)) {
        // console.log('cont[O]', Number(Number(val) / 0.57));
        // console.log('cont[O]', this._roundToNearestPoint5(Number(Number(val) / 0.57)));
        // let occas = this._roundToNearestPoint5(Number(Number(val) / 0.57)).toFixed(1);
        // // console.log('cont[F]', Number(Number(occas) * 0.75));
        // // console.log('cont[F]', this._roundToNearestPoint5(Number(Number(occas) * 0.75)));
        // // let freq = this._roundToNearestPoint5(Number(Number(occas) * 0.75)).toFixed(1);        

        // let occValue = this.occasionalForm.get('value').value;     
        // if (occValue == null || occValue == '' || Number(occas) > Number(occValue)) {
        //   this.occasionalCalcNum = Number(occas);
        //   this.occasionalCalc = occas;
        //   this.occasionalForm.patchValue({
        //     value: occas
        //   });
        // }  
        
        // this.frequentForm.patchValue({
        //   value: freq
        // });        
      //}
    }  
  }   

  _calcOccFromAll(occVal:string, freqVal:string, contVal:string) {
    // console.log(occVal, freqVal, contVal);
    let occEquivalent = 0.0;
    if (occVal != null || occVal != '') {
      occEquivalent = Number(occVal);
    }
    let freqOccEquivalent = 0.0;
    if (freqVal != null || freqVal != '') {
      // console.log('freqVal', Number(Number(freqVal) / 0.75));
      // console.log('freqVal', this._roundToNearestPoint5(Number(Number(freqVal) / 0.75)));
      freqOccEquivalent = Number(freqVal) / 0.75;
    }
    let contOccEquivalent = 0.0;
    if (contVal != null || contVal != '') {
      // console.log('contVal', Number(Number(contVal) / 0.57));
      // console.log('contVal', this._roundToNearestPoint5(Number(Number(contVal) / 0.57)));
      contOccEquivalent = Number(contVal) / 0.57;
    }
    if (contOccEquivalent > freqOccEquivalent) {
      if (contOccEquivalent > occEquivalent) {
        this.occasionalCalc = this._roundToNearestPoint5(contOccEquivalent).toFixed(1);
      } else {
        this.occasionalCalc = this._roundToNearestPoint5(occEquivalent).toFixed(1);
      }
    } else {
      if (freqOccEquivalent > occEquivalent) {
        this.occasionalCalc = this._roundToNearestPoint5(freqOccEquivalent).toFixed(1);
      } else {
        this.occasionalCalc = this._roundToNearestPoint5(occEquivalent).toFixed(1);
      }
    }
  }
  
  ngOnInit(): void {
    // load form values
    for (var i = 0, len = this.demands.length; i < len; i++) {
      var demand = this.demands[i];
      var demandForm = new FormGroup({
        id: new FormControl(''),
        value: new FormControl(''),
        frequency: new FormControl(''),
        demandType: new FormGroup({
          id: new FormControl(this.demandType.id),
        })
      });
      if (demand) {
        demandForm.patchValue(demand);

        if (demand.frequency == "O") {
          this.occasionalForm = demandForm;   
          this.occasionalCalc = demand.value; 
          if (this.occasionalCalc) {
            this.occasionalCalcNum = Number(this.occasionalCalc);
          }          
        } else if (demand.frequency == "F") {
          this.frequentForm = demandForm;          
        } else if (demand.frequency == "C") {
          this.continuousForm = demandForm;          
        } else {
          demandForm.patchValue({
            "frequency": "O"
          })
          this.occasionalForm = demandForm;
        }
      }
      // console.log("Send to Array");
      this.demandsFormArray.push(demandForm);
    };

    // calculate initial (not sure if this is still needed)
    // if (this.occasionalForm) {
    //   this.occasionalFromFrequent = Math.ceil(this.frequentForm.value.value / 0.75) * .5;
    //   this.occasioinalFromContinous = Math.ceil(this.continuousForm.value.value / 0.57) * .5;
    //   if (this.occasioinalFromContinous != null && this.occasioinalFromContinous != "" && this.occasioinalFromContinous > this.occasionalFromFrequent) {
    //     this.occasionalForm.setValue({
    //       id: this.occasionalForm.value.id,
    //       value: this.occasioinalFromContinous,
    //       frequency: this.occasionalForm.value.frequency,
    //       demandType: this.occasionalForm.value.demandType
    //     });
    //   }
    //   if (this.occasionalFromFrequent != null && this.occasionalFromFrequent != "" && this.occasionalFromFrequent > this.occasioinalFromContinous) {
    //     this.occasionalForm.setValue({
    //       id: this.occasionalForm.value.id,
    //       value: this.occasionalFromFrequent,
    //       frequency: this.occasionalForm.value.frequency,
    //       demandType: this.occasionalForm.value.demandType,
    //     });
    //   }
    // }

    // set defaults if unable to load values
    if (!this.frequentForm) {
      this.frequentForm = new FormGroup({
        id: new FormControl(),
        value: new FormControl(),
        frequency: new FormControl("F"),
        demandType: new FormGroup({
          id: new FormControl(this.demandType.id),
        })
      });
    }

    if (!this.occasionalForm) {
      this.occasionalForm = new FormGroup({
        id: new FormControl(),
        value: new FormControl(),
        frequency: new FormControl("O"),
        demandType: new FormGroup({
          id: new FormControl(this.demandType.id),
        })
      });

    }

    if (!this.continuousForm) {
      this.continuousForm = new FormGroup({
        id: new FormControl(),
        value: new FormControl(),
        frequency: new FormControl("C"),
        demandType: new FormGroup({
          id: new FormControl(this.demandType.id),
        })
      });
    }

    // disable: set guidance labels
    // let occValue = this.occasionalForm.get('value').value;
    // let freqValue = this.frequentForm.get('value').value;
    // let contValue = this.continuousForm.get('value').value;
    // //console.log(occValue, freqValue, contValue);
    // if (occValue != null && occValue != '') {
    //   this._calcOccGuidance(occValue);
    // }
    // if (freqValue != null && freqValue != '') {
    //   this._calcFreqGuidance(freqValue);
    // }
    // if (contValue != null && contValue != '') {
    //   this._calcContGuidance(contValue);
    // }
    
    // set Test Criteria if O/F/C updated
    this._getControlValueChangesWithRestrictions(this.occasionalForm).subscribe(e => {
      this._calcOccFromAll(e.value, this.frequentForm.get('value').value, this.continuousForm.get('value').value);
    });  

    this._getControlValueChangesWithRestrictions(this.frequentForm).subscribe(e => {
      this._calcOccFromAll(this.occasionalForm.get('value').value, e.value, this.continuousForm.get('value').value);
    });    

    this._getControlValueChangesWithRestrictions(this.continuousForm).subscribe(e => {
      this._calcOccFromAll(this.occasionalForm.get('value').value, this.frequentForm.get('value').value, e.value);
    });    
    // init the ui
    this._calcOccFromAll(this.occasionalForm.get('value').value, this.frequentForm.get('value').value, this.continuousForm.get('value').value);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    // console.log('demand-mh this.companyRules', this.companyRules);
    if (this.companyRules != null && this.companyRule == null) {
      if (this.demands.length > 0 && this._demandMap[this.demands[0].demandType.id] in this.companyRules) {
        this.companyRule = this.companyRules[this._demandMap[this.demands[0].demandType.id]];
        this.companyMax = this.companyRules[this._demandMap[this.demands[0].demandType.id] + 'Max'];   
        if (this.companyMax != null) {
          this.companyMaxNum = Number(this.companyMax);
          this.companyMax = this.companyMaxNum.toFixed(1);
        }
        //console.log(this.companyRule, this.companyMax);     
      }
    }
  }  

  public onWarnClick() {
    const dialogRef = this.dialog.open(MessageDialog, {
      data: {
        title: 'Warning',
        message: 'Company rules have been applied.'
      }
    });
  } 
}
