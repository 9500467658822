import { Component, OnInit, OnChanges, SimpleChanges, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MessageDialog } from '../shared/message-dialog.component';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from "@angular/forms";
import { JobDemand } from "../shared/shared-component";

@Component({
  selector: 'app-demand-pt-item',
  templateUrl: './demand-pt-item.component.html',
  styleUrls: ['./demand-view.component.css']
})

export class DemandPTItemViewComponent implements OnInit, OnChanges {
  @Input() demand: JobDemand;
  @Input() demandsFormArray: FormArray;
  @Input() isDisabled: boolean = false;
  @Input() companyRules: any | null;

  companyRule: string | null = null;
  companyRating: string | null = null;

  demandForm = new FormGroup({
    id: new FormControl(),
    value: new FormControl(),
    frequency: new FormControl(),
    optional: new FormControl(),
    demandType: new FormGroup({
      id: new FormControl(),
    })
  });

  // id to key
  private _demandMap: any = {
    1: 'Kneel',
    2: 'ReachForward',
    3: 'ReachExtended',
    4: 'Stoop',
    5: 'Squat',
    6: 'StairClimb',
    12: 'LadderClimb',
    13: 'Crawl',
    15: 'ReachSide'
  };

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.demandForm.patchValue(this.demand);
    this.demandsFormArray.push(this.demandForm);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    // console.log('demand-pt this.companyRules', this.companyRules);

    if (this.companyRules != null && this.companyRule == null) {
      if (this._demandMap[this.demand.demandType.id] in this.companyRules) {
        this.companyRule = this.companyRules[this._demandMap[this.demand.demandType.id]];
        this.companyRating = this.companyRules[this._demandMap[this.demand.demandType.id] + 'Rating'];        
      }
    }
  }

  public onWarnClick() {
    const dialogRef = this.dialog.open(MessageDialog, {
      data: {
        title: 'Warning',
        message: 'Company rules may apply and override this setting. Contact your administrator if you want more information.'
      }
    });
  }  
}
