  <h1 mat-dialog-title>Add Medical Condition</h1>

  <mat-dialog-content class="mat-typography" style="min-height: 390px;">
    <form [formGroup]="medicalClearanceForm">
      <input id="status" type="hidden" formControlName="status" value="PENDING">
      <div class="form-row">
        <mat-form-field style="width: 475px;">
          <mat-label>Condition *</mat-label>
          <mat-select formControlName="condition" (selectionChange)="onHealthConditionChange($event)">
            <mat-option value="Blood Pressure" *ngIf="data.clearanceId > 0">
              Blood Pressure
            </mat-option>
            <mat-option *ngFor="let hc of healthConditions" value={{hc.value}}>
              {{hc.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="outline" style="width: 475px;">
          <mat-label>
            Condition Notes
          </mat-label>
          <textarea matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="5"
                    cdkAutosizeMaxRows="5"
                    id="otherInfo" formControlName="otherInfo" required></textarea>
        </mat-form-field>
      </div>

      <div class="form-row" formGroupName="practice">
        <mat-form-field style="width:475px;">
          <mat-label>Select Practice</mat-label>
          <mat-autocomplete #autoPractice="matAutocomplete" panelClass="bigPanel" (optionSelected)="setPracticeFormData($event)">

            <mat-option *ngFor="let practice of filteredPractices" [value]="practice" style="width:1000px">
              {{practice.name}}
            </mat-option>
          </mat-autocomplete>
          <input type="text" matInput [matAutocomplete]="autoPractice" (keyup)="onPracticeKey($event)" formControlName="name" />
          <input type="hidden" formControlName="id" />
        </mat-form-field>
      </div>

      <div class="form-row" formGroupName="doctor">
        <mat-form-field style="width:475px;">
          <mat-label>Select Doctor</mat-label>
          <input type="text" matInput [matAutocomplete]="autoDoctor" formControlName="name" (keyup)="onDoctorKey($event)" id="name" />
          <mat-autocomplete #autoDoctor="matAutocomplete" panelClass="bigPanel" (optionSelected)="setDoctorFormData($event)">
            <mat-option *ngFor="let doctor of filteredDoctors" [value]="doctor" style="width:1000px">
              {{doctor.name}}
            </mat-option>
          </mat-autocomplete>
          <input type="hidden" formControlName="id" />
        </mat-form-field>
      </div>

    </form>

    <mat-dialog-actions>
      <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Cancel</button>
      <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onSubmit()" [disabled]="!medicalClearanceForm.valid" type="submit">Save</button>
    </mat-dialog-actions>
