import { Component, Inject, OnInit } from '@angular/core';
import { PEFARequestService } from './pefarequest.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PEFARequest, Job, ReferralType } from '../shared/shared-component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SharedService } from '../shared/shared.service';
import moment from 'moment';

@Component({
  selector: 'app-pefarequest-entry',
  templateUrl: './pefarequest-entry.component.html'
})

export class PEFARequestEntryComponent implements OnInit {
  pefaRequestService: PEFARequestService;
  sharedService: SharedService;
  referralTypeOptions: ReferralType[];

  model: PEFARequest;

  jobOptions: Job[];
  filteredJobs: Job[];

  pefaRequestForm = new FormGroup({
    Worker: new FormGroup({
  //    id: new FormControl(''),
      firstName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(moment()),
      lastName: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      home: new FormControl(''),
      gender: new FormControl(''),
      work: new FormControl(''),
      site: new FormControl(''),
      emailAddress: new FormControl('', Validators.required)
    }),
    RequestedTime: new FormControl(moment()),
    AppointmentDate: new FormControl(moment()),
    Recruiter: new FormControl(''),
    ResponseTime: new FormControl(''),
    RequesterEmail: new FormControl(''),
    PefaJob: new FormGroup({
//      id: new FormControl(''),
      Site: new FormControl(''),
      Name: new FormControl(''),
      Job: new FormGroup({
        id: new FormControl('0'),
      })
    }),
    PaymentMethod: new FormControl('', Validators.required),
    //Status: new FormControl(''),
    ReferralType: new FormGroup(
      {
        id: new FormControl('', Validators.required),
      }
    ),
    otherInfo: new FormControl(''),
  });

  constructor(
    pefaRequestService: PEFARequestService,
    private fb: FormBuilder,
    private router: Router,
    sharedService: SharedService
) {
    this.pefaRequestService = pefaRequestService;
    this.sharedService = sharedService;

  }

  ngOnInit() {
    this.pefaRequestService.getJobs().subscribe(result =>
      this.jobOptions = result);
    this.sharedService.getReferralTypes().subscribe(result =>
      this.referralTypeOptions = result);

  }

  displayFn(user?: Job): string | undefined {
    return user ? user.name : undefined;
  }

  onJobKey(event: KeyboardEvent) { // with type info
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredJobs = this.jobOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);

    if (this.filteredJobs.length == 1 && this.filteredJobs[0].name.toLowerCase() == filterValue) {
      let jobId = this.filteredJobs[0].id;
      let jobName = this.filteredJobs[0].name;
      this.pefaRequestForm.patchValue({
        PefaJob: {
          Name: jobName,
          Job: {
            id: jobId
          }
        }
      });
    } else {
      this.pefaRequestForm.patchValue({
        PefaJob: {
          Job: {
            id: '0'
          }
        }
      });


    }

  }

  setJobFormData($event: MatAutocompleteSelectedEvent) {
    let jobId = $event.option.value.id;
    let jobName = $event.option.viewValue;
    this.pefaRequestForm.patchValue({

      PefaJob: {
        Name: jobName,
        Job: {
          id: jobId
        }
      }
    });
  }

  onSubmit() {

    //model = new PEFARequest('',new Date(), this.pefaRequestForm.get()

    this.pefaRequestService.createNewPEFARequest(this.pefaRequestForm.value).subscribe();    
    this.router.navigateByUrl("/thanks");
  }

}
