import { Component, OnInit, Inject, ViewChild, Provider, Output, EventEmitter, Input } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Company } from "../shared/shared-component";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { MatDialog } from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: 'app-office-company-list',
  templateUrl: './office-company-list.component.html',
})

export class OfficeCompanyListComponent implements OnInit {

  public officeAdminService: OfficeAdminPEFAService;
  public companies: Company[] = [];

  currentListId: number = -1;
  loading: boolean = true;
  active: boolean = true;

  //Material Table
  displayedColumns2 = [
    "name",
    "address",
    "abn",
    "status",
    "action",
  ];

  dataSource: MatTableDataSource<Company>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadAllCompanies();
  }

  constructor(public dialog: MatDialog, officeAdminService: OfficeAdminPEFAService, public fb: FormBuilder, public messageService: MessageService) {
    this.officeAdminService = officeAdminService;
    this.dataSource = new MatTableDataSource();
  }

  //Material Table
  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  toggleActive(event: MatCheckboxChange) {
    this.active = event.checked;
    if (this.active) {
      this.dataSource.data = this.companies.filter(p => p.status != 'INACTIVE');
    } else {
      this.dataSource.data = this.companies;
    }
  }

  loadAllCompanies() {
    this.officeAdminService.getAllCompany().subscribe(
      result => {
        this.companies = result;
        if (this.active) {
          this.dataSource.data = result.filter(p => p.status != 'INACTIVE');
        } else {
          this.dataSource.data = result;
        }
         
        this.loading = false;
    })
  }

  delete(id: string) {
    this.loading = true;
    this.officeAdminService.deleteCompany(id).subscribe(() => {
      this.loadAllCompanies();
    }
    );
  }
}
