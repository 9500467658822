import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { PrimeIcons } from "primeng/api";
import { map } from "rxjs/operators";
import { AddMedicalClearanceDialog } from "../../medicalclearance/add-medical-clearance-dialog.component";
import { MedicalClearanceService } from "../../medicalclearance/medicalclearance.service";
import { MessageService } from "../../message/message.service";
import { PEFARequestService } from "../../pefarequest/pefarequest.service";
import { ConfirmDialog } from "../confirm-dialog.component";
import { NoteDialog } from "../note-dialog.component";
import { JOBFIT_DATETIME_FORMAT, NoteView, PEFARequest } from "../shared-component";
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-note-view-medical',
  templateUrl: './note-view-medical.component.html',
  styleUrls: ['./note-view-list.css']
})

export class NoteViewMedicalComponent {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  @Input()
  noteView: NoteView;

  @Input()
  requestID: string;

  @Output() changed = new EventEmitter<boolean>();

  constructor(public medicalService: MedicalClearanceService,
    public pefaService: PEFARequestService,
    private dialog: MatDialog, public messageService:MessageService) { }
   
  public delete() {
    this.medicalService.delete(this.noteView.id, this.requestID).subscribe(result => {
      this.changed.emit();
    });
  }

  public edit() {
    const dialogRef = this.dialog.open(AddMedicalClearanceDialog, {
      data: {
        clearanceId: this.noteView.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.changed.emit(result);
    });
  }



  public complete() {

    var clearance = this.noteView.details;
    clearance.status = "COMPLETED";
    clearance.outcome = "DECLINED";
    if (clearance.doctor && !clearance.doctor.id) {
      delete clearance.doctor
    }

    this.medicalService.saveMedicalClearance(this.noteView.id, clearance).subscribe(result => {
        this.changed.emit();
    });
  }

}
