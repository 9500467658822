import { Component, OnInit, Inject, ViewChild, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { MessageService } from '../message/message.service';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Note, PEFARequest } from "../shared/shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { ConfirmDialog } from "./confirm-dialog.component";
import { NoteDialog } from "./note-dialog.component";
import { JOBFIT_DATETIME_FORMAT } from "../shared/shared-component";


@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.css']
  
})

export class NoteListComponent {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  private _notes: Note[] = [];
  public dataSource: MatTableDataSource<Note> = new MatTableDataSource<Note>();
  @Input() pefaId: string;
  @Output() changed: EventEmitter<boolean> = new EventEmitter(true);



  detail = [
    //"important",
    "content",
    "createdBy",
    "createdDate",
    "adminOnly",
    "actions",

  ];

  summary = [
    "content",
    "createdDate",
  ];

  displayedColumns = this.detail;

  @Input() view: string = "detail";

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //Modal
  constructor(public pefaService: PEFARequestService, public messageService: MessageService, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource<Note>();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = "ACTIVE"
    this.loadNotes();
    console.log("View type : " + this.view);
    if (this.view == "summary") {
      this.displayedColumns = this.summary;
      this.dataSource.data = this._notes.filter(function (element, index, array) {
        return element.important;
      })
    } else {
      this.displayedColumns = this.detail;
    }
  }

  loadNotes() {
    this.pefaService.getPEFANotes(this.pefaId).subscribe(response => {
      this._notes = response;
      if (this.view == "summary") {
        this.displayedColumns = this.summary;
        this.dataSource.data = this._notes.filter(function (element, index, array) {
          return element.important;

        })
      } else {
        this.displayedColumns = this.detail;
        this.dataSource.data = this._notes;
      }

      
    }
    );
  }

   public get notes() {
    return this._notes;
  }


  public deleteNote(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {message:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {
        var note = this._notes.find(n => n.id == id);
        console.log(JSON.stringify(result));
        var values = {
          id: id,
          status: "ARCHIVE",
          content: note.content,
          reason: result.message,
          important: note.important,
          adminOnly: note.adminOnly,
        }
        this.pefaService.savePefaNote(this.pefaId, values).subscribe(result => {
          this.messageService.add("Note Archived");
          this.changed.emit();
        });
      }
    });
  }

  public editNote(id: string) {
    const dialogRef = this.dialog.open(NoteDialog, {
      data: { message: true, noteId: id, pefaId: this.pefaId}
    });

    dialogRef.afterClosed().subscribe(null,null, () => {
        this.messageService.add("Note Updated");
        this.loadNotes();
        this.changed.emit();
      }
    );
  }      
}
