
  <mat-sidenav-container class="sidenav-container">
    <!-- Sidebar -->

    <mat-sidenav
                 #sidenav mode="side" opened fixedBottomGap="0">

        <mat-nav-list *ngIf="navMenuItems">
            <a *ngFor="let navMenuItem of navMenuItems" mat-list-item [routerLinkActive]='["link-active"]' [routerLink]='[navMenuItem.link]'>
              <span>{{navMenuItem.label}}</span>
            </a>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>

      <!--Page content insertion-->
      <!--<router-outlet></router-outlet>-->
      <!--<main role="main" class="col-md-10 ml-sm-auto col-lg-10 px-4">-->
      <app-messages></app-messages>
      <router-outlet></router-outlet>
      <!--</main>-->

    </mat-sidenav-content>
  </mat-sidenav-container>
