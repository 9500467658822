import { FormControl, Validators, FormGroup, FormArray } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MessageService } from "../message/message.service";


@Component({
  selector: 'dialog-modal-add-attachment',
  templateUrl: 'pefa-add-attachment-dialog.component.html',
})
export class AddAttachmentDialog implements OnInit {

  type: string;
  filename: string;

  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<AddAttachmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pefaService: PEFARequestService, public messageService: MessageService) {
  }



  ngOnInit(): void {
  };

 
  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }


  onSubmit(files: File): void {
    var pefaId = this.data.pefaId;


    let fileToUpload = <File>files[0];

    var filesName = fileToUpload.name;

    if (this.filename.trim().length == 0) {
      this.filename = filesName;
    }

    var names = filesName.split(".");

    if (!this.filename.endsWith("." + names[1])) {
      this.filename += "." + names[1];
    }


    this.pefaService.addAttachment(pefaId, fileToUpload, this.filename, this.type).subscribe(() => {
      this.messageService.add("Files successfully attached");
      this.dialogRef.close(true);
    }
    );

  }

}
