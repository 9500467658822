import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Report } from "../shared/shared-component";

@Injectable({
  providedIn: 'root'
})
export class ReportService {


  private client: HttpClient; 
  private baseURL: string;
  private requestURL = 'api/Reports'

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.client = http;
    this.baseURL = baseUrl;
  }


  public getReports(): Observable<Report[]> {
    return this.client.get<Report[]>(this.requestURL);
  }

  public getReport(id:string): Observable<Report> {
    return this.client.get<Report>(this.requestURL + "/" + id);
  }

  generateReport(id: string, value: FormData) {
    return this.client.post(this.requestURL + "/" + id, value, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
