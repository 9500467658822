import { FormControl, Validators, FormGroup, FormArray } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MessageTemplate, Booking, PEFARequest, Attachment, JOBFIT_DATE_FORMAT } from "../shared/shared-component";
import { SharedService } from "../shared/shared.service";
import { MessageService } from "../message/message.service";
import { MatSelectChange } from "@angular/material/select";
import moment from "moment";


@Component({
  selector: 'dialog-modal-send-message',
  templateUrl: 'pefa-message-send-dialog.component.html',
})
export class SendMessageDialog implements OnInit {
  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  public messageForm = new FormGroup({
    subject: new FormControl(''),
    content: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required),
    type: new FormControl('SMS', Validators.required),
    bookingId: new FormControl(''),
    attachments: new FormArray([]),
  });

  messageTemplates: MessageTemplate[] = [];
  bookings: Booking[] = [];
  pefaRequest: PEFARequest;
  selectedTemplate: MessageTemplate;


  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<SendMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pefaRequestService: PEFARequestService, public sharedService: SharedService) {
  }



  ngOnInit(): void {
    this.pefaRequestService.getPEFABookings(this.data.pefaId).subscribe(
      bookings => {
        this.bookings = bookings;
        if (this.bookings.length > 0) { 
          this.messageForm.patchValue({
            'bookingId': bookings[0].id
          })
        }
      } , error => console.error(error));

    this.pefaRequestService.getPEFARequest(this.data.pefaId).subscribe(
      pefa => {
        this.pefaRequest = pefa;
        this.pefaRequestService.getPEFAAttachments(this.pefaRequest.id).subscribe(response => {
          for (let entry of response) {
            this.addAttachment(entry);
          };
        }
        );
      }, error => console.error(error));

    this.sharedService.getMessageTemplates().subscribe(
      templates => {
        this.messageTemplates = templates;
      }, error => console.error(error));

  };


  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(): void {

    var value = this.messageForm.value;
    if (this.messageForm.controls.bookingId.value == '') {
      delete value.bookingId;
    }
    this.pefaRequestService.sendMessage(this.data.pefaId, value).subscribe(
      () => {
        this.dialogRef.close(true);
      }
    );

  }

  public updateContent(): void {
    var objectId = this.data.pefaId;

    if (this.selectedTemplate.object == 'BOOKING') {
      if (this.bookings.length == 0) {
        return;
      }
      objectId = this.messageForm.get('bookingId').value;
    }

    this.sharedService.getMessageFromTemplates(this.selectedTemplate.id, objectId).subscribe(content => {
      this.messageForm.patchValue({
        'content': content
      })
    });

  }

  getAssessorName(): string {

    var objectId = this.messageForm.get('bookingId').value;
    var book = this.bookings.find(r => r.id == objectId);
    if (book.assessor && book.assessor) {
      return book.assessor.firstName + " " + book.assessor.lastName;
    } else {
      return '';
    }

  }

  getProviderName(): string {
    var objectId = this.messageForm.get('bookingId').value;
    var book = this.bookings.find(r => r.id == objectId);
    return book.provider.name;
  }


  get attachments() : FormArray {
    return this.messageForm.get('attachments') as FormArray

  }

  addAttachment(attachment :Attachment) {

    this.attachments.push(new FormGroup({

      selected: new FormControl(false),
      id: new FormControl(attachment.id),
      name: new FormControl(attachment.filename),
      type: new FormControl(attachment.type),
      date: new FormControl(moment(attachment.attachmentDate)),
    }));

  }

}
