import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { PrimeIcons } from "primeng/api";
import { map } from "rxjs/operators";
import { MessageService } from "../../message/message.service";
import { PEFARequestService } from "../../pefarequest/pefarequest.service";
import { ConfirmDialog } from "../confirm-dialog.component";
import { JOBFIT_DATETIME_FORMAT, NoteView, PEFARequest, Timezone } from "../shared-component";
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-note-view-booking',
  templateUrl: './note-view-booking.component.html'
})

export class NoteViewBookingComponent {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;
  public localtime: String;
  public apptDateTime: Date;


  @Input()
  noteView: NoteView;

  @Input()
  pefa: PEFARequest;

  @Output() changed = new EventEmitter<boolean>();

  constructor(public pefaService: PEFARequestService, public dialog: MatDialog) { 
  }

  ngOnInit(): void {
    if (this.noteView) {
      // is previously hasn't been set choose default (Australia/Brisbane)
      this.noteView.details.appointmentTimezone = this.noteView.details.appointmentTimezone ?? Timezone.DEFAULT;
      this.localtime =  Timezone.convertUtcToTZ(this.noteView.details.appointmentDateTime, moment.tz.guess());
      // convert to the time at appointment location
      this.apptDateTime = Timezone.convertUtcToTZAsDateObject(this.noteView.details.appointmentDateTime, this.noteView.details.appointmentTimezone);
    }
  }

  public refresh() {
    this.changed.emit(true);
  }
}
