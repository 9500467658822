import { Component, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Booking, PEFARequest, Timezone } from "../shared/shared-component";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { JOBFIT_DATE_FORMAT, JOBFIT_TIME_FORMAT } from "../shared/shared-component";
import moment from "moment";


@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css']
})

export class BookingListComponent {
  public readonly dateFormat = JOBFIT_DATE_FORMAT;
  public readonly timeFormat = JOBFIT_TIME_FORMAT;

  private _bookings: Booking[] = [];
  public time = moment.tz.guess();
  public dataSource: MatTableDataSource<Booking>;
  @Input() pefaRequest: PEFARequest;

  @Output() changed = new EventEmitter<boolean>();


  displayedColumns = [
    "appointmentdate",
    "region",
    "status",
    "workerStatus",
    "type",
    "provider",
    "assessor",
    "notes",
    "actions",

  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //Modal

  constructor(public pefaService: PEFARequestService) {
    this.dataSource = new MatTableDataSource<Booking>();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pefaService.getPEFABookings(this.pefaRequest.id).subscribe(response => {
      this._bookings = response;
      this._bookings.forEach((x) => {
        // is previously hasn't been set choose default (Australia/Brisbane)
        x.appointmentTimezone =  x.appointmentTimezone ?? Timezone.DEFAULT;
        x.localtime =  Timezone.convertUtcToTZ(x.appointmentDateTime, moment.tz.guess());
        // convert to the time at appointment location
        x.appointmentDateTime = Timezone.convertUtcToTZAsDateObject(x.appointmentDateTime, x.appointmentTimezone);
      });
      this.dataSource.data = this._bookings;
    });
  }

  reloadList() {

    this.pefaService.getPEFABookings(this.pefaRequest.id).subscribe(response => {
      this._bookings = response;
      this.dataSource.data = this._bookings;
      this.changed.emit();
    }
    );

  }

  public get bookings() {
    return this._bookings;
  }

}
