<ng-template #NoCompanyToList>
  <p *ngIf="loading == false">There are no Notes from this request</p>
</ng-template>

<div class="container-fluid" *ngIf="notes.length > 0; else NoCompanyToList">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.id }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="content">
      <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="highlight">Content</mat-header-cell>
      <mat-cell *matCellDef="let row" >{{ row.content}} </mat-cell> 
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
      <mat-cell *matCellDef="let row" ><span *ngIf="row.audit && row.audit.createdBy else elseName">{{row.audit.createdBy.userName}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span *ngIf="row.audit else elseName">{{ row.audit.createdDate| date:dateFormat }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="adminOnly">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Admin Only</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.adminOnly}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="important">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Important</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row"><mat-icon *ngIf="row.important" matTooltipClass="class-tooltip"  class="warn" aria-hidden="false" style="color: red;" aria-label="Important">priority_high</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">
        <button (click)="editNote(row.id)" mat-raised-button   type="button"><mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon></button>
        <button (click)="deleteNote(row.id)" mat-raised-button  type="button"><mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon></button>

      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{'highlight' : row.important}"></mat-row>
  </mat-table>

</div>
<mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
