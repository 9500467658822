import { Component, AfterViewInit, Input, OnInit } from "@angular/core";
import { Report, JOBFIT_DATE_FORMAT, ReportCriteria } from "../shared/shared-component";
import { FormBuilder, FormGroup, FormControl, RequiredValidator, Validators } from "@angular/forms";
import { ReportService } from "./report.service";
import moment from "moment";

@Component({
  selector: 'app-criteria-date',
  templateUrl: './date-criteria.component.html',
})

export class DateCriteriaComponent implements OnInit {

  @Input() criteria: ReportCriteria;
  public loading: boolean = true;
  public readonly dateFormat = JOBFIT_DATE_FORMAT;

  @Input() criteriaForm: FormGroup

  constructor(
    private fb: FormBuilder,
    public reportService: ReportService,
  ) {

  }

  public ngOnInit() {
    if (this.criteria.required) {
      this.criteriaForm.addControl(this.criteria.parameterName, new FormControl(moment(), Validators.required));
    } else {
      this.criteriaForm.addControl(this.criteria.parameterName, new FormControl(''));
    }
  }

}

