<h1>PEFA Requests</h1>

<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>

<div class="mat-elevation-z8">
  <mat-table #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.worker"><a [routerLink]="['/pefarequest', row.id]">{{ row.worker.firstName}} {{ row.worker.lastName}}</a></span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="job.title">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.pefaJob else elseName">{{ row.pefaJob.job.name }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="site">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Job Site</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.pefaJob else elseName">{{ row.pefaJob.site }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="referrerDetails.name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Requested By</mat-header-cell>
      <mat-cell *matCellDef="let row"><span *ngIf="row.referrerDetails else elseName">{{ row.referrerDetails.name }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="appointmentdate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Appointment Date</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.appointmentDate| date:'shortDate' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="responseTime">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Response Date</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.responseTime}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.status }} - {{row.statusNote}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="score">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Score</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.score }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="messages">
      <mat-header-cell mat-header-cell *matHeaderCellDef>Messages</mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{ row.messages.length}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="report">
      <mat-header-cell mat-header-cell *matHeaderCellDef [ngClass]="'reportCol'"></mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row" [ngClass]="'reportCol'">
        <div *ngIf="row.report; else elseBlock">
          <a mat-raised-button style="text-decoration: none;" href="/api/PEFARequests/getReport/{{row.report.id}}" download>D/L</a>
        </div>
        <ng-template #elseBlock>

          <button mat-raised-button href="" [disabled]="true">D/l</button>
        </ng-template>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
</div>

<ng-template #elseName>
  <span>&nbsp;</span>
</ng-template>
