<h1 mat-dialog-title>Make - PEFA Booking</h1>
<div class="container-fluid" mat-dialog-content>
  <div class="row">
    <div class="col" *ngIf="groupedProviders && groupedProviders.length > 0">

      <form class="material-form">
        <!--Provider select row-->
        <div class="col">
          <div class="row mt-2">

            <div>
              <mat-form-field class="formFieldWidth350">
                <mat-label>Select Provider</mat-label>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="updateAssessorList($event)" class="bigPanel">
                  <mat-optgroup *ngFor="let listProviders of groupedProviders" [label]="listProviders[0]" [disabled]="false">
                    <mat-option *ngFor="let provider of listProviders[1]" [value]="provider" style="width:1000px;" [class]="classForProvider(provider)">
                      <ng-container><p>{{provider.name}} | {{provider.ladderRatingKG}}KG | <small>Location <span *ngIf="provider.location">{{provider.location.address}}</span></small></p>                </ng-container>
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>

                <input type="text" matInput [matAutocomplete]="auto" (keyup)="onProviderKey($event)" />
                <input type="hidden" [(value)]="selectedProvider" />
              </mat-form-field>
            </div>
            <div>
              <span><mat-checkbox (click)="showAllItems()">Show All</mat-checkbox></span>&nbsp;
              <span><a href="https://www.google.com/maps/d/viewer?mid=1NIuuqNNnJafVyyKz-yrcrZrnnPXYfgXF&ll=-27.556354762175797%2C133.95890250000002&z=5" target="_blank">View Map</a></span>
            </div>
          </div>

          <div class="row mt-2" *ngIf="locations && locations.length > 1">
            <div>
              <mat-form-field class="formFieldWidth350">
                <mat-label>Select Provider Location</mat-label>
                <mat-select [(value)]="selectedLocation" panelClass="bigPanel">
                  <mat-option *ngFor="let location of locations" [value]="location.id" style="width:1000px">
                    <ng-container><p>{{location.address}}</p></ng-container>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
          <div *ngIf="company != null && company.bookingNotes != null" class="row mt-2">
            <div class="alert alert-warning" role="alert" style="width: 370px;padding: 0.5rem;font-size: 0.9em;">
              {{company.bookingNotes}}
            </div>
          </div>
          <div class="row mt-2">
            <div>
              <mat-form-field class="formFieldWidth350">
                <mat-label>Select Assessor</mat-label>
                <mat-select [(value)]="selectedAssessor" (selectionChange)="evaluateProviderStatus()">
                  <mat-option *ngFor="let assessor of assessors" value={{assessor.id}}>
                    {{assessor.firstName}} {{assessor.lastName}} {{assessor.qualifications}} {{assessor.assessmentCount}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-2">
            <div>
              <mat-form-field class="formFieldWidth350">
                <mat-label>Select Appointment Type</mat-label>
                <mat-select [(value)]="selectedBooking">
                  <mat-option *ngFor="let appointment of bookingTypes" value={{appointment.id}}>
                    {{appointment.displayName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!--date Picker row-->
          <div class="row mt-2">
            <div>
              <mat-form-field>
                <mat-label> Select Appointment Date</mat-label>
                <input matInput [min]="minBookingDate" [matDatepicker]="picker" [(ngModel)]="bookingDate" name="picker" placeholder="Choose a date" (dateChange)="bookingDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="row mt-2">
            <div class="time-container mr-2">
              <mat-form-field>
                <!-- <mat-label ng-model="time">Your Time - {{time}}</mat-label> -->
                <mat-label ng-model="time">Appointment Time</mat-label>
                <input matInput [(ngModel)]="bookHourTime" name="picker" placeholder="Choose a time" (change)="onTimeChange()">
                <mat-hint>My Time: {{localTime}}</mat-hint>
              </mat-form-field>
            </div>
            <div class="timezone-container">
              <mat-form-field>
                <mat-label>Appointment Timezone</mat-label>
                <mat-select [(value)]="selectedTimeZone" (selectionChange)="onTimezoneChange($event)">
                  <mat-option *ngFor="let tz of timezones" value={{tz.value}} class="tz-item">
                    {{tz.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <app-availability></app-availability>
          </div>

          <!--Status dropdown row-->
          <div class="row mt-3">
            <div>
              <mat-form-field>
                <mat-label>Select Provider Status</mat-label>
                <mat-select [(value)]="selectedStatus">
                  <mat-option value={{bookStatusEnum.NEW}}>NEW</mat-option>
                  <mat-option value={{bookStatusEnum.CONFIRMED}}>CONFIRMED</mat-option>
                  <mat-option value={{bookStatusEnum.CHECKEDIN}}>CHECKED IN</mat-option>
                  <mat-option value={{bookStatusEnum.COMPLETED}}>COMPLETED</mat-option>
                  <mat-option value={{bookStatusEnum.RESCHEDULED}}>RESCHEDULED</mat-option>
                  <mat-option value={{bookStatusEnum.CANCELLED}}>CANCELLED</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row mt-2">
            <div>
              <mat-form-field>
                <mat-label>Select Worker Status</mat-label>
                <mat-select [(value)]="selectedWorkerStatus">
                  <mat-option value={{bookStatusEnum.NEW}}>NEW</mat-option>
                  <mat-option value={{bookStatusEnum.CONFIRMED}}>CONFIRMED</mat-option>
                  <mat-option value={{bookStatusEnum.CANCELLED}}>CANCELLED</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!--Status Cancel Note! -->
          <div class="row" *ngIf="selectedStatus == bookStatusEnum.CANCELLED">
            <!--remove from dom here condition TODO-->
            <div>
              <mat-form-field class="text-area-full-width">
                <textarea matInput matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=5 placeholder="Cancel Reason" name="statusCancelReason" [(ngModel)]="statusCancelReason"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div>
              <mat-form-field class="text-area-full-width">
                <textarea matInput matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=5 placeholder="Add Booking Note" name="bookingNote" [(ngModel)]="bookingNote"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col">
          <div>
            <strong>Select Job Demands to be Tested</strong>   
            <div class="alert alert-secondary" style="font-size: 0.9em">Balance and MSK screen are always included.<br/>Stair Climb is required when including Ladder Climb.</div>
            <div class="form-row">
              <mat-checkbox [disabled]="true" [(ngModel)]="checked" [ngModelOptions]="{standalone: true}">MSK</mat-checkbox>
            </div>
            <div class="form-row">
              <mat-checkbox [disabled]="true" [(ngModel)]="checked" [ngModelOptions]="{standalone: true}">Balance</mat-checkbox>
            </div>
            <div style="margin-top: 5px;margin-bottom: 2px;"><a class="small-lnk" (click)="onSelectAll()">Select All</a> / <a class="small-lnk" (click)="onDeSelectAll()">De-select All</a></div>
          </div>
          <!-- <div *ngIf="this.company != null && this.company.jodCompanyId != null" style="font-size: 0.8em;margin-left: 15px; margin-bottom: 10px;"><img src="/assets/jod.png" height="17px"/> Linked to JOD Subscription.
          </div> -->
          <div class="form-row" *ngFor="let demand of demands; let j=index">
            <mat-checkbox *ngIf="demand.demandType.name != 'Balance'" (change)="onChangeDemand($event)" [name]="'demand' + j" [(ngModel)]="demand.selected">{{demand.demandType.name}}</mat-checkbox>
          </div>

        </div>
      </form>
    </div>

    <div class="col">
      <app-worker-view [requestId]="data.pefaId" [provider]="selectedProvider"></app-worker-view>
    </div>
  </div>
</div>

<mat-dialog-actions>
        <button mat-raised-button color="primary" style="margin-top: 1rem;" mat-button (click)="onNoClick(false)">Close</button>
        <button mat-raised-button color="warn" style="margin-top: 1rem;" mat-button (click)="savebooking()">Save</button>
        <div class="alert alert-danger" *ngIf="showHasNoDemands" style="font-size: 0.9em; margin-left: 5px; margin-top: 20px; padding: 8px;">Warning: <strong>Missing Job Demands</strong>.  Please confirm Job is correct.</div>
</mat-dialog-actions>
