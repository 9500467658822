import { FormControl, Validators, FormGroup, FormArray } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, Component, OnInit } from "@angular/core";
import { OfficeAdminPEFAService } from "../office-admin-pefa/office-admin-pefa.service";
import { PEFARequestService } from "../pefarequest/pefarequest.service";
import { MessageService } from "../message/message.service";
import { MedicalClearance } from "../shared/shared-component";
import { SharedService } from "../shared/shared.service";


@Component({
  selector: 'dialog-modal-medical-clearance-letter',
  templateUrl: 'medical-clearance-letter-dialog.component.html',
})
export class MedicalClearanceLetterDialog implements OnInit {

  conditions: MedicalClearance[];

  generating: boolean = false;

  public medicalClearanceForm = new FormGroup({
    id: new FormControl(''),
    clearances: new FormArray([])
  });



  /*
   *
   *
      public long PefaID { get; set; }
      public string InvoiceRef { get; set; }
      public double Amount { get; set; }
      public string Currency { get; set; }
   *
   * 
   */
  constructor(
    public dialogRef: MatDialogRef<MedicalClearanceLetterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pefaService: PEFARequestService, public messageService:MessageService) {
  }



  ngOnInit(): void {
    this.pefaService.getPEFAClearances(this.data.pefaId).subscribe(
      clearance => {
        this.conditions = clearance;


        for (let entry of this.conditions) {
          this.addClearancesForm(entry);
        };
      }, error => console.error(error));
  };

  addClearancesForm(condition: MedicalClearance) {
    if (condition.status == "NEW" || condition.status == "PENDING" ) {
      var clearancesList = this.medicalClearanceForm.get('clearances') as FormArray
      clearancesList.push(
        new FormGroup({
          selected: new FormControl(true),
          id: new FormControl(condition.id),
          condition: new FormControl(condition.condition),
          outcome: new FormControl(condition.outcome),
          otherInfo: new FormControl(condition.otherInfo),
          status: new FormControl(condition.status),
          doctor: new FormGroup({
            id: new FormControl(condition.doctor.id),
            name: new FormControl(condition.doctor.name),
          })
        })

      )
    }


  }

  get clearances() {
    return this.medicalClearanceForm.get('clearances') as FormArray
  }


  onNoClick(yn: boolean): void {
    this.dialogRef.close(yn);
  }

  onSubmit(): void {
    this.medicalClearanceForm.controls.id.setValue(this.data.pefaId);

    var stuff = this.medicalClearanceForm.get('clearances') as FormArray;

    var formData = new FormData();
    for (var i = 0; i < this.clearances.length; i++) {
      // instead of passing this.arrayValues.toString() iterate for each item and append it to form.
      formData = this.convertModelToFormData(this.medicalClearanceForm.value, formData);
      if (this.clearances.at(i).get("selected").value) {

      }
    }

    this.generating = true;

    this.pefaService.generateMedicalClearanceLetter(this.data.pefaId, formData).subscribe(data => {
      SharedService.downloadFile(data);
      this.messageService.add("Medical Clearance Letter Generated");
      this.dialogRef.close();
      this.generating = false;
      }
    );

  }



  public convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    let formData = form || new FormData();
    let formKey;

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertModelToFormData(element, formData, tempFormKey);
        });
      }
      else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
        this.convertModelToFormData(model[propertyName], formData, formKey);
      else
        formData.append(formKey, model[propertyName].toString());
    }
    return formData;
  }
}
