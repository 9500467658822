import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { PrimeIcons } from "primeng/api";
import { map } from "rxjs/operators";
import { MessageService } from "../../message/message.service";
import { PEFARequestService } from "../../pefarequest/pefarequest.service";
import { ConfirmDialog } from "../confirm-dialog.component";
import { JOBFIT_DATETIME_FORMAT, NoteView, PEFARequest } from "../shared-component";
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-note-view-attachment',
  templateUrl: './note-view-attachment.component.html'
})

export class NoteViewAttachmentComponent {
  public readonly dateFormat = JOBFIT_DATETIME_FORMAT;

  @Input()
  noteView: NoteView;

  @Input()
  requestID: string;

  @Output() changed = new EventEmitter<boolean>();

  constructor(public pefaService: PEFARequestService, public dialog: MatDialog) { }

  public downloadAttachment() {

    this.pefaService.downloadAttachment(this.noteView.id).subscribe(data => {

      SharedService.downloadFile(data);
    })


  }


  public deleteAttachment() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: { message: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.outcome) {

        this.pefaService.deleteAttachment(this.requestID, this.noteView.id, result.message).subscribe(() => {
          this.changed.emit(true);
        });
      }
    });


  }

}
