<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Payment {{noteView.details.invoiceAmount | currency}} by {{noteView.details.paymentType}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  
  <mat-card>
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-label>
              Invoice Reference
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.invoiceReference}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Invoice Date
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.invoiceDate}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Payment Reference
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.paymentReference}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Other Info
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.otherInfo}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-label>
              By
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdBy?.userName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-label>
              Created Date
            </mat-label>
          </div>
          <div class="col">
            <span>{{noteView.details.audit?.createdDate | date:dateFormat}}</span>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>

</mat-expansion-panel>
