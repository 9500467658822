<ng-template #NoCompanyToList>
  <p *ngIf="loading == false">There are no companies</p>
</ng-template>



<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>


<div class="container-fluid" *ngIf="companies.length > 0; else NoCompanyToList">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{ row.name }}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let row"><span>{{row.type}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let row"><button mat-raised-button color="primary" style="margin-top: 1rem;" (click)="selectCompany(row)">Select</button></mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

</div>
<mat-paginator [pageSizeOptions]="[10, 25]"></mat-paginator>
